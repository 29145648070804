import React, { Component } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import Loader from "../Loader/loader.jsx";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

const development = [
  { value: "property_developer", label: "Property developer" },
  { value: "building_management", label: "Building management" },
  { value: "project_management", label: "Project manager (construction)" },
  { value: "brokerage_real_estate", label: "Brokerage or Agent (Real estate)" },
  {
    value: "other_development",
    label: "Other dedicated firms in development or management",
  },
];

const designAndEngineering = [
  {
    value: "architecture_or_interior_design",
    label: "Architecture or Interior design",
  },
  { value: "mep_engineering", label: "MEP Engineering (design)" },
  { value: "structural_engineering", label: "Structural Engineering (design)" },
  { value: "expediting", label: "Expediting firm/agency" },
  {
    value: "other_design_and_engineering",
    label: "Other dedicated firms in design or approvals",
  },
];

const gc_construction = [
  {
    value: "construction_superintendant",
    label: "Construction superintendant",
  },
  { value: "general_contractor", label: "General contractor" },
  { value: "subcontractor", label: "Subcontractor" },
  { value: "builder", label: "Builder" },
  {
    value: "other_gc_contruction",
    label: "Other dedicated firms in construction and demolition",
  },
];

const groupedOptions = [
  {
    label: "Development, Management or Brokerage",
    options: development,
  },
  {
    label: "Design, Engineering & Expediting related firms",
    options: designAndEngineering,
  },
  {
    label: "G.C., Construction & Demolition related firms",
    options: gc_construction,
  },
];

const formatGroupLabel = (data) => (
  <div
    style={{
      width: "100%",
      backgroundColor: "black",
      padding: "5px 0px",
      display: "flex",
      margin: 0,
    }}
  >
    <span className="has-text-grey-light is-size-5">{data.label}</span>
  </div>
);

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      maxSteps: 2,
      selectedMetroAreas: [],
      customerTypeError: false,
      customerNameError: false,
      promotiveFirms: null,
      otherPromotiveFirm: "",
      corporateCustomer: "",
      individualOwners: null,
      otherCompanies: "",
      companyOrOwnerName: "",
      firstName: "",
      lastName: "",
      zipCode: "",
      email: "",
      showError: false,
      errorMessage: "",
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentDidMount() {
    const { isSpLoggedIn, isUserLoggedIn } = this.props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      this.props.history.push("/");
    }
  }

  nextStep = () => {
    const { currentStep, maxSteps } = this.state;
    if (currentStep < maxSteps) {
      if (currentStep === 0) {
        if (this.validateSelectedMetroAreas()) {
          this.setState({
            currentStep: Number(currentStep) + 1,
          });
        }
      }
      if (currentStep === 1) {
        if (this.validateCompanyType()) {
          this.setState({
            currentStep: Number(currentStep) + 1,
          });
        }
      }
    }
  };

  previousStep = () => {
    const { currentStep } = this.state;
    if (currentStep === 2) {
      this.setState({
        promotiveFirms: null,
        otherPromotiveFirm: "",
        corporateCustomer: "",
        individualOwners: null,
        otherCompanies: "",
      });
    }
    if (currentStep > 0) {
      this.setState({
        currentStep: Number(currentStep) - 1,
      });
    }
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  validatePersonalInformation = () => {
    let companyNameValidation = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let zipCodeValidation = /^\d{5}$/;
    const {
      companyOrOwnerName,
      zipCode,
      firstName,
      lastName,
      email,
    } = this.state;
    if (companyOrOwnerName === "") {
      this.showError("Please enter Company / individual owner name");
      return false;
    }
    if (!companyNameValidation.test(companyOrOwnerName.trim())) {
      this.showError("Please enter a valid Company / individual owner name");
      return false;
    }
    if (zipCode === "") {
      this.showError("Please enter zip code");
      return false;
    }
    if (!zipCodeValidation.test(zipCode)) {
      this.showError("Please enter a valid zip code");
      return false;
    }
    if (firstName === "") {
      this.showError("Please enter name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    this.clearError();
    return true;
  };

  validateCompanyType = () => {
    const {
      promotiveFirms,
      otherPromotiveFirm,
      corporateCustomer,
      individualOwners,
      otherCompanies,
    } = this.state;
    if (
      promotiveFirms === null &&
      corporateCustomer === "" &&
      individualOwners === null &&
      otherCompanies === ""
    ) {
      this.setState({
        customerTypeError: true,
      });
      this.showError(
        "Please tell us whether you are a Multifamily development related firms / Corporate / Indiviual / Other"
      );
      return false;
    }
    if (
      promotiveFirms !== null &&
      promotiveFirms.value.includes("other") &&
      otherPromotiveFirm === ""
    ) {
      this.setState({
        customerTypeError: true,
      });
      this.showError("Please enter your firm type");
      return false;
    } else {
      this.setState({
        customerTypeError: false,
      });
    }
    this.clearError();
    return true;
  };

  validateSelectedMetroAreas = () => {
    const { selectedMetroAreas } = this.state;
    if (selectedMetroAreas.length > 0) {
      this.clearError();
      return true;
    }
    this.showError("Please choose a metropolitan area");
    return false;
  };

  handleChange = (event) => {
    if (event.target.name === "zipCode") {
      let zipCodeRegex = /^[0-9]{0,5}$/;
      if (zipCodeRegex.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleSelectChange = (name) => (value) => {
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxClick = (name) => {
    const selection = this.state[name];
    const { selectedMetroAreas } = this.state;
    if (selection === true) {
      this.setState({
        [name]: false,
        selectedMetroAreas: [
          ...selectedMetroAreas.filter((item) => item !== name),
        ],
      });
    } else {
      this.setState({
        [name]: true,
        selectedMetroAreas: [...selectedMetroAreas, name],
      });
    }
  };

  handleSubmit = () => {
    if (this.validatePersonalInformation()) {
      const {
        selectedMetroAreas,
        promotiveFirms,
        corporateCustomer,
        individualOwners,
        otherCompanies,
        otherPromotiveFirm,
        companyOrOwnerName,
        zipCode,
        firstName,
        lastName,
        email,
      } = this.state;
      let customer_type = null;
      if (promotiveFirms !== null && !promotiveFirms.label.includes("other")) {
        customer_type = promotiveFirms.value;
      }
      if (promotiveFirms !== null && promotiveFirms.label.includes("other")) {
        customer_type = otherPromotiveFirm;
      }
      if (corporateCustomer !== "") {
        customer_type = corporateCustomer;
      }
      if (individualOwners !== null) {
        customer_type = individualOwners.value;
      }
      if (otherCompanies !== "") {
        customer_type = otherCompanies;
      }
      const body = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        zip_code: zipCode,
        email: email.trim(),
        metropolitan_area: selectedMetroAreas,
        customer_type: customer_type.trim(),
        company_name: companyOrOwnerName.trim(),
      };
      this.startLoader();
      axios
        .post(`${BASE_URL}/potential-user/customer`, body, {
          withCredentials: true,
        })
        .then(() => {
          this.stopLoader();
          this.props.history.push("/customer/thanks");
        })
        .catch((error) => {
          this.stopLoader();
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
          console.error(error.response);
        });
    }
  };

  renderFormB1() {
    return (
      <React.Fragment>
        <div className="form-main">
          <div className="form-title">
            <h3>Find service providers</h3>
            <p>
              Find service providers with the level of expertise you need, in
              your area, legal, marketing, insurance, brokerage, design,
              engineering, expediting, demolition, construction, installation,
              maintenance, cleaning, everyone.
            </p>
          </div>

          <div className="metropolitan-area">
            <h4 className="area-title">Select your Metropolitan Area:</h4>
            <div className="area-names">
              <ul>
                <li>
                  <div onClick={() => this.handleCheckboxClick("New York")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["New York"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>New York Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Los Angeles")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Los Angeles"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Los Angeles Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Chicago")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Chicago"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Chicago Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Dallas")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Dallas"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Dallas Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Houston")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Houston"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Houston Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Washington")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Washington"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Washington Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Miami")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Miami"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Miami Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Philadelphia")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Philadelphia"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Philadelphia Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Atlanta")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Atlanta"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Atlanta Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Boston")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Boston"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Boston Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Phoenix")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Phoenix"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Phoenix Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Seattle")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Seattle"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Seattle Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Denver")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Denver"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Denver Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Austin")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Austin"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Austin Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => this.handleCheckboxClick("San Francisco")}
                  >
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["San Francisco"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>San Francisco Metro
                      Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Orlando")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Orlando"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Orlando Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Tampa")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Tampa"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Tampa Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Charlotte")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Charlotte"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Charlotte Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Detroit")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Detroit"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Detroit Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("San Antonio")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["San Antonio"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>San Antonio Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Portland")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Portland"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Portland Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Sacramento")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Sacramento"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Sacramento Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div onClick={() => this.handleCheckboxClick("Las Vegas")}>
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={this.state["Las Vegas"] === true}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Las Vegas Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() =>
                      this.handleCheckboxClick("Riverside-san Bernardino")
                    }
                  >
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={
                          this.state["Riverside-san Bernardino"] === true
                        }
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Riverside-san
                      Bernardino Metro Area
                    </label>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() =>
                      this.handleCheckboxClick(
                        "Raleigh-Durham Research Triangle"
                      )
                    }
                  >
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => {}}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={
                          this.state["Raleigh-Durham Research Triangle"] ===
                          true
                        }
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Raleigh-Durham Research
                      Triangle Metro Area
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className="next-btn">
              <button
                type="button"
                onClick={this.nextStep}
                className="next-butn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFormB2Part1() {
    const {
      promotiveFirms,
      corporateCustomer,
      individualOwners,
      otherCompanies,
      otherPromotiveFirm,
    } = this.state;
    return (
      <React.Fragment>
        <div className="form-main select-area-form">
          <div className="back-btn">
            <button className="back-butn" onClick={this.previousStep}>
              <i className="fas fa-arrow-left"></i>Back
            </button>
          </div>

          <div className="form-fields">
            <form>
              {corporateCustomer !== "" ||
              individualOwners !== null ||
              otherCompanies !== "" ? null : (
                <div className="form-group">
                  <label>Multifamily development related firms:</label>
                  <Select
                    styles={{
                      groupHeading: (base) => ({
                        ...base,
                        flex: "1 1",
                        color: "white",
                        backgroundColor: "black",
                        margin: 0,
                        paddingTop: 0,
                      }),
                      control: (base) => ({
                        ...base,
                        background: "#fcfcfc none repeat scroll 0 0",
                        border: "1px solid #ececec",
                      }),
                    }}
                    value={promotiveFirms}
                    isClearable={true}
                    onChange={this.handleSelectChange("promotiveFirms")}
                    name="promotiveFirms"
                    id="promotiveFirms"
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      spacing: {
                        baseUnit: 4.84,
                        controlHeight: 46,
                        menuGutter: 9.68,
                      },
                    })}
                  />
                </div>
              )}
              {promotiveFirms !== null &&
                promotiveFirms.value.includes("other") && (
                  <div className="form-group">
                    <label>Other:</label>
                    <div className="control">
                      <input
                        className="form-control"
                        type="text"
                        name="otherPromotiveFirm"
                        id="otherPromotiveFirm"
                        value={otherPromotiveFirm}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                )}
              {promotiveFirms !== null ||
              individualOwners !== null ||
              otherCompanies !== "" ? null : (
                <div className="form-group">
                  <label>Corporate customer (3+ projects/Yr.):</label>
                  <input
                    type="text"
                    name="corporateCustomer"
                    id="corporateCustomer"
                    value={corporateCustomer}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
              {promotiveFirms !== null ||
              corporateCustomer !== "" ||
              otherCompanies !== "" ? null : (
                <div className="form-group">
                  <label>Individual owners:</label>
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        background: "#fcfcfc none repeat scroll 0 0",
                        border: "1px solid #ececec",
                      }),
                    }}
                    value={individualOwners}
                    isClearable={true}
                    onChange={this.handleSelectChange("individualOwners")}
                    name="individualOwners"
                    id="individualOwners"
                    options={[
                      { label: "Small Business", value: "Small Business" },
                      { label: "Appartment Owner", value: "Appartment Owner" },
                    ]}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      spacing: {
                        baseUnit: 4.84,
                        controlHeight: 46,
                        menuGutter: 9.68,
                      },
                    })}
                  />
                </div>
              )}
              {promotiveFirms !== null ||
              corporateCustomer !== "" ||
              individualOwners !== null ? null : (
                <div className="form-group">
                  <label>All other companies:</label>
                  <input
                    id="otherCompanies"
                    name="otherCompanies"
                    value={otherCompanies}
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              )}
              <div className="next-btn">
                <button
                  type="button"
                  onClick={this.nextStep}
                  className="next-butn"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFormB2Part2() {
    const {
      companyOrOwnerName,
      firstName,
      lastName,
      zipCode,
      email,
    } = this.state;
    return (
      <React.Fragment>
        <div className="form-main select-area-form">
          <div className="back-btn">
            <button className="back-butn" onClick={this.previousStep}>
              <i className="fas fa-arrow-left"></i>Back
            </button>
          </div>

          <div className="form-fields">
            <form>
              <div className="form-group">
                <label>Company / individual owner name:</label>
                <input
                  id="companyOrOwnerName"
                  name="companyOrOwnerName"
                  value={companyOrOwnerName}
                  onChange={this.handleChange}
                  type="text"
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Zip Code:</label>
                <input
                  type="text"
                  required
                  name="zipCode"
                  value={zipCode}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>First Name:</label>
                <input
                  type="text"
                  required
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Last Name:</label>
                <input
                  type="text"
                  required
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  required
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="next-btn">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="next-butn"
                >
                  submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { currentStep, showError, errorMessage, showLoader } = this.state;
    const {
      isUserLoggedIn,
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal small-popup error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            // childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <section className="form-wrapper select-area-main">
          <div className="form-inner">
            {currentStep === 0
              ? this.renderFormB1()
              : currentStep === 1
              ? this.renderFormB2Part1()
              : this.renderFormB2Part2()}
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

export default withRouter(Request);
