import React, { useState, useEffect } from "react";
import Styles from "./VendorDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import { BASE_URL } from "../../../Constants/serverConfig";
import { useToasts } from "react-toast-notifications";

const ShowProfile = (props) => {
  const {
    selectedProfile,
    setSelectedProfile,
    vendorUpdated,
    token,
    // serviceProvider,
  } = props;
  const [updatedSelectedProfile, setUpdatedSelectedProfile] = useState({
    ...selectedProfile,
  });
  const { addToast } = useToasts();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [disableUpdate, setDisableUpdate] = useState(false);

  useEffect(() => {
    const name = updatedSelectedProfile.receiver_name.split(" ");
    setFirstName(name[0]);
    setLastName(name[1]);
    setEmail(updatedSelectedProfile.receiver_email);
  }, [updatedSelectedProfile]);

  const handleFileUpload = (event) => {
    if (token !== null) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      // axios
      //   .patch(`${BASE_URL}/account/profile-image`, formData, {
      //     headers: {
      //       authorization: `Bearer ${token}`,
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((response) => {
      //     console.log("picture url = ", [...response.data.profile_image]);
      //     console.log("check = ", {
      //       ...selectedProfile,
      //       user_id: {
      //         ...selectedProfile.user_id,
      //         profile_image: [...response.data.profile_image],
      //       },
      //     });
      //     setUpdatedSelectedProfile({
      //       ...selectedProfile,
      //       user_id: {
      //         ...selectedProfile.user_id,
      //         profile_image: response.data.profile_image,
      //       },
      //     });
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }
  };

  const updateUserInfo = (accountId) => {
    // setDisableUpdate(true);
    console.log("accountId === ", accountId);
    if (email !== updatedSelectedProfile.receiver_email) {
      addToast(
        `An email will be sent to ${email} so you can log in to Propagile`
      );
    }
    const DATA = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      street_address: !!streetAddress ? streetAddress : "Dummy Address",
      city: !!city ? city : "Dummy city",
      state: !!state ? state : "Dummy state",
      zip_code: !!zipCode ? zipCode : "Dummy Zip",
    };
    console.log("update = ", updatedSelectedProfile);
    console.log("DATA = ", DATA);
    axios
      .patch(
        `https://api.propagile.com/api/v1/license-management/inactive/account/${accountId}`,
        DATA,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          vendorUpdated();
          setSelectedProfile(null);
          setDisableUpdate(false);
        }
      })
      .catch((err) => {
        console.error(
          "Something went wrong while de-activating a licence: ",
          err
        );
        vendorUpdated(false);
        setDisableUpdate(false);
        addToast("Could not update vendor details.", {
          appearance: "error",
        });
      });
  };

  /*   const onChangeInfo = (event) => {
    const { name, value } = event.target;
    let tempVendor = {
      ...updatedSelectedProfile,
      user_id: { ...updatedSelectedProfile.user_id },
    };
    tempVendor.user_id[name] = value;
    setUpdatedSelectedProfile({
      ...tempVendor,
      user_id: { ...tempVendor.user_id },
    });
  }; */

  return (
    <div className="modal is-active custom-modal success-message">
      <div className="modal-background" />
      <div
        className="modal-content"
        style={{ margin: 0, maxWidth: "none", width: "80%" }}
      >
        <div
          className="success-message-modal"
          style={{
            height: "auto",
            borderRadius: "10px",
            maxWidth: "none",
            backgroundColor: "white",
            border: "none",
            padding: "1% 10%",
          }}
        >
          <div style={{ paddingRight: "0.75em" }}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                height: "25px",
                width: "25px",
                marginLeft: "100%",
                paddingLeft: "9%",
                paddingTop: "0",
                cursor: "pointer",
              }}
              onClick={() => setSelectedProfile(null)}
            />
          </div>
          <div className={Styles.vendorProfile}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={Styles.vendorProfileLabels}>
                Vendor First Name
              </div>
              <input
                className={Styles.vendorInputField}
                type="text"
                name="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // value={updatedSelectedProfile.user_id.first_name}
                // onChange={(e) => onChangeInfo(e)}
              />
              <div className={Styles.vendorProfileLabels}>Vendor Last Name</div>
              <input
                className={Styles.vendorInputField}
                type="text"
                name="last_name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                // value={updatedSelectedProfile.user_id.last_name}
                // onChange={(e) => onChangeInfo(e)}
              />
              <div className={Styles.vendorProfileLabels}>Vendor Email</div>
              <input
                className={Styles.vendorInputField}
                type="text"
                name="email_address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // value={updatedSelectedProfile.user_id.email_address}
                // onChange={(e) => onChangeInfo(e)}
                /* disabled={
                  serviceProvider.id === selectedProfile.owner_id ? false : true
                } */
              />
            </div>
            {/* <img
              className={Styles.vendorProfilePicture}
              alt={"Picture not found"}
              src={
                !!selectedProfile &&
                !!selectedProfile.user_id &&
                !!selectedProfile.user_id.profile_image.length
                  ? selectedProfile.user_id.profile_image[0]
                  : null
              }
            /> */}
            <div className="pro-pic hidden-767">
              <div className="dashboard-pic is-280x280">
                <figure
                  style={{
                    backgroundImage: `url(${
                      !!selectedProfile &&
                      !!selectedProfile.user_id &&
                      !!selectedProfile.user_id.profile_image.length
                        ? selectedProfile.user_id.profile_image[0]
                        : ""
                    })`,
                  }}
                ></figure>
                <div className="image">
                  <div className="pic-btn">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="profile-picture-desktop"
                      name="profile-picture"
                      type="file"
                      onChange={handleFileUpload}
                    />
                    <label htmlFor="profile-picture-desktop">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* --- end-uploading- picture */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={Styles.vendorProfileLabels}>Vendor Address</div>
            <input
              className={Styles.vendorInputField}
              style={{ width: "80%" }}
              type="text"
              name="streetAddress"
              placeholder="Street Address"
              onChange={(e) => setStreetAddress(e.target.value)}
              // value={selectedProfile.user_id.last_name}
              // readOnly
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={Styles.vendorProfileLabels}>City</div>
              <input
                className={Styles.vendorInputField}
                type="text"
                name="city"
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
                // value={selectedProfile.user_id.last_name}
                // readOnly
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={Styles.vendorProfileLabels}>State</div>
              <input
                className={Styles.vendorInputField}
                type="text"
                name="state"
                placeholder={"State"}
                onChange={(e) => setState(e.target.value)}
                // value={selectedProfile.user_id.last_name}
                // readOnly
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={Styles.vendorProfileLabels}>Zip Code</div>
              <input
                className={Styles.vendorInputField}
                type="text"
                name="zipCode"
                placeholder="ZipCode"
                onChange={(e) => setZipCode(e.target.value)}
                // value={selectedProfile.user_id.last_name}
                // readOnly
              />
            </div>
          </div>
          <button
            style={{
              alignItems: "start",
              marginBottom: "1em",
              cursor: "pointer",
              opacity: !!disableUpdate ? "0.5" : "1",
            }}
            onClick={() =>
              !!disableUpdate ? {} : updateUserInfo(selectedProfile.id)
            }
            className="btn-fill"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowProfile;
