import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import { IMAGE_TYPES } from "../../Constants/utils";
import { BASE_URL } from "../../Constants/serverConfig";
import Loader from "../Loader/loader";

const PortfolioManagement = () => {
  const token = useSelector((state) => state.token.token);

  //State
  const [
    showPictureDeleteConfirmation,
    setShowPictureDeleteConfirmation,
  ] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [alreadyAddedPortfolios, setAlreadyAddedPortfolios] = useState([]);
  const [files, setFiles] = useState([]);
  const [portfolioFiles, setPortfolioFiles] = useState([]);
  const [selectedPictureDeleteIndex, setSelectedPictureDeleteIndex] = useState(
    -1
  );
  const [portfolioPicturesToDelete, setPortfolioPicturesToDelete] = useState(
    []
  );
  const [selectedTypeToDelete, setSelectedTypeToDelete] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [
    showPortfolioDeleteConfirmation,
    setShowPortfolioDeleteConfirmation,
  ] = useState(false);

  useEffect(() => {
    getLatestPortfolios();
  }, []);

  const getLatestPortfolios = () => {
    if (token !== null) {
      setShowLoader(true);
      axios
        .get(`${BASE_URL}/template/portfolio`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          setShowLoader(false);
          if (response.status === 200) {
            setAlreadyAddedPortfolios(response.data);
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.error(error.response);
        });
    }
  };

  const displayError = (message) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const displaySuccess = (message) => {
    setMessage(message);
    setShowSuccess(true);
  };

  const clearMessage = () => {
    setErrorMessage("");
    setShowError(false);
    setMessage("");
    setShowSuccess(false);
  };

  const showPictureDeleteConfirmationDialog = (index, image_type) => {
    setShowPictureDeleteConfirmation(true);
    setSelectedPictureDeleteIndex(index);
    setSelectedTypeToDelete(image_type);
  };

  const clearConfirmation = () => {
    setShowPictureDeleteConfirmation(false);
    setSelectedPictureDeleteIndex(-1);
    setSelectedTypeToDelete("");
    setShowPortfolioDeleteConfirmation(false);
  };

  const deletePicture = () => {
    if (selectedTypeToDelete === IMAGE_TYPES.PORTFOLIO_IMAGE_UPLOADED) {
      removePortfolioPicture();
      clearConfirmation();
    } else {
      removePicture();
      clearConfirmation();
    }
  };

  const removePicture = () => {
    let newFiles = [...files];
    newFiles.splice(selectedPictureDeleteIndex, 1);
    newFiles = newFiles.filter((x) => x !== undefined);
    setFiles([...newFiles]);
  };

  const removePortfolioPicture = () => {
    let newPortfolioFiles = [...portfolioFiles];
    newPortfolioFiles.splice(selectedPictureDeleteIndex, 1);
    setPortfolioPicturesToDelete([
      ...portfolioPicturesToDelete,
      portfolioFiles[selectedPictureDeleteIndex],
    ]);
    setPortfolioFiles([...newPortfolioFiles]);
  };

  const removePortfolioPictureFromServer = (fileName) => {
    if (token !== null) {
      setShowLoader(true);
      axios({
        method: "delete",
        url: `${BASE_URL}/template/file`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          template_id: selectedPortfolio.id,
          file_name: fileName,
        },
      })
        .then(() => {
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
          console.error(error);
        });
    }
  };

  const savePortfolio = async () => {
    if (alreadyAddedPortfolios.length >= 10) {
      displayError(
        "You can add only 10 predefined portfolios. Please choose an existing or remove existing portfolio."
      );
      return;
    }
    if (files.length === 0 && portfolioPicturesToDelete.length === 0) {
      displayError("Please make some changes to save the portfolio.");
      return;
    }
    let options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    setShowLoader(true);
    for (let i = 0; i < files.length; i++) {
      if (files[i] !== undefined) {
        const imageFile = files[i];
        if (imageFile.size / 1024 / 1024 > 3) {
          const compressedFile = await imageCompression(imageFile, options);
          files[i] = compressedFile;
        }
      }
    }
    setShowLoader(false);
    const formData = getFormData(
      "Test",
      "Lorem Ipsum",
      selectedPortfolio,
      files
    );
    setShowLoader(true);
    axios
      .post(`${BASE_URL}/template/portfolio`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setShowLoader(false);
        if (response.status === 200) {
          displaySuccess("Portfolio saved successfully.");
          setFiles([]);
          getLatestPortfolios();
          setPortfolioPicturesToDelete([]);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.response && typeof error.response.data === "string") {
          displayError(error.response.data);
        }
        console.error(error);
      });
  };

  const updatePortfolio = async () => {
    if (files.length === 0 && portfolioPicturesToDelete.length === 0) {
      displayError(
        "No new changes found in portfolio to edit, please make changes to this portfolio then click save"
      );
      return;
    }
    let options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    setShowLoader(true);
    for (let i = 0; i < files.length; i++) {
      if (files[i] !== undefined) {
        const imageFile = files[i];
        if (imageFile.size / 1024 / 1024 > 3) {
          const compressedFile = await imageCompression(imageFile, options);
          files[i] = compressedFile;
        }
      }
    }
    setShowLoader(false);
    for (let i = 0; i < portfolioPicturesToDelete.length; i++) {
      console.log(portfolioPicturesToDelete[i]);
      removePortfolioPictureFromServer(portfolioPicturesToDelete[i].fileName);
    }
    const formData = getFormData(
      "Test",
      "Lorem Ipsum bjabjhabjh gahvchab cas jghjbabch",
      null,
      files
    );
    setShowLoader(true);
    axios
      .patch(
        `${BASE_URL}/template/portfolio/${selectedPortfolio.id}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setShowLoader(false);
        if (response.status === 200) {
          displaySuccess("Portfolio updated successfully.");
          setFiles([]);
          setPortfolioFiles([]);
          setSelectedPortfolio(null);
          setAlreadyAddedPortfolios([]);
          getLatestPortfolios();
          setPortfolioPicturesToDelete([]);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.response && typeof error.response.data === "string") {
          displayError(error.response.data);
        }
        console.error(error);
      });
  };

  const deletePortfolio = () => {
    setShowLoader(true);
    axios
      .delete(`${BASE_URL}/template/portfolio/${selectedPortfolio.id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setShowLoader(false);
        setFiles([]);
        setPortfolioFiles([]);
        setSelectedPortfolio(null);
        setShowPortfolioDeleteConfirmation(false);
        getLatestPortfolios();
        setPortfolioPicturesToDelete([]);
        setTimeout(() => {
          displaySuccess("Portfolio has been deleted successfully");
        }, 200);
      })
      .catch((error) => {
        console.error(error);
        setShowLoader(false);
        displayError("Failed to delete the saved portfolio.");
      });
  };

  const getFormData = (name, description, selectedPortfolio, files) => {
    let alreadyAddedImages = [];
    let bodyFormData = new FormData();
    bodyFormData.set("name", name);
    bodyFormData.set("description", description);
    if (selectedPortfolio !== null) {
      for (let x = 0; x < selectedPortfolio.files.length; x++) {
        alreadyAddedImages.push(selectedPortfolio.files[x].path);
      }
      bodyFormData.append("portfolio_path", JSON.stringify(alreadyAddedImages));
    }
    for (let x = 0; x < files.length; x++) {
      if (files[x] !== undefined) {
        bodyFormData.append("files", files[x]);
      }
    }
    return bodyFormData;
  };

  const selectPortfolio = (event) => {
    let portfolio = alreadyAddedPortfolios.find(
      (x) => x.id === Number(event.target.value)
    );
    if (
      portfolio &&
      selectedPortfolio &&
      selectedPortfolio !== null &&
      portfolio.id !== selectedPortfolio.id &&
      (files.length > 0 || portfolioPicturesToDelete.length > 0)
    ) {
      if (
        !window.confirm(
          "You have made some changes but not saved, unsaved changes will be lost."
        )
      ) {
        return;
      } else {
        setPortfolioPicturesToDelete([]);
        setFiles([]);
      }
    }
    if (portfolio) {
      setSelectedPortfolio(portfolio);
      setPortfolioFiles(portfolio.files);
      setFiles([]);
    } else {
      clearPortfolioSelection();
    }
  };

  const clearPortfolioSelection = () => {
    setSelectedPortfolio(null);
    setPortfolioFiles([]);
    setFiles([]);
  };

  const handleFilesUpload = async (index, event) => {
    let iteratorIndex = index;
    let uploadFiles = [...files];
    let totalPictures =
      uploadFiles.filter((x) => x !== undefined).length +
      portfolioFiles.length +
      event.target.files.length;
    if (totalPictures > 6) {
      displayError("Cannot upload more than 6 pictures");
    } else {
      let newUploadedFiles = [...event.target.files];
      let areFilesValid = true;
      for (let i = 0; i < newUploadedFiles.length; i++) {
        const fileType = newUploadedFiles[i]["type"];
        const validImageTypes = [
          "image/gif",
          "image/jpeg",
          "image/png",
          "video/mp4",
        ];
        if (!validImageTypes.includes(fileType)) {
          areFilesValid = false;
          return;
        } else {
          if (iteratorIndex > 5) {
            iteratorIndex = 0;
          }
          uploadFiles[iteratorIndex] = newUploadedFiles[i];
          iteratorIndex++;
        }
      }
      if (!areFilesValid) {
        displayError(
          "You have uploaded an invalid format file. Please upload gif, jpeg or png image file"
        );
      } else {
        setFiles(uploadFiles);
      }
    }
    await setTimeout(null, 50);
  };

  const renderImages = () => {
    let html = [];
    for (let i = 0; i < 6; i++) {
      let file = files[i];
      let portfolioItem = portfolioFiles[i];
      if (portfolioItem) {
        html.push(
          <div
            key={`already-uploaded-${i}`}
            className="portfolio_images clearfix"
          >
            <div
              className="img_thumbnail"
              style={{
                backgroundImage: `url(${portfolioItem.path})`,
              }}
            >
              <span
                onClick={() =>
                  showPictureDeleteConfirmationDialog(
                    i,
                    IMAGE_TYPES.PORTFOLIO_IMAGE_UPLOADED
                  )
                }
              >
                <i className="fas fa-times" />
              </span>
            </div>
          </div>
        );
      } else if (file) {
        html.push(
          <div key={`new-uploaded-${i}`} className="portfolio_images clearfix">
            <div
              className="img_thumbnail"
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
              }}
            >
              <span
                onClick={() =>
                  showPictureDeleteConfirmationDialog(
                    i,
                    IMAGE_TYPES.SERVICE_IMAGE_NEW
                  )
                }
              >
                <i className="fas fa-times" />
              </span>
            </div>
          </div>
        );
      } else {
        html.push(
          <div key={`not-uploaded-${i}`} className="portfolio_images clearfix">
            <div
              className="img_thumbnail"
              style={{
                backgroundColor: "#E2ECED",
              }}
            >
              <div className="file-upload-new">
                <img
                  src={require("../../assets/images/upload-icon.png")}
                  alt="Upload Icon"
                />
                <input
                  multiple={true}
                  type="file"
                  onChange={(event) => handleFilesUpload(i, event)}
                  name="files"
                  accept="image/jpeg,image/gif,image/png"
                />
              </div>
            </div>
          </div>
        );
      }
    }
    return html;
  };

  return (
    <div>
      <Loader text="Loading..." open={showLoader} />
      <div
        className="services_main_body three_A_image"
        style={{ minHeight: "80vh" }}
      >
        <div className="container">
          <div className="s_m_b_head  clearfix">
            <span className="s_m_1">Portfolio management</span>{" "}
            <span className="s_m_3">
              manage your portfolios to assign portfolio to your active services
            </span>
          </div>
          <div className="clearfix" />
          <div className="prop_3A_updated">
            <div className="portfolio_main_imgs clearfix">{renderImages()}</div>
            <div className="clearfix further_options">
              <div className="select">
                <select
                  value={selectedPortfolio ? selectedPortfolio.id : ""}
                  onChange={selectPortfolio}
                >
                  {alreadyAddedPortfolios.length > 0 ? (
                    <option>Choose a portfolio</option>
                  ) : null}
                  {alreadyAddedPortfolios.length > 0 ? (
                    alreadyAddedPortfolios.map((x, index) => {
                      return (
                        <option key={x.id} value={x.id}>
                          Portfolio-{index + 1}
                        </option>
                      );
                    })
                  ) : (
                    <option>No Portfilio added</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="clearfix new_sep_dashboard_body">
            <div>
              <p className="has-text-weight-bold">
                Services associated with this portfolio, any changes to this
                portfolio will cause effects in following services
              </p>
              <div className="segment_steps_body clearfix">
                {selectedPortfolio === null ? (
                  <p>
                    You have not selected any portfolio yet. The services
                    associated with the portfolio will appear here.
                  </p>
                ) : selectedPortfolio.associated_services.length > 0 ? (
                  selectedPortfolio.associated_services.map((x) => (
                    <div key={`service-${x.id}`} className="popup-item-boxes">
                      <div className="tbl">
                        <div
                          onClick={(event) => event.preventDefault()}
                          className="tbl-cell md_ch_2"
                        >
                          <div className="tbl">
                            <div className="tbl-cell">
                              {x.label_to_show.split("_")[1]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>
                    No Service(s) is currently associated with this portfolio
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="clearfix select_line_services_opt">
            {selectedPortfolio === null ? (
              <a onClick={savePortfolio} className="select_line_services_opt_a">
                Save
              </a>
            ) : (
              <>
                <a
                  onClick={savePortfolio}
                  className="select_line_services_opt_a"
                >
                  Save as new
                </a>
                <a
                  onClick={updatePortfolio}
                  className="select_line_services_opt_a"
                  style={{ marginLeft: 10 }}
                >
                  Save changes
                </a>
                <a
                  onClick={() => {
                    setShowPortfolioDeleteConfirmation(true);
                  }}
                  className="select_line_services_opt_a"
                  style={{ marginLeft: 10 }}
                >
                  Delete
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {showError && (
        <div className="modal is-active custom-modal error-message">
          <div className="modal-background" />
          <div className="modal-content">
            <div className="error-message-modal">
              <button onClick={clearMessage} className="modal-close" />
              <p>{errorMessage}</p>
            </div>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className="modal is-active custom-modal success-message">
          <div className="modal-background" />
          <div className="modal-content">
            <div className="success-message-modal">
              <button onClick={clearMessage} className="modal-close" />
              <p>{message}</p>
              <a onClick={clearMessage} className="btn-fill">
                OK
              </a>
            </div>
          </div>
        </div>
      )}
      {showPictureDeleteConfirmation && (
        <div className="modal is-active custom-modal error-message">
          <div className="modal-background" />
          <div className="modal-content">
            <div className="error-message-modal">
              <button onClick={clearConfirmation} className="modal-close" />
              <p>Are you sure to delete this picture?</p>
              <div className="map-buton" style={{ float: "none" }}>
                <button onClick={deletePicture} className="btn-fill">
                  Delete
                </button>
                <button
                  onClick={clearConfirmation}
                  className="btn-empty"
                  style={{
                    backgroundColor: "#fcc75c " /* #f9b62f */,
                    color: "#fff",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPortfolioDeleteConfirmation && (
        <div className="modal is-active custom-modal error-message">
          <div className="modal-background" />
          <div className="modal-content">
            <div className="error-message-modal">
              <button onClick={clearConfirmation} className="modal-close" />
              <p>
                Deleting this portfolio will cause effects in all services
                associated with it. Are you sure to delete this portfolio?
              </p>
              <div className="map-buton" style={{ float: "none" }}>
                <button onClick={deletePortfolio} className="btn-fill">
                  Delete
                </button>
                <button
                  onClick={clearConfirmation}
                  className="btn-empty"
                  style={{
                    backgroundColor: "#fcc75c " /* #f9b62f */,
                    color: "#fff",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioManagement;
