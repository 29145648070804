import React, { Component } from "react";
import ReactMapboxGl, { GeoJSONLayer } from "react-mapbox-gl";
import axios from "axios";
import Loader from "../../Loader/loader.jsx";
import { BASE_URL } from "../../../Constants/serverConfig.jsx";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});

const style = "mapbox://styles/muhammad-asad-266/cjus8d2q83bsf1gqw8dbriqvm";

const mapStyle = {
  height: "100%",
  width: "100%",
};

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMap: -1,
      mapToView: null,
      editMapIndex: -1,
      hardSkills: [],
      files: [],
      liscensed: false,
      unLiscensed: true,
      insured: false,
      notInsured: true,
      size: "",
      center: [],
      zipCodes: null,
      selectedZipCode: [],
      selectedPloygons: [],
      selectedMetroArea: "",
      showMap: false,
      showLoader: false,
      showMetroAreaPicker: false,
      showError: false,
      errorMessage: "",
    };
    this.supplyChain = React.createRef();
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  closePopup = () => {
    this.setState({
      showMap: false,
      showMetroAreaPicker: false,
      showEndorsementsForm: false,
      showReviewsForm: false,
      mapToView: null,
      editMapIndex: -1,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilesUpload = (event) => {
    if (event.target.files.length > 6) {
      this.setState({
        showError: true,
        errorMessage: "Cannot upload more than 6 pictures",
      });
    } else {
      this.setState({
        [event.target.name]: [...event.target.files],
      });
    }
  };

  handleClick = (map, event) => {
    const features = map.queryRenderedFeatures(event.point);
    if (
      features[0] !== undefined &&
      features[0].properties !== undefined &&
      features[0].properties.zipCode !== undefined
    ) {
      const { selectedZipCode, selectedPloygons, zipCodes } = this.state;
      const index = selectedZipCode.findIndex(
        (zipCode) => zipCode === features[0].properties.zipCode
      );
      if (index === -1) {
        const rawZipCodes = zipCodes.features.find(
          (feature) =>
            feature.properties.zipCode === features[0].properties.zipCode
        );
        var myGeoJSON = {};
        myGeoJSON.type = "FeatureCollection";
        myGeoJSON.features = [rawZipCodes];
        this.setState({
          selectedPloygons: [...selectedPloygons, myGeoJSON],
          selectedZipCode: [...selectedZipCode, features[0].properties.zipCode],
        });
      } else {
        var array1 = [...selectedPloygons];
        var array2 = [...selectedZipCode];
        array1.splice(index, 1);
        array2.splice(index, 1);
        this.setState({ selectedPloygons: array1, selectedZipCode: array2 });
      }
    }
  };

  handleCheckboxClick = (name) => {
    const { selectedMetroArea } = this.state;
    if (selectedMetroArea === name) {
      this.setState({
        selectedMetroArea: "",
      });
    } else {
      this.setState({
        selectedMetroArea: name,
      });
      this.getGeoJsonForMap(name);
    }
  };

  removePicture = (index) => {
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({
      files: [...files],
    });
  };

  getGeoJsonForMap = (areaName) => {
    this.startLoader();
    let url = `http://propagile.com/api/v1/zip-code/metropolitan-area/${areaName}/county`;
    axios
      .get(url)
      .then((response) => {
        const point =
          response.data.features[0].geometry.geometries[0].coordinates;
        this.setState({
          center: point,
          zipCodes: response.data,
          showMap: true,
          showMetroAreaPicker: false,
        });
        this.stopLoader();
      })
      .catch((error) => {
        console.error(error);
        this.stopLoader();
      });
  };

  saveService = () => {
    this.clearError();
    const {
      files,
      liscensed,
      insured,
      size,
      center,
      zipCodes,
      selectedZipCode,
      selectedPloygons,
      hardSkills,
    } = this.state;
    const {
      selectedSegment,
      selectedSubSegment,
      selectedHardSkill,
      selectedMethod,
      selectedExperience,
    } = this.supplyChain.current.state;
    if (selectedExperience === undefined || selectedExperience === null) {
      this.showError("Please choose a service.");
      return;
    }
    if (size === "") {
      this.showError(
        "Please enter max project size which you can handle for selected service"
      );
    }
    if (selectedZipCode.length === 0) {
      this.showError(
        "Please pick some zip codes at which you provide this service"
      );
    }
    let zip_code = this.getFormattedZipCodesArray(selectedZipCode);
    const selected_service = this.getSelectedService(
      selectedSegment,
      selectedSubSegment,
      selectedHardSkill,
      selectedMethod,
      selectedExperience,
      insured,
      liscensed,
      zip_code,
      size
    );
    const service = {
      segment_id: selectedSegment.id,
      sub_segment_id: selectedSubSegment.id,
      hard_skill_id: selectedHardSkill.id,
      method_id: selectedMethod.id,
      method_detail_id: selectedExperience.id,
      size_handle: size,
      licensed: liscensed === true ? 1 : 0,
      insured: insured === true ? 1 : 0,
      files,
      zip_code,
      selected_service,
    };
    var bodyFormData = this.getFormData(
      selectedSegment,
      selectedSubSegment,
      selectedHardSkill,
      selectedMethod,
      selectedExperience,
      size,
      liscensed,
      insured,
      zip_code,
      selected_service,
      files
    );
    this.startLoader();
    axios
      .post(`${BASE_URL}/supply-chain/selected-service`, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      })
      .then((response) => {
        this.stopLoader();
        this.supplyChain.current.clearSelections();
        this.setState({
          hardSkills: [
            ...hardSkills,
            {
              map: {
                center,
                zipCodes,
                selectedZipCode,
                selectedPloygons,
              },
              service,
            },
          ],
          selectedZipCode: [],
          selectedPloygons: [],
          size: "",
          selectedMap: -1,
        });
      })
      .catch((error) => {
        this.stopLoader();
        this.showError("Failed to add service: " + error.response.data);
      });
  };

  removeService = (index) => {
    const { hardSkills } = this.state;
    hardSkills.splice(index, 1);
    this.setState({
      hardSkills: [...hardSkills],
    });
  };

  selectMap = (index, map) => {
    const { selectedMap } = this.state;
    if (selectedMap === index) {
      this.setState({
        selectedMap: -1,
        selectedZipCode: [],
        selectedPloygons: [],
      });
    } else {
      this.setState({
        selectedMap: index,
        selectedZipCode: [...map.selectedZipCode],
        selectedPloygons: [...map.selectedPloygons],
      });
    }
  };

  viewMap = (map) => {
    this.setState({
      mapToView: map,
    });
  };

  editMap = (index, map) => {
    this.setState({
      selectedZipCode: [...map.selectedZipCode],
      selectedPloygons: [...map.selectedPloygons],
      center: map.center,
      zipCodes: map.zipCodes,
      editMapIndex: index,
    });
  };

  updateMap = () => {
    const {
      editMapIndex,
      center,
      zipCodes,
      selectedZipCode,
      selectedPloygons,
      hardSkills,
    } = this.state;
    if (selectedZipCode.length === 0) {
      this.showError("Cannot update map because no zip code is selected");
      return;
    }
    const map = {
      center,
      zipCodes,
      selectedZipCode,
      selectedPloygons,
    };
    let zip_code = this.getFormattedZipCodesArray(selectedZipCode);
    hardSkills[editMapIndex].map = map;
    hardSkills[editMapIndex].service.zip_code = zip_code;
    hardSkills[
      editMapIndex
    ].service.selected_service.Segments[0].SubSegments[0].HardSkills[0].Methods[0].MethodDetails[0].ZipCodes = zip_code;
    this.setState({
      hardSkills: [...hardSkills],
      editMapIndex: -1,
      selectedZipCode: [],
      selectedPloygons: [],
    });
  };

  getFormData(
    selectedSegment,
    selectedSubSegment,
    selectedHardSkill,
    selectedMethod,
    selectedExperience,
    size,
    liscensed,
    insured,
    zip_code,
    selected_service,
    files
  ) {
    var bodyFormData = new FormData();
    bodyFormData.set("segment_id", selectedSegment.id);
    bodyFormData.set("sub_segment_id", selectedSubSegment.id);
    bodyFormData.set("hard_skill_id", selectedHardSkill.id);
    bodyFormData.set("method_id", selectedMethod.id);
    bodyFormData.set("method_detail_id", selectedExperience.id);
    bodyFormData.set("size_handle", size);
    bodyFormData.set("licensed", liscensed === true ? 1 : 0);
    bodyFormData.set("insured", insured === true ? 1 : 0);
    bodyFormData.set("zip_code", zip_code);
    bodyFormData.set("selected_service", selected_service);
    for (var x = 0; x < files.length; x++) {
      bodyFormData.append("files", files[x]);
    }
    return bodyFormData;
  }

  getFormattedZipCodesArray(selectedZipCode) {
    let zip_code = [];
    selectedZipCode.forEach((zipCode) => {
      zip_code.push({ zip_code: zipCode });
    });
    return zip_code;
  }

  getSelectedService(
    selectedSegment,
    selectedSubSegment,
    selectedHardSkill,
    selectedMethod,
    selectedExperience,
    insured,
    liscensed,
    zip_code,
    size
  ) {
    return {
      Segments: [
        {
          id: selectedSegment.id,
          name: selectedSegment.value,
          SubSegments: [
            {
              id: selectedSubSegment.id,
              name: selectedSubSegment.value,
              HardSkills: [
                {
                  id: selectedHardSkill.id,
                  name: selectedHardSkill.value,
                  Methods: [
                    {
                      id: selectedMethod.id,
                      name: selectedMethod.value,
                      MethodDetails: [
                        {
                          id: selectedExperience.id,
                          name: selectedExperience.value,
                          insured: insured === true ? "Insured" : "Not Insured",
                          licensed:
                            liscensed === true ? "Licensed" : "Unlicensed",
                          ZipCodes: zip_code,
                          size_handle: size,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  }

  renderMetroAreaSection(selectedMetroArea) {
    return (
      <div className="field mt-20">
        <label className="label">Select your Metropolitan Area:</label>
        <div className="control">
          <div className="columns">
            <div className="column is-5">
              <div
                onClick={() => this.handleCheckboxClick("New York")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "New York"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">New York Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Los Angeles")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Los Angeles"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Los Angeles Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Chicago")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Chicago"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Chicago Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Dallas")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Dallas"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Dallas Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Houston")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Houston"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Houston Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Washington")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Washington"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Washington Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Miami")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Miami"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Miami Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Philadelphia")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Philadelphia"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">
                  Philadelphia Metro Area
                </label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Atlanta")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Atlanta"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Atlanta Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Boston")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Boston"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Boston Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Phoenix")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Phoenix"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Phoenix Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Seattle")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Seattle"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Seattle Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Denver")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Denver"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Denver Metro Area</label>
              </div>
            </div>
            <div className="column is-7">
              <div
                onClick={() => this.handleCheckboxClick("Austin")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Austin"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Austin Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("San Francisco")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "San Francisco"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">
                  San Francisco Metro Area
                </label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Orlando")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Orlando"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Orlando Metro Area</label>
              </div>
              <div
                onClick={() =>
                  this.handleCheckboxClick("Raleigh-Durham Research Triangle")
                }
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={
                    selectedMetroArea === "Raleigh-Durham Research Triangle"
                  }
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">
                  Raleigh-Durham Research Triangle Metro Area
                </label>
              </div>
              <div
                onClick={() =>
                  this.handleCheckboxClick("Riverside-san Bernardino")
                }
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Riverside-san Bernardino"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">
                  Riverside-san Bernardino Metro Area
                </label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Tampa")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Tampa"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Tampa Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Charlotte")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Charlotte"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Charlotte Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Detroit")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Detroit"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Detroit Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("San Antonio")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "San Antonio"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">San Antonio Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Portland")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Portland"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Portland Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Sacramento")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Sacramento"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Sacramento Metro Area</label>
              </div>
              <div
                onClick={() => this.handleCheckboxClick("Las Vegas")}
                className="field"
              >
                <input
                  onChange={() => {}}
                  checked={selectedMetroArea === "Las Vegas"}
                  className="is-checkradio"
                  type="checkbox"
                />
                <label className="has-text-black">Las Vegas Metro Area</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      files,
      unLiscensed,
      liscensed,
      notInsured,
      insured,
      zipCodes,
      size,
      hardSkills,
      selectedMetroArea,
      showMap,
      showMetroAreaPicker,
      selectedPloygons,
      showLoader,
      selectedMap,
      mapToView,
      editMapIndex,
      showError,
      errorMessage,
    } = this.state;
    return (
      <React.Fragment>
        <Loader text="Loading..." open={showLoader} />
        <div className={`modal ${showMap ? "is-active" : ""}`}>
          <div className="modal-background" />
          <div
            className="modal-content"
            style={{
              height: "80vh",
              width: "80vw",
              minHeight: "80vh",
              minWidth: "80vw",
              backgroundColor: "#fff",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              style={{ height: "70%" }}
              className="columns is-marginless is-paddingless"
            >
              <div className="column is-marginless is-paddingless is-12">
                {zipCodes !== null && (
                  <Map
                    // eslint-disable-next-line react/style-prop-object
                    style={style}
                    containerStyle={mapStyle}
                    center={this.state.center}
                    onClick={this.handleClick}
                  >
                    {selectedPloygons.map((geoJson) => (
                      <GeoJSONLayer
                        key={geoJson.features[0].properties.zipCode}
                        data={geoJson}
                        fillPaint={{
                          "fill-color": "rgba(200, 100, 240, 0.4)",
                          "fill-outline-color": "rgba(0, 0, 0, 1)",
                        }}
                      />
                    ))}
                    <GeoJSONLayer
                      data={zipCodes}
                      fillPaint={{
                        "fill-color": "rgba(200, 100, 240, 0.0)",
                        "fill-outline-color": "rgba(0, 0, 0, 1)",
                      }}
                      symbolLayout={{
                        "text-field": "{zipCode}",
                        "text-font": [
                          "Open Sans Semibold",
                          "Arial Unicode MS Bold",
                        ],
                        "text-offset": [0, 0.6],
                        "text-anchor": "top",
                        "symbol-placement": "point",
                        "text-size": 10,
                      }}
                    />
                  </Map>
                )}
              </div>
            </div>
            <div className="columns" style={{ height: "30%", padding: 10 }}>
              <div className="column is-10">
                <p>Added maps</p>
                <div className="columns mt-10 is-multiline">
                  {hardSkills.map((hardSkill, index) => {
                    const selectedService = hardSkill.service.selected_service;
                    return (
                      <div key={index} className="column is-6">
                        <div
                          onClick={() => this.selectMap(index, hardSkill.map)}
                          className="field"
                        >
                          <input
                            onChange={() => {}}
                            checked={selectedMap === index}
                            className="is-checkradio"
                            type="checkbox"
                          />
                          <label className="has-text-black">
                            Map for{" "}
                            {
                              selectedService.Segments[0].SubSegments[0]
                                .HardSkills[0].name
                            }{" "}
                            /{" "}
                            {
                              selectedService.Segments[0].SubSegments[0]
                                .HardSkills[0].Methods[0].name
                            }
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="column is-2">
                <button
                  onClick={this.closePopup}
                  className="button is-success mt-10 is-block is-fullwidth is-medium"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={this.closePopup}
            className="modal-close is-large"
            aria-label="close"
          />
        </div>
        {mapToView !== null && (
          <div className={`modal ${mapToView !== null ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div
              className="modal-content"
              style={{
                height: "80vh",
                width: "80vw",
                minHeight: "80vh",
                minWidth: "80vw",
                backgroundColor: "#fff",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div
                style={{ height: "100%" }}
                className="columns is-marginless is-paddingless"
              >
                <div className="column is-marginless is-paddingless is-12">
                  <Map
                    // eslint-disable-next-line react/style-prop-object
                    style={style}
                    containerStyle={mapStyle}
                    center={mapToView.center}
                  >
                    {mapToView.selectedPloygons.map((geoJson) => (
                      <GeoJSONLayer
                        key={geoJson.features[0].properties.zipCode}
                        data={geoJson}
                        fillPaint={{
                          "fill-color": "rgba(200, 100, 240, 0.4)",
                          "fill-outline-color": "rgba(0, 0, 0, 1)",
                        }}
                        symbolLayout={{
                          "text-field": "{zipCode}",
                          "text-font": [
                            "Open Sans Semibold",
                            "Arial Unicode MS Bold",
                          ],
                          "text-offset": [0, 0.6],
                          "text-anchor": "top",
                          "symbol-placement": "point",
                          "text-size": 10,
                        }}
                      />
                    ))}
                  </Map>
                </div>
              </div>
            </div>
            <button
              onClick={this.closePopup}
              className="modal-close is-large"
              aria-label="close"
            />
          </div>
        )}
        {editMapIndex !== -1 && (
          <div className={`modal ${editMapIndex !== -1 ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div
              className="modal-content"
              style={{
                height: "80vh",
                width: "80vw",
                minHeight: "80vh",
                minWidth: "80vw",
                backgroundColor: "#fff",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div
                style={{ height: "70%" }}
                className="columns is-marginless is-paddingless"
              >
                <div className="column is-marginless is-paddingless is-12">
                  {zipCodes !== null && (
                    <Map
                      // eslint-disable-next-line react/style-prop-object
                      style={style}
                      containerStyle={mapStyle}
                      center={this.state.center}
                      onClick={this.handleClick}
                    >
                      {selectedPloygons.map((geoJson) => (
                        <GeoJSONLayer
                          key={geoJson.features[0].properties.zipCode}
                          data={geoJson}
                          fillPaint={{
                            "fill-color": "rgba(200, 100, 240, 0.4)",
                            "fill-outline-color": "rgba(0, 0, 0, 1)",
                          }}
                        />
                      ))}
                      <GeoJSONLayer
                        data={zipCodes}
                        fillPaint={{
                          "fill-color": "rgba(200, 100, 240, 0.0)",
                          "fill-outline-color": "rgba(0, 0, 0, 1)",
                        }}
                        symbolLayout={{
                          "text-field": "{zipCode}",
                          "text-font": [
                            "Open Sans Semibold",
                            "Arial Unicode MS Bold",
                          ],
                          "text-offset": [0, 0.6],
                          "text-anchor": "top",
                          "symbol-placement": "point",
                          "text-size": 10,
                        }}
                      />
                    </Map>
                  )}
                </div>
              </div>
              <div className="columns" style={{ height: "30%", padding: 10 }}>
                <div className="column is-10">
                  <p>Added maps</p>
                  <div className="columns mt-10 is-multiline">
                    {hardSkills.map((hardSkill, index) => {
                      const selectedService =
                        hardSkill.service.selected_service;
                      return (
                        <div key={index} className="column is-6">
                          <div
                            onClick={() => this.selectMap(index, hardSkill.map)}
                            className="field"
                          >
                            <input
                              onChange={() => {}}
                              checked={selectedMap === index}
                              className="is-checkradio"
                              type="checkbox"
                            />
                            <label className="has-text-black">
                              Map for{" "}
                              {
                                selectedService.Segments[0].SubSegments[0]
                                  .HardSkills[0].name
                              }{" "}
                              /{" "}
                              {
                                selectedService.Segments[0].SubSegments[0]
                                  .HardSkills[0].Methods[0].name
                              }
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="column is-2">
                  <button
                    onClick={this.updateMap}
                    className="button is-success mt-10 is-block is-fullwidth is-medium"
                  >
                    Update Map
                  </button>
                </div>
              </div>
            </div>
            <button
              onClick={this.closePopup}
              className="modal-close is-large"
              aria-label="close"
            />
          </div>
        )}
        <div className={`modal ${showMetroAreaPicker ? "is-active" : ""}`}>
          <div className="modal-background" />
          <div
            className="modal-content"
            style={{
              height: "80vh",
              width: "80vw",
              minHeight: "80vh",
              minWidth: "80vw",
              backgroundColor: "#fff",
              padding: 10,
            }}
          >
            <div className="columns is-marginless is-paddingless">
              <div className="column is-marginless is-paddingless is-12">
                {this.renderMetroAreaSection(selectedMetroArea)}
              </div>
            </div>
          </div>
          <button
            onClick={this.closePopup}
            className="modal-close is-large"
            aria-label="close"
          />
        </div>
        <div className="columns">
          <div className="column is-12">
            <p className="subtitle is-4 is-size-5 has-text-black">
              Step 2: Services selection, coverage and portfolio upload
            </p>
          </div>
        </div>
        {showError && (
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="notification is-danger text">
                <button
                  autoFocus={true}
                  onClick={this.clearError}
                  className="delete"
                />
                {errorMessage}
              </div>
            </div>
          </div>
        )}
        <div className="columns">
          <div className="column is-6">
            {/* <SupplyChain ref={this.supplyChain} /> */}
            <div className="field mt-10">
              <label className="label">
                Upload related portfolio: (Make it visual, show your work to
                gain more interest from customers)
              </label>
              <div className="control">
                <div className="file">
                  <label className="file-label">
                    <input
                      multiple={true}
                      className="file-input"
                      type="file"
                      onChange={this.handleFilesUpload}
                      name="files"
                      accept="image/jpeg,image/gif,image/png"
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                      <span className="file-label">Choose a file…</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="columns mt-10">
              {files.map((file, index) => (
                <div key={index} className="column is-3">
                  <img
                    alt="pic"
                    className="image is-128x128"
                    src={URL.createObjectURL(file)}
                  />
                  <button
                    onClick={() => this.removePicture(index)}
                    className="button mt-10 is-danger is-fullwidth"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
            <div
              onClick={() => {
                this.setState({
                  liscensed: false,
                  unLiscensed: !unLiscensed,
                });
              }}
              className="field"
            >
              <input
                onChange={() => {}}
                className="is-checkradio"
                type="checkbox"
                checked={unLiscensed}
              />
              <label className="has-text-black">
                I am Un-licensed for this skill
              </label>
            </div>
            <div
              onClick={() => {
                this.setState({
                  liscensed: !liscensed,
                  unLiscensed: false,
                });
              }}
              className="field"
            >
              <input
                onChange={() => {}}
                className="is-checkradio"
                type="checkbox"
                checked={liscensed}
              />
              <label className="has-text-black">
                I am Licensed for this skill
              </label>
            </div>
            <div
              onClick={() => {
                this.setState({
                  insured: false,
                  notInsured: !notInsured,
                });
              }}
              className="field"
            >
              <input
                onChange={() => {}}
                className="is-checkradio"
                type="checkbox"
                checked={notInsured}
              />
              <label className="has-text-black">Not Insured</label>
            </div>
            <div
              onClick={() => {
                this.setState({
                  insured: !insured,
                  notInsured: false,
                });
              }}
              className="field"
            >
              <input
                onChange={() => {}}
                className="is-checkradio"
                type="checkbox"
                checked={insured}
              />
              <label className="has-text-black">I am insured</label>
            </div>
            <button
              className="button mt-10 is-success is-medium"
              onClick={() => {
                if (zipCodes === null) {
                  this.setState({
                    showMetroAreaPicker: true,
                    selectedMetroArea: "",
                  });
                } else {
                  this.setState({
                    showMap: true,
                  });
                }
              }}
            >
              Selected Service Locations
            </button>
            <div className="field mt-10">
              <label className="label">
                Maximum size of project you can manage for this skill on your
                own or with your team:
              </label>
              <div className="control">
                <input
                  className="input is-medium"
                  type="number"
                  name="size"
                  value={size}
                  onChange={this.handleChange}
                  placeholder="Enter project size"
                />
              </div>
            </div>
            <button
              className="button is-success is-medium"
              onClick={this.saveService}
            >
              Add more services
            </button>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-12">
            <p className="subtitle is-4 is-size-5 has-text-black">
              Added Services
            </p>
          </div>
          <div className="column is-12">
            <table className="table is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Services</th>
                  <th>Method</th>
                  <th>LOD/LOX</th>
                  <th>Insured</th>
                  <th>Licensed</th>
                  <th>Max project size(Sqft)</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {hardSkills.map((hardSkill, index) => {
                  const selectedService = hardSkill.service.selected_service;
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        {
                          selectedService.Segments[0].SubSegments[0]
                            .HardSkills[0].name
                        }
                      </td>
                      <td>
                        {
                          selectedService.Segments[0].SubSegments[0]
                            .HardSkills[0].Methods[0].name
                        }
                      </td>
                      <td>
                        {
                          selectedService.Segments[0].SubSegments[0]
                            .HardSkills[0].Methods[0].MethodDetails[0].name
                        }
                      </td>
                      <td>
                        {
                          selectedService.Segments[0].SubSegments[0]
                            .HardSkills[0].Methods[0].MethodDetails[0].insured
                        }
                      </td>
                      <td>
                        {
                          selectedService.Segments[0].SubSegments[0]
                            .HardSkills[0].Methods[0].MethodDetails[0].licensed
                        }
                      </td>
                      <td>
                        {
                          selectedService.Segments[0].SubSegments[0]
                            .HardSkills[0].Methods[0].MethodDetails[0]
                            .size_handle
                        }
                      </td>
                      <td>
                        <span
                          onClick={() => this.viewMap(hardSkill.map)}
                          title="View Map"
                          style={{ cursor: "pointer" }}
                          className="icon has-text-success"
                        >
                          <i className="fas fa-eye" />
                        </span>
                        {/* <span
                          onClick={() => this.editMap(index, hardSkill.map)}
                          title="Edit Map"
                          style={{ cursor: "pointer" }}
                          className="icon has-text-warning"
                        >
                          <i className="fas fa-edit" />
                        </span> */}
                        <span
                          onClick={() => this.removeService(index)}
                          title="Remove this skill"
                          style={{ cursor: "pointer" }}
                          className="icon has-text-danger"
                        >
                          <i className="fas fa-ban" />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Services;
