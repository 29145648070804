/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SecureLS from "secure-ls";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import axios from "axios";
import FreeFeatures from "./FreeFeatures.jsx";
import StandardFeatures from "./StandardFeatures.jsx";
import BusinessFeatures from "./BusinessFeatures.jsx";
import NewFooter from "../Footer/NewFooter.jsx";
import NewHeader from "../Header/NewHeader.jsx";
import GetInTouchForm from "../Footer/GetInTouchForm.jsx";
// import { withStyles } from "@material-ui/core/styles";

/* const styles = (theme) => ({
  freeTrialSpan: {
    position: "static",
    width
  },
}); */

class SubscriptionUpgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: "annual",
      showPopup: false,
      showError: false,
      errorMessage: "",
      freeSubscription: {},
      standardSubscription: {},
      businessSubscription: {},
      openGetInTouchModal: false,
    };
    this.footerRef = React.createRef();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  componentDidMount() {
    this._isMounted = true;
    const localStore = new SecureLS();
    const type = localStore.get("xc");
    if (type) {
      setTimeout(() => {
        if (this._isMounted) {
          localStore.remove("xc");
          this.setState({
            paymentType: type,
          });
        }
      }, 200);
    }
    let elements = document.getElementsByClassName("top_part_same_height");
    this.equalHeight(elements);
    elements = document.getElementsByClassName("pricing-list");
    this.equalHeight(elements);
    window.scrollTo(0, 0);

    axios
      .get(`https://api.propagile.com/api/v1/membership/membership`)
      .then((res) => {
        res.data.map((item) => {
          if (item.name === "Free") {
            this.setState({ freeSubscription: item });
          } else if (item.name === "Standard") {
            this.setState({ standardSubscription: item });
          } else if (item.name === "Business") {
            this.setState({ businessSubscription: item });
          }
        });
      })
      .catch((err) => {
        console.log("Error while fetching subscriptions Amount: ", err);
      });
  }

  equalHeight = (group) => {
    let tallest = 0;
    for (let index = 0; index < group.length; index++) {
      let element = group[index];
      let thisHeight = element.offsetHeight;
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    }
    for (let index = 0; index < group.length; index++) {
      let element = group[index];
      element.style.height = `${tallest}px`;
    }
  };

  setPaymentType = () => {
    if (this.state.paymentType === "annual") {
      this.setState({
        paymentType: "monthly",
      });
    } else {
      this.setState({
        paymentType: "annual",
      });
    }
  };

  redirectToStripe = (membership) => {
    const { serviceProvider } = this.props;
    if (
      membership === serviceProvider.membership_information.membership_stripe_id
    ) {
      this.showError(
        "Cannot regain the membership you already have. Please choose someother membership."
      );
      return;
    }
    //below changes are made as per the request of propAgile Team in ticket # 753
    if (
      serviceProvider.membership_information.membership_stripe_id ===
        /* "standard-pre-launch-annual" */ "standard-annual" ||
      serviceProvider.membership_information.membership_stripe_id ===
        "standard-monthly"
    ) {
      if (membership === "free") {
        this.showError(
          "You cannot downgrade to Free. Please contact support for further details about memberships."
        );
        return;
      }
    }
    const { paymentType } = this.state;
    let stripe = window.Stripe("pk_test_kDN5G7K0AMgrNM6w5D3Afo0a00q3zIqhxW");
    const localStore = new SecureLS();
    localStore.set("xc", paymentType);
    stripe
      .redirectToCheckout({
        items: [{ plan: membership, quantity: 1 }],
        customerEmail: serviceProvider.email_address,
        successUrl: `https://propagile.com/thanks?status=upgrade&payment=success`,
        cancelUrl: `https://propagile.com/thanks?status=upgrade&payment=failed`,
        billingAddressCollection: "required",
      })
      .then(function(result) {
        if (result.error) {
          console.error(result.error.message);
        }
      });
  };

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  showDowngradeError = () => {
    this.showError(
      "You already have this membership or a higher member, you cannot downgrade. Please contact us for more information"
    );
  };

  // openGetInTouchForm = () => {
  //   this.clearError();
  //   this.footerRef.current.showGetInTouch();
  // };
  handleCloseModal = () => {
    console.log("in closing modal");
    this.setState({ openGetInTouchModal: false });
    // this.setState({
    //   openGetInTouchModal: "false",
    // });
  };
  handleOpenModal = () => {
    console.log("In submitting");
    this.setState({ openGetInTouchModal: true });
    this.setState({
      openGetInTouchModal: true,
    });
  };
  render() {
    const {
      isUserLoggedIn,
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
      // classes,
    } = this.props;
    const {
      paymentType,
      showPopup,
      showError,
      errorMessage,
      /* freeSubscription */
      standardSubscription,
      businessSubscription,
    } = this.state;
    const membershipId = serviceProvider.membership_information.membership_id;

    console.log("In subscription", this.state.openGetInTouchModal);

    return (
      <div>
        {!!this.state.openGetInTouchModal && (
          <GetInTouchForm
            openGetInTouchModal={this.state.openGetInTouchModal}
            handleCloseModal={() =>
              this.setState({ openGetInTouchModal: false })
            }
          />
        )}
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                {errorMessage.includes(
                  "Please contact us for more information"
                ) ? (
                  // <a onClick={this.openGetInTouchForm} className="btn-fill">
                  <a
                    onClick={() => this.setState({ openGetInTouchModal: true })}
                    className="btn-fill"
                  >
                    Get In touch
                  </a>
                ) : (
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={`${
            showPopup === true ? "modal mainn-map is-active" : "modal mainn-map"
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card p_m_card">
            <button
              onClick={this.togglePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <table>
                  <thead>
                    <tr>
                      <th>Line of services</th>
                      <th>Services</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="servic_blue">
                        {""}
                        Electrical Install/Repair
                      </td>
                      <td className="servic_orange">_Wiring & Rewiring</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Electrical Install/Repair</td>
                      <td className="servic_orange">_Service Panels</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Specialty inspections</td>
                      <td className="servic_orange">_Asbestos</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Specialty inspections</td>
                      <td className="servic_orange">_Mold</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">General Removal/Disposal</td>
                      <td className="servic_orange">_Hauling & Containers</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">General Removal/Disposal</td>
                      <td className="servic_orange">_Flooring & Carpets</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">
                        Finish carpentry Install/Repair
                      </td>
                      <td className="servic_orange">_Kitchen Cabinetry</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">
                        Finish carpentry Install/Repair
                      </td>
                      <td className="servic_orange">_Custom Millwork</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Plumbing Install/Repair</td>
                      <td className="servic_orange">_Leak Detection</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Plumbing Install/Repair</td>
                      <td className="servic_orange">_Gas Repair</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <div className="section pckg_new_head">
          <div className="container container-fluid">
            <h1>
              {/* Increase Job Opportunities */}Connect With More Customers In
              More Ways!
              {/* <span>in Property Renovation Projects </span> */}
            </h1>
            <p style={{ color: "#fcc75c " /* #e99709 */ }}>
              We made it easy to promote services everywhere in minutes...{" "}
              <span>You will not need to hire anyone!</span>
              {/* <span>NO NEED FOR EXPERTS, </span> Promote your services online in
              minutes. */}
            </p>
          </div>
        </div>
        <section id="pricing" className="pricing-area pt-115">
          <div className="p_contain clearfix">
            {/* <h4>Phase 1: Interior construction in NYC tri-state area</h4>
             */}
            <p className="mem_op_h">Membership options</p>
            <div className="p_basic_price first_pckg">
              <div className="single-pricing text-center pricing-color-1">
                <div className="top_part_same_height">
                  <div className="pricing-title mt-20">
                    <h4 className="title">Free</h4>
                  </div>
                  <div className="pricing-price price-after">
                    <h1>No credit card required </h1>
                  </div>
                  <div>
                    <a
                      className="btn-join"
                      onClick={() =>
                        membershipId === 1 ||
                        membershipId === 2 ||
                        membershipId === 3
                          ? this.showDowngradeError()
                          : this.redirectToStripe("free")
                      }
                    >
                      Join Now
                    </a>
                  </div>
                </div>
                <FreeFeatures />
              </div>
            </div>
            <div className="p_basic_price p_standard_price second_pckg">
              <div className="single-pricing text-center pricing-active pricing-color-2">
                <div className="top_part_same_height">
                  <div className="pricing-title mt-20">
                    <div className="hot">hot</div>
                    <h4 className="title highlight_title">
                      <span
                        // className={classes.freeTrialSpan}
                        style={{ position: "static" }}
                      >
                        (Free 15-Day Trial){" "}
                      </span>
                      Standard
                    </h4>
                  </div>
                  <div className="pricing-price  price-before">
                    <h2>
                      <span className="price" id="change1">
                        {paymentType === "annual" && (
                          <React.Fragment>
                            ${standardSubscription.annually}/<span>Yr</span>.
                          </React.Fragment>
                        )}
                        {paymentType === "monthly" && (
                          <React.Fragment>
                            ${standardSubscription.monthly}/<span>Mo</span>.
                          </React.Fragment>
                        )}
                      </span>
                    </h2>
                  </div>
                  <div className="changeer">
                    <span
                      className={
                        paymentType === "monthly"
                          ? "chang_text_2"
                          : "chang_text"
                      }
                    >
                      Per month
                    </span>
                    <label id="switch" className="switch">
                      <input
                        className="input"
                        type="checkbox"
                        onClick={() => this.setPaymentType()}
                        onChange={() => {}}
                        checked={paymentType === "annual"}
                      />
                      <span className="slider round" />
                    </label>
                    <span
                      className={
                        paymentType === "annual" ? "chang_text_2" : "chang_text"
                      }
                    >
                      Per Year
                    </span>
                  </div>
                  <div className="pricing-btn top_margin">
                    <p>&nbsp;</p>
                    <a
                      className="btn-join"
                      onClick={() =>
                        membershipId === 2 || membershipId === 3
                          ? this.showDowngradeError()
                          : this.redirectToStripe(
                              paymentType === "annual"
                                ? /* "standard-pre-launch-annual" changes made as 
                                per the request of propAgile team - ticket#753*/
                                  "standard-annual"
                                : "standard-monthly"
                            )
                      }
                    >
                      Join Now
                    </a>
                  </div>
                </div>
                <StandardFeatures isSubscriptionUpgrade={true} />
              </div>
            </div>
            <div className="p_basic_price last_pckg">
              <div className="single-pricing text-center pricing-color-3">
                <div className="top_part_same_height">
                  <div className="pricing-title mt-20">
                    <h4 className="title">Business</h4>
                  </div>
                  <div className="pricing-price  price-before">
                    <h2>
                      <span className="price" id="change1">
                        {paymentType === "annual" && (
                          <React.Fragment>
                            ${businessSubscription.annually}/<span>Yr</span>.
                          </React.Fragment>
                        )}
                        {paymentType === "monthly" && (
                          <React.Fragment>
                            ${businessSubscription.monthly}/<span>Mo</span>.
                          </React.Fragment>
                        )}
                      </span>
                    </h2>
                  </div>
                  <div className="changeer">
                    <span
                      className={
                        paymentType === "monthly"
                          ? "chang_text_2"
                          : "chang_text"
                      }
                    >
                      Per month
                    </span>
                    <label id="switch" className="switch">
                      <input
                        className="input"
                        type="checkbox"
                        onClick={() => this.setPaymentType()}
                        onChange={() => {}}
                        checked={paymentType === "annual"}
                      />
                      <span className="slider round" />
                    </label>
                    <span
                      className={
                        paymentType === "annual" ? "chang_text_2" : "chang_text"
                      }
                    >
                      Per Year
                    </span>
                  </div>
                  <div className="top_margin">
                    <p>&nbsp;</p>
                    <a
                      className="btn-join"
                      onClick={() =>
                        membershipId === 3
                          ? this.showDowngradeError()
                          : this.redirectToStripe(
                              paymentType === "annual"
                                ? /* "business-pre-launch-annual" changes made as 
                                per the request of propAgile team - ticket#753*/
                                  "business-annual"
                                : "business-monthly"
                            )
                      }
                    >
                      Join Now
                    </a>
                  </div>
                </div>
                <BusinessFeatures />
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Footer ref={this.footerRef} />
        ) : (
          <NewFooter ref={this.footerRef} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(/* withStyles(styles) */ SubscriptionUpgrade));
