import {
  SET_NEW_INVITEES,
  SET_LICENSE_AVAILABLE,
} from "../Constants/actionTypes.jsx";

const setNewRequestList = (data) => {
  return {
    type: SET_NEW_INVITEES,
    payload: data,
  };
};

const setLicenseAvailable = (data) => {
  return {
    type: SET_LICENSE_AVAILABLE,
    payload: data,
  };
};

export { setNewRequestList, setLicenseAvailable };
