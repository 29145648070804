import { SAVE_USER, REMOVE_USER } from "../Constants/actionTypes.jsx";

const initialState = {
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case REMOVE_USER:
      return Object.assign({}, state, {
        user: action.payload,
      });
    default:
      return state;
  }
};
