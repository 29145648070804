import { BASE_URL } from "../Constants/serverConfig";
import axios from "axios";

export const fetchNewInvitees = (
  token,
  setNewRequestList,
  setLicenseAvailable
) => {
  axios
    .get(`${BASE_URL}/license-management/request`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        // setNewRequestList(res.data.request_list);
        setLicenseAvailable(res.data.licenses_available);
      }
    })
    .catch((err) => console.log("Something went wrong, ", err));
};
