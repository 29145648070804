// Packages
import React, { useState } from "react";
import Iframe from "react-iframe";
import axios from "axios";
import { useSelector } from "react-redux";

// Components
import FooterWithoutOptions from "../Footer";
// import FooterWithoutOptions from "../Footer/footerWithoutOptions";

// Constants
import { BASE_URL } from "../../Constants/serverConfig";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
import Header from "../Header/header";

const TeamDashboard = ({ isUserLoggedIn, isSpLoggedIn, handleLogout }) => {
  const token = useSelector((state) => state.token.token);
  const serviceProvider = useSelector(
    (state) => state.serviceProvider.serviceProvider
  );
  const [projects, setProjects] = useState([]);
  const [isProjectShown, setIsProjectShown] = useState(false);
  const [project, setProject] = useState("");

  /*   const showProjects = () => {
    if (isProjectShown === true) {
      setIsProjectShown(false);
    }
  }; */

  const showProjectsList = () => {
    return (
      <section className="modal-card-body home-modal">
        <div className="endos_panel2 clearfix">
          <div className="endorsment-form">
            <section className="modal-card-body" style={{ paddingTop: "0" }}>
              <div className="endos_panel3 clearfix">
                <table style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>Sr. no</th>
                      <th>Project</th>
                      <th style={{ width: "10rem" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{member.project_name}</td>
                          <td>
                            <div
                              className="tooltip"
                              style={{ borderBottom: "none" }}
                              onClick={() => {
                                assignProject(member.project_url);
                                setIsProjectShown(true);
                              }}
                            >
                              <i
                                style={{ cursor: "pointer" }}
                                className="fas fa-angle-double-right"
                              />
                              <span
                                style={{
                                  cursor: "pointer",
                                  top: "-20px",
                                }}
                                className="tooltiptext"
                              >
                                Go to Project
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    axios
      .get(`${BASE_URL}/project`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProjects([...response.data]);
      })
      .catch((error) => {
        setProjects([]);
        console.error(error, "Error Message");
      });
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const assignProject = (project_url) => {
    setProject(project_url);
  };

  return (
    <div>
      {/* {!!isSpLoggedIn || !!isUserLoggedIn ? (
        <HeaderWithoutOptions
          isDark={true}
          isUserLoggedIn={isUserLoggedIn}
          isSpLoggedIn={isSpLoggedIn}
          handleLogout={handleLogout}
          serviceProvider={serviceProvider}
        />
      ) : (
        <NewHeader />
      )} */}
      {!!isSpLoggedIn || !!isUserLoggedIn ? (
        <Header
          isDark={true}
          isUserLoggedIn={isUserLoggedIn}
          isSpLoggedIn={isSpLoggedIn}
          handleLogout={handleLogout}
          serviceProvider={serviceProvider}
          childRef={(ref) => (this.headerChildRef = ref)}
        />
      ) : (
        <NewHeader />
      )}
      <div className="services_main_top_head new_sep_dashboard">
        <div className="p_tabs" style={{ paddingTop: "0", marginBottom: "0" }}>
          <a
            className={"p_tab_active"}
            style={{ cursor: "default", pointerEvents: "none" }}
          >
            Projects
          </a>
        </div>
      </div>

      <div
        style={{
          height: "68vh",
          overflow: "scroll",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isProjectShown ? (
          <Iframe
            url={project}
            display="initial"
            position="relative"
            width="100%"
            height={window.innerHeight}
          />
        ) : (
          showProjectsList()
        )}
      </div>
      <div style={{ height: "16vh" }}>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <FooterWithoutOptions />
        ) : (
          <NewFooter />
        )}
      </div>
    </div>
  );
};

export default TeamDashboard;
