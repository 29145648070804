/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import Loader from "../../Loader/loader";
import { BASE_URL } from "../../../Constants/serverConfig";
import {
  leadershipFunctionOptions,
  workFunctionOptions,
  managementFunctionOptions,
} from "../utils/commons";
import { Grid } from "@material-ui/core";
import ServiceSteps from "./ServiceSteps";

class ParametersManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dcClassification: "",
      licensed: "",
      insured: "",
      is24Hours: "",
      union_worker: "",
      showLoader: false,
      showSuccess: false,
      showError: false,
      loadingMessage: "",
      message: "",
      errorMessage: "",
      showServices: false,
      selectedServiceForOtherParameters: null,
      showCoverageError: false,
      baseWork: [],
      leadership: [],
      management: [],
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
      loadingMessage: "Loading...",
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
      loadingMessage: "",
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  clearCoverageError = () => {
    this.setState({
      showCoverageError: false,
    });
  };

  showSuccess = (message) => {
    this.setState({
      showSuccess: true,
      message,
    });
  };

  clearMessage = () => {
    this.setState({
      showSuccess: false,
      message: "",
    });
  };

  handleClassificationSelection = (value) => {
    this.setState({
      dcClassification: value,
    });
  };

  handleLicensedSelection = (value) => {
    this.setState({
      licensed: value,
    });
  };

  handleInsuredSelection = (value) => {
    this.setState({
      insured: value,
    });
  };

  handle24HourSelection = (value) => {
    this.setState({
      is24Hours: value,
    });
  };

  handleUnionWorkerSelection = (value) => {
    this.setState({
      union_worker: value,
    });
  };

  assignParameters = () => {
    const {
      baseWork,
      leadership,
      management,
      dcClassification,
      licensed,
      insured,
      is24Hours,
      union_worker,
      selectedServiceForOtherParameters,
    } = this.state;
    const {
      token,
      showError,
      showSuccess,
      startLoader,
      stopLoader,
      getServices,
    } = this.props;
    if (selectedServiceForOtherParameters === null) {
      showError("Please select a service");
      return false;
    }
    if (
      selectedServiceForOtherParameters.coverage_selected === false ||
      selectedServiceForOtherParameters.total_zip_codes_selected === 0
    ) {
      showError(
        "Please choose coverage for this service before from Service Coverage."
      );
      return false;
    }
    if (
      baseWork.length === 0 &&
      leadership.length === 0 &&
      management.length === 0
    ) {
      showError("Please select job functions you can manage for this service");
      return false;
    }
    if (dcClassification === "") {
      showError(
        "Please select design/construction level of detail classification"
      );
      return false;
    }
    if (licensed === "") {
      showError("Please tell us whether you are licensed for this skill?");
      return false;
    }
    if (insured === "") {
      showError("Please tell us whether you are insured for this skill?");
      return false;
    }
    if (is24Hours === "") {
      showError(
        "Please tell us whether you are providing this service for 24 hours"
      );
      return false;
    }
    if (union_worker === "") {
      showError("Please tell us whether you are a union worker or not?");
      return false;
    }
    if (token !== null) {
      let selectedServiceDetails = this.getFormattedService(
        selectedServiceForOtherParameters
      );
      let body = {
        selected_services: [selectedServiceDetails],
      };
      startLoader();
      axios
        .patch(`${BASE_URL}/service/details`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            showSuccess("Parameters assigned successfully.");
            this.setState({
              dcClassification: "",
              licensed: "",
              insured: "",
              is24Hours: "",
              union_worker: "",
              baseWork: [],
              leadership: [],
              management: [],
              selectedServiceForOtherParameters: null,
            });
            getServices();
          }
        })
        .catch((error) => {
          stopLoader();
          if (error.response && typeof error.response.data === "string") {
            showError(error.response.data);
          }
          console.error(error);
        });
    }
  };

  getFormattedService = (service) => {
    const {
      baseWork,
      leadership,
      management,
      dcClassification,
      licensed,
      insured,
      is24Hours,
      union_worker,
    } = this.state;
    return {
      selected_service_id: service.selected_service_id,
      insured: insured === "Yes" ? 1 : 0,
      licensed: licensed === "Yes" ? 1 : 0,
      job_functions: [
        ...baseWork.map((x) => x.value),
        ...leadership.map((x) => x.value),
        ...management.map((x) => x.value),
      ],
      dc_classification: dcClassification,
      round_clock_service: is24Hours === "Yes" ? 1 : 0,
      union_worker: union_worker === "Yes" ? 1 : 0,
    };
  };

  toggleServicesDropdown = () => {
    this.setState({
      showServices: !this.state.showServices,
    });
  };

  selectServiceForOtherParameters = (service) => {
    if (
      service.coverage_selected === false ||
      service.total_zip_codes_selected === 0
    ) {
      this.setState({
        selectedServiceForOtherParameters: null,
        dcClassification: "",
        is24Hours: "",
        insured: "",
        licensed: "",
        union_worker: "",
        baseWork: [],
        leadership: [],
        management: [],
        showServices: false,
        showCoverageError: true,
      });
      return false;
    }
    if (service.is_complete) {
      this.setState({
        selectedServiceForOtherParameters: service,
        dcClassification: service.service_parameters.dc_classification,
        is24Hours:
          service.service_parameters.round_clock_service === true
            ? "Yes"
            : "No",
        union_worker:
          service.service_parameters.union_worker === true ? "Yes" : "No",
        insured: service.service_parameters.insured === true ? "Yes" : "No",
        licensed: service.service_parameters.licensed === true ? "Yes" : "No",
        showServices: false,
        baseWork: service.service_parameters.job_functions
          .filter((x) => x.job_function_type === "Base Work")
          .map((x) => ({
            value: x.value,
            label: x.value,
          })),
        leadership: service.service_parameters.job_functions
          .filter((x) => x.job_function_type === "Leadership Work")
          .map((x) => ({
            value: x.value,
            label: x.value,
          })),
        management: service.service_parameters.job_functions
          .filter((x) => x.job_function_type === "Management Work")
          .map((x) => ({
            value: x.value,
            label: x.value,
          })),
      });
    } else {
      this.setState({
        selectedServiceForOtherParameters: service,
        dcClassification: "",
        is24Hours: "",
        union_worker: "",
        insured: "",
        licensed: "",
        baseWork: [],
        leadership: [],
        management: [],
        showServices: false,
      });
    }
  };

  handleSelectChange = (name) => (value) => {
    const { baseWork, leadership, management } = this.state;
    let totalCount = 0;
    if (name === "baseWork") {
      totalCount = leadership.length + management.length + value.length;
    }
    if (name === "leadership") {
      totalCount = baseWork.length + management.length + value.length;
    }
    if (name === "management") {
      totalCount = baseWork.length + leadership.length + value.length;
    }
    if (totalCount <= 3) {
      this.setState({
        [name]: value,
      });
    } else {
      this.showError(
        "You can only select 3 functions from work, leadership and management functions"
      );
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      dcClassification,
      licensed,
      insured,
      is24Hours,
      union_worker,
      showSuccess,
      message,
      showError,
      errorMessage,
      showLoader,
      loadingMessage,
      showServices,
      selectedServiceForOtherParameters,
      showCoverageError,
      baseWork,
      leadership,
      management,
    } = this.state;
    const {
      servicesPromoted,
      showInstructions,
      moveToStep,
      membershipInfo,
    } = this.props;
    return (
      <React.Fragment>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                {errorMessage.includes("upgrade") ? (
                  <a
                    onClick={
                      this.redirectToMembershipsAndSaveCurrentInformation
                    }
                    className="btn-fill"
                  >
                    Upgrade
                  </a>
                ) : (
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        {showCoverageError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearCoverageError}
                  className="modal-close"
                />
                <p>
                  Please choose coverage for this service before from Service
                  Coverage.
                </p>
                <div className="map-buton" style={{ float: "none" }}>
                  <button onClick={() => moveToStep(4)} className="btn-fill">
                    Select Coverage
                  </button>
                  <button
                    onClick={this.clearCoverageError}
                    className="btn-empty"
                  >
                    Skip for now
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSuccess && (
          <div className="modal is-active custom-modal success-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="success-message-modal">
                <button onClick={this.clearMessage} className="modal-close" />
                <p>{message}</p>
                <a onClick={this.clearMessage} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        <Loader text={loadingMessage} open={showLoader} />
        <div className="services_main_body">
          <div className="container">
            <ServiceSteps
              moveToStep={this.props.moveToStep}
              service={"paremeters"}
            />
            <div className="s_m_b_head  clearfix">
              <span className="s_m_1">Step 4 of 4:</span>{" "}
              <span className="s_m_2">Service parameters,</span>{" "}
              <span className="s_m_3">
                For a best match each services will have it's own parameters
                (your selection must match your portfolio)
              </span>
            </div>
            <div className="clearfix" />
            <div className="upload_portfolio clearfix parameter_dropdown">
              <div className="upload_option">
                <div className="inputContainer">
                  <div
                    onClick={this.toggleServicesDropdown}
                    className="select-styled active"
                  >
                    {selectedServiceForOtherParameters !== null
                      ? `${selectedServiceForOtherParameters.name}`
                      : "Select Service"}
                    <div className="select-icon">
                      <i className=" fa fa-angle-down"></i>
                    </div>
                  </div>
                  {showServices === true ? (
                    <ul className="select-options">
                      {servicesPromoted.map((x) => (
                        <li
                          key={`level:${x.level}:serviceId:${x.serviceId}`}
                          onClick={() =>
                            this.selectServiceForOtherParameters(x)
                          }
                        >
                          {x.name}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="clearfix" />
            <div className="divide_segments clearfix lastp_5A">
              <div className="divide_segments_1 clearfix">
                <h3>
                  4.1 Job functions:{" "}
                  <span>
                    {" "}
                    Select up to 3 from drop down(s)
                    <br />
                    Selection will be shown in association with each service on
                    your profile{" "}
                  </span>
                </h3>
                <div className="upload_portfolio clearfix">
                  <label>Base work type</label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      control: (base) => ({
                        ...base,
                        background: "white none repeat scroll 0 0",
                        border: "1px solid #ececec",
                      }),
                    }}
                    value={baseWork}
                    isClearable={true}
                    onChange={this.handleSelectChange("baseWork")}
                    name="baseWork"
                    id="baseWork"
                    isMulti={true}
                    options={workFunctionOptions}
                    placeholder="Please choose option(s)"
                  />
                </div>
                {!(membershipInfo.membership_id === 1) && (
                  <div className="upload_portfolio clearfix">
                    <label>Leadership work</label>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        control: (base) => ({
                          ...base,
                          background: "white none repeat scroll 0 0",
                          border: "1px solid #ececec",
                        }),
                      }}
                      value={leadership}
                      isClearable={true}
                      onChange={this.handleSelectChange("leadership")}
                      name="leadership"
                      id="leadership"
                      isMulti={true}
                      options={leadershipFunctionOptions}
                      placeholder="Please choose option(s)"
                    />
                  </div>
                )}
                {!(membershipInfo.membership_id !== 3) && (
                  <div className="upload_portfolio clearfix">
                    <label>Management work</label>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        control: (base) => ({
                          ...base,
                          background: "white none repeat scroll 0 0",
                          border: "1px solid #ececec",
                        }),
                      }}
                      value={management}
                      isClearable={true}
                      onChange={this.handleSelectChange("management")}
                      name="management"
                      id="management"
                      isMulti={true}
                      options={managementFunctionOptions}
                      placeholder="Please choose option(s)"
                    />
                  </div>
                )}
                <div className="clearfix"></div>
                <h3>
                  4.2 Quality level (Design/Construction): <br />{" "}
                  <span>
                    Portfolio must reflect selection to avoid being reported or
                    downgraded
                  </span>
                </h3>
                <Grid container>
                  <div className="break_divss clearfix"></div>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={() => this.handleClassificationSelection(1)}
                      className="md-checkbox"
                    >
                      <input
                        checked={dcClassification === 1}
                        onChange={() => null}
                        onClick={(event) => event.preventDefault()}
                        id="basic"
                        type="checkbox"
                      />
                      <label htmlFor="basic"></label>
                    </div>
                    <a>
                      <div className="tbl">
                        <div style={{ color: "#000" }}>Basic $</div>
                      </div>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={() => this.handleClassificationSelection(2)}
                      className="md-checkbox"
                    >
                      <input
                        checked={dcClassification === 2}
                        onChange={() => null}
                        onClick={(event) => event.preventDefault()}
                        id="standard"
                        type="checkbox"
                      />
                      <label htmlFor="standard"></label>
                    </div>
                    <a>
                      <div className="tbl">
                        <div style={{ color: "#000" }}>Standard $$</div>
                      </div>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={() => this.handleClassificationSelection(3)}
                      className="md-checkbox"
                    >
                      <input
                        checked={dcClassification === 3}
                        onChange={() => null}
                        onClick={(event) => event.preventDefault()}
                        id="highend"
                        type="checkbox"
                      />
                      <label htmlFor="highend"></label>
                    </div>
                    <a>
                      <div className="tbl">
                        <div style={{ color: "#000" }}>High End $$$</div>
                      </div>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={() => this.handleClassificationSelection(4)}
                      className="md-checkbox"
                    >
                      <input
                        checked={dcClassification === 4}
                        onChange={() => null}
                        onClick={(event) => event.preventDefault()}
                        id="na"
                        type="checkbox"
                      />
                      <label htmlFor="na"></label>
                    </div>

                    <a>
                      <div className="tbl">
                        <div style={{ color: "#000" }}>No Applicable</div>
                      </div>
                    </a>
                  </Grid>
                </Grid>
              </div>
              <div className="divide_segments_2 clearfix">
                <h3>4.3 Additional parameters specific to this selection </h3>

                <div className="upload_portfolio clearfix ">
                  <label>Licensed?</label>
                  <div className="select is-fullwidth">
                    <select
                      name="licensed"
                      onChange={this.handleChange}
                      value={licensed}
                    >
                      <option value="">Choose an option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>

                <div className="upload_portfolio clearfix ">
                  <label>Insured?</label>
                  <div className="select is-fullwidth">
                    <select
                      name="insured"
                      onChange={this.handleChange}
                      value={insured}
                    >
                      <option value="">Choose an option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>

                <div className="upload_portfolio clearfix ">
                  <label>Union?</label>
                  <div className="select is-fullwidth">
                    <select
                      name="union_worker"
                      onChange={this.handleChange}
                      value={union_worker}
                    >
                      <option value="">Choose an option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>

                <div className="upload_portfolio clearfix ">
                  <label>24-Hour Service?</label>
                  <div className="select is-fullwidth">
                    <select
                      name="is24Hours"
                      onChange={this.handleChange}
                      value={is24Hours}
                    >
                      <option value="">Choose an option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />
            <div className="clearfix select_line_services_opt">
              <a
                className="select_line_services_opt_a"
                onClick={this.assignParameters}
              >
                Submit
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ParametersManagement;
