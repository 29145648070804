import React, { useState, useEffect } from "react";
import styles from "./VendorDetails.module.css";
import axios from "axios";
import { BASE_URL } from "../../../Constants/serverConfig";
import LicencesAdded from "./LicencesAdded";
import NewInvitees from "./NewInvitees";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import {
  setNewRequestList,
  setLicenseAvailable,
} from "../../../Actions/Vendors";
import { fetchNewInvitees } from "../../../utils/fetchNewInvitees";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";

const VandorDetails = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [disableAdd, setDisableAdd] = useState(false);
  const [disableInvite, setDisableInvite] = useState(false);
  const { token, serviceProvider } = props;
  const [showError, setShowError] = useState(false);
  const [showModalBuyLicense, setShowModalBuyLicense] = useState(false);
  const [errorMessage, setShowErrorMessage] = useState(false);
  const [buyMoreLicences, setBuyMoreLicences] = useState(false);
  const [membershipName, setMembershipName] = useState(
    props.serviceProvider.membership_information.membership_name
  );
  const { addToast } = useToasts();
  const history = useHistory();
  useEffect(() => {
    // we need to have available license count according to newFlow
    fetchNewInvitees(token, props.setNewRequestList, props.setLicenseAvailable);

    /* axios
      .patch(`https://api.propagile.com/api/v1/account/licensed-profile`, {
        first_name: "testing-fname",
        last_name: "testing-lname",
        email_address: "testing-Email",
        street_address: "testing-String",
        city: "testing-String",
        state: "testing-String",
        zip_code: 0,
      })
      .then((res) => {
        console.log("vendor response ==> ", res);
      })
      .catch((err) => {
        console.log("Vendor error ==> ", err);
      }); */
  }, []);

  useEffect(() => {
    if (!!props.requestList && !!props.requestList.length) {
      if (props.licenseAvailable - props.requestList.length === 0) {
        setDisableAdd(true);
      } else if (props.licenseAvailable - props.requestList.length > 0) {
        setDisableAdd(false);
      }
    } else {
      setDisableAdd(false);
    }
  }, [props.licenseAvailable, props.requestList]);

  const licensedAccountCreationRequest = () => {
    // if (!!props.requestList && !!props.requestList.length) {
    if (!!firstName && !!lastName && !!email) {
      setDisableInvite(true);
      let data = {
        receiver_list: [{
          guest_name: firstName + " " + lastName,
          email_address: email
        }
        ]
      };
      // let data = {
      //   receiver_list: [...props.requestList],
      // };
      axios
        .post(`${BASE_URL}/license-management/request`, data, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            fetchNewInvitees(
              token,
              props.setNewRequestList,
              props.setLicenseAvailable
            );
            props.setNewRequestList([]);
            setDisableInvite(false);
            addToast("Invitations send successfully.", {
              appearance: "success",
            });
          }
        })
        .catch((err) => {
          setDisableInvite(false);
          console.error(err);
          if (props.licenseAvailable === 0) {
            addToast("Buy more license to send more invites.");
          }
          addToast("Could not send Invitation.", {
            appearance: "error",
          });
          setShowModalBuyLicense(true)
        });
    }
  };

  const setError = (errorMessage) => {
    setShowError(true);
    setShowErrorMessage(errorMessage);
  };

  const clearError = () => {
    setShowError(false);
    setShowErrorMessage(null);
  };

  const addLicenseToRequestList = () => {
    if (!!firstName && !!lastName && !!email) {
      if (validateEmail(email)) {
        let newList = [...props.requestList];
        let newRecord = {
          guest_name: `${firstName} ${lastName}`,
          email_address: email,
        };
        let checkIfEmailExists = newList.filter(
          (list) => list.email_address === newRecord.email_address
        );
        if (!!checkIfEmailExists && !!checkIfEmailExists.length) {
          addToast("Email already added.", {
            appearance: "error",
          });
          setEmail("");
        } else {
          newList.push(newRecord);
          props.setNewRequestList(newList);
          setFirstName("");
          setLastName("");
          setEmail("");
        }
      } else {
        setError("Please enter a valid email");
      }
    } else {
      setError(
        "Please enter all details. i.e, First Name, Last Name and Email."
      );
    }
  };

  const validateEmail = (email) => {
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    if (!emailValidation.test(email.trim())) return false;
    else return true;
  };

  const buyAdditionalLicense = () => {
    let stripe = window.Stripe("pk_test_kDN5G7K0AMgrNM6w5D3Afo0a00q3zIqhxW");
    // stripe key
    stripe
      .redirectToCheckout({
        lineItems: [{ price: "additional-license", quantity: 1 }],
        mode: "payment",
        customerEmail: serviceProvider.email_address,

        successUrl: `https://propagile.com/thanks?status=new&payment=success&title=additional-licence`,
        cancelUrl: `https://propagile.com/thanks?status=new&payment=failed&title=additional-licence`,

        // use below 2 lines in development and for production upper lines will be used.

        // successUrl: `http://localhost:3000/thanks?status=new&payment=success&title=additional-licence`,
        // cancelUrl: `http://localhost:3000/thanks?status=new&payment=failed&title=additional-licence`,
      })
      .then(function (result) {
        if (result.error) {
          console.error(result.error.message);
        }
      });
  };

  // console.log("props ==>", props);
  return (
    <Paper elevation={3} style={{ padding: "0.5rem" }}>
      <div className={styles.main}>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={clearError} className="modal-close"></button>
                <p>{errorMessage}</p>
                <a onClick={clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}

        {showModalBuyLicense && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={()=>{setShowModalBuyLicense(false);clearError()}} className="modal-close"></button>
                <i class="fa fa-exclamation-circle" aria-hidden="true" style={{fontSize:"5rem", color:"#dab511"}}></i>
                <h1 style={{fontSize:"3rem", fontWeight:"800"}}>Oops!</h1>
                <h2 style={{fontSize:"1rem", fontWeight:"600"}}>Could not send Invitation.</h2>
                <p style={{fontStyle:"normal"}}>You need to buy more licenses to add an account manager.</p>
                <a onClick={buyAdditionalLicense} className="btn-fill">
                  BUY
                </a>
              </div>
            </div>
          </div>
        )}

        {membershipName !== "Free" && (
          <>
            <div className={styles.heading} style={{ marginBottom: "15px" }}>{`Licences added`}</div>
            {/*  show table here.... */}

            <LicencesAdded token={token} serviceProvider={serviceProvider} />

            <div className={styles.subTitleDetails}>

            </div>
            <div
              className={styles.heading}
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
            >{`Add a new account manager.`} <span
              style={{ marginLeft: "5px", fontWeight: "bold", color: "black", fontSize: "14px" }}
            >{`(you have ${props.licenseAvailable /* -
        props.requestList
          .length  */
              } more licences available)`}</span></div>

            <div className={styles.licenceDetails}>
              <input
                className={styles.inputField}
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={"First name"}
              />
              <input
                className={styles.inputField}
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={"Last name"}
              />
              <input
                className={styles.inputField}
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={"Name the coverage area"}
              />
              <input
                className={styles.inputField}
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Email"}
              />

              <button
                className={styles.myButton}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  opacity: !!disableAdd ? "0.5" : "1",
                }}
                // onClick={!!disableAdd ? () => { } : (addLicenseToRequestList)}
                onClick={!!disableAdd ? () => { } : licensedAccountCreationRequest}
              >
                Add
              </button>
            </div>
            {/* <NewInvitees token={token} /> */}
            <div
              style={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              <button
                className={styles.myButton}
                style={{
                  marginTop: "1em",
                  display: "none",
                  opacity:
                    !!props.requestList &&
                      !!props.requestList.length &&
                      !disableInvite
                      ? "1"
                      : "0.5",
                }}
                onClick={
                  !!props.requestList &&
                    !!props.requestList.length &&
                    !disableInvite
                    ? licensedAccountCreationRequest
                    : () => {  }
                }
              >
                Invite
              </button>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "2.5em"
                }}
              >
                <b>Need more licenses?
                  <i onClick={buyAdditionalLicense} style={{ cursor: "pointer", color: "#1a67a3" }}> You can buy here.</i></b>
                {/* <button
                  className={styles.myButton}
                  style={{
                    marginTop: "2.5em",
                    //width: "150px",
                    height: "45px",
                    fontSize: "16px",

                  }}
                  onClick={buyAdditionalLicense}
                >
                  Buy more licenses
                </button> */}
              </div>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  console.log("state ==> ", state);
  return {
    licenseAvailable: state.Vendors.licenseAvailable,
    requestList: state.Vendors.requestList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setNewRequestList,
      setLicenseAvailable,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VandorDetails);
