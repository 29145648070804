/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "recompose";
import imageCompression from "browser-image-compression";
import { BASE_URL } from "../../../Constants/serverConfig";
import {
  IMAGE_COMPRESSION_OPTIONS,
  IMAGE_TYPES,
} from "../../../Constants/utils";
import ServiceSteps from "./ServiceSteps";

class PortfolioManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      portfolioFiles: [],
      servicePictureFiles: [],
      selectedPortfolio: null,
      alreadyAddedPortfolios: [],
      selectedActiveService: {},
      showPortfolioDeleteConfirmation: false,
      savePortfolio: false,
      showPictureDeleteConfirmation: false,
      selectedPictureDeleteIndex: -1,
      selectedTypeToDelete: "",
      showImagePicker: false,
      showServiceImages: false,
      imagePickerTab: "portfolio",
      selectedPortfolioFiles: [],
      servicePicturesToDelete: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.maxHardSkillAllowed !== nextProps.maxHardSkillAllowed ||
      this.props.token !== nextProps.token ||
      this.props.servicesPromoted !== nextProps.servicesPromoted ||
      this.props.showInstructions !== nextProps.showInstructions ||
      this.props.showError !== nextProps.showError ||
      this.props.showSuccess !== nextProps.showSuccess ||
      this.props.startLoader !== nextProps.startLoader ||
      this.props.stopLoader !== nextProps.stopLoader ||
      this.props.getServices !== nextProps.getServices ||
      this.props.showDataLossWarning !== nextProps.showDataLossWarning ||
      this.state.files !== nextState.files ||
      this.state.portfolioFiles !== nextState.portfolioFiles ||
      this.state.servicePictureFiles !== nextState.servicePictureFiles ||
      this.state.selectedPortfolio !== nextState.selectedPortfolio ||
      this.state.alreadyAddedPortfolios !== nextState.alreadyAddedPortfolios ||
      this.state.selectedActiveService !== nextState.selectedActiveService ||
      this.state.showPortfolioDeleteConfirmation !==
        nextState.showPortfolioDeleteConfirmation ||
      this.state.savePortfolio !== nextState.savePortfolio ||
      this.state.showPictureDeleteConfirmation !==
        nextState.showPictureDeleteConfirmation ||
      this.state.selectedPictureDeleteIndex !==
        nextState.selectedPictureDeleteIndex ||
      this.state.selectedTypeToDelete !== nextState.selectedTypeToDelete ||
      this.state.showImagePicker !== nextState.showImagePicker ||
      this.state.showServiceImages !== nextState.showServiceImages ||
      this.state.imagePickerTab !== nextState.imagePickerTab ||
      this.state.selectedPortfolioFiles !== nextState.selectedPortfolioFiles ||
      this.state.servicePicturesToDelete !== nextState.servicePicturesToDelete
    );
  }

  componentDidUpdate() {
    let elements = document.getElementsByClassName("popup-item-boxes");
    this.equalHeight(elements);
    const { files } = this.state;
    if (files.length > 0) {
      this.props.showDataLossWarning(true);
    } else {
      this.props.showDataLossWarning(false);
    }
  }

  equalHeight = (elements) => {
    let tallest = 0;
    for (let index = 0; index < elements.length; index++) {
      let thisHeight = elements[index].offsetHeight;
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    }
    for (let index = 0; index < elements.length; index++) {
      let element = elements[index];
      element.style.height = `${tallest}px`;
    }
  };

  removeSelectedPictureConfirmation = (index, image_type) => {
    this.setState({
      showPictureDeleteConfirmation: true,
      selectedPictureDeleteIndex: index,
      selectedTypeToDelete: image_type,
    });
  };

  clearConfirmation = () => {
    this.setState({
      showPortfolioDeleteConfirmation: false,
      showPictureDeleteConfirmation: false,
      selectedPictureDeleteIndex: -1,
      selectedTypeToDelete: "",
    });
  };

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef) {
      childRef(this);
    }
    this.getLatestPortfolios();
    window.addEventListener("beforeunload", this.beforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload);
    const { childRef } = this.props;
    if (childRef) {
      childRef(undefined);
    }
  }

  beforeunload = (e) => {
    const { files } = this.state;
    if (files.length > 0) {
      e.preventDefault();
      e.returnValue = false;
    } else {
      e.returnValue = true;
    }
  };

  getLatestPortfolios = () => {
    const { token, startLoader, stopLoader } = this.props;
    if (token !== null) {
      startLoader();
      axios
        .get(`${BASE_URL}/template/portfolio`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            console.log("response of portfolios => ", response);
            this.setState({
              alreadyAddedPortfolios: response.data,
            });
          }
        })
        .catch((error) => {
          stopLoader();
          console.error(error.response);
        });
    }
  };

  handleFilesUpload = async (event) => {
    const { showError } = this.props;
    const { servicePictureFiles, selectedPortfolioFiles } = this.state;
    let files = [...this.state.files];
    let totalPictures =
      files.filter((x) => x !== undefined).length +
      selectedPortfolioFiles.length +
      servicePictureFiles.length +
      event.target.files.length;
    if (totalPictures > 6) {
      showError("Cannot upload more than 6 pictures");
    } else {
      let newUploadedFiles = [...event.target.files];
      let areFilesValid = true;
      newUploadedFiles.forEach((file) => {
        const fileType = file["type"];
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        if (!validImageTypes.includes(fileType)) {
          areFilesValid = false;
          return;
        }
      });
      if (!areFilesValid) {
        showError(
          "You have uploaded an invalid format file. Please upload gif, jpeg or png image file"
        );
      } else {
        this.setState({
          files: [...files, ...newUploadedFiles],
        });
      }
    }
    await setTimeout(null, 50);
  };

  deletePicture = () => {
    const {
      selectedPictureDeleteIndex,
      selectedTypeToDelete,
      servicePictureFiles,
      servicePicturesToDelete,
    } = this.state;
    console.log("selectedPictureDeleteIndex ==> ", selectedPictureDeleteIndex);
    console.log("selectedTypeToDelete ==> ", selectedTypeToDelete);
    console.log("servicePictureFiles ==> ", servicePictureFiles);
    console.log("servicePicturesToDelete ==> ", servicePicturesToDelete);
    switch (selectedTypeToDelete) {
      case IMAGE_TYPES.SERVICE_IMAGE_NEW:
        this.removePicture(selectedPictureDeleteIndex);
        break;
      case IMAGE_TYPES.PORTFOLIO_IMAGE_SELECTED:
        this.removePortfolioSelectedPicture(selectedPictureDeleteIndex);
        break;
      case IMAGE_TYPES.SERVICE_IMAGE_UPLOADED:
        console.log("Hello from 3rd case");
        this.setState({
          servicePicturesToDelete: [
            ...servicePicturesToDelete,
            servicePictureFiles[selectedPictureDeleteIndex],
          ],
          servicePictureFiles: servicePictureFiles.filter(
            (x) =>
              x.fileName !==
              servicePictureFiles[selectedPictureDeleteIndex].fileName
          ),
        });
        break;
      default:
        break;
    }
    console.log("servicePictureFiles => ", servicePictureFiles);
    this.setState({
      showPictureDeleteConfirmation: false,
    });
  };

  removePicture = (index) => {
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({
      files: [...files],
    });
  };

  removePortfolioSelectedPicture = (index) => {
    let { selectedPortfolioFiles } = this.state;
    selectedPortfolioFiles.splice(index, 1);
    this.setState({
      selectedPortfolioFiles: [...selectedPortfolioFiles],
    });
  };

  removeServicePicture = (fileName) => {
    const { token, startLoader, stopLoader } = this.props;
    if (token !== null) {
      startLoader();
      axios({
        method: "delete",
        url: `${BASE_URL}/supply-chain/portfolio`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          delete_files: fileName,
        },
      })
        .then(() => {
          stopLoader();
        })
        .catch((error) => {
          stopLoader();
          console.error(error);
        });
    }
  };

  savePortfolio = async () => {
    const {
      token,
      showError,
      showSuccess,
      startLoader,
      stopLoader,
    } = this.props;
    const {
      files,
      alreadyAddedPortfolios,
      selectedPortfolioFiles,
      servicePictureFiles,
    } = this.state;
    if (alreadyAddedPortfolios.length >= 10) {
      showError(
        "You can add only 10 predefined portfolios. Please choose an existing or remove existing portfolio."
      );
      return;
    }
    startLoader();
    for (let i = 0; i < files.length; i++) {
      if (files[i] !== undefined) {
        const imageFile = files[i];
        if (imageFile.size / 1024 / 1024 > 3) {
          const compressedFile = await imageCompression(
            imageFile,
            IMAGE_COMPRESSION_OPTIONS
          );
          files[i] = compressedFile;
        }
      }
    }
    stopLoader();
    const formData = this.getFormData(
      "Test",
      "Lorem Ipsum",
      selectedPortfolioFiles,
      servicePictureFiles,
      files
    );
    startLoader();
    axios
      .post(`${BASE_URL}/template/portfolio`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        stopLoader();
        if (response.status === 200) {
          showSuccess("Portfolio saved successfully.");
          this.setState({
            files: [],
            portfolioFiles: [],
            servicePictureFiles: [],
            selectedPortfolio: null,
            alreadyAddedPortfolios: [],
            selectedActiveService: {},
            showPortfolioDeleteConfirmation: false,
            savePortfolio: false,
            showPictureDeleteConfirmation: false,
            selectedPictureDeleteIndex: -1,
            selectedTypeToDelete: "",
            showImagePicker: false,
            showServiceImages: false,
            imagePickerTab: "portfolio",
            selectedPortfolioFiles: [],
          });
          this.getLatestPortfolios();
        }
      })
      .catch((error) => {
        stopLoader();
        if (error.response && typeof error.response.data === "string") {
          showError(error.response.data);
        }
        console.error(error);
      });
  };

  getFormData = (
    name,
    description,
    selectedPortfolioFiles,
    servicePictureFiles,
    files
  ) => {
    let alreadyAddedImages = [];
    var bodyFormData = new FormData();
    bodyFormData.set("name", name);
    bodyFormData.set("description", description);
    if (selectedPortfolioFiles.length > 0 || servicePictureFiles.length > 0) {
      for (let x = 0; x < selectedPortfolioFiles.length; x++) {
        alreadyAddedImages.push(selectedPortfolioFiles[x].path);
      }
      for (let x = 0; x < servicePictureFiles.length; x++) {
        alreadyAddedImages.push(servicePictureFiles[x].path);
      }
      bodyFormData.append("portfolio_path", JSON.stringify(alreadyAddedImages));
    }
    for (let x = 0; x < files.length; x++) {
      if (files[x] !== undefined) {
        bodyFormData.append("files", files[x]);
      }
    }
    return bodyFormData;
  };

  selectPortfolio = (event) => {
    const { alreadyAddedPortfolios } = this.state;
    let portfolio = alreadyAddedPortfolios.find(
      (x) => x.id === Number(event.target.value)
    );
    if (portfolio) {
      this.setState({
        selectedPortfolio: portfolio,
        portfolioFiles: portfolio.files,
      });
    } else {
      this.clearPortfolioSelection();
    }
  };

  selectActiveService = (service) => {
    const { selectedActiveService } = this.state;
    const isAlreadySelected =
      selectedActiveService.selected_service_id === service.selected_service_id;
    if (isAlreadySelected) {
      this.setState({
        selectedActiveService: {},
        servicePictureFiles: [],
      });
    } else {
      this.setState({
        selectedActiveService: service,
        showServiceImages: true,
      });
      this.fetchAllInformationOfService(service.selected_service_id);
    }
  };

  fetchAllInformationOfService = (serviceId) => {
    const { token, startLoader, stopLoader } = this.props;
    if (token !== null) {
      this.getLatestPortfolios();
      startLoader();
      axios
        .get(`${BASE_URL}/supply-chain/service-data/${serviceId}`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          stopLoader();
          this.setState({
            servicePictureFiles: response.data.portfolio_files,
          });
        })
        .catch((error) => {
          stopLoader();
          console.error(error.response);
        });
    }
  };

  renderImages = () => {
    const { files, selectedPortfolioFiles, servicePictureFiles } = this.state;
    let html = [];
    let totalImages = 0;
    servicePictureFiles.forEach((x, i) => {
      if (totalImages < 6) {
        html.push(
          <div
            key={`already-uploaded-${i}`}
            className="portfolio_images clearfix"
          >
            <div
              className="img_thumbnail"
              style={{
                backgroundImage: `url(${x.path})`,
              }}
            >
              <span
                onClick={() =>
                  this.removeSelectedPictureConfirmation(
                    i,
                    IMAGE_TYPES.SERVICE_IMAGE_UPLOADED
                  )
                }
              >
                <i className="fas fa-times" />
              </span>
            </div>
            <p style={{ textAlign: "center", marginTop: 5, color: "#a2a2a2" }}>
              Already uploaded service image
            </p>
          </div>
        );
      }
      totalImages++;
    });
    selectedPortfolioFiles.forEach((x, i) => {
      if (totalImages < 6) {
        html.push(
          <div
            key={`portfolio-selected-${i}`}
            className="portfolio_images clearfix"
          >
            <div
              className="img_thumbnail"
              style={{
                backgroundImage: `url(${x.path})`,
              }}
            >
              <span
                onClick={() =>
                  this.removeSelectedPictureConfirmation(
                    i,
                    IMAGE_TYPES.PORTFOLIO_IMAGE_SELECTED
                  )
                }
              >
                <i className="fas fa-times" />
              </span>
            </div>
            <p style={{ textAlign: "center", marginTop: 5, color: "#a2a2a2" }}>
              Image selected from a portfolio
            </p>
          </div>
        );
      }
      totalImages++;
    });
    files.forEach((x, i) => {
      if (totalImages < 6) {
        html.push(
          <div key={`new-uploaded-${i}`} className="portfolio_images clearfix">
            <div
              className="img_thumbnail"
              style={{
                backgroundImage: `url(${URL.createObjectURL(x)})`,
              }}
            >
              <span
                onClick={() =>
                  this.removeSelectedPictureConfirmation(
                    i,
                    IMAGE_TYPES.SERVICE_IMAGE_NEW
                  )
                }
              >
                <i className="fas fa-times" />
              </span>
            </div>
            <p style={{ textAlign: "center", marginTop: 5, color: "#a2a2a2" }}>
              New uploaded image
            </p>
          </div>
        );
      }
      totalImages++;
    });
    for (let i = totalImages; i < 6; i++) {
      html.push(
        <div key={`not-uploaded-${i}`} className="portfolio_images clearfix">
          <div
            onClick={this.openImagesPopup}
            className="img_thumbnail"
            style={{
              backgroundColor: "#E2ECED",
            }}
          >
            <div className="file-upload-new">
              <img
                src={require("../../../assets/images/upload-icon.png")}
                alt="Upload Icon"
              />
            </div>
          </div>
        </div>
      );
    }
    return html;
  };

  openImagesPopup = () => {
    this.setState({
      showImagePicker: true,
    });
  };

  closeImagePicker = () => {
    this.setState({
      showImagePicker: false,
      files: [],
      selectedPortfolioFiles: [],
    });
  };

  closeShowImages = () => {
    this.setState({
      files: [],
      portfolioFiles: [],
      servicePictureFiles: [],
      selectedPortfolio: null,
      selectedActiveService: {},
      showPortfolioDeleteConfirmation: false,
      savePortfolio: false,
      showPictureDeleteConfirmation: false,
      selectedPictureDeleteIndex: -1,
      selectedTypeToDelete: "",
      showImagePicker: false,
      showServiceImages: false,
      imagePickerTab: "portfolio",
      selectedPortfolioFiles: [],
    });
  };

  saveAndCloseImagePicker = () => {
    this.setState({
      showImagePicker: false,
    });
  };

  toggleSavePortfolioCheckbox = () => {
    this.setState({
      savePortfolio: !this.state.savePortfolio,
    });
  };

  selectPortfolioFile = (file) => {
    const { selectedPortfolioFiles, files, servicePictureFiles } = this.state;
    const { showError } = this.props;
    let index = selectedPortfolioFiles.findIndex(
      (x) => x.fileName === file.fileName
    );
    if (index === -1) {
      let totalPictures =
        files.length +
        servicePictureFiles.length +
        selectedPortfolioFiles.length +
        1;
      if (totalPictures <= 6) {
        this.setState({
          selectedPortfolioFiles: [...selectedPortfolioFiles, file],
        });
      } else {
        showError("Cannot upload more than 6 pictures");
      }
    } else {
      selectedPortfolioFiles.splice(index, 1);
      this.setState({
        selectedPortfolioFiles: [...selectedPortfolioFiles],
      });
    }
  };

  assignPortfolio = async () => {
    const {
      selectedActiveService,
      selectedPortfolio,
      files,
      servicePictureFiles,
      selectedPortfolioFiles,
      servicePicturesToDelete,
    } = this.state;
    const {
      token,
      getServices,
      showError,
      startLoader,
      stopLoader,
      showSuccess,
    } = this.props;
    if (token !== null) {
      if (selectedActiveService === {}) {
        showError("Please choose service to assign to portfolio");
        return;
      }
      if (
        selectedPortfolio === null &&
        files.length === 0 &&
        selectedPortfolioFiles.length === 0 &&
        servicePictureFiles.length === 0
      ) {
        showError("Please add pictures to assign to services");
        return;
      }
      let services = [];
      let alreadyAddedImages = [];
      services.push(this.getFormattedService(selectedActiveService));
      let url = "";
      let bodyFormData = new FormData();
      url = `${BASE_URL}/service/portfolio`;
      for (let i = 0; i < files.length; i++) {
        if (files[i] !== undefined) {
          const imageFile = files[i];
          if (imageFile.size / 1024 / 1024 > 3) {
            const compressedFile = await imageCompression(
              imageFile,
              IMAGE_COMPRESSION_OPTIONS
            );
            files[i] = compressedFile;
          }
        }
      }
      bodyFormData.set("selected_services", JSON.stringify(services));
      for (let x = 0; x < files.length; x++) {
        if (files[x] !== undefined) {
          bodyFormData.append("files", files[x]);
        }
      }
      if (selectedPortfolioFiles.length > 0) {
        for (let x = 0; x < selectedPortfolioFiles.length; x++) {
          alreadyAddedImages.push(selectedPortfolioFiles[x].path);
        }
        bodyFormData.append(
          "portfolio_path",
          JSON.stringify(alreadyAddedImages)
        );
      }
      startLoader();
      axios
        .patch(url, bodyFormData, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          stopLoader();
          if (response.status === 200) {
            servicePicturesToDelete.map((x) =>
              this.removeServicePicture(x.fileName)
            );
            showSuccess("Portfolio assigned successfully.");
            this.setState({
              files: [],
              portfolioFiles: [],
              servicePictureFiles: [],
              selectedPortfolio: null,
              alreadyAddedPortfolios: [],
              selectedActiveService: {},
              showPortfolioDeleteConfirmation: false,
              savePortfolio: false,
              showPictureDeleteConfirmation: false,
              selectedPictureDeleteIndex: -1,
              selectedTypeToDelete: "",
              showImagePicker: false,
              showServiceImages: false,
              imagePickerTab: "portfolio",
              selectedPortfolioFiles: [],
            });
            getServices();
            this.getLatestPortfolios();
          }
        })
        .catch((error) => {
          stopLoader();
          if (error.response && typeof error.response.data === "string") {
            showError(error.response.data);
          }
          console.error(error);
        });
    }
  };

  getFormattedService = (service) => {
    return {
      selected_service_id: service.selected_service_id,
    };
  };

  render() {
    const {
      showPortfolioDeleteConfirmation,
      showPictureDeleteConfirmation,
      selectedActiveService,
      selectedPortfolio,
      showImagePicker,
      imagePickerTab,
      alreadyAddedPortfolios,
      portfolioFiles,
      selectedPortfolioFiles,
      files,
      showServiceImages,
      servicePicturesToDelete,
    } = this.state;
    const { servicesPromoted, maxHardSkillAllowed } = this.props;
    return (
      <React.Fragment>
        <div className="services_main_body three_A_image">
          <div className="container">
            <ServiceSteps
              moveToStep={this.props.moveToStep}
              service={"portfolios"}
            />
            {/* <div class="p_services_selected clearfix">
              <ul id="progressbar">
                <li className="completed">
                  <a onClick={() => this.props.moveToStep(2)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 1
                    </span>{" "}
                    <br />
                    Services Selection
                  </a>
                </li>
                <li className="active">
                  <a onClick={() => this.props.moveToStep(3)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 2
                    </span>{" "}
                    <br />
                    Portfolios
                  </a>
                </li>
                <li>
                  <a onClick={() => this.props.moveToStep(4)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 3
                    </span>{" "}
                    <br />
                    coverage
                  </a>
                </li>
                <li>
                  <a onClick={() => this.props.moveToStep(5)}>
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      Step 4
                    </span>{" "}
                    <br />
                    Service Paremeters
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="s_m_b_head  clearfix">
              <span className="s_m_1">Step 2 of 4:</span>{" "}
              <span className="s_m_2">Portfolio / image management,</span>{" "}
              <span className="s_m_3">
                upload/assign portfolio to active services{" "}
              </span>
            </div>
            <div className="clearfix"></div>
            <div className="clearfix new_sep_dashboard_body">
              <div className="">
                <div className="new-profile-panel-header select_assign_heading">
                  <div className="new_p_tbl_1">
                    <p className="services-selected-title-center">
                      Select service to assign a portfolio or to edit
                    </p>
                  </div>
                  <div className=" new_p_tbl_2">
                    <p className="services-selected-title">
                      Selected services <span>{servicesPromoted.length}</span>{" "}
                      out of <span>{maxHardSkillAllowed}</span>
                    </p>
                  </div>
                </div>
                <div className="segment_steps_body clearfix">
                  {servicesPromoted.map((x) => (
                    <div
                      key={`level:${x.level}:serviceId:${x.serviceId}`}
                      className="popup-item-boxes"
                    >
                      <div
                        onClick={() => this.selectActiveService(x)}
                        className="tbl"
                      >
                        <div
                          onClick={(event) => event.preventDefault()}
                          className="tbl-cell md_ch_2"
                        >
                          <div className="tbl">
                            <div className="tbl-cell">{x.name}</div>
                          </div>
                        </div>
                        <div
                          onClick={(event) => event.preventDefault()}
                          className="tbl-cell md_ch_1"
                        >
                          <div className="md-checkbox">
                            <input
                              id={`level:${x.level}:serviceId:${x.serviceId}`}
                              type="checkbox"
                              onChange={() => {}}
                              onClick={(event) => event.preventDefault()}
                              checked={
                                selectedActiveService.serviceId ===
                                  x.serviceId &&
                                selectedActiveService.level === x.level &&
                                selectedActiveService.selected_service_id ===
                                  x.selected_service_id
                              }
                            />
                            <label
                              htmlFor={`level:${x.level}:serviceId:${x.serviceId}`}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showServiceImages && (
          <div className="modal mainn-map is-active">
            <div className="modal-background" />
            <div className="modal-card">
              <button
                onClick={this.closeShowImages}
                className="delete"
                aria-label="close"
              />
              <section className="modal-card-body">
                <div style={{ padding: 20 }} className="columns is-multiline">
                  <div className="column is-12">
                    <p className="title is-4">Images Selected</p>
                  </div>
                  <div className="column is-12">
                    <div className="prop_3A_updated">
                      <div className="portfolio_main_imgs clearfix">
                        {this.renderImages()}
                      </div>
                      <div className="clearfix further_options">
                        <label
                          onClick={this.savePortfolio}
                          className="control control--checkbox"
                        >
                          <span onClick={(event) => event.preventDefault()}>
                            Save as Portfolio{" "}
                          </span>
                          <input
                            onClick={(event) => event.preventDefault()}
                            type="checkbox"
                          />
                          <div
                            onClick={(event) => event.preventDefault()}
                            className="control__indicator bounceUp"
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix select_line_services_opt">
                  <a
                    onClick={
                      (selectedPortfolioFiles.length !== 0 ||
                        servicePicturesToDelete.length !== 0 ||
                        files.length !== 0) &&
                      selectedActiveService !== {}
                        ? this.assignPortfolio
                        : null
                    }
                    className="select_line_services_opt_a"
                  >
                    Assign & Exit
                  </a>
                </div>
              </section>
            </div>
          </div>
        )}
        {showImagePicker && (
          <div className="modal mainn-map is-active">
            <div className="modal-background" />
            <div className="modal-card">
              <button
                onClick={this.closeImagePicker}
                className="delete"
                aria-label="close"
              />
              <section className="modal-card-body">
                <div
                  style={{ minHeight: "80vh", padding: 20 }}
                  className="columns is-multiline"
                >
                  <div className="column is-12">
                    <div className="tabs image-uploader-tabs is-toggle is-fullwidth">
                      <ul>
                        <li
                          className={
                            imagePickerTab === "portfolio" ? "is-active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              this.setState({
                                imagePickerTab: "portfolio",
                              });
                            }}
                          >
                            <span className="icon is-small">
                              <i
                                className="far fa-file-alt"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>Select Images from Portfolio</span>
                          </a>
                        </li>
                        <li
                          className={
                            imagePickerTab === "new_image" ? "is-active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              this.setState({
                                imagePickerTab: "new_image",
                              });
                            }}
                          >
                            <span className="icon is-small">
                              <i
                                className="fas fa-image"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>Upload New Images</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {imagePickerTab === "portfolio" ? (
                      <div className="columns is-multiline">
                        <div className="column is-12">
                          <p className="title is-4">
                            Please choose a portfolio and then click on images
                            to assign to the service(s)
                          </p>
                        </div>
                        <div className="column is-3">
                          <div className="clearfix further_options">
                            <div className="select is-fullwidth">
                              <select
                                value={
                                  selectedPortfolio !== null
                                    ? selectedPortfolio.id
                                    : -1
                                }
                                onChange={this.selectPortfolio}
                              >
                                <option>Choose a portfolio</option>
                                {alreadyAddedPortfolios.map((x, index) => {
                                  return (
                                    <option key={x.id} value={x.id}>
                                      Portfolio-{index + 1}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="column is-9">
                          <div className="portfolio_main_imgs clearfix">
                            {portfolioFiles.map((image, i) => {
                              let isFileSelected =
                                selectedPortfolioFiles.findIndex(
                                  (x) => x.fileName === image.fileName
                                ) !== -1;
                              return (
                                <div
                                  onClick={() =>
                                    this.selectPortfolioFile(image)
                                  }
                                  key={`already-uploaded-${i}`}
                                  className="portfolio_images clearfix"
                                  style={{
                                    width: "33.3%",
                                  }}
                                >
                                  <div
                                    className="img_thumbnail_new"
                                    style={{
                                      backgroundImage: `url(${image.path})`,
                                    }}
                                  >
                                    {isFileSelected ? (
                                      <span>
                                        <img
                                          src={require("../../../assets/images/selected.png")}
                                          alt="selected"
                                        />
                                      </span>
                                    ) : (
                                      <span>
                                        <img
                                          src={require("../../../assets/images/not-selected.png")}
                                          alt="not-selected"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="columns is-multiline">
                          <div className="column is-12">
                            <p className="title is-4">
                              Please choose a files to upload, the images will
                              be assigned to the service(s)
                            </p>
                          </div>
                          <div className="column is-3">
                            <div className="file">
                              <label className="file-label">
                                <input
                                  multiple={true}
                                  className="file-input"
                                  type="file"
                                  onChange={this.handleFilesUpload}
                                  name="files"
                                  accept="image/jpeg,image/gif,image/png"
                                />
                                <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                  </span>
                                  <span className="file-label">
                                    Choose files…
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="column is-9">
                            {files.map((file, i) => (
                              <div
                                key={`new-uploaded-${i}`}
                                className="portfolio_images clearfix"
                              >
                                <div
                                  className="img_thumbnail"
                                  style={{
                                    backgroundImage: `url(${URL.createObjectURL(
                                      file
                                    )})`,
                                  }}
                                >
                                  <span
                                    onClick={() =>
                                      this.removeSelectedPictureConfirmation(
                                        i,
                                        IMAGE_TYPES.SERVICE_IMAGE_NEW
                                      )
                                    }
                                  >
                                    <i className="fas fa-times" />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="column is-2 is-offset-10">
                  <button
                    onClick={this.saveAndCloseImagePicker}
                    className="button is-medium is-fullwidth is-warning"
                  >
                    Save
                  </button>
                </div>
              </section>
            </div>
          </div>
        )}
        {showPortfolioDeleteConfirmation && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearConfirmation}
                  className="modal-close"
                />
                <p>Are you sure to delete this portfolio?</p>
                <div className="map-buton" style={{ float: "none" }}>
                  <button onClick={this.deletePortfolio} className="btn-fill">
                    Delete
                  </button>
                  <button
                    onClick={this.clearConfirmation}
                    className="btn-empty"
                    style={{
                      backgroundColor: "#fcc75c" /* #f9b62f */,
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showPictureDeleteConfirmation && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearConfirmation}
                  className="modal-close"
                />
                <p>Are you sure to delete this picture?</p>
                <div className="map-buton" style={{ float: "none" }}>
                  <button onClick={this.deletePicture} className="btn-fill">
                    Delete
                  </button>
                  <button
                    onClick={this.clearConfirmation}
                    className="btn-empty"
                    style={{
                      backgroundColor: "#fcc75c " /* #f9b62f */,
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(PortfolioManagement);
