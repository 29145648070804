/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import { SupplyChainMode } from "./utils/constants.tsx";
import PromotedServicesPanel from "./components/promotedServicesPanel";
import ActiveKeywordsPanel from "./components/activeKeywordsPanel";
import ServiceSteps from "../Dashboard/components/ServiceSteps";
import DropdownTreeSelect from 'react-dropdown-tree-select'
import Container from './dropdownTreeSelectComponent';

import './index.css';
let data = require('../../Constants/dataSource.json');

const supplyChainMasterFormat = ({
  mode,
  selectedLineOfServices,
  maxHardSkillAllowed,
  token,
  showError,
  saveServices,
  moveToStep,
  isDummyDashboard,
}) => {
  const [level, setLevel] = useState(1);
  const [tableD, setTableD] = useState(data);
  const [currentParent, setCurrentParent] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0)
  const [arrayChildFirstLevel, setArrayChildFirstLevel] = useState([]);
  const [selectedServicesToPromote, setSelectedServicesToPromote] = useState(
    []
  );
  const [supplyChain, setSupplyChain] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [selectedServiceToExpand, setSelectedServiceToExpand] = useState({});
  const [serviceExpanded, setServiceExpanded] = useState({});
  const [activeKeywords, setActiveKeywords] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/items-wbs-branching/item/table_d/0`)
      .then((response) => {
        //setTableD(response.data);
        setSupplyChain({
          ...supplyChain,
          [level]: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onChangeSelect = (currentNode, selectedNodes) => {
    console.log('onChange::', currentNode)
    console.log('onChanges::', selectedNodes)
    //const listCurrent = [...selectedServicesToPromote,selectedNodes];
    //console.log(listCurrent);
    setSelectedServicesToPromote(selectedNodes)

  }

  const onActionSelect = (node, action) => {

    console.log('onAction::', action, node)
  }
  const onNodeToggleSelect = currentNode => {
    console.log('onNodeToggle::', currentNode)
  }

  const fetchNextData = (item, level, addToSelected = false) => {
    if (level === 2) {
      if (selectedServiceToExpand[1] === `level:1-${item.id}`) {
        if (Object.keys(activeKeywords).length !== 0) {
          setShowPopup(true);
          return;
        }
      }
    }
    axios
      .get(
        `${BASE_URL}/items-wbs-branching/item/${item.next_level}/${item.id}`,
        token !== "" && {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSupplyChain({
          ...supplyChain,
          [level]: response.data,
        });
        setSelectedServiceToExpand({
          ...selectedServiceToExpand,
          [level - 1]: `level:${level - 1}-${item.id}`,
        });
        setLevel(level);
        setShowPopup(true);
        if (addToSelected) {
          selectKeywordsArray(level, response.data);
          removeDuplicatesFromPromotedServices(level, response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateChildrenList = (index) => {

    setArrayChildFirstLevel(tableD[index].children);
    setCurrentParent(tableD[index].name);
    setCurrentIndex(index)
    setShowPopup(true);

  }

  const expandChildren = (level, item) => {
    if (
      serviceExpanded.level === level &&
      serviceExpanded.serviceId === item.id
    ) {
      setServiceExpanded({});
      setSupplyChain({
        ...supplyChain,
        [level + 1]: [],
      });
    } else {
      fetchNextData(item, level + 1);
      setServiceExpanded({
        level: level,
        serviceId: item.id,
      });
    }
  };

  const addRemoveActiveKeywords = (level, item) => {
    let promotedServices = [...selectedServicesToPromote];
    let selectedServicesToPromotedFormatted = selectedServicesToPromote.map(
      (x) => `level:${x.level}-serviceId:${x.serviceId}`
    );
    if (
      selectedServicesToPromotedFormatted.includes(
        `level:${level}-serviceId:${item.id}`
      )
    ) {
      let index = selectedServicesToPromotedFormatted.indexOf(
        `level:${level}-serviceId:${item.id}`
      );
      promotedServices.splice(index, 1);
      setSelectedServicesToPromote([...promotedServices]);
      let servicesToKeep = activeKeywords.filter(
        (x) => x.level === level + 1 && x.parentId !== item.id
      );
      setActiveKeywords([...servicesToKeep]);
    } else {
      if (promotedServices.length < maxHardSkillAllowed) {
        let supplyChainToSendInService = [];
        for (let x = level - 1; x >= 1; x--) {
          const elements = supplyChain[x];
          supplyChainToSendInService.push({
            level: x,
            elements: [...elements],
          });
        }
        setSelectedServicesToPromote([
          ...selectedServicesToPromote,
          {
            supplyChain: supplyChainToSendInService,
            ...item,
            level: level,
            serviceId: item.id,
            selected_service_id: 0,
            uuid: "",
            coverage_selected: false,
            total_zip_codes_selected: 0,
            is_active: false,
            is_complete: false,
            is_default: false,
            service_parameters: {
              dc_classification: null,
              insured: null,
              job_functions: [],
              licensed: null,
              round_clock_service: null,
              union_worker: null,
            },
          },
        ]);
        fetchNextData(item, level + 1, true);
      } else {
        showError(
          `Cannot select more than ${maxHardSkillAllowed} promoted services`
        );
      }
    }
  };

  const onChildrenExpandToAddToActiveKeywords = (
    isParentSelected,
    level,
    item
  ) => {
    if (
      serviceExpanded.level === level &&
      serviceExpanded.serviceId === item.id
    ) {
      setServiceExpanded({});
      setSupplyChain({
        ...supplyChain,
        [level + 1]: [],
      });
    } else if (!isParentSelected) {
      fetchNextData(item, level + 1);
      setServiceExpanded({
        level: level,
        serviceId: item.id,
      });
    } else {
      selectKeywords(level, item);
    }
  };

  const selectKeywordsArray = (level, items) => {
    let services = [...activeKeywords];
    let servicesFormatted = services.map(
      (x) => `level:${x.level}:serviceId:${x.serviceId}:parentId:${x.parentId}`
    );
    items.forEach((item) => {
      services = addToKeywordsList(services, servicesFormatted, level, item);
    });
    setActiveKeywords([...services]);
  };

  const removeDuplicatesFromPromotedServices = (level, items) => {
    setSelectedServicesToPromote((prevState) => {
      let promotedServices = [...prevState];
      let selectedServicesToPromotedFormatted = promotedServices.map(
        (x) => `level:${x.level}-serviceId:${x.serviceId}`
      );
      items.forEach((item) => {
        if (
          selectedServicesToPromotedFormatted.includes(
            `level:${level}-serviceId:${item.id}`
          )
        ) {
          let index = promotedServices.findIndex((x) => {
            return Number(x.level) === level && x.serviceId === item.id;
          });
          if (index !== -1) {
            promotedServices.splice(index, 1);
          }
        }
      });
      return promotedServices;
    });
  };

  const selectKeywords = (level, item) => {
    let services = [...activeKeywords];
    let servicesFormatted = services.map(
      (x) => `level:${x.level}:serviceId:${x.serviceId}:parentId:${x.parentId}`
    );
    let promotedServices = [...selectedServicesToPromote];
    let selectedServicesToPromotedFormatted = promotedServices.map(
      (x) => `level:${x.level}-serviceId:${x.serviceId}`
    );
    if (
      selectedServicesToPromotedFormatted.includes(
        `level:${level}-serviceId:${item.id}`
      )
    ) {
      let index = selectedServicesToPromotedFormatted.indexOf(
        `level:${level}-serviceId:${item.id}`
      );
      promotedServices.splice(index, 1);
    }
    services = addToKeywordsList(services, servicesFormatted, level, item);
    setSelectedServicesToPromote([...promotedServices]);
    setActiveKeywords([...services]);
  };

  const addToKeywordsList = (services, servicesFormatted, level, item) => {
    if (
      services.length > 0 &&
      servicesFormatted.includes(
        `level:${level}:serviceId:${item.id}:parentId:${item.parent_id}`
      )
    ) {
      let index = servicesFormatted.indexOf(
        `level:${level}:serviceId:${item.id}:parentId:${item.parent_id}`
      );
      services.splice(index, 1);
    } else {
      let supplyChainToSendInService = [];
      for (let x = level - 1; x >= 1; x--) {
        const elements = supplyChain[x];
        supplyChainToSendInService.push({
          level: x,
          elements: [...elements],
        });
      }
      services.push({
        supplyChain: supplyChainToSendInService,
        ...item,
        level: level,
        serviceId: item.id,
        parentId: item.parent_id,
        selected_service_id: 0,
        uuid: "",
        coverage_selected: false,
        total_zip_codes_selected: 0,
        is_active: false,
        is_complete: false,
        is_default: false,
        service_parameters: {
          dc_classification: null,
          insured: null,
          job_functions: [],
          licensed: null,
          round_clock_service: null,
          union_worker: null,
        },
      });
    }
    return services;
  };

  const setAsPromotedService = (level, item) => {
    let promotedServices = [...selectedServicesToPromote];
    let selectedServicesToPromotedFormatted = selectedServicesToPromote.map(
      (x) => `level:${x.level}-serviceId:${x.serviceId}`
    );
    if (
      selectedServicesToPromotedFormatted.includes(
        `level:${level}-serviceId:${item.id}`
      )
    ) {
      let index = selectedServicesToPromotedFormatted.indexOf(
        `level:${level}-serviceId:${item.id}`
      );
      promotedServices.splice(index, 1);
      setSelectedServicesToPromote([...promotedServices]);
    } else {
      if (promotedServices.length < maxHardSkillAllowed) {
        let supplyChainToSendInService = [];
        for (let x = level - 1; x >= 1; x--) {
          const elements = supplyChain[x];
          supplyChainToSendInService.push({
            level: x,
            elements: [...elements],
          });
        }
        setSelectedServicesToPromote([
          ...selectedServicesToPromote,
          {
            supplyChain: supplyChainToSendInService,
            ...item,
            level: level,
            serviceId: item.id,
            selected_service_id: 0,
            uuid: "",
            coverage_selected: false,
            total_zip_codes_selected: 0,
            is_active: false,
            is_complete: false,
            is_default: false,
            service_parameters: {
              dc_classification: null,
              insured: null,
              job_functions: [],
              licensed: null,
              round_clock_service: null,
              union_worker: null,
            },
          },
        ]);
      } else {
        showError(
          `Cannot select more than ${maxHardSkillAllowed} promoted services`
        );
      }
    }
  };

  const childItem = (i, x, isParentSelected) => {
    let selectedServicesToPromotedFormatted = selectedServicesToPromote.map(
      (item) => `level:${item.level}-serviceId:${item.serviceId}`
    );
    let servicesFormatted = activeKeywords.map(
      (item) =>
        `level:${item.level}:serviceId:${item.serviceId}:parentId:${item.parentId}`
    );
    return (
      <div
        key={`level:${i}-${x.id}`}
        className={
          (isParentSelected &&
            servicesFormatted.includes(
              `level:${i}:serviceId:${x.id}:parentId:${x.parent_id}`
            )) ||
            (!isParentSelected &&
              selectedServicesToPromotedFormatted.includes(
                `level:${i}-serviceId:${x.id}`
              ))
            ? "popup-item-boxes is_selected_popup"
            : "popup-item-boxes"
        }
        style={{ marginLeft: (i - 2) * 20 }}
      >
        <div className="tbl">
          <div className="tbl-cell md_ch_2">
            <div
              onClick={() =>
                isParentSelected
                  ? selectKeywords(i, x)
                  : setAsPromotedService(i, x)
              }
              className="tbl"
            >
              <div className="tbl-cell">{x.name}</div>
            </div>
          </div>
          <div className="tbl-cell md_ch_1">
            <div
              onClick={() =>
                isParentSelected
                  ? selectKeywords(i, x)
                  : setAsPromotedService(i, x)
              }
              className="md-checkbox"
            >
              <input
                onChange={() => { }}
                onClick={(event) => {
                  event.preventDefault();
                }}
                checked={
                  (isParentSelected &&
                    servicesFormatted.includes(
                      `level:${i}:serviceId:${x.id}:parentId:${x.parent_id}`
                    )) ||
                  (!isParentSelected &&
                    selectedServicesToPromotedFormatted.includes(
                      `level:${i}-serviceId:${x.id}`
                    )) === true
                }
                id={`level:${i}-${x.id}`}
                type="checkbox"
              />
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                htmlFor={`level:${i}-${x.id}`}
              ></label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const childItemWithChildren = (i, x, isParentSelected) => {
    let selectedServicesToPromotedFormatted = selectedServicesToPromote.map(
      (x) => `level:${x.level}-serviceId:${x.serviceId}`
    );
    let servicesFormatted = activeKeywords.map(
      (item) =>
        `level:${item.level}:serviceId:${item.serviceId}:parentId:${item.parentId}`
    );
    return (
      <div
        key={`level:${i}-${x.id}`}
        className={
          servicesFormatted.includes(
            `level:${i}:serviceId:${x.id}:parentId:${x.parent_id}`
          )
            ? "popup-item-boxes is_selected_popup"
            : "popup-item-boxes"
        }
        style={{ marginLeft: (i - 2) * 20 }}
      >
        <div className="tbl">
          <div className="tbl-cell md_ch_2">
            <div
              onClick={() => {
                onChildrenExpandToAddToActiveKeywords(isParentSelected, i, x);
              }}
              className="tbl"
            >
              <div className="tbl-cell">{x.name}</div>
            </div>
          </div>
          {x.can_promote &&
            (selectedServicesToPromotedFormatted.includes(
              `level:${i}-serviceId:${x.id}`
            ) ||
              (serviceExpanded.level === i &&
                serviceExpanded.serviceId === x.id)) && (
              <div className="tbl-cell md_ch_1">
                <div
                  onClick={() => {
                    addRemoveActiveKeywords(i, x);
                  }}
                  className="md-checkbox"
                >
                  <input
                    onChange={() => { }}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    checked={selectedServicesToPromotedFormatted.includes(
                      `level:${i}-serviceId:${x.id}`
                    )}
                    id={`level:${i}-${x.id}`}
                    type="checkbox"
                  />
                  <label
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    htmlFor={`level:${i}-${x.id}`}
                  ></label>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  const parentItemWithoutCheckbox = (i, x) => {
    return (
      <div
        key={`level:${i}-${x.id}`}
        className="popup-item-boxes without_checkbox"
      >
        <div className="tbl">
          <div
            onClick={() => {
              expandChildren(i, x);
            }}
            className="tbl-cell md_ch_2"
          >
            <div className="tbl">
              <div className="tbl-cell">{x.name}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const populateItems = () => {
    let items = [];
    for (let i = 2; i <= level; i++) {
      let parent = undefined;
      if (i !== 2) {
        parent = selectedServiceToExpand[i - 1];
      }
      if (parent) {
        let index = items.findIndex((x) => x.key === parent);
        let parentParts = parent.split("-");
        let selectedServicesToPromotedFormatted = selectedServicesToPromote.map(
          (x) => `level:${x.level}-serviceId:${x.serviceId}`
        );
        let isParentSelected = selectedServicesToPromotedFormatted.includes(
          `${parentParts[0]}-serviceId:${parentParts[1]}`
        );
        items.splice(
          index + 1,
          0,
          ...supplyChain[i].map((x) => {
            return x.next_level === "false"
              ? childItem(i, x, isParentSelected)
              : childItemWithChildren(i, x, isParentSelected);
          })
        );
      } else {
        items.push(
          ...supplyChain[i].map((x) => {
            return parentItemWithoutCheckbox(i, x);
          })
        );
      }
    }
    return items;
  };

  const closePopup = () => {
    saveServices(selectedServicesToPromote, activeKeywords);
    setShowPopup(false);
    setSelectedServicesToPromote([]);
  };



  return (
    <>
      {SupplyChainMode.DEMO !== mode && (
        <ServiceSteps moveToStep={moveToStep} />
      )}
      {showPopup && (
        <div className="modal is-active new_sep_popup mainn-map">
          <div className="modal-background"></div>
          <div
            className="modal-content"
            style={{ width: "90vw", overflow: "initial" }}
          >
            <div
              style={{
                borderRadius: 8,
                padding: 10,
                overflow: "auto",
                minHeight: 390,
                height: "100%",
                overflowX: "hidden",
              }}
              className="has-background-white"
            >
              <div className="columns oneb_popup">
                <div className="column is-5 popup-select">
                  {/* <div style={{ paddingTop: 10 }}>{populateItems()}</div> */}
                  {/* <DropdownTreeSelect id="tradeInput" className='bootstrap-demo' texts={{ placeholder: 'SELECT TRADE', inlineSearchPlaceholder: "Do not find the trade? Search here" }} data={arrayChildFirstLevel}  inlineSearchInput={true} keepTreeOnSearch={true} showDropdown={'always'} id="tradeInput" className='bootstrap-demo' texts={{ placeholder: 'SELECT TRADE', inlineSearchPlaceholder: "Do not find the trade? Search here" }} data={arrayChildFirstLevel}  inlineSearchInput={true} keepTreeOnSearch={true} showDropdown={'always'} onChange={onChangeSelect} onAction={onActionSelect} onNodeToggle={onNodeToggleSelect}></DropdownTreeSelect> */}
                  <Container data={arrayChildFirstLevel} onChange={onChangeSelect} onAction={onActionSelect} onNodeToggle={onNodeToggleSelect} />
                  <div style={{ height: '500px' }}></div>
                </div>
                <div className="column is-7 oneb_popup_right popup-cont">

                  <PromotedServicesPanel
                    promotedService={selectedServicesToPromote}
                    currentParent={currentParent}
                    currentIndex={currentIndex}
                    data={tableD}
                  />
                  <div className="map-buton">
                    <button
                      type="button"
                      onClick={closePopup}
                      className="btn-fill"
                    >
                      Save
                    </button>
                  </div>
                  {/* <ActiveKeywordsPanel
                    mode={mode}
                    activeKeywords={activeKeywords}
                  /> */}
                </div>
              </div>
            </div>
            <button
              onClick={() => setShowPopup(false)}
              className="delete"
              aria-label="close"
            ></button>
          </div>
        </div>
      )}
      <div
        className={
          SupplyChainMode.DEMO !== mode
            ? "services_main_body new_sep_dashboard_body"
            : " new_sep_dashboard_body"
        }
      >
        <div className="clearfix">
          {SupplyChainMode.DEMO !== mode && (
            <div className="new-profile-panel-header">
              <div className="new_p_tbl_1">
                <p className="services-selected-title-center">
                  Select up to <span>{maxHardSkillAllowed}</span> services to be
                  promoted on your profile.
                </p>
              </div>
              <div className=" new_p_tbl_2">
                <p className="services-selected-title">
                  Selected services <span>{selectedLineOfServices}</span> out of{" "}
                  <span>{maxHardSkillAllowed}</span>
                </p>
              </div>
            </div>
          )}
          <div className="clearfix"></div>
          <div className="segment_steps_body clearfix">
            {tableD.map((x, index) => {
              return (
                <a
                  key={x.id}
                  onClick={() => {
                    // fetchNextData(x, 1 + 1); old version
                    updateChildrenList(index);
                  }}
                >
                  <div className="tbl">
                    <div className="tbl-cell">{x.name}</div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default supplyChainMasterFormat;
