import React, { useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader";
import Header from "../Header/header";
import BaseForm from "./BaseForm";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "64px",
    color: "#1a67a3",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
    },
  },
  subHeading: {
    fontSize: "34px",
    color: "#1a67a3",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  banner: {
    backgroundColor: "#1a67a3",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  bannerText: {
    fontSize: "24px",
    color: "white",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  yellowText: {
    color: "#fcc75c",
    fontWeight: 600,
  },
  checkboxLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  checkBoxSpan: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    marginLeft: "6px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
  importantNotesTitle: {
    fontSize: "11px",
    textAlign: "center",
  },
  importantNotesPoints: {
    fontSize: "11px",
  },
  importantNotesBox: {
    margin: "auto",
    width: "fit-content",
  },
  formikInput: {
    width: "100%",
  },
  button: {
    border: "3px solid red",
    borderRadius: "12px",
    backgroundColor: "#fcc75c ",
    color: "red",
    fontSize: "22px",
    fontWeight: 600,
    textTransform: "none",
    marginTop: "20px",
  },

  formButtons: {
    border: "1px solid #1a67a3",
    color: "#1a67a3",
    background: "#f8fafb none repeat scroll 0 0",
    display: "inline-block",
    fontSize: "16px",
    fontweight: "600",
    margin: "0 0 15px 0",
    padding: "5px 15px",
    textTransform: "uppercase",
    width: "auto",
    "&:hover": {
      background: "#1a67a3 none repeat scroll 0 0",
      color: "#f8fafb",
    },
  },
  selectedTradeField: {
    backgroundColor: "#e6e6e6",
    width: "fit-content",
    padding: "0 0 0 15px",
  },
}));

const InvitationByForemen = ({
  isUserLoggedIn,
  isSpLoggedIn,
  handleLogout,
  serviceProvider,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      {!!isSpLoggedIn || !!isUserLoggedIn ? (
        <Header
          isDark={true}
          isUserLoggedIn={isUserLoggedIn}
          isSpLoggedIn={isSpLoggedIn}
          handleLogout={handleLogout}
          serviceProvider={serviceProvider}
        />
      ) : (
        <NewHeader />
      )}
      <Box style={{ minHeight: "85vh" }}>
        <Box textAlign="center">
          <Typography className={classes.heading}>
            For Foremen, By Invitation Only!
          </Typography>
          <Typography className={classes.subHeading}>
            YOU'VE BEEN INVITED TO PROMOTE SERVICES ONLINE AND BUILD TEAMS FOR
            FREE
          </Typography>
        </Box>
        <Box textAlign="center" className={classes.banner}>
          <Typography className={classes.bannerText}>
            Standard membership worth $1,200 is{" "}
            <span className={classes.yellowText}>free for one year</span> upon
            fulfilment of minimum requirements below
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          style={{ padding: "10px 30px", width: "100%" }}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} />
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Box style={{ display: "flex" }}>
              <CheckBox />
              <Typography className={classes.checkboxLabel}>
                Promote at least one service and upload 6 related portfolio
                images
              </Typography>
            </Box>
            <Box style={{ display: "flex" }}>
              <CheckBox />
              <Typography className={classes.checkboxLabel}>
                Get 5 endorsements
                <span className={classes.checkBoxSpan}>
                  (We've made this step easy to achieve)
                </span>
              </Typography>
            </Box>
            <Box style={{ display: "flex" }}>
              <CheckBox />
              <Typography className={classes.checkboxLabel}>
                Get 3 reviews from former or current customers
                <span className={classes.checkBoxSpan}>
                  (We've made this step easy to achieve)
                </span>
              </Typography>
            </Box>
            <Box style={{ display: "flex" }}>
              <CheckBox />
              <Typography className={classes.checkboxLabel}>
                Build a team of 5
                <span className={classes.checkBoxSpan}>
                  (We've made this step easy to achieve)
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ padding: "10px 30px", width: "100%" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              className={classes.importantNotesTitle}
              style={{ textAlign: "center" }}
            >
              IMPORTANT NOTES:
            </Typography>
            <Box className={classes.importantNotesBox}>
              <Typography className={classes.importantNotesPoints}>
                1. Profile view and promotion only available upon fulfillment of
                requirements
              </Typography>
              <Typography className={classes.importantNotesPoints}>
                2. Limited to 3 invitations per trade per metropolitan area
              </Typography>
              <Typography className={classes.importantNotesPoints}>
                3. First 3 profiles to fulfill requirements will get the
                membership for free
              </Typography>
              <Typography className={classes.importantNotesPoints}>
                4. Incomplete profiles will be removed after 30 days
              </Typography>
              <Typography className={classes.importantNotesPoints}>
                5. Payment method is required for verification purposes, you
                will be charged $1
              </Typography>
              <Box style={{ textAlignLast: "center" }}>
                <Button
                  className={classes.button}
                  onClick={() => setOpen(true)}
                >
                  Send me the invitation
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Base Form that opens in a dialog */}
        <BaseForm classes={classes} open={open} setOpen={setOpen} />
      </Box>

      <Footer />
    </>
  );
};

export default InvitationByForemen;
