import React from "react";

const FreeFeatures = () => {
  return (
    <>
      {/* Ticket # 794 requires to add and/or update text in this component - Nov 16th, 2022 - Old text is commented */}
      <div className="new_feaut">
        <h5 className="feature">Features</h5>
        <div className="pricing-list ">
          <ul>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Labor ONLY (No companies, contractors or subs allowed)
              {/* (workers, students. All levels of experience) */}
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Must complete requirements below for profile promotion
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Promote Up to 3{" "}
              <span className="promo_span"> (Labor level) </span> Skilled or
              Unskilled services
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Promote Labor level function only
            </li>
            {/*  <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Profile promotion enabled upon completion of requirements
            </li> */}
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Single License only
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Service coverage <span className="promo_span_2"> Up To 100 </span>{" "}
              Zip codes
            </li>
          </ul>

          <label className="sub-label-pricing">
            Requirements to enable profile and promotion:
          </label>
          <ul>
            <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Upload minimum (1) portfolio image per service offered
            </li>
            <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Obtain (2) endorsements to enable profile
              {/*               <span className="border-bottom-span-pricing">
                <i>And</i>
              </span>{" "}
              (2) reviews */}{" "}
              (we made it simple)
            </li>

            <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Obtain (2) reviews to enable profile (we made it simple)
            </li>
            {/* <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Complete this requirements within 30 days
            </li> */}
          </ul>
          <label
            className="sub-label-pricing"
            style={{ marginTop: 10, color: "#fff", marginBottom: 20 }}
          >
            (Incomplete profiles will be removed after 30 days)
          </label>
        </div>
      </div>

      {/* from subscriptionUpgrade.jsx
       <div className="new_feaut">
        <h5 className="feature">Features</h5>
        <div className="pricing-list ">
          <ul>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Labor ONLY (workers, students. All levels of experience)
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Profile promotion enabled upon completion of requirements
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Promote Up to 3{" "}
              <span className="promo_span"> (Labor level) </span> Skilled or
              Unskilled services
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Service coverage <span className="promo_span_2"> Up To 100 </span>{" "}
              Zip codes
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              {/*  Ticket # 786 requires us to remove these texts 
              {/* Be added to projects (October 2020) 
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              {/*  Ticket # 786 requires us to remove these texts
              {/*  Be added to favorites (October 2020)
            </li>
          </ul>
          <label className="sub-label-pricing">
            Requirements to enable profile and promotion:
          </label>
          <ul>
            <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Upload minimum (1) portfolio image per service offered
            </li>
            <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Obtain (2) endorsements{" "}
              <span className="border-bottom-span-pricing">
                <i>And</i>
              </span>{" "}
              (2) reviews (we made it simple)
            </li>
            <li className="sub-list-item-pricing">
              <span style={{ fontSize: "0.25rem" }} className="list_check">
                <i className="fas fa-circle" />
              </span>
              Complete this requirements within 30 days
            </li>
          </ul>
          <label
            className="sub-label-pricing"
            style={{ marginTop: 10, color: "#fff", marginBottom: 20 }}
          >
            (Incomplete profiles will be removed after 30 days)
          </label>
        </div>
      </div> */}
    </>
  );
};

export default FreeFeatures;
