import React, { useState, useEffect } from "react";
import _ from "lodash";

const PromotedServicesPanel = ({ promotedService, currentParent, currentIndex, data }) => {

  const [promotedServices, setPromotedServices] = useState([]);

  useEffect(() => {

    //const groupPromotedTrade = _.groupBy(promotedService, 'parent_code')
    setPromotedServices(arrayAgrupado);
    //console.log(arrayAgrupado);

  }, [promotedService])

  const arrayAgrupado = promotedService.reduce((acumulador, objeto) => {
    // Verificar si ya existe un grupo con el mismo ID
    const grupoExistente = acumulador.find(grupo => grupo.parent_code === objeto.parent_code);

    // Si el grupo ya existe, agregar el objeto al grupo existente
    if (grupoExistente) {
      grupoExistente.elementos.push(objeto);
    } else {
      // Si el grupo no existe, crear uno nuevo y agregar el objeto
      const childParent = data[currentIndex].children.find(grupo => grupo.masterformat_code === objeto.parent_code);

      acumulador.push({ parent_code: objeto.parent_code, name: childParent.name, elementos: [objeto] });
    }

    return acumulador;
  }, []);

  return (
    <div className="form-group">
      {/* <div className="new-profile-panel-header"> */}
      <div className="">
        <div className="new_p_tbl_1">
          {/* <p className="services-selected-title-center">
            Selected Trade Panel
          </p> */}
          <h3 className="services-selected-title-center">
            Selected Trade Panel
          </h3>
        </div>
      </div>
      {/* <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="parent-label">
          <h6>
            {currentParent}
          </h6>
        </div>
        <div className="child-label">
          <h6>
            {currentParent}
          </h6>
        </div>
      </div> */}
      {
        promotedServices !== undefined &&
        promotedServices.map((childs, index) => (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="parent-label">
                <h6>
                  {currentParent}
                </h6>
              </div>
              <div className="child-label" style={{padding: `10px ${childs.name.length > 20 ? '100px':'60px'} 10px 25px`}}>
                <h6>
                  {childs.name}
                </h6>
              </div>
            </div>

            <div className="segment_steps_body clearfix">
              {childs !== undefined &&
                childs.elementos.map((x, index) => (               

                    <div
                      // key={`active-services-selected-${x.serviceId}-${x.level}`}
                      key={`active-services-selected-${index}`}
                      className="popup-item-boxes"
                    >

                      <div className="tbl">
                        <div className="tbl-cell md_ch_2">
                          <div className="tbl">
                            <div className="tbl-cell">{x.name}</div>

                          </div>
                        </div>
                      </div>
                    </div>
                 
                ))}
            </div>
          </>
        ))
      }
      {/* <div className="text-center">
        <p>service being promoted in your profile</p>
      </div> */}
    </div>
  );
};

export default PromotedServicesPanel;
