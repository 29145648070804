//USER ACTIONS
const SAVE_USER = "SAVE_USER";
const UPDATE_USER_PROFILE_PICTURE = "UPDATE_USER_PROFILE_PICTURE";
const REMOVE_USER = "REMOVE_USER";

//SERVICE PROVIDER ACTIONS
const SAVE_SERVICE_PROVIDER = "SAVE_SERVICE_PROVIDER";
const UPDATE_SERVICE_PROVIDER_PROFILE_PICTURE =
  "UPDATE_SERVICE_PROVIDER_PROFILE_PICTURE";
const REMOVE_SERVICE_PROVIDER = "REMOVE_SERVICE_PROVIDER";

//TOKEN ACTIONS
const SAVE_TOKEN = "SAVE_TOKEN";
const REMOVE_TOKEN = "REMOVE_TOKEN";

//VENDOR DETAILS ACTIONS
const SET_NEW_INVITEES = "SET_NEW_INVITEES";
const SET_LICENSE_AVAILABLE = "SET_LICENSE_AVAILABLE";

export {
  SAVE_USER,
  UPDATE_USER_PROFILE_PICTURE,
  REMOVE_USER,
  SAVE_SERVICE_PROVIDER,
  UPDATE_SERVICE_PROVIDER_PROFILE_PICTURE,
  REMOVE_SERVICE_PROVIDER,
  SAVE_TOKEN,
  REMOVE_TOKEN,
  SET_NEW_INVITEES,
  SET_LICENSE_AVAILABLE,
};
