/* eslint-disable jsx-a11y/anchor-is-valid */

// Packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Components
import Footer from "../Footer";
import Header from "../Header/header";
import NewHeader from "../Header/NewHeader";
import { Box } from "@material-ui/core";

class LeadLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWhatPopup: false,
    };
  }

  ref = React.createRef();
  headerRef = React.createRef();

  componentDidMount() {
    const script = document.createElement("script");
    script.id = "page-sense";
    script.src =
      "https://cdn.pagesense.io/js/propagile/a104ce787de64435be353cddd7e6b745.js";
    script.async = true;
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    const script = document.getElementById("page-sense");
    document.head.removeChild(script);
  }

  render() {
    const {
      isUserLoggedIn,
      isSpLoggedIn,
      serviceProvider,
      handleLogout,
    } = this.props;
    const { showWhatPopup } = this.state;
    return (
      <div>
        <div className={`modal mainn-map ${showWhatPopup ? "is-active" : ""}`}>
          <div className="modal-background" />
          <div className="modal-card">
            <button
              onClick={() => {
                this.setState({
                  showWhatPopup: false,
                });
              }}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>
                  Customers needs you and you need them! <br /> PropAGILE will
                  help you find each other.
                </h1>
                <div
                  className="area-names"
                  style={{ width: 700, maxWidth: "100%" }}
                >
                  <p>
                    To make search results meaningful, the supply chain must be
                    populated with vendors at each metropolitan area, until this
                    milestone is achieved the supply chain will have the status
                    "Under-Launch", and vendors will join at 75% discount
                  </p>
                  <p>
                    All functions are operable during "Under-Launch" status,
                    only search is disabled. You can create a profile, select
                    the services you provide, upload images to show your work,
                    select zip codes of coverage and have an online profile
                    instantly; with a single click you can share your portfolio
                    with friends, family and customers via email or social
                    networks, and also with a single click you can build your
                    online reputation by requesting professional endorsements
                    and customer reviews, your profile will be populated with
                    their responses.
                  </p>
                  <p>
                    Once sufficient vendors have populated the supply chain at
                    each area, the status "Under-Launch" will be removed,
                    customer search will be enabled and 75% discount will NOT
                    longer apply
                  </p>
                  <div className="map-buton">
                    <button
                      onClick={() => {
                        this.setState({
                          showWhatPopup: false,
                        });
                      }}
                      style={{
                        backgroundColor: "#fcc75c " /* #f9b62f */,
                        color: "#fff",
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {serviceProvider &&
        (serviceProvider.user_type === 5 || serviceProvider.user_type === 6) ? (
          !!isSpLoggedIn || !!isUserLoggedIn ? (
            <Header
              isDark={true}
              isUserLoggedIn={isUserLoggedIn}
              isSpLoggedIn={isSpLoggedIn}
              handleLogout={handleLogout}
              serviceProvider={serviceProvider}
            />
          ) : (
            <NewHeader />
          )
        ) : !!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            isDark={true}
          />
        ) : (
          <NewHeader />
        )}

        <div
          ref={this.headerRef}
          className="home_banner"
          style={{ minHeight: " 100vh" }}
        >
          <div className="home_ban_body clearfix">
            <div className="container clearfix">
              <div className="home_ban_body_1" style={{ padding: "0" }}>
                <h3>A marketplace for everyone in construction!</h3>
                <h1>
                  Promote your services <br /> without the need of tech experts!
                </h1>
              </div>
              <div
                className="learmore_div"
                style={{
                  width: "200px",
                  backgroundColor: "#ffffffd4",
                  borderRadius: "10px",
                }}
              >
                <Link
                  onClick={() => {
                    window.pagesense = window.pagesense || [];
                    window.pagesense.push(["trackEvent", "learn more"]);
                  }}
                  style={{ borderRadius: "10px" }}
                  to="/vendor/subscriptions"
                >
                  START HERE!
                </Link>
                <p className="learnmore_p2">
                  <Link
                    onClick={() => {
                      window.pagesense = window.pagesense || [];
                      window.pagesense.push([
                        "trackEvent",
                        "see sample profile",
                      ]);
                    }}
                    to="/profile/edit"
                  >
                    (See your own sample profile)
                  </Link>
                </p>
              </div>
            </div>

            {/* <div className="container no_space_top">
              <div className="home_ban_body_2 clearfix">
                
              </div>
            </div> 
             <div className="container no_space_top">
              <div className="home_ban_body_2 clearfix">
                <div className="learmore_div">
                  <p className="learnmore_p1">
                    Connect services with property renovations like never before
                  </p>
                  <p className="learnmore_p2">
                    NO NEED FOR EXPERTS,
                    <span> Promote your services in minutes</span>{" "}
                    <Link
                      onClick={() => {
                        window.pagesense = window.pagesense || [];
                        window.pagesense.push([
                          "trackEvent",
                          "see sample profile",
                        ]);
                      }}
                      to="/profile/edit"
                    >
                      (See your own sample profile)
                    </Link>
                  </p>
                  <p className="learnmore_p3">
                    Join for free or get the membership that best suit you
                  </p>
                  <Link
                    onClick={() => {
                      window.pagesense = window.pagesense || [];
                      window.pagesense.push(["trackEvent", "learn more"]);
                    }}
                    to="/vendor/subscriptions"
                  >
                    learn more
                  </Link>
                </div>
                <div className="demo_div">
                  <p>We are ready to help</p>
                  <a href="#">schedule a demo </a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="demo_div">
            <a href="#">schedule a demo </a>
          </div>
        </div>
        <Box style={{ marginTop: "auto" }}>
          <Footer />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default connect(mapStateToProps, null)(LeadLanding);
