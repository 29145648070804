const workFunctionOptions = [
  { value: "Cleaning", label: "Cleaning" },
  { value: "Polishing", label: "Polishing" },
  { value: "Removal", label: "Removal" },
  { value: "Demolition", label: "Demolition" },
  {
    value: "Equipment Operator",
    label: "Equipment Operator",
  },
  {
    value: "Maintenance",
    label: "Maintenance",
  },
  {
    value: "Repair",
    label: "Repair",
  },
  {
    value: "Restoration",
    label: "Restoration",
  },
  {
    value: "Installation",
    label: "Installation",
  },
  {
    value: "Replacement",
    label: "Replacement",
  },
  {
    value: "Construction",
    label: "Construction",
  },
  {
    value: "Custom Fabrication",
    label: "Custom Fabrication",
  },
  {
    value: "Assembly",
    label: "Assembly",
  },
  {
    value: "Skilled Labor",
    label: "Skilled Labor",
  },
  {
    value: "Unskilled Laborer",
    label: "Unskilled Laborer",
  },
];

const leadershipFunctionOptions = [
  {
    value: "Project Supervisor",
    label: "Project Supervisor",
  },
  {
    value: "Superintendent",
    label: "Superintendent",
  },
  {
    value: "Handyman",
    label: "Handyman",
  },
  {
    value: "Foreman",
    label: "Foreman",
  },
  {
    value: "Crew leader",
    label: "Crew leader",
  },
  {
    value: "Sub-contractor",
    label: "Sub-contractor",
  },
];

const managementFunctionOptions = [
  {
    value: "Scrum Master",
    label: "Scrum Master",
  },
  {
    value: "Agile Coach",
    label: "Agile Coach",
  },
  {
    value: "General Manager",
    label: "General Manager",
  },
  {
    value: "Contractor",
    label: "Contractor",
  },
  {
    value: "Project Manager",
    label: "Project Manager",
  },
  {
    value: "Coordinator",
    label: "Coordinator",
  },
];

export {
  workFunctionOptions,
  leadershipFunctionOptions,
  managementFunctionOptions,
};
