import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import IndividualInformation from "./components/individualInformation";
import Services from "./components/services";
import Promotion from "./components/promotion";
import NewHeader from "../Header/NewHeader.jsx";
import NewFooter from "../Footer/NewFooter.jsx";

class IndividualProfile extends Component {
  render() {
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
          />
        ) : (
          <NewHeader />
        )}
        <section className="root">
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <p className="subtitle is-4 has-text-centered has-text-black">
                  Portfolio completeness will enhance your online presence
                </p>
              </div>
            </div>
            <IndividualInformation serviceProvider={serviceProvider} />
            <hr />
            <Services />
            <hr />
            <Promotion />
          </div>
          <br />
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default connect(mapStateToProps, null)(IndividualProfile);
