import React, { useEffect, useState } from 'react'
import axios from "axios";
import { BASE_URL } from '../../../Constants/serverConfig';
import TreeTrades from './TreeTrades';
const DATA_TRADES = require('../../../Constants/dataSource.json');
function Teams({ token, showError, showSuccess, clearMessage, serviceProvider, saveServiceProvider }) {

    const [showPopup, setShowPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentTradeIndex, setCurrentTradeIndex] = useState(50);
    const [currentTradeValue, setCurrentTradeValue] = useState(50);
    const [teamsList, setTeamsList] = useState([]);
    const [teamsListToDelete, setTeamsListToDelete] = useState([]);
    const [tradePathSelected, setTradePathSelected] = useState();
    const [tradePathSelectedID, setTradePathSelectedID] = useState();
    const [viewMode, setViewMode] = useState(false);
    const [teamName, setTeamName] = useState("");

    useEffect(() => {

        getAllTeams();


    }, []);

    useEffect(() => {

        console.log(teamsList.length);
        if (teamsList.length) {
            setCurrentStep(3);
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
    }, [teamsList]);


    const getAllTeams = () => {
        axios
            .get(`${BASE_URL}/teams`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setTeamsList(response.data.data)
            })
            .catch((error) => {

            });
    }

    const closePopup = () => {
        setShowPopup(false);
    }

    const closeViewPopup = () => {
        setShowPopup(false);
        setViewMode(false);
        setTradePathSelected([]);
        setTradePathSelectedID(0);
    };

    const handleShowPopup = () => {
        setCurrentStep(1);
        setShowPopup(true);
    };
    const handleTeamName = (evt) => {
        setTeamName(evt.target.value);
    };

    const onChange = (evt) => {
        setCurrentTradeIndex(evt.target.options[evt.target.selectedIndex].id)
        setCurrentTradeValue(evt.target.value)
    }

    const handleNextStep = () => {
        setCurrentStep(2);
    }

    const handleBackStep = () => {
        setCurrentStep(1);
    }

    const updateGroupSelection = (data) => {

        setTradePathSelected(data);
    }

    const saveNewTeam = () => {

        axios.post(`${BASE_URL}/teams`,
            {
                name: teamName,
                trade_name: DATA_TRADES[currentTradeIndex].name,
                trade_masterformat_code: DATA_TRADES[currentTradeIndex].masterformat_code,
                trade: JSON.stringify(tradePathSelected)
            }, {
            headers: {
                authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                getAllTeams();
                closeViewPopup();
            })
            .catch((error) => {

            });

    }

    const updateTeam = () => {

        axios.patch(`${BASE_URL}/teams/` + tradePathSelectedID,
            {
                name: teamName,
                trade: JSON.stringify(tradePathSelected)
            }, {
            headers: {
                authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                console.log(response);
                getAllTeams();
                closeViewPopup();
            })
            .catch((error) => {

            });

    }

    const addListToDelete = (evt, teamID) => {

       
        if (evt.target.checked) {
            setTeamsListToDelete([...teamsListToDelete, teamID.id]);
        } else {
            const updateList = teamsListToDelete.filter(item => item !== teamID.id);
            setTeamsListToDelete(updateList);
        }


    }

    const deleteTeams = (teamID) => {
        console.log('hiii');
        axios.delete(`${BASE_URL}/teams`,
            {
                headers: {
                    authorization: `Bearer ${token}`
                },
                data: {

                    id: [teamID.id]

                }
            })
            .then((response) => {
                getAllTeams();
                
            })
            .catch((error) => {

            });

    }

    const showTradePath = (data, name, id) => {


        setTradePathSelected(data);
        setTradePathSelectedID(id);
        setTeamName(name);
        setViewMode(true);
        setShowPopup(true);

    }

    const StepOne = () => {
        return (
            <div className="step-one form-group">
                <h5 className='my-4'>Team Name</h5>
                <p style={{ textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <input className="form-control" autoFocus value={teamName} onChange={handleTeamName}></input>
                <h5 className='my-4'>Selected a trade</h5>
                <p style={{ textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                </p>
                <select className="form-control" onChange={onChange} value={currentTradeValue}>
                    {/* Mapea el array de opciones y crea un elemento option por cada elemento */}
                    <option id={50} value={50} disabled selected>Choose an option</option>
                    {DATA_TRADES.map((option, index) => (
                        <option key={index} id={index} value={option.path}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
        )
    }

    const StepZero = () => {
        return (
            <div>
                <p style={{ textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <div className="map-buton ">
                    <button
                        type="button"
                        onClick={handleShowPopup}
                        className="btn-fill"
                    >
                        Create My Team
                    </button>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className={`modal small-popup ` + (showPopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className="modal-card ">
                    <section className="modal-card-body home-modal">
                        <div className="endos_panel2 clearfix">

                            {currentStep == 0 &&
                                <StepZero></StepZero>
                            }
                            {currentStep == 1 &&
                                <StepOne></StepOne>
                            }
                            {currentStep == 2 &&
                                <TreeTrades data={[DATA_TRADES[currentTradeIndex]]} saveGroup={updateGroupSelection}></TreeTrades>
                            }
                            {viewMode &&
                                <TreeTrades data={typeof tradePathSelected === 'object' ? tradePathSelected : JSON.parse(tradePathSelected)} saveGroup={updateGroupSelection}></TreeTrades>
                            }
                            <br />

                            <div className="map-buton ">
                                {currentStep == 1 &&
                                    <>
                                        <button
                                            type="button"
                                            onClick={closePopup}
                                            className="btn-fill exit-trade-modal"

                                        >
                                            Exit
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleNextStep}
                                            className="btn-fill"
                                            disabled={currentTradeIndex == 50 ? true : false}
                                        >
                                            Next
                                        </button>
                                    </>
                                }
                                {currentStep == 2 &&
                                    <><button
                                        type="button"
                                        onClick={handleBackStep}
                                        className="btn-fill"
                                    >
                                        Back
                                    </button>
                                        <button
                                            type="button"
                                            onClick={saveNewTeam}
                                            className="btn-fill"
                                        >
                                            Save
                                        </button>
                                    </>
                                }
                                {viewMode &&
                                    <><button
                                        type="button"
                                        onClick={closeViewPopup}
                                        className="btn-fill"
                                    >
                                        Exit
                                    </button>
                                        <button
                                            type="button"
                                            onClick={updateTeam}
                                            className="btn-fill"
                                        >
                                            Update
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className='services_main_body'>
                <div className="container">
                    <div className='d-flex flex-row justify-content-between'>
                        <h3 className='my-3'>Teams</h3>
                        <div>
                            {/* <button className='btn btn-danger my-3' onClick={deleteTeams}>Remove Team</button> */}
                            <button className='btn btn-primary my-3' onClick={() => { setCurrentStep(1); setShowPopup(true); }}>New Team</button>
                        </div>

                    </div>

                    {teamsList.length == 0 &&
                        <StepZero></StepZero>
                    }
                    {teamsList.length > 0 &&
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Created</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Trade</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teamsList.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <th scope="row">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" onClick={(e) => { addListToDelete(e, item) }} id="defaultCheck1" />
                                                </div>
                                            </th> */}
                                            <td>{item.created_at}</td>
                                            <td>{item.name}</td>
                                            <td>{item.main_trade}</td>
                                            <td>
                                                {/* <i class="fa fa-trash" style={{marginRight:"10px", cursor:"pointer"}} onClick={() => { deleteTeams(item) }}></i>
                                                <i class="fas fa-eye" ></i> */}
                                                
                                                <button style={{border:"0px"}}  onClick={() => showTradePath(item.trade, item.name, item.id)}><i class="fas fa-eye" ></i></button>
                                                <button style={{border:"0px"}}  onClick={() => () => { deleteTeams(item)}}><i class="fa fa-trash" ></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    }
                    {/* <button onClick={() => { setShowPopup(true); }}>
                        Show tree
                    </button> */}

                </div>
            </div>
        </React.Fragment>
    )
}

export default Teams