import React from "react";
import Swiper from "react-id-swiper";

const params = {
  grabCursor: true,
  centeredSlides: false,
  slidesPerView: 8,
  activeSlideKey: "1",
  navigation: {
    nextEl: "#js-next1",
    prevEl: "#js-prev1"
  },
  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    640: {
      slidesPerView: 4,
      spaceBetween: 20
    },
    992: {
      slidesPerView: 8,
      spaceBetween: 30
    }
  },
  spaceBetween: 30,
  containerClass: "swiper-container"
};

const services = [
  {
    id: 1,
    name: "Architecture, drafting and 3D modeling services",
    imagePath: require("../../assets/images/1.jpg")
  },
  {
    id: 2,
    name: "BIM services (Architecture)",
    imagePath: require("../../assets/images/2.jpg")
  },
  {
    id: 3,
    name: "Boiler inspection services",
    imagePath: require("../../assets/images/3.jpg")
  },
  {
    id: 4,
    name: "Concrete construction finishes",
    imagePath: require("../../assets/images/4.jpg")
  },
  {
    id: 5,
    name: "Demolition and waste removal services",
    imagePath: require("../../assets/images/5.jpg")
  },
  {
    id: 6,
    name: "Floor installation services",
    imagePath: require("../../assets/images/6.jpg")
  },
  {
    id: 7,
    name: "Kitchen design",
    imagePath: require("../../assets/images/7.jpg")
  },
  {
    id: 8,
    name: "Painting and plastering",
    imagePath: require("../../assets/images/8.jpg")
  },
  {
    id: 9,
    name: "Plumbing installation and repair services",
    imagePath: require("../../assets/images/9.jpg")
  },
  {
    id: 10,
    name: "Property insurance services",
    imagePath: require("../../assets/images/10.jpg")
  },
  {
    id: 11,
    name: "Scaffold Services",
    imagePath: require("../../assets/images/11.jpg")
  },
  {
    id: 12,
    name: "Wall and floor framing",
    imagePath: require("../../assets/images/12.jpg")
  }
];

const SampleServices = () => {
  return (
    <section>
      <div className="container is-fullhd">
        <div className="columns">
          <div className="column is-12">
            <Swiper {...params}>
              {services &&
                services.map(service => {
                  return (
                    <div key={service.id}>
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100px"
                        }}
                        src={service.imagePath}
                        alt="services"
                      />
                      <br />
                      <p className="has-text-centered" style={{ fontSize: 12 }}>
                        {service.name}
                      </p>
                    </div>
                  );
                })}
            </Swiper>
            <div
              id="js-prev1"
              style={{ outline: "none" }}
              className="swiper-button-prev"
            ></div>
            <div
              id="js-next1"
              style={{ outline: "none" }}
              className="swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SampleServices;
