/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "recompose";
import axios from "axios";
import { BASE_URL, CLIENT_TEAM_URL } from "../../Constants/serverConfig.jsx";
import ChangePassword from "./components/changePassword.jsx";
import IndividualInformation from "../ServiceProviders/components/individualInformation.jsx";
import { saveServiceProvider } from "../../Actions/serviceProvider.jsx";
import Promotion from "../ServiceProviders/components/promotion.jsx";
import Loader from "../Loader/loader.jsx";
import Header from "../Header/header";
import PortfolioManagement from "./components/portfolio-management.jsx";
import PortfolioManagementPanel from "../PortfolioManagement/index";
import ServicesKeywordsManagement from "./components/servicesKeywordsManagement.jsx";
// import CoverageManagement from "./components/coverage-management.jsx";
import CoverageManagement from "./components/CoverageManagemenet.jsx";

import ParametersManagement from "./components/parameters-management.jsx";
import { SERVICEPROVIDER, TAB_KEY } from "../../Constants/localStorageKeys.jsx";
import SecureLS from "secure-ls";
import SupplyChainMasterFormat from "../SupplyChain/supplyChainMasterFormat";
import { SupplyChainMode } from "../SupplyChain/utils/constants.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
/*custom css*/

import "./components/dashboard.css";
import CompanyInfo from "./components/CompanyInfo.jsx";
import VandorDetails from "./components/VandorDetails.jsx";
import ProfileQrCode from "../ProfileQrCode/index.jsx";
import NewHeader from "../Header/NewHeader.jsx";
import NewFooter from "../Footer/NewFooter.jsx";
import { Box } from "@material-ui/core";
import OnlineReputationAndPromotionalTools from "../ServiceProviders/components/OnlineReputationAndPromotionalTools.jsx";
import Teams from "./components/Teams.jsx";
/*custom js*/

class BusinessDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: "",
      action: "",
      selectedService: "",
      type: "",
      activeServices: [],
      inactiveServices: [],
      activeMaps: [],
      inactiveMaps: [],
      showError: false,
      errorMessage: "",
      membershipInfo: {},
      profileStatus: "",
      showSuccess: false,
      showLoader: false,
      message: "",
      password: "",
      selectedTab: "personalInfo",
      requestMembershipSelection: false,
      selectedLineOfServices: [],
      step: 0,
      showInstructions: false,
      activeKeywords: [],
      inActiveKeywords: [],
      servicesPromoted: [],
      serverResponseActiveKeywords: [],
      serverResponseInActiveKeywords: [],
      serverResponseServicesPromoted: [],
      showDataLossWarning: false,
      showCompanyInfo: false,
      showVendorDetails: false,
      showChangePassword: false,
      currentMenuSelected: null,
      showSideBar: true,
      toggleServices: false,
      togglePersonalInfo: false,
      marginLeftMenuMobile: isMobile ? -320 : 'auto',
      buttonMenuMobileLeft: 0,
      classArrowMenu: 'fas fa-chevron-right'
    };
    this.headerChildRef = React.createRef();
    this.portfolioManagementRef = React.createRef();
  }

  componentDidMount() {
    // const { showLoader } = this.state;
    this.setState({ showLoader: true });
    this.getServices();

    const _selectedTab = localStorage.getItem(TAB_KEY);
    const { serviceProvider } = this.props;
    if (serviceProvider !== null) {
      /*  
        commented piece of code is old logic. New logic is implemented according to revisions suggested by PropAgile Team. 
        New Logic: No content should be displayed when user first comes to dashboard and/or click on services tab from dashboard.
        updated: new logic mentioned above is again asked to be reverted in ticket#746
        update Oct,12th - Ticket 756 wants personal info to be displayed by default after login.

        let tab = localStorage.getItem(TAB_KEY, "services");
        this.setState({
          membershipInfo: serviceProvider.membership_information,
          selectedTab:
            tab !== null && tab !== "" && tab !== undefined ? tab : "services",
        });
        */
      if (_selectedTab === "personalInfo") {
        this.setState({
          membershipInfo: serviceProvider.membership_information,
          selectedTab: "personalInfo",
          currentMenuSelected: "companyInfo",
        });
      } else if (_selectedTab === "services") {
        this.setState({
          membershipInfo: serviceProvider.membership_information,
          selectedTab: "services",
        });
      } else if (_selectedTab === "promotion") {
        this.setState({
          membershipInfo: serviceProvider.membership_information,
          selectedTab: "promotion",
        });
      } else if (_selectedTab === "portfolio") {
        this.setState({
          membershipInfo: serviceProvider.membership_information,
          selectedTab: "portfolio",
        });
      }
    }
    const script = document.createElement("script");
    script.id = "page-sense";
    script.src =
      "https://cdn.pagesense.io/js/propagile/a104ce787de64435be353cddd7e6b745.js";
    script.async = true;
    document.head.appendChild(script);
    this.setState({ showLoader: false });
  }

  componentWillUnmount() {
    const script = document.getElementById("page-sense");
    document.head.removeChild(script);
  }

  getServices = () => {
    const { token } = this.props;
    if (token !== null) {
      axios
        .get(`${BASE_URL}/supply-chain/selected-service`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const { step } = this.state;
            let result = this.parseServerResponse(response.data);
            this.setState({
              servicesPromoted: result.services,
              activeKeywords: result.keywords,
              serverResponseServicesPromoted: result.services,
              serverResponseActiveKeywords: result.keywords,
              step: step > 2 ? step : result.services.length > 0 ? 2 : 1,
            });
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
      axios
        .get(`${BASE_URL}/potential-user/service`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let result = this.parseInActiveKeywordsServerResponse(response.data);
          this.setState({
            inActiveKeywords: result,
          });
        })
        .catch((error) => {
          console.error(error.response);
        });
      axios
        .get(`${BASE_URL}/supply-chain/map`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.setState({
            activeMaps: response.data,
          });
        })
        .catch((error) => {
          console.error(error.response);
        });
      axios
        .get(`${BASE_URL}/potential-user/map`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.setState({
            inactiveMaps: response.data,
          });
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };

  parseServerResponse = (data) => {
    let services = [];
    let keywords = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let level = 0;
      let level_branch = element.level_branch;
      let keys = Object.keys(level_branch).filter((x) => x.includes("level"));
      let nextLevelInfo = level_branch[keys[0]][0];
      let parentId = nextLevelInfo.id;
      while (keys.length > 0) {
        level++;
        keys = Object.keys(nextLevelInfo).filter((x) => x.includes("level"));
        if (level === 2) {
          parentId = nextLevelInfo.id;
        }
        if (keys.length === 0) {
          break;
        }
        nextLevelInfo = nextLevelInfo[keys[0]][0];
      }
      let service = {
        can_promoted: element.is_promoted,
        id: element.item_id,
        next_level: element.next_level,
        name: element.label_to_show.split("_")[1],
        item_group: "",
        keyboard: "",
        selected_service_id: element.selected_service_id,
        serviceId: element.item_id,
        supplementary_sibling: null,
        parent_id: parentId,
        level: level,
        uuid: element.uuid,
        coverage_selected: element.coverage_selected,
        total_zip_codes_selected: element.total_zip_codes_selected,
        zip_code: element.zip_code,
        is_active: element.is_active,
        is_complete: element.is_complete,
        is_default: element.is_default,
        service_parameters: element.service_parameters,
        single_map: element.single_map,
      };
      if (service.can_promoted === true) {
        services.push(service);
      } else {
        keywords.push(service);
      }
    }
    return { services, keywords };
  };

  parseInActiveKeywordsServerResponse = (data) => {
    let keywords = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let level = 0;
      let level_branch = element.level_branch;
      let keys = Object.keys(level_branch).filter((x) => x.includes("level"));
      let nextLevelInfo = level_branch[keys[0]][0];
      let parentId = nextLevelInfo.id;
      while (keys.length > 0) {
        level++;
        keys = Object.keys(nextLevelInfo).filter((x) => x.includes("level"));
        if (level === 2) {
          parentId = nextLevelInfo.id;
        }
        if (keys.length === 0) {
          break;
        }
        nextLevelInfo = nextLevelInfo[keys[0]][0];
      }
      level_branch = element.level_branch;
      keys = Object.keys(level_branch).filter((x) => x.includes("level"));
      nextLevelInfo = level_branch[keys[0]][0];
      let service = {
        id: nextLevelInfo.id,
        next_level: element.next_level,
        name: element.label_to_how.split("_")[1],
        item_group: "",
        keyboard: "",
        selected_service_id: element.selected_service_id,
        serviceId: nextLevelInfo.id,
        supplementary_sibling: null,
        parent_id: parentId,
        level: level,
        uuid: element.uuid,
        coverage_selected: element.coverage_selected,
        total_zip_codes_selected: element.total_zip_codes_selected,
        is_active: element.is_active,
        is_complete: element.is_complete,
        is_default: element.is_default,
        service_parameters: element.service_parameters,
      };
      keywords.push(service);
    }
    return keywords;
  };

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  showSuccess = (message) => {
    this.setState({
      showSuccess: true,
      message,
    });
  };

  clearMessage = () => {
    this.setState({
      showSuccess: false,
      message: "",
    });
  };

  showInstructions = () => {
    this.setState({
      showInstructions: true,
    });
  };

  clearInstruction = () => {
    this.setState({
      showInstructions: false,
    });
  };

  setCurrentVisitingTab = (tabName) => {
    localStorage.setItem(TAB_KEY, tabName);
  };

  getCurrentVisitingTab = () => {
    return localStorage.getItem("tab");
  };

  showDataLossWarning = (value) => {
    this.setState({
      showDataLossWarning: value,
    });
  };

  moveToNextStep = () => {
    const { step, servicesPromoted, showDataLossWarning } = this.state;
    if (showDataLossWarning) {
      if (window.confirm("Do you really want to leave?")) {
        this.move(step, servicesPromoted);
        this.setState({
          showDataLossWarning: false,
        });
      }
    } else {
      this.move(step, servicesPromoted);
    }
  };

  move = (step, servicesPromoted) => {
    switch (step) {
      case 1:
        if (servicesPromoted.length === 0) {
          this.showError("Please choose a service to promote");
          return;
        }
        this.setState({
          step: step + 1,
        });
        break;
      case 2:
        this.setState({
          step: step + 1,
        });
        break;
      case 3:
        this.setState({
          step: step + 1,
        });
        break;
      case 4:
        this.setState({
          step: step + 1,
        });
        break;
      default:
        break;
    }
  };

  jump = (step, servicesPromoted) => {
    switch (step) {
      case 1:
        this.setState({
          step,
        });
        break;
      case 2:
        if (servicesPromoted.length === 0) {
          this.showError("Please choose a service to promote");
          return;
        }
        this.setState({
          step,
        });
        break;
      case 3:
        if (servicesPromoted.length === 0) {
          this.showError("Please choose a service to promote");
          return;
        }
        this.setState({
          step,
        });
        break;
      case 4:
        if (servicesPromoted.length === 0) {
          this.showError("Please choose a service to promote");
          return;
        }
        this.setState({
          step,
        });
        break;
      case 5:
        if (servicesPromoted.length === 0) {
          this.showError("Please choose a service to promote");
          return;
        }
        this.setState({
          step,
        });
        break;
      default:
        break;
    }
  };

  moveToStep = (step) => {
    const { servicesPromoted, showDataLossWarning } = this.state;
    if (showDataLossWarning) {
      if (window.confirm("Do you really want to leave?")) {
        this.jump(step, servicesPromoted);
        this.setState({
          showDataLossWarning: false,
        });
      }
    } else {
      this.jump(step, servicesPromoted);
    }
  };

  updateServiceProviderMembershipInfo = (data) => {
    const { serviceProvider, saveServiceProvider } = this.props;
    serviceProvider.city = data.city;
    serviceProvider.code = data.code;
    serviceProvider.company_description = data.company_description;
    serviceProvider.company_name = data.company_name;
    serviceProvider.company_page = data.company_page;
    serviceProvider.contact_email = data.contact_email;
    serviceProvider.coupon = data.coupon;
    serviceProvider.email_address = data.email_address;
    serviceProvider.email_status = data.email_status;
    serviceProvider.first_name = data.first_name;
    serviceProvider.id = data.id;
    serviceProvider.last_name = data.last_name;
    serviceProvider.last_seen_at = data.last_seen_at;
    serviceProvider.profile_image = data.profile_image;
    serviceProvider.profile_status = data.profile_status;
    serviceProvider.request_membership_selection =
      data.request_membership_selection;
    serviceProvider.state = data.state;
    serviceProvider.street_address = data.street_address;
    serviceProvider.user_type = data.user_type;
    serviceProvider.username = data.username;
    serviceProvider.vendor_contact_number = data.vendor_contact_number;
    serviceProvider.vendor_email = data.vendor_email;
    serviceProvider.vendor_last_name = data.vendor_last_name;
    serviceProvider.vendor_name = data.vendor_name;
    serviceProvider.vendor_type = data.vendor_type;
    serviceProvider.zip_code = data.zip_code;
    serviceProvider.membership_information.additional_method_remaining =
      data.membership_information.additional_method_remaining;

    serviceProvider.membership_information.hard_skill_remaining =
      data.membership_information.hard_skill_remaining;

    serviceProvider.membership_information.max_hard_skill_allowed =
      data.membership_information.max_hard_skill_allowed;

    serviceProvider.membership_information.max_method_per_hard_skill_allowed =
      data.membership_information.max_method_per_hard_skill_allowed;

    serviceProvider.membership_information.max_size_project_allowed =
      data.membership_information.max_size_project_allowed;

    serviceProvider.membership_information.max_zip_code_allowed =
      data.membership_information.max_zip_code_allowed;

    serviceProvider.membership_information.zip_code_remaining =
      data.membership_information.zip_code_remaining;

    serviceProvider.membership_information.us_metro_area_remaining =
      data.membership_information.us_metro_area_remaining;

    serviceProvider.membership_information.metro_area_selected =
      data.membership_information.metro_area_selected;

    if (data.vendor_type !== undefined) {
      serviceProvider.vendor_type = data.vendor_type;
    }
    this.setState({
      membershipInfo: serviceProvider.membership_information,
    });
    this.getServices();

    saveServiceProvider(serviceProvider);
    let ls = new SecureLS();
    ls.set(SERVICEPROVIDER, serviceProvider);
  };

  getServiceTabHeading = (step) => {
    switch (step) {
      case 1:
        return <h1>Step 1 of 5: Services Selection</h1>;
      case 2:
        return <h1>Step 2 of 5: Promoted services and keywords</h1>;
      case 3:
        return <h1>Step 3 of 5: Portfolio/image management</h1>;
      case 4:
        return <h1>Step 4 of 5: Service coverage</h1>;
      case 5:
        return <h1>Step 5 of 5: Service Paremeters</h1>;
      default:
        return null;
    }
  };

  saveServices = (services, keywords) => {
    const { token } = this.props;
    this.makeApiCallToSaveServices(services, keywords, token);
  };

  makeApiCallToSaveServices(services, keywords, token) {
    let activeServices = this.formatServices(true, services);
    let activeKeywords = this.formatServices(false, keywords);
    let body = {
      selected_services: [
        {
          active_service: [...activeServices, ...activeKeywords],
          inactive_service: [],
        },
      ],
    };
    body = JSON.stringify(body);
    this.startLoader();
    axios
      .post(`${BASE_URL}/service/business-service`, body, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.showSuccess("Services add successfully");
          this.getServices();
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(error.response.data);
      });
  }

  formatServices(canPromoted, services) {
    let activeServices = [];
    services.forEach((element) => {
      let chain = { can_promoted: canPromoted };
      let parentId = element.parent_id;
      let currentId = element.id;
      for (let index = 0; index < element.supplyChain.length; index++) {
        const chainElements = element.supplyChain[index];
        let parentElement = chainElements.elements.find(
          (x) => x.id === parentId
        );
        if (parentElement === undefined) {
          chain = {
            ...chain,
          };
          break;
        }
        chain = {
          ...chain,
          [`level_${parentElement.next_level.split("_")[1]}`]: currentId,
        };
        parentId = parentElement.parent_id;
        currentId = parentElement.id;
        if (parentId === undefined) {
          chain = {
            ...chain,
            level_d: currentId,
          };
          break;
        }
      }
      activeServices.push(chain);
    });
    return activeServices;
  }

  navigateToProfile = () => {
    this.props.history.push(`/pro1/${this.props.serviceProvider.username}`);
  };

  handleServices = () => {
    this.setState({
      selectedTab: "services",
      toggleServices: !this.state.toggleServices,
      togglePersonalInfo: false,
    });
    this.setCurrentVisitingTab("services");
  };
  handlePersonalInfo = () => {
    this.setState({
      selectedTab: "personalInfo",
      togglePersonalInfo: !this.state.togglePersonalInfo,
      toggleServices: false,
    });
    this.setCurrentVisitingTab("personalInfo");
  };

  handlePromotion = () => {
    this.setState({
      selectedTab: "promotion",
      togglePersonalInfo: false,
      toggleServices: false,
    });
    this.setCurrentVisitingTab("promotion");
  };
  handleTeam = () => {
    this.setState({
      selectedTab: "teams",
      togglePersonalInfo: false,
      toggleServices: false,
    });
    this.setCurrentVisitingTab("teams");
  };

  handlePortfolio = () => {
    this.setState({
      selectedTab: "portfolio",
      togglePersonalInfo: false,
      toggleServices: false,
    });
    this.setCurrentVisitingTab("portfolio");
  };

  showMobilePanel = () => {

    if (this.state.marginLeftMenuMobile == 0) {
      this.setState({
        marginLeftMenuMobile: isMobile ? -320 : 'auto',
        buttonMenuMobileLeft: 0,
        classArrowMenu: 'fas fa-chevron-right'
      })
    } else {
      this.setState({
        marginLeftMenuMobile: isMobile ? 0 : 'auto',
        buttonMenuMobileLeft: 310,
        classArrowMenu: 'fas fa-chevron-left'
      })
    }
  }

  render() {
    const {
      showError,
      errorMessage,
      showSuccess,
      message,
      selectedTab,
      showLoader,
      servicesPromoted,
      requestMembershipSelection,
      step,
      showSideBar,
      togglePersonalInfo,
      toggleServices,
    } = this.state;
    const {
      handleLogout,
      serviceProvider,
      isUserLoggedIn,
      isSpLoggedIn,
      token,
    } = this.props;
    console.log();
    return (
      <React.Fragment>
        <div
          className={`modal small-popup ${requestMembershipSelection === true ? "is-active" : ""
            } `}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>
                  Welcome{" "}
                  {serviceProvider && serviceProvider !== null
                    ? `${serviceProvider.first_name} ${serviceProvider.last_name}`
                    : ""}
                </h1>
                <br />
                <p className="has-text-weight-normal has-text-justified">
                  It seems like you do not have any membership associated to
                  your account. Please choose a membership or start a free trial
                  to unlock your full potential.
                </p>
                <div className="map-buton">
                  <button
                    onClick={this.redirectToMemberships}
                    className="btn-fill"
                  >
                    Join now
                  </button>
                  <button onClick={this.activateTrial} className="btn-fill">
                    Free Trial
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Loader text="Loading..." open={showLoader} />
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <Box style={{ display: "flex" }}>
          {/* Left hand side panel */}
          <div
            style={{ flex: 1, marginLeft: this.state.marginLeftMenuMobile }}
            className={`left_dashboard_menu chiller-theme toggled ${!showSideBar ? "trigger_left_dashboard_menu" : ""
              } `}
          >
            <a id="show-sidebar" href="javascript:void(0)" onClick={this.showMobilePanel} style={{ left: this.state.buttonMenuMobileLeft }}>

              <i class="fas fa-bars"></i>
            </a>
            <nav id="sidebar" class="sidebar-wrapper">
              {/* <div
              onClick={() => {
                this.setState({
                  ...this.state,
                  showSideBar: !showSideBar,
                });
              }}
            >
              <i className="fa fa-times" style={{ cursor: "pointer" }}></i>
            </div> */}
              <div className="input_search">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control"
                />
                <i className="fa fa-search"></i>
              </div>
              <div className="menu_barr">
                <div className="p_tabs">
                  <a href="/vendor/dashboard">
                    <i className="fa fa-tachometer-alt"></i> Dashboard
                  </a>
                  <div class="sidebar-dropdown">
                    <a
                      onClick={this.handlePersonalInfo}
                      className={
                        selectedTab === "personalInfo" ? "p_tab_active " : ""
                      }
                    >
                      <i className="fa fa-user"></i>
                      Personal Information
                      <FontAwesomeIcon
                        icon={
                          !!togglePersonalInfo ? faChevronUp : faChevronDown
                        }
                        style={{
                          paddingLeft: "0.5rem",
                        }}
                      />
                    </a>
                    <div
                      class="sidebar-submenu"
                      style={{
                        display: !!togglePersonalInfo ? "contents" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                currentMenuSelected: "companyInfo",
                              })
                            }
                          >
                            Company Info
                          </a>
                        </li>
                        {serviceProvider.membership_information
                          .membership_id === 3 &&
                          !!serviceProvider.can_manage_licenses && (
                            <li>
                              <a
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    currentMenuSelected: "vendorDetails",
                                  })
                                }
                              >
                                License Manager
                              </a>
                            </li>
                          )}
                        <li>
                          <a
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                currentMenuSelected: "changePassword",
                              })
                            }
                          >
                            Change Password
                          </a>
                        </li>
                        <li>
                          <ProfileQrCode serviceProvider={serviceProvider} />
                        </li>

                      </ul>
                    </div>
                  </div>
                  <div className="sidebar-dropdown">
                    <a
                      onClick={this.handleServices}
                      className={`${selectedTab === "services" ? "p_tab_active" : ""
                        } d-none`}
                    >
                      <i className="fa fa-cogs"></i>
                      Services
                      <FontAwesomeIcon
                        icon={!!toggleServices ? faChevronUp : faChevronDown}
                        style={{ paddingLeft: "0.5rem" }}
                      />
                    </a>
                    <div
                      className={`sidebar-submenu`}
                      style={{
                        display: !!this.state.toggleServices
                          ? "contents"
                          : "none",
                      }}
                    >
                      <ul>
                        <li>
                          <a
                            onClick={() => this.moveToStep(2)}
                            className={step === 2 ? "p_select_active" : ""}
                          >
                            Services Selection
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => this.moveToStep(3)}
                            className={step === 3 ? "p_select_active" : ""}
                          >
                            Services Portfolios
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => this.moveToStep(4)}
                            className={step === 4 ? "p_select_active" : ""}
                          >
                            Services Coverage
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => this.moveToStep(5)}
                            className={step === 5 ? "p_select_active" : ""}
                          >
                            Service Paremeters
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a
                    onClick={this.handleTeam}
                    className={
                      selectedTab === "teams" ? "p_tab_active" : ""
                    }
                  >
                    {" "}
                    <i className="fas fa-signal"></i>
                    Teams
                  </a>
                  <a
                    onClick={this.handlePromotion}
                    className={
                      selectedTab === "promotion" ? "p_tab_active" : ""
                    }
                  >
                    {" "}
                    <i className="fas fa-signal"></i>
                    Online reputation and promotional tools
                  </a>

                  <a
                    onClick={this.handlePortfolio}
                    className={
                      selectedTab === "portfolio" ? "p_tab_active" : ""
                    }
                  >
                    <i class="fa fa-briefcase"></i>
                    Portfolio Management tools
                  </a>

                  {/* FALTA validar membresia business */}
                  <a href={CLIENT_TEAM_URL + "?tk_auth=" + serviceProvider.token_login} target="_blank">
                    <i class="fa fa-briefcase"></i>
                    Building Construction Teams
                  </a>

                </div>
              </div>
            </nav>
          </div>

          {/* <Box style={{ minHeight: "20vh", backgroundColor: "#1a67a3" }} /> */}

          {/* right hand side container */}
          <Box style={{ flex: 4, position: "relative" }}>
            {(selectedTab === "personalInfo" ||
              selectedTab === "promotion") && (
                <div className={`services_main_body c_p`}>
                  <div className="container">
                    {selectedTab === "promotion" &&
                      serviceProvider &&
                      serviceProvider !== null && (
                        // <Promotion
                        <OnlineReputationAndPromotionalTools
                          servicesPromoted={servicesPromoted}
                          token={token}
                          saveServiceProvider={saveServiceProvider}
                          serviceProvider={serviceProvider}
                          showSuccess={this.showSuccess}
                          clearMessage={this.clearMessage}
                        />
                      )}
                    {selectedTab !== "promotion" &&
                      this.state.currentMenuSelected === "companyInfo" ? (
                      serviceProvider.membership_information.membership_id ===
                        3 ? (
                        <CompanyInfo
                          serviceProvider={serviceProvider}
                          token={token}
                          saveServiceProvider={
                            this.updateServiceProviderMembershipInfo
                          }
                        />
                      ) : (
                        <IndividualInformation
                          token={token}
                          saveServiceProvider={
                            this.updateServiceProviderMembershipInfo
                          }
                          serviceProvider={serviceProvider}
                          handleLogout={handleLogout}
                        />
                      )
                    ) : selectedTab !== "promotion" &&
                      this.state.currentMenuSelected === "changePassword" ? (
                      <ChangePassword
                        token={token}
                        showError={this.showError}
                        showSuccess={this.showSuccess}
                        clearMessage={this.clearMessage}
                      />
                    ) : selectedTab !== "promotion" &&
                      this.state.currentMenuSelected === "vendorDetails" ? (
                      <VandorDetails
                        token={token}
                        showError={this.showError}
                        showSuccess={this.showSuccess}
                        clearMessage={this.clearMessage}
                        serviceProvider={serviceProvider}
                        saveServiceProvider={
                          this.updateServiceProviderMembershipInfo
                        }
                      />
                    ) : null}

                  </div>
                </div>
              )}
            {selectedTab === "portfolio" &&
              serviceProvider &&
              serviceProvider !== null && <PortfolioManagementPanel />}
            {selectedTab === "services" && this.renderServices()}
            {selectedTab === "teams" &&
              serviceProvider &&
              serviceProvider !== null && (
                <Teams token={token}
                  showError={this.showError}
                  showSuccess={this.showSuccess}
                  clearMessage={this.clearMessage}
                  serviceProvider={serviceProvider}
                  saveServiceProvider={
                    this.updateServiceProviderMembershipInfo
                  } />
                // <Promotion
                // <OnlineReputationAndPromotionalTools
                //   servicesPromoted={servicesPromoted}
                //   token={token}
                //   saveServiceProvider={saveServiceProvider}
                //   serviceProvider={serviceProvider}
                //   showSuccess={this.showSuccess}
                //   clearMessage={this.clearMessage}
                // />
              )}
          </Box>
        </Box>
        <NewFooter />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                {errorMessage.includes("upgrade") ? (
                  <a
                    onClick={
                      this.redirectToMembershipsAndSaveCurrentInformation
                    }
                    className="btn-fill"
                  >
                    Upgrade
                  </a>
                ) : (
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        {showSuccess && (
          <div className="modal is-active custom-modal success-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="success-message-modal">
                <button onClick={this.clearMessage} className="modal-close" />
                <p>{message}</p>
                <a onClick={this.clearMessage} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderServices() {
    const {
      step,
      showInstructions,
      activeKeywords,
      inActiveKeywords,
      servicesPromoted,
    } = this.state;
    const { token, serviceProvider } = this.props;
    return (
      <React.Fragment>
        {showInstructions && (
          <div className="modal is-active mainn-map">
            <div className="modal-background" />
            <div className="modal-card">
              <button
                onClick={this.clearInstruction}
                className="delete"
                aria-label="close"
              />
              <section className="modal-card-body home-modal">
                <div className="endos_panel2 clearfix">
                  {step === 1 && (
                    <React.Fragment>
                      <p className="has-text-left">
                        We defragmented the entire supply chain of services by
                        dividing it into segments, sub-segments, lines of
                        services and services to enable modularity in supply and
                        demand. Here are some examples:{" "}
                      </p>
                      <p className="has-text-left">
                        1. Segments (Due Diligence inspections, Architectural
                        Design & Compliance, Construction Management, etc.)
                      </p>
                      <p className="has-text-left">
                        2. Sub-Segments (Pre/Post & during construction
                        Inspections, Surveys & Site documentation, Construction
                        Admin & Procurement, etc.)
                      </p>
                      <p className="has-text-left">
                        3. Lines of services (General inspections, 3D point
                        cloud Scan & registration, Construction Supervision,
                        etc.).
                      </p>
                      <p className="has-text-left">
                        You can select a total maximum of 3 lines of services
                        which can be picked from different segments or
                        sub-segments
                      </p>
                    </React.Fragment>
                  )}
                  {step === 2 && (
                    <p className="has-text-left">
                      "You can have active and inactive services, and move them
                      from active to inactive to match your needs. You will be
                      ranked based on profile accuracy, completeness, reviews
                      and customer interaction; add only services you will show
                      work for to better benefit from PropAgile's ranking
                      system"
                    </p>
                  )}
                  {step === 3 && (
                    <p className="has-text-left">
                      STEP 3 INSTRUCTIONS HERE....
                    </p>
                  )}
                  {step === 4 && (
                    <p className="has-text-left">
                      STEP 4 INSTRUCTIONS HERE....
                    </p>
                  )}
                  {step === 5 && (
                    <p className="has-text-left">
                      STEP 5 INSTRUCTIONS HERE....
                    </p>
                  )}
                  <div className="map-buton">
                    <button
                      onClick={this.clearInstruction}
                      className="btn-fill"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
        {step === 1 &&
          this.renderStep1(
            token,
            servicesPromoted,
            serviceProvider
              ? serviceProvider.membership_information
                .max_promoted_services_allowed
              : 0
          )}
        {step === 2 &&
          this.renderStep2(
            token,
            serviceProvider,
            activeKeywords,
            inActiveKeywords,
            servicesPromoted
          )}
        {step === 3 &&
          this.renderStep3(
            token,
            servicesPromoted,
            serviceProvider
              ? serviceProvider.membership_information
                .max_promoted_services_allowed
              : 0
          )}
        {step === 4 &&
          this.renderStep4(
            token,
            servicesPromoted,
            serviceProvider.membership_information,
            serviceProvider
              ? serviceProvider.membership_information
                .max_promoted_services_allowed
              : 0
          )}
        {step === 5 &&
          this.renderStep5(
            token,
            servicesPromoted,
            serviceProvider.membership_information
          )}
        <div className="services-next-bg">
          <a
            onClick={step === 5 ? this.navigateToProfile : this.moveToNextStep}
            className="nxt-bn"
          >
            {step === 5 ? "View my profile" : "Next step"}
          </a>
        </div>
      </React.Fragment>
    );
  }

  renderStep1 = (accessToken, servicesPromoted, maxHardSkillAllowed) => {
    return (
      <div className="services_main_body">
        <div className="container">
          <SupplyChainMasterFormat
            maxHardSkillAllowed={maxHardSkillAllowed}
            token={accessToken}
            mode={SupplyChainMode.BUSINESS}
            showError={this.showError}
            saveServices={this.saveServices}
            selectedLineOfServices={servicesPromoted.length}
            moveToStep={this.moveToStep}
          />
        </div>
      </div>
    );
  };

  renderStep2 = (
    token,
    serviceProvider,
    activeKeywords,
    inActiveKeywords,
    servicesPromoted
  ) => {
    return (
      // <Services />
      <ServicesKeywordsManagement
        servicesPromoted={servicesPromoted}
        activeKeywords={activeKeywords}
        inActiveKeywords={inActiveKeywords}
        showInstructions={this.showInstructions}
        token={token}
        serviceProvider={serviceProvider}
        moveToStep={this.moveToStep}
        getServices={this.getServices}
        showError={this.showError}
        showSuccess={this.showSuccess}
        startLoader={this.startLoader}
        stopLoader={this.stopLoader}
        headerChildRef={this.headerChildRef}
        updateServiceProviderMembershipInfo={
          this.updateServiceProviderMembershipInfo
        }
        showSideBar={this.state.showSideBar}
      />
    );
  };

  renderStep3 = (token, servicesPromoted, maxHardSkillAllowed) => {
    return (
      <PortfolioManagement
        childRef={(ref) => (this.portfolioManagementRef = ref)}
        token={token}
        maxHardSkillAllowed={maxHardSkillAllowed}
        servicesPromoted={servicesPromoted}
        showInstructions={this.showInstructions}
        showError={this.showError}
        showSuccess={this.showSuccess}
        startLoader={this.startLoader}
        stopLoader={this.stopLoader}
        moveToStep={this.moveToStep}
        getServices={this.getServices}
        showDataLossWarning={this.showDataLossWarning}
      />
    );
  };

  renderStep4 = (
    token,
    servicesPromoted,
    membershipInfo,
    maxHardSkillAllowed
  ) => {
    return (
      <CoverageManagement
        token={token}
        maxHardSkillAllowed={maxHardSkillAllowed}
        servicesPromoted={servicesPromoted}
        membershipInfo={membershipInfo}
        showInstructions={this.showInstructions}
        showError={this.showError}
        showSuccess={this.showSuccess}
        startLoader={this.startLoader}
        stopLoader={this.stopLoader}
        moveToStep={this.moveToStep}
        getServices={this.getServices}
      />
    );
  };

  renderStep5 = (token, servicesPromoted, membershipInfo) => {
    return (
      <ParametersManagement
        token={token}
        servicesPromoted={servicesPromoted}
        membershipInfo={membershipInfo}
        showInstructions={this.showInstructions}
        showError={this.showError}
        showSuccess={this.showSuccess}
        startLoader={this.startLoader}
        stopLoader={this.stopLoader}
        moveToStep={this.moveToStep}
        getServices={this.getServices}
      />
    );
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveServiceProvider,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BusinessDashboard);
