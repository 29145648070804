//PACKAGES
import React from 'react'

export default function Button(props) {
    return (
        <>
            <button
                className="signup-btn"
                style={props.style}
                onClick={props.onClick}
                >
                {props.text}
            </button>
        </>
    )
}
