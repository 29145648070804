import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { BASE_URL } from "../../Constants/serverConfig";
import Loader from "../Loader/loader";

class CustomerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      zipCode: "",
      email: "",
      userId: "",
      password: "",
      showPassword: false,
      showError: false,
      errorMessage: "",
      termsAccepted: false,
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentDidMount() {
    const { isSpLoggedIn, isUserLoggedIn } = this.props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      this.props.history.push("/");
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { handleUserLogin } = this.props;
    const {
      firstName,
      lastName,
      zipCode,
      email,
      userId,
      password,
    } = this.state;
    const body = {
      first_name: firstName,
      last_name: lastName,
      zip_code: zipCode,
      email_address: email,
      username: userId,
      password,
      user_type: 1,
    };
    this.startLoader();
    axios
      .post(`${BASE_URL}/entrance/signup`, body, { withCredentials: true })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          handleUserLogin(response.data);
          this.props.history.push("/customer/request");
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  render() {
    const {
      firstName,
      lastName,
      zipCode,
      email,
      userId,
      password,
      showPassword,
      showError,
      errorMessage,
      termsAccepted,
      showLoader,
    } = this.state;
    return (
      <section className="hero is-success is-fullheight">
        <Loader text="Loading..." open={showLoader} />
        <div className="hero-body">
          <div className="container center-align-content">
            <div className="column is-10">
              <div className="box custom-background">
                {showError && (
                  <div className="columns">
                    <div className="column is-12">
                      <div className="notification is-danger text">
                        <button
                          autoFocus={true}
                          onClick={this.clearError}
                          className="delete"
                        />
                        {errorMessage}
                      </div>
                    </div>
                  </div>
                )}
                <Link
                  to="/"
                  className="title center-align-content has-text-black"
                >
                  PropAGILE
                </Link>
                <hr />
                <h3 className="title has-text-left has-text-black">Sign Up</h3>
                <p className="subtitle has-text-left has-text-black">
                  You are one step away from unlocking the true potential of
                  your business.
                </p>
                <div className="columns">
                  <div className="column is-12">
                    <form onSubmit={this.handleSubmit}>
                      <div className="field mt-20">
                        <label className="label">Name</label>
                        <div className="control">
                          <input
                            className="input is-medium"
                            type="text"
                            required
                            placeholder="Name"
                            name="firstName"
                            value={firstName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="field mt-20">
                        <label className="label">Last Name</label>
                        <div className="control">
                          <input
                            className="input is-medium"
                            type="text"
                            required
                            placeholder="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="field mt-20">
                        <label className="label">Zip Code</label>
                        <div className="control">
                          <input
                            className="input is-medium"
                            type="number"
                            required
                            placeholder="Zip Code"
                            name="zipCode"
                            value={zipCode}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="field mt-20">
                        <label className="label">Email</label>
                        <div className="control">
                          <input
                            className="input is-medium"
                            type="email"
                            required
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="field mt-20">
                        <label className="label">User ID</label>
                        <div className="control">
                          <input
                            className="input is-medium"
                            type="text"
                            required
                            placeholder="User ID"
                            name="userId"
                            value={userId}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <label className="label mt-20">Password</label>
                      <div className="field has-addons">
                        <div className="control is-expanded">
                          <input
                            className="input is-medium"
                            type={showPassword ? "text" : "password"}
                            required
                            placeholder="Your Password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="control">
                          <button
                            type="button"
                            onClick={this.togglePassword}
                            className="button is-medium"
                          >
                            <i
                              className={
                                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                              }
                            />
                          </button>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-12">
                          <p className="subtitle is-4 is-size-5 has-text-black">
                            Terms & conditions
                          </p>
                          <p className="has-text-black has-text-justified">
                            You must read all terms and conditions below before
                            continuing with signup. * All (PropAgile) Members
                            are subject to the following policies, including the
                            Member Code of Conduct and all (PropAgile) Bylaws,
                            located at: http://www.propagile.com.  By clicking
                            &quot;I accept&quot; below you are indicating you
                            have read and agree to these (PropAgile) policies.
                            You may also choose to decline (PropAgile)
                            membership by hitting back or closing your browser,
                            at which point you will leave the signup process.
                          </p>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-12">
                          <div
                            onClick={() => {
                              this.setState({
                                termsAccepted: !termsAccepted,
                              });
                            }}
                            className="field"
                          >
                            <input
                              className="is-checkradio"
                              type="checkbox"
                              checked={termsAccepted}
                              onChange={() => {}}
                            />
                            <label className="label">I Accept</label>
                          </div>
                        </div>
                      </div>
                      <button
                        disabled={!termsAccepted}
                        className="button is-success mt-20 is-block is-large is-fullwidth"
                      >
                        Sign Up
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(CustomerSignup);
