/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import Footer from "../Footer";
import Header from "../Header/header";
import Loader from "../Loader/loader.jsx";
import { BASE_URL } from "../../Constants/serverConfig.jsx";
import Select from "react-select";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
import { Box } from "@material-ui/core";
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      zipCode: "",
      captcha: "",
      showCouponPopup: false,
      showLoader: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      selectTradeOptions: [],
      trade: [],
    };
    this.captcha = React.createRef();
  }

  componentDidMount() {
    const { isSpLoggedIn, isUserLoggedIn } = this.props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      this.props.history.push("/");
    }

    axios
      .get(
        "https://api.propagile.com/api/v1/items-wbs-branching/masterformat?name="
      )
      .then((res) => {
        this.setState({
          selectTradeOptions: res.data.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        });
      })
      .catch((err) => {
        console.log("Error in get call: ", err);
      });
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  showSuccess = (message) => {
    this.setState({
      showSuccess: true,
      successMessage: message,
    });
  };

  clearSuccess = () => {
    this.setState({
      showSuccess: false,
      successMessage: "",
    });
  };

  onChange = (value) => {
    this.setState({
      captcha: value,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleZipCodeChange = (event) => {
    let zipCodeRegex = /^[0-9]*$/;
    if (
      zipCodeRegex.test(event.target.value) &&
      event.target.value.length <= 5
    ) {
      this.setState({
        zipCode: event.target.value,
      });
    }
  };

  handleTradeSelect = (trade) => {
    this.setState({ trade: trade });
  };
  validate = () => {
    this.clearError();
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let zipCodeValidation = /^\d{5}$/;
    const { name, email, zipCode, captcha, trade } = this.state;
    if (!captcha || captcha === "" || captcha === null) {
      this.showError("Please confirm that you are not a robot");
      return false;
    }
    if (name.trim() === "") {
      this.showError("Please enter name");
      return false;
    }
    if (!nameValidation.test(name.trim())) {
      this.showError(
        "Please enter a valid name, name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email.trim() === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    if (zipCode.trim() === "") {
      this.showError("Please enter zip code");
      return false;
    }
    if (!zipCodeValidation.test(zipCode)) {
      this.showError("Please enter a valid zip code");
      return false;
    }
    if (trade.length < 1) {
      this.showError("Please choose a tradename");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    const { zipCode } = this.state;
    this.startLoader();
    axios
      .get(`${BASE_URL}/zip-code/find-zip-code?zip_code=${zipCode}`)
      .then((response) => {
        if (response.status === 200) {
          this.submitCouponRequest();
        }
      })
      .catch((error) => {
        this.stopLoader();
        if (error.response.status === 409) {
          this.captcha.reset();
          this.showError(
            "Opps! We do not have service in your area yet, but we will find our way to you and let you know when you are ready to join"
          );
        }
      });
  };

  submitCouponRequest = () => {
    const { name, email, zipCode, captcha, trade } = this.state;
    const body = {
      name: name.trim(),
      email_address: email.trim(),
      zip_code: zipCode,
      trade: trade.map((item) => ({
        id: item.value,
        name: item.label,
      })),
      "g-recaptcha-response": captcha,
    };
    axios
      .post(`${BASE_URL}/coupon-management/coupon-request`, body)
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.showSuccess(
            "It is exciting to have you be a part of the list of specialists, we sent you an invitation coupon, Please check your email and enter the code at Sign up; If email is not found, please check the spam folder and move to inbox or check as 'no spam' to allow communications from PropAgile."
          );
          this.captcha.reset();
          this.setState({
            name: "",
            email: "",
            zipCode: "",
            captcha: "",
            trade: [],
            showCouponPopup: false,
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
        console.error(error.response);
      });
  };

  render() {
    const {
      isUserLoggedIn,
      isSpLoggedIn,
      serviceProvider,
      handleLogout,
    } = this.props;
    const {
      showCouponPopup,
      name,
      email,
      zipCode,
      showLoader,
      showError,
      errorMessage,
      showSuccess,
      successMessage,
      selectTradeOptions,
      trade,
    } = this.state;
    console.log("trade==> ", trade);
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        <div
          className={`modal mainn-map all-field-left-align ${
            showCouponPopup ? "is-active" : ""
          }`}
        >
          <div className="modal-background"></div>
          <div className="modal-card">
            <button
              onClick={() => {
                this.setState({
                  showCouponPopup: false,
                });
              }}
              className="delete"
              aria-label="close"
            ></button>
            <section className="modal-card-body home-modal">
              <div className="clearfix gtouch endos_panel2">
                <h1>REQUEST INVITATION COUPON</h1>
                <hr />
                <form onSubmit={this.handleSubmit}>
                  <div className="columns">
                    <div className="column">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          className="form-control"
                          type="text"
                          value={name}
                          name="name"
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="email"
                          value={email}
                          name="email"
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="form-group">
                        <label>Zip code</label>
                        <input
                          className="form-control"
                          type="text"
                          value={zipCode}
                          name="zipCode"
                          required
                          onChange={this.handleZipCodeChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="form-group">
                        <label>Trade</label>
                        <Select
                          value={this.trade}
                          isMulti
                          onChange={(e) => this.setState({ trade: e })}
                          options={selectTradeOptions}
                          isOptionDisabled={() => trade.length === 3}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column">
                      <div className="captcha">
                        <ReCAPTCHA
                          sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                          onChange={this.onChange}
                          ref={(e) => (this.captcha = e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="map-buton">
                    <button type="submit" className="btn-fill">
                      REQUEST INVITATION COUPON
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {showSuccess && (
          <div className="modal is-active custom-modal success-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="success-message-modal">
                <button
                  onClick={this.clearSuccess}
                  className="modal-close"
                ></button>
                <p>{successMessage}</p>
                <a onClick={this.clearSuccess} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <div className="landing_inner" style={{ minHeight: "85vh" }}>
          <div className="landing_inner_main_top_head">
            <div className="container">
              <h1>
                You are in this page because you provide{" "}
                <span>HVAC, Electrical, Plumbing</span> and/or{" "}
                <span>24/7 Interior construction-repair services.</span> And,
                someone invited you to join the{" "}
                <span> lists of specialist</span> servicing the New York
                Metropolitan Area
              </h1>
            </div>
          </div>
          <div className="landing_inner_main_top_subhead">
            <p>
              <strong>BY INVITATION ONLY!</strong> Join now, complete the
              requirements below and <span>Standard membership</span> is yours
              to promote services for free for 6 months
            </p>
          </div>
          <div className="membership_checkboxes">
            <div className="favorite-sec">
              <label>
                <input type="checkbox" readOnly={true} checked={true} />
                <span className="checkmark"></span>
                Enter your invitation coupon at SIGN UP or{" "}
                <a
                  onClick={() => {
                    this.setState({
                      showCouponPopup: true,
                    });
                  }}
                >
                  request one here
                </a>
              </label>
            </div>
            <div className="favorite-sec">
              <label>
                <input type="checkbox" readOnly={true} checked={true} />
                <span className="checkmark"></span>
                <strong>
                  You must complete the 3 requirements below within 30 days
                </strong>
              </label>
            </div>
            <div className="favorite-sec">
              <label>
                <input type="checkbox" readOnly={true} checked={true} />
                <span className="checkmark"></span>
                Promote at least one service and upload 6 related portfolio
                images
              </label>
            </div>
            <div className="favorite-sec">
              <label>
                <input type="checkbox" readOnly={true} checked={true} />
                <span className="checkmark"></span>
                Get 5 colleague/coworker endorsements{" "}
                <span>(No worries, we made this step easy)</span>
              </label>
            </div>
            <div className="favorite-sec">
              <label>
                <input type="checkbox" readOnly={true} checked={true} />
                <span className="checkmark"></span>
                Get 3 reviews from former or current customers{" "}
                <span>(No worries, we made this step easy)</span>
              </label>
            </div>
          </div>
          <div className="container">
            <div className="home_ban_body home_ban_body_2 clearfix">
              <div className="learmore_div click_trial_btn">
                <Link
                  to={`/vendor/sign-up?metro=undefined&subsription=free-trial-membership`}
                >
                  Create a specialist profile now
                </Link>
              </div>
              <div className="demo_div">
                <p>Not sure what to do?</p>
                <p>We are ready to help you.</p>
                <a href="#">schedule a demo or a call</a>
              </div>
            </div>
          </div>
        </div>
        <Box style={{ marginTop: "auto" }}>
          <Footer />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingPage));
