//PACKAGES
import React from "react";

export default function Message(props) {
  return props.showMessage ? (
    <div className="modal is-active custom-modal error-message">
      <div className="modal-background" />
      <div className="modal-content">
        <div className="error-message-modal">
          <button
            onClick={() => props.clearMessage(false)}
            className="modal-close"
          />
          <p>{props.message}</p>
          <a onClick={() => props.clearMessage(false)} className="btn-fill">
            OK
          </a>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
