/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import axios from "axios";
import Swiper from "react-id-swiper";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
//import ReactImageMagnify from "react-image-magnify";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import { TextField } from "@material-ui/core";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
// import { TextField } from "@mui/material";

const hardSkillsSwiperParams = {
  spaceBetween: 30,
  shouldSwiperUpdate: false,
  pagination: {
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  containerClass: "swiper-container swiper-container1",
};

const reasons = [
  "I think this profile violates Terms of Use",
  "I think this person is impersonating someone",
  "I think this person does not represent a real individual",
  "I think this this profile need to be updated",
  "I think the image is irrelevant",
  "I think the image is deceiving",
  "I think the image was uploaded by mistake",
];

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      vendorData: null,
      insured: "",
      is24Hours: "",
      licensed: "",
      isFavorite: false,
      jobFunction: [],
      digitalCardMedia: null,
      currentPromotedService: null,
      promotedServices: null,
      keywords: null,
      ratingCalculated: null,
      vendorEndorsement: null,
      selectedParentId: 0,
      selectedChildId: 0,
      showLoader: false,
      showReviewsPopup: false,
      viewMore: false,
      selectedImagePath: null,
      showComingSoonPopup: false,
      showServicesPopup: false,
      swiper: null,
      swiper2: null,
      images: [],
      showReportImagePopup: false,
      showReportProfilePopup: false,
      reportReasons: [],
      showError: false,
      errorMessage: "",
      showSuccess: false,
      message: "",
      notFound: false,
      notFoundMessage: "",
      reportImageName: "",
      reportImageEmail: "",
      reportProfileName: "",
      reportProfileEmail: "",
    };
  }

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  showSuccess = (message) => {
    this.setState({
      showSuccess: true,
      message,
    });
  };

  clearMessage = () => {
    this.setState({
      showSuccess: false,
      message: "",
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isSpLoggedIn !== nextProps.isSpLoggedIn ||
      this.props.isUserLoggedIn !== nextProps.isUserLoggedIn ||
      this.props.handleLogout !== nextProps.handleLogout ||
      this.props.serviceProvider !== nextProps.serviceProvider ||
      this.state.reviews !== nextState.reviews ||
      this.state.vendorData !== nextState.vendorData ||
      this.state.insured !== nextState.insured ||
      this.state.is24Hours !== nextState.is24Hours ||
      this.state.licensed !== nextState.licensed ||
      this.state.isFavorite !== nextState.isFavorite ||
      this.state.digitalCardMedia !== nextState.digitalCardMedia ||
      this.state.promotedServices !== nextState.promotedServices ||
      this.state.keywords !== nextState.keywords ||
      this.state.ratingCalculated !== nextState.ratingCalculated ||
      this.state.vendorEndorsement !== nextState.vendorEndorsement ||
      this.state.selectedParentId !== nextState.selectedParentId ||
      this.state.selectedChildId !== nextState.selectedChildId ||
      this.state.showLoader !== nextState.showLoader ||
      this.state.showReviewsPopup !== nextState.showReviewsPopup ||
      this.state.viewMore !== nextState.viewMore ||
      this.state.selectedImagePath !== nextState.selectedImagePath ||
      this.state.showComingSoonPopup !== nextState.showComingSoonPopup ||
      this.state.showServicesPopup !== nextState.showServicesPopup ||
      this.state.swiper !== nextState.swiper ||
      this.state.swiper2 !== nextState.swiper2 ||
      this.state.images !== nextState.images ||
      this.state.showReportImagePopup !== nextState.showReportImagePopup ||
      this.state.showReportProfilePopup !== nextState.showReportProfilePopup ||
      this.state.reportReasons !== nextState.reportReasons ||
      this.state.showError !== nextState.showError ||
      this.state.errorMessage !== nextState.errorMessage ||
      this.state.showSuccess !== nextState.showSuccess ||
      this.state.message !== nextState.message ||
      this.state.notFound !== nextState.notFound ||
      this.state.notFoundMessage !== nextState.notFoundMessage ||
      this.state.reportProfileName !== nextState.reportProfileName ||
      this.state.reportProfileEmail !== nextState.reportProfileEmail ||
      this.state.reportImageName !== nextState.reportImageName ||
      this.state.reportImageEmail !== nextState.reportImageEmail
    );
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  likeDislikeImage = (body) => {
    console.log(body);
    this.startLoader();
    axios
      .post(`https://api.propagile.com/api/v1/digital-card/rate-image`, body)
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          console.log(response, "Message");
          {
            body.rating_type === 1
              ? this.showSuccess("Image Liked successfully.")
              : this.showSuccess("Image Disliked successfully.");
          }
          this.setState({
            reportReasons: [],
            showReportImagePopup: false,
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
        console.error(error);
        this.showError(error.message);
      });
  };

  componentDidMount() {
    const { user } = this.props.match.params;
    this.startLoader();
    axios
      .get(`${BASE_URL}/digital-card/vendor/${user}`)
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          let images = [];
          for (let i = 0; i < 6; i++) {
            if (
              response.data.digitalCardMedia !== null &&
              response.data.digitalCardMedia.portafolioMedia[i] !== undefined
            ) {
              let image = response.data.digitalCardMedia.portafolioMedia[i];
              image.index = i;
              images.push(image);
            } else {
              images.push({
                index: i,
                path: require("../../assets/images/not-found.jpg"),
                fileName: "not-found.png",
              });
            }
          }
          let currentPromotedService = response.data.currentPromotedService[0];
          let selectedImage = {
            index: 0,
            path: require("../../assets/images/not-found.jpg"),
            fileName: "not-found.png",
          };
          if (response.data.digitalCardMedia.portafolioMedia.length > 0) {
            selectedImage = response.data.digitalCardMedia.portafolioMedia[0];
            selectedImage.index = 0;
          }
          this.setState({
            vendorData: response.data.vendorData,
            insured: response.data.insured,
            is24Hours:
              response.data.round_clock_service === true ? "Yes" : "No",
            licensed: response.data.licensed,
            isFavorite: response.data.isFavorite,
            jobFunction: response.data.jobFunction,
            digitalCardMedia: response.data.digitalCardMedia,
            selectedImagePath: selectedImage,
            promotedServices: response.data.promotedServices,
            keywords: response.data.keywords,
            ratingCalculated: response.data.ratingCalculated,
            vendorEndorsement: response.data.vendorEndorsement,
            currentPromotedService: currentPromotedService,
            selectedParentId: currentPromotedService.id,
            selectedChildId: currentPromotedService.child_id,
            images,
          });
          axios
            .get(
              `${BASE_URL}/review/vendor/${user}/parent/${currentPromotedService.id}/child/${currentPromotedService.child_id}`
            )
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  reviews: response.data,
                  notFound: false,
                  notFoundMessage: "",
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
          setTimeout(() => {
            const { swiper } = this.state;
            let selectedIndex = response.data.promotedServices.findIndex(
              (element) =>
                element.id === currentPromotedService.id &&
                element.child_id === currentPromotedService.child_id
            );
            if (swiper !== null) {
              swiper.slideTo(selectedIndex);
            }
          }, 200);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({
            notFound: true,
            notFoundMessage: error.response.data,
          });
        }
        this.stopLoader();
        console.error(error);
      });
  }

  getStarRating(rating) {
    var html = [];
    for (var i = 1; i <= 5; i++) {
      if (i <= rating) {
        html.push(
          <span key={`star-${i}`}>
            <i style={{ color: "orange" }} className={`fa fa-star`} />
          </span>
        );
      } else {
        html.push(
          <span key={`star-${i}`}>
            <i style={{ color: "orange" }} className={`far fa-star`} />
          </span>
        );
      }
    }
    return html;
  }

  toggleReviewsPopup = () => {
    this.setState({
      showReviewsPopup: !this.state.showReviewsPopup,
    });
  };

  // getEndorsements = (endorsements) => {
  //   let chunk = 5;
  //   let html = [];
  //   for (let i = 0; i < endorsements.length; i += chunk) {
  //     let tempArray = endorsements.slice(i, i + chunk);
  //     html.push(
  //       <div key={`parent-${i}`} className="swiper-slide">
  //         {tempArray.map((element, index) => (
  //           <div key={index} className="test-discrip">
  //             <h5>{element.autor_name}</h5>
  //             <h6>{element.autor_occupation}</h6>
  //             <div className="post-date">
  //               <i className="fa fa-clock" /> 01 September 2019
  //             </div>
  //             <p>{element.body}</p>
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return html;
  // };

  openTab = (user, supplyChain, parentId, childId) => {
    this.startLoader();
    axios
      .get(
        `${BASE_URL}/review/vendor/${user}/parent/${parentId}/child/${childId}`
      )
      .then((response) => {
        console.log("response ==> ", response);
        if (response.status === 200) {
          this.setState({
            reviews: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        `${BASE_URL}/digital-card/vendor/${user}/supply-chain/${supplyChain}/parent/${parentId}/child/${childId}`
      )
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          let images = [];
          for (let i = 0; i < 6; i++) {
            if (
              response.data.digitalCardMedia !== null &&
              response.data.digitalCardMedia.portafolioMedia[i] !== undefined
            ) {
              let image = response.data.digitalCardMedia.portafolioMedia[i];
              image.index = i;
              images.push(image);
            } else {
              images.push({
                index: i,
                path: require("../../assets/images/not-found.jpg"),
                fileName: "not-found.png",
              });
            }
          }
          let selectedImage = {
            index: 0,
            path: require("../../assets/images/not-found.jpg"),
            fileName: "not-found.png",
          };
          if (response.data.digitalCardMedia.portafolioMedia.length > 0) {
            selectedImage = response.data.digitalCardMedia.portafolioMedia[0];
            selectedImage.index = 0;
          }
          this.setState({
            vendorData: response.data.vendorData,
            insured: response.data.insured,
            is24Hours:
              response.data.round_clock_service === true ? "Yes" : "No",
            licensed: response.data.licensed,
            isFavorite: response.data.isFavorite,
            jobFunction: response.data.jobFunction,
            digitalCardMedia: response.data.digitalCardMedia,
            selectedImagePath: selectedImage,
            promotedServices: response.data.promotedServices,
            keywords: response.data.keywords,
            ratingCalculated: response.data.ratingCalculated,
            vendorEndorsement: response.data.vendorEndorsement,
            selectedParentId: parentId,
            selectedChildId: childId,
            images,
          });
          const { swiper2 } = this.state;
          if (swiper2 !== null) {
            swiper2.slideTo(0);
          }
        }
      })
      .catch((error) => {
        this.stopLoader();
        console.error(error);
      });
  };

  getHardSkillsForSwiper = (promotedService) => {
    const { is24Hours, selectedParentId, selectedChildId } = this.state;
    let chunk = 1;
    let html = [];
    for (let i = 0; i < promotedService.length; i += chunk) {
      let tempArray = promotedService.slice(i, i + chunk);
      html.push(
        <div key={`parent-${i}`} className="swiper-slide">
          <nav className="tabs is-boxed is-fullwidth illo">
            <ul>
              {tempArray.map((element, index) => (
                <li key={index} className="tab is-active">
                  <label onClick={this.handleSwipeLeft} className="m_svc_left">
                    More services
                  </label>
                  <a>
                    {element.parent_name} _ <span>{element.child_name}</span>{" "}
                    {element.id === selectedParentId &&
                      element.child_id === selectedChildId &&
                      is24Hours === "Yes" && (
                        <span className="twenty-four-hour-tag">(24 Hr.)</span>
                      )}
                  </a>
                  <label
                    onClick={this.handleSwipeRight}
                    className="m_svc_right"
                  >
                    More services
                  </label>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      );
    }
    return html;
  };

  handleSwipeRight = () => {
    const { swiper } = this.state;
    swiper.slideNext();
  };

  handleSwipeLeft = () => {
    const { swiper } = this.state;
    swiper.slidePrev();
  };

  setLargeImageViewPicture = (picture) => {
    let index = picture.index;
    const { swiper2 } = this.state;
    if (swiper2 !== null) {
      swiper2.slideTo(index);
    }
    this.setState({
      selectedImagePath: picture,
    });
  };

  toggleComingSoonPopup = () => {
    this.setState({
      showComingSoonPopup: !this.state.showComingSoonPopup,
    });
  };

  toggleServicesPopup = () => {
    this.setState({
      showServicesPopup: !this.state.showServicesPopup,
    });
  };

  handleSlideChange = () => {
    const { promotedServices, vendorData, swiper } = this.state;
    this.openTab(
      vendorData.profile_name,
      promotedServices[swiper.activeIndex].uuid,
      promotedServices[swiper.activeIndex].id,
      promotedServices[swiper.activeIndex].child_id
    );
  };

  handleImageSlideChange = () => {
    const { images, swiper2 } = this.state;
    this.setState({
      selectedImagePath: images[swiper2.activeIndex],
    });
  };

  toggleReportImagePopup = () => {
    this.setState({
      showReportImagePopup: !this.state.showReportImagePopup,
      reportReasons: [],
    });
  };

  toggleReportProfilePopup = () => {
    this.setState({
      showReportProfilePopup: !this.state.showReportProfilePopup,
      reportReasons: [],
    });
  };

  selectImageReportReason = (selection) => {
    console.log("Selected Checkbox 1", selection);

    const { reportReasons } = this.state;

    console.log("Checked Checkboxes 1", reportReasons);
    if (!reportReasons.includes(selection)) {
      reportReasons.push(selection);
      this.setState({
        reportReasons: [...reportReasons],
      });
    } else {
      reportReasons.splice(reportReasons.indexOf(selection), 1);
      console.log("After deletion: 1", reportReasons);
      this.setState({
        reportReasons: [...reportReasons],
      });
    }
  };

  handleReportImageSubmission = () => {
    const {
      reportReasons,
      selectedParentId,
      selectedChildId,
      promotedServices,
      swiper,
      selectedImagePath,
      reportImageName,
      reportImageEmail,
    } = this.state;

    const { user } = this.props.match.params;

    /* ------------------------------------ */
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    if (reportImageName.length === 0) {
      this.showError("Please enter Name to report image");
      return;
    }
    if (!nameValidation.test(reportImageName.trim())) {
      this.showError(
        "Please enter a valid name, name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (reportImageEmail.length === 0) {
      this.showError("Please enter Email to report image");
      return;
    }
    if (!emailValidation.test(reportImageEmail.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }

    /* ------------------------- */
    if (reportReasons.length === 0) {
      this.showError("Please choose a reason.");
      return;
    }
    let reason = "";
    for (let i = 0; i < reportReasons.length; i++) {
      reason += reasons[reportReasons[i]] + ", ";
    }
    reason = reason.trim();
    reason = reason.substr(0, reason.length - 1);
    this.startLoader();
    axios
      .post(`${BASE_URL}/report/portfolio`, {
        profile_name: user,
        reason,
        parent_id: selectedParentId,
        child_id: selectedChildId,
        supply_chain_uuid: promotedServices[swiper.activeIndex].uuid,
        file_path: selectedImagePath.path,
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.showSuccess("Portfolio reported successfully.");
          this.setState({
            reportReasons: [],
            showReportImagePopup: false,
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
        console.error(error);
        this.showError("Failed to report portfolio");
      });
  };

  handleReportProfileSubmission = () => {
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    const { reportReasons, reportProfileName, reportProfileEmail } = this.state;
    const { user } = this.props.match.params;

    /* ------------------------------- */

    if (reportProfileName.length === 0) {
      this.showError("Please enter Name to report profile");
      return;
    }
    if (!nameValidation.test(reportProfileName.trim())) {
      this.showError(
        "Please enter a valid name, name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (reportProfileEmail.length === 0) {
      this.showError("Please enter Email to report profile");
      return;
    }
    if (!emailValidation.test(reportProfileEmail.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    /* ------------------------------------ */

    if (reportReasons.length === 0) {
      this.showError("Please choose a reason.");
      return;
    }
    let reason = "";
    for (let i = 0; i < reportReasons.length; i++) {
      reason += reasons[reportReasons[i]] + ", ";
    }
    reason = reason.trim();
    reason = reason.substr(0, reason.length - 1);
    this.startLoader();
    axios
      .post(`${BASE_URL}/report/profile`, {
        profile_name: user,
        reason,
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.showSuccess("Profile reported successfully.");
          this.setState({
            reportReasons: [],
            showReportProfilePopup: false,
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
        console.error(error);
        this.showError("Failed to report profile");
      });
  };

  render() {
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    const {
      reviews,
      vendorData,
      digitalCardMedia,
      promotedServices,
      ratingCalculated,
      vendorEndorsement,
      jobFunction,
      showLoader,
      showReviewsPopup,
      selectedImagePath,
      showComingSoonPopup,
      showServicesPopup,
      images,
      showReportImagePopup,
      showReportProfilePopup,
      showError,
      errorMessage,
      showSuccess,
      message,
      reportReasons,
      notFound,
      notFoundMessage,
      reportProfileName,
      reportProfileEmail,
      reportImageName,
      reportImageEmail,
    } = this.state;

    let mediaPath =
      "https://firebasestorage.googleapis.com/v0/b/propagile-cd779.appspot.com/o/logo.png?alt=media&token=ed1994f1-f3ee-4ab5-afa2-17c70a4f33d0";
    return notFound === true ? (
      <React.Fragment>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section
          style={{
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="new_profile_design"
        >
          <h1 className="has-text-centered title is-4">{notFoundMessage}</h1>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Loader text="Loading..." open={showLoader} />
        <div
          className={`${showReviewsPopup ? "is-active" : ""} modal mainn-map`}
        >
          <div className="modal-background" />
          <div className="modal-card">
            <button
              onClick={this.toggleReviewsPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body ">
              {showReviewsPopup && reviews.length !== 0 ? (
                reviews.map((x, index) => {
                  return (
                    <div
                      key={index}
                      className="al-fb-reviews al-fb-reviews-1 align-left"
                    >
                      <header className="header">
                        <img
                          className="avatar lazyloaded"
                          src={require("../../assets/images/dummy-profile-pic-male1.jpg")}
                          alt="dummy"
                        />
                        <h3
                          className="title"
                          data-fontsize="18"
                          data-lineheight="27"
                        >
                          <span
                            className="rating"
                            title={`${x.rating} out of 5`}
                          >
                            {this.getStarRating(x.rating)}
                          </span>{" "}
                          <span className="name">
                            Review by {x.autor_first_name} {x.autor_last_name}
                          </span>
                        </h3>
                        <div className="meta">Created at {x.created_at}</div>
                      </header>
                      <div className="review-body review-body-short">
                        <p>{x.comment}</p>
                      </div>
                      <br />
                    </div>
                  );
                })
              ) : (
                <div className="column is-12">
                  <p className="title is-3 has-text-centered">No Reviews</p>
                </div>
              )}
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showComingSoonPopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleComingSoonPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Coming Soon</h1>
                <br />
                <p className="has-text-weight-normal has-text-justified">
                  This feature is coming soon.
                </p>
                <div className="map-buton">
                  <button
                    onClick={this.toggleComingSoonPopup}
                    className="btn-fill"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showServicesPopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleServicesPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h3 className="title">All Interior Services: </h3>
                <ul>
                  <table className="all-services">
                    {promotedServices !== null &&
                      promotedServices.map((promotedService, index) => {
                        return (
                          <tr className="all-services-row">
                            <td
                              className="all-services-data"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const { promotedServices } = this.state;
                                this.toggleServicesPopup();
                                const { swiper } = this.state;
                                let selectedIndex = promotedServices.findIndex(
                                  (element) =>
                                    element.id === promotedService.id &&
                                    element.child_id ===
                                      promotedService.child_id
                                );
                                if (swiper !== null) {
                                  swiper.slideTo(selectedIndex);
                                }
                              }}
                            >
                              {promotedService.parent_name} _{" "}
                              {promotedService.child_name}
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </ul>
                <div className="map-buton">
                  <button
                    onClick={this.toggleServicesPopup}
                    className="btn-fill"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showReportProfilePopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleReportProfilePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Report Profile</h1>
                <br />
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <TextField
                    id="reportProfileName"
                    variant="outlined"
                    placeholder="Name"
                    style={{
                      marginRight: "5px",
                    }}
                    onChange={(e) =>
                      this.setState({ reportProfileName: e.target.value })
                    }
                  />
                  <TextField
                    id="reportProfileEmail"
                    variant="outlined"
                    placeholder="Email"
                    style={{ marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({ reportProfileEmail: e.target.value })
                    }
                  />
                </div>
                <p className="has-text-weight-normal has-text-left">
                  Tell us a little more about why you are reporting
                </p>
                <div className="clearfix" />
                <div
                  className="membership_checkboxes"
                  style={{ display: "block", padding: 0 }}
                >
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(0)}
                        onClick={() => this.selectImageReportReason(0)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[0]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(1)}
                        onClick={() => this.selectImageReportReason(1)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[1]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(2)}
                        onClick={() => this.selectImageReportReason(2)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[2]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(3)}
                        onClick={() => this.selectImageReportReason(3)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[3]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(4)}
                        onClick={() => this.selectImageReportReason(4)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[4]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(5)}
                        onClick={() => this.selectImageReportReason(5)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[5]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(6)}
                        onClick={() => this.selectImageReportReason(6)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[6]}
                    </label>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="map-buton">
                  <button
                    onClick={this.handleReportProfileSubmission}
                    className="btn-fill"
                  >
                    SUBMIT
                  </button>
                  <button
                    onClick={this.toggleReportProfilePopup}
                    className="btn-empty"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showReportImagePopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleReportImagePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Report Image</h1>
                <br />
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <TextField
                    id="reportImageName"
                    variant="outlined"
                    placeholder="Name"
                    style={{
                      marginRight: "5px",
                    }}
                    onChange={(e) =>
                      this.setState({ reportImageName: e.target.value })
                    }
                  />
                  <TextField
                    id="reportImageEmail"
                    variant="outlined"
                    placeholder="Email"
                    style={{ marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({ reportImageEmail: e.target.value })
                    }
                  />
                </div>
                <p className="has-text-weight-normal has-text-left">
                  Tell us a little more about why you are reporting
                </p>
                <div className="clearfix" />
                <div
                  className="membership_checkboxes"
                  style={{ display: "block", padding: 0 }}
                >
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(0)}
                        onClick={() => this.selectImageReportReason(0)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[0]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(1)}
                        onClick={() => this.selectImageReportReason(1)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[1]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(2)}
                        onClick={() => this.selectImageReportReason(2)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[2]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(3)}
                        onClick={() => this.selectImageReportReason(3)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[3]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(4)}
                        onClick={() => this.selectImageReportReason(4)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[4]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(5)}
                        onClick={() => this.selectImageReportReason(5)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[5]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(6)}
                        onClick={() => this.selectImageReportReason(6)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[6]}
                    </label>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="map-buton">
                  <button
                    onClick={this.handleReportImageSubmission}
                    className="btn-fill"
                  >
                    SUBMIT
                  </button>
                  <button
                    onClick={this.toggleReportImagePopup}
                    className="btn-empty"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {showSuccess && (
          <div className="modal is-active custom-modal success-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="success-message-modal">
                <button onClick={this.clearMessage} className="modal-close" />
                <p>{message}</p>
                <a onClick={this.clearMessage} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section className="new_profile_design">
          <div className="container clearfix">
            <div className="right_main_slider">
              <div className="porfolio-main clearfix">
                <div className="swiper-container swiper-container1">
                  <div className="swiper-wrapper">
                    {promotedServices !== null && (
                      <Swiper
                        getSwiper={(e) => {
                          this.setState({
                            swiper: e,
                          });
                        }}
                        on={{
                          slideChange: this.handleSlideChange,
                        }}
                        {...hardSkillsSwiperParams}
                      >
                        {this.getHardSkillsForSwiper(promotedServices)}
                      </Swiper>
                    )}
                  </div>
                </div>
                <div
                  className="portfolio-in-sec"
                  style={{ overflow: "scroll" }}
                >
                  <div id="tab1" className="content-tab">
                    <Swiper
                      shouldSwiperUpdate={true}
                      getSwiper={(e) => {
                        this.setState({
                          swiper2: e,
                        });
                      }}
                      on={{
                        slideChange: this.handleImageSlideChange,
                      }}
                    >
                      {images.map((image, index) => {
                        return (
                          <div key={index} className="gallery-top">
                            <div
                              className="main-profile-img"
                              style={{
                                backgroundImage: `url(${image.path})`,
                              }}
                            >
                              {image.fileName != "not-found.png" && (
                                <div className="report-like">
                                  <div className="like-icons">
                                    <a
                                      onClick={() => {
                                        const { vendorData } = this.state;
                                        const body = {
                                          profile_name: vendorData.first_name,
                                          file_path: image.path,
                                          rating_type: 1,
                                        };
                                        this.likeDislikeImage(body);
                                      }}
                                    >
                                      <i className="fa fa-thumbs-up" />
                                    </a>
                                    <a
                                      className="dislike "
                                      onClick={() => {
                                        const { vendorData } = this.state;
                                        const body = {
                                          profile_name: vendorData.first_name,
                                          file_path: image.path,
                                          rating_type: 0,
                                        };
                                        this.likeDislikeImage(body);
                                      }}
                                    >
                                      <i className="fa fa-thumbs-down" />
                                    </a>
                                  </div>
                                  <a
                                    className="report-pro"
                                    onClick={this.toggleReportImagePopup}
                                  >
                                    Report this Image
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Swiper>
                    <div className="new-bullet">
                      {images.map((image, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => this.setLargeImageViewPicture(image)}
                            style={{ margin: 3 }}
                            className={
                              selectedImagePath.index === image.index
                                ? "swiper-pagination-bullet swiper-pagination-bullet-active"
                                : "swiper-pagination-bullet"
                            }
                          />
                        );
                      })}
                    </div>
                    <div className="gallery-thumbs">
                      {images
                        .filter((x) => x.index !== selectedImagePath.index)
                        .map((image, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                this.setLargeImageViewPicture(image)
                              }
                              className="gall-thumb"
                            >
                              <div className="new-gall-thumb-tbl">
                                <div
                                  style={{
                                    backgroundImage: `url(${image.path})`,
                                  }}
                                  className="new-gall-thumb-cell"
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="left_testi">
              <p className="left_testi_heading">
                {vendorData !== null ? vendorData.vendor_level : ""}
              </p>
              <span className="left_testi_license">
                <a onClick={this.toggleServicesPopup}>
                  <u>(See All Services)</u>
                </a>
              </span>
              <span className="testi_user_name">
                {vendorData !== null
                  ? `${vendorData.first_name} ${vendorData.last_name}`
                  : ""}
              </span>
              <div
                className="left_testi_dp"
                style={{
                  backgroundImage: `url(${
                    digitalCardMedia !== null
                      ? digitalCardMedia.userProfileMedia[0]
                      : ""
                  })`,
                }}
              />
              <span className="left_testi_skills_selected">
                <a>
                  <u>{jobFunction.join("/")}</u>
                </a>
              </span>
              <div className="left_testi_stars">
                {ratingCalculated !== null ? (
                  <p>
                    <span style={{ color: "#4a4a4a", fontWeight: 500 }}>
                      Customer Reviews
                    </span>
                    {"  "}
                    {this.getStarRating(ratingCalculated.ratingCalculated)}
                    <span
                      className="left_testi_stars_count"
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleReviewsPopup}
                    >
                      ({reviews.length})
                    </span>
                  </p>
                ) : null}
              </div>
              <div className="left_testi_comp_info clearfix">
                {vendorData !== null && vendorData.company_name !== "" ? (
                  <div className="left_testi_left clearfix">
                    <a style={{ marginBottom: 4 }}>{vendorData.company_name}</a>
                  </div>
                ) : null}
                <div className="clearfix" />
                <div className="left_testi_left clearfix">
                  <a>
                    <i className="fa fa-map-marker-alt" />
                    {vendorData !== null ? `${vendorData.state}, USA` : ""}
                  </a>
                  <a
                    href={`tel:${
                      vendorData !== null && vendorData.phone_number !== null
                        ? vendorData.phone_number
                        : ""
                    }`}
                  >
                    <i className="fa fa-phone" />
                    {vendorData !== null && vendorData.phone_number !== null
                      ? vendorData.phone_number
                      : ""}
                  </a>
                  <a
                    href={
                      vendorData !== null
                        ? `mailto:${vendorData.email_address}`
                        : ""
                    }
                  >
                    <i className="fa fa-envelope" />
                    {vendorData !== null ? `${vendorData.email_address}` : ""}
                  </a>
                </div>
                <div className="left_testi_right clearfix">
                  <div className="clearfix" />
                  <div className="favorite-sec">
                    <label onClick={this.toggleComingSoonPopup}>
                      <input disabled={true} type="checkbox" />
                      <span className="checkmark" />
                      Save To Favorites
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label onClick={this.toggleComingSoonPopup}>
                      <input disabled={true} type="checkbox" />
                      <span className="checkmark" />
                      Save To Project
                    </label>
                  </div>
                  {vendorData !== null && vendorData.company_page !== "" ? (
                    <div className="favorite-sec">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="comp_web_a"
                        href={`https://${vendorData.company_page}`}
                      >
                        {vendorData.company_page}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="left_testi_endorsement">
                <h2>Colleague / Co-worker endorsements</h2>
                <div className="endorse_list">
                  {vendorEndorsement !== null &&
                  vendorEndorsement.length > 0 ? (
                    vendorEndorsement.map((x) => {
                      return (
                        <div key={x.uuid} className="endorse_list_li">
                          <div className="endorse_list_left">
                            <span className="endorse_list_left_name">
                              {x.autor_name}
                            </span>
                            <span className="endorse_list_left_designation">
                              {x.autor_occupation}
                            </span>
                          </div>
                          <div className="endorse_list_right">
                            <span>{x.created}</span>
                          </div>
                          <div className="clearfix" />
                          <p>{x.body}</p>
                        </div>
                      );
                    })
                  ) : (
                    <h1>No Endorsement Found</h1>
                  )}
                </div>
                <div className="righ-social">
                  <h1>Share this profile</h1>
                  <FacebookShareButton
                    url={window.location.href}
                    quote={"Click to see my digital profile"}
                  >
                    <i className="fab fa-facebook-f" />
                  </FacebookShareButton>
                  <PinterestShareButton
                    media={mediaPath}
                    url={window.location.href}
                    description={"Click to see my digital profile"}
                  >
                    <i className="fab fa-pinterest-p" />
                  </PinterestShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={"Click to see my digital profile"}
                  >
                    <i className="fab fa-twitter" />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={window.location.href}
                    title={"Click to see my digital profile"}
                  >
                    <i className="fab fa-linkedin" />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={window.location.href}
                    title="Click to see my digital profile"
                  >
                    <i className="fab fa-whatsapp" />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={window.location.href}
                    subject={"Click to see my digital profile"}
                  >
                    <i className="fa fa-envelope" />
                  </EmailShareButton>
                  <p className="reprot-profile">
                    <a onClick={this.toggleReportProfilePopup}>
                      Report This Profile{" "}
                      <img
                        src={require("../../assets/images/report-icon.png")}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(connect(mapStateToProps, null), withRouter)(Card);
