//PACKAGES
import React,{useState} from 'react'

//COMPONENTS
import Button from '../Botton/Button'

export default function Form(props) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)


    const togglePassword=()=>{
        setShowPassword(!showPassword)
    }

    const getBody=(
        firstName,
        lastName,
        email,
        userId,
        password,
      )=> {
        const body = {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email_address: email.trim(),
          username: userId.trim(),
          password,
          user_type: 6,
        }
        return body;
    }

    const resetStates=()=>{
        setFirstName("")
        setLastName("")
        setEmail("")
        setPassword("")
        setUserName("")
    }


    return (
        <>
            <form onSubmit={(event)=>{props.handleSubmit(event,getBody(firstName,lastName,email,userName,password),resetStates)}}>
                <div className="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        required
                        name="firstName"
                        value={firstName}
                        onChange={(e)=>{setFirstName(e.target.value)}}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        required
                        name="lastName"
                        value={lastName}
                        onChange={(e)=>{setLastName(e.target.value)}}
                        className="form-control"
                    />
                </div>
                
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        required
                        name="email"
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        required
                        name="userName"
                        value={userName}
                        onChange={(e)=>{setUserName(e.target.value)}}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        required
                        name="password"
                        value={password}
                        onChange={(e)=>{setPassword(e.target.value)}}
                        className="form-control"
                    />
                    <a onClick={togglePassword} className="view-password">
                    <img
                        alt="show password"
                        src={require("../../assets/images/view-password-icon.png")}
                    />
                    </a>
                </div>
            
                <div className="signup-btns">
                    <Button style={{ width: "100%" }} text={"SignUp"}/>
                </div>
            </form>
          
        </>
    )
}
