/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import SecureLS from "secure-ls";
import querystring from "querystring";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import NewHeader from "../Header/NewHeader";
// import NewFooter from "../Footer/NewFooter";

class VendorLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      captcha: "",
      showError: false,
      errorMessage: "",
      metroArea: "",
      rememberMe: false,
      showLoader: false,
    };
    this.captcha = React.createRef();
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentWillMount() {
    const localStore = new SecureLS();
    const rememberMe = localStore.get("otc");
    if (rememberMe) {
      const user = localStore.get("otg");
      this.setState({
        username: user.username,
        password: user.password,
        rememberMe: true,
      });
    }
  }

  componentDidMount() {
    const { isSpLoggedIn, isUserLoggedIn, location } = this.props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      if (location.state !== undefined && location.state.from !== undefined) {
        this.props.history.push(location.state.from.pathname);
      } else {
        this.props.history.push("/");
      }
    }
    const { search } = this.props.history.location;
    if (search !== "") {
      const metroArea = querystring.parse(search)["?metro"];
      this.setState({
        metroArea,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  rememberMe = (rememberMe) => {
    rememberMe = !rememberMe;
    this.setState({
      rememberMe,
    });
  };

  onChange = (value) => {
    this.setState({
      captcha: value,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { handleServiceProviderLogin } = this.props;
    const { username, password, captcha, rememberMe } = this.state;
    if (!username || username.trim() === "" || username === null) {
      this.showError("Please enter an username");
      return;
    }
    if (!password || password === "" || password === null) {
      this.showError("Please enter your password");
      return;
    }
    if (!captcha || captcha === "" || captcha === null) {
      this.showError("Please confirm that you are not a robot");
      return;
    }
    if (rememberMe) {
      const localStore = new SecureLS();
      localStore.set("otc", true);
      localStore.set("otg", { username, password });
    } else {
      const localStore = new SecureLS();
      localStore.remove("otc");
      localStore.remove("otg");
    }
    const body = {
      username: username.trim(),
      password,
      "g-recaptcha-response": captcha,
    };
    this.startLoader();
    axios
      .post(`${BASE_URL}/entrance/login`, body, { withCredentials: true })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          if (
            response.data.user.user_type === 2 ||
            response.data.user.user_type === 4 ||
            response.data.user.user_type === 5 ||
            response.data.user.user_type === 6 ||
            response.data.user.user_type === 'Foreman' ||
            response.data.user.user_type === 'Subcontractor' ||
            response.data.user.user_type === 'Superintendent' ||
            response.data.user.user_type === 'Laborer'
          ) {

            if (response.data.user.memberOf == "CONNECTIONS") {
              //window.location.href = "https://www.propagile.com/building-construction-teams/login?token=" + response.data.token_login;
              window.location.href = "http://localhost:3000/login?token=" + response.data.token_login;
            }
            else {

              handleServiceProviderLogin(response.data);
              if (response.data.user.user_type === 5) {
                this.props.history.push("/construction-project-management/team");
              } else if (response.data.user.user_type === 6) {
                this.props.history.push(
                  "/construction-project-management/project-manager"
                );
              } else if (
                this.props.location.state !== undefined &&
                this.props.location.state.from !== undefined
              ) {
                this.props.history.push(this.props.location.state.from.pathname);
              } else {
                this.props.history.push("/vendor/dashboard");
              }
            }
          }
        } else {
          this.captcha.reset();
          this.setState({
            captcha: "",
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.captcha.reset();
        this.setState({
          captcha: "",
        });
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  render() {
    const {
      username,
      password,
      showError,
      errorMessage,
      metroArea,
      rememberMe,
      showLoader,
    } = this.state;
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}

        <section className="form-wrapper" style={{ minHeight: "86vh" }}>
          <div className="form-inner">
            <div className="form-main">
              <div className="form-title">
                <h3>Login</h3>
                <p>Please login to proceed.</p>
              </div>

              <div className="form-fields">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>Username or Email</label>
                    <input
                      className="form-control"
                      type="text"
                      value={username}
                      name="username"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={password}
                      name="password"
                      required
                      onChange={this.handleChange}
                    />
                    <Link
                      className="forget-pasword"
                      to="/vendor/reset-password-request"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                  <div className="captcha">
                    <ReCAPTCHA
                      sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                      onChange={this.onChange}
                      ref={(e) => (this.captcha = e)}
                    />
                  </div>
                  <div
                    onClick={() => this.rememberMe(rememberMe)}
                    className="checkbox"
                  >
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        onChange={() => { }}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        checked={rememberMe}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>Remember Me
                    </label>
                  </div>
                  <div className="login-btn">
                    <button type="submit" className="btn btn-default">
                      Login
                    </button>
                  </div>
                </form>
                <div className="get-started">
                  Don't have an account?{" "}
                  <Link to={`/vendor/subscriptions?metro=${metroArea}`}>
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default withRouter(VendorLogin);
