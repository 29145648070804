export const IMAGE_TYPES = {
  SERVICE_IMAGE_UPLOADED: "SERVICE_IMAGE_UPLOADED",
  PORTFOLIO_IMAGE_SELECTED: "PORTFOLIO_IMAGE_SELECTED",
  SERVICE_IMAGE_NEW: "SERVICE_IMAGE_NEW",
};

export const IMAGE_COMPRESSION_OPTIONS = {
  maxSizeMB: 3,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const basePath = "https://www.propagile.com/";
export const baseApiPath = "https://api.propagile.com/api/v1/";
