/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Swiper from "react-id-swiper";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
import {
  DEMO_PROFILE_DATA,
  DEMO_PROFILE_PORTFOLIO_1,
  DEMO_PROFILE_PORTFOLIO_2,
  DEMO_PROFILE_PORTFOLIO_3,
  DEMO_PROFILE_PORTFOLIO_4,
  DEMO_PROFILE_PORTFOLIO_5,
  DEMO_PROFILE_PORTFOLIO_6,
} from "../../Constants/localStorageKeys";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

const hardSkillsSwiperParams = {
  spaceBetween: 30,
  shouldSwiperUpdate: true,
  pagination: {
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  containerClass: "swiper-container swiper-container1",
};

const reasons = [
  "I think this profile violates Terms of Use",
  "I think this person is impersonating someone",
  "I think this person does not represent a real individual",
  "I think this this profile need to be updated",
  "I think the image is irrelevant",
  "I think the image is deceiving",
  "I think the image was uploaded by mistake",
];

const reviews = [
  {
    autor_first_name: "Lorem",
    autor_last_name: "Ipsum",
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created_at: "19-11-2019 03:28",
    rating: 5,
    title: "",
    vendorName: "Pepe",
  },
  {
    autor_first_name: "Lorem",
    autor_last_name: "Ipsum",
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created_at: "19-11-2019 03:28",
    rating: 5,
    title: "",
    vendorName: "Pepe",
  },
  {
    autor_first_name: "Lorem",
    autor_last_name: "Ipsum",
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created_at: "19-11-2019 03:28",
    rating: 5,
    title: "",
    vendorName: "Pepe",
  },
  {
    autor_first_name: "Lorem",
    autor_last_name: "Ipsum",
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created_at: "19-11-2019 03:28",
    rating: 5,
    title: "",
    vendorName: "Pepe",
  },
  {
    autor_first_name: "Lorem",
    autor_last_name: "Ipsum",
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created_at: "19-11-2019 03:28",
    rating: 5,
    title: "",
    vendorName: "Pepe",
  },
];

const vendorEndorsement = [
  {
    uuid: "1",
    autor_name: "Lorem Ipsum",
    body:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created: "19-11-2019 03:28",
    autor_occupation: "occupation",
  },
  {
    uuid: "2",
    autor_name: "Lorem Ipsum",
    body:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created: "19-11-2019 03:28",
    autor_occupation: "occupation",
  },
  {
    uuid: "3",
    autor_name: "Lorem Ipsum",
    body:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created: "19-11-2019 03:28",
    autor_occupation: "occupation",
  },
  {
    uuid: "4",
    autor_name: "Lorem Ipsum",
    body:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    created: "19-11-2019 03:28",
    autor_occupation: "occupation",
  },
];

class CardDummy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      companyWebsite: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      state: "",
      file: null,
      insured: "",
      is24Hours: "",
      licensed: "",
      servicesPromoted: [],
      activeKeywords: [],
      showReviewsPopup: false,
      selectedImagePath: null,
      showComingSoonPopup: false,
      showServicesPopup: false,
      swiper: null,
      swiper2: null,
      images: [],
      showReportImagePopup: false,
      showReportProfilePopup: false,
      reportReasons: [],
      notFound: false,
      notFoundMessage: "",
      swiperIndex: 0,
      jobFunctions: "",
    };
  }

  componentDidMount() {
    let data = localStorage.getItem(DEMO_PROFILE_DATA);
    if (data === null) {
      this.props.history.push("/profile/edit");
      return;
    }
    let storageImages = [];
    storageImages.push(localStorage.getItem(DEMO_PROFILE_PORTFOLIO_1));
    storageImages.push(localStorage.getItem(DEMO_PROFILE_PORTFOLIO_2));
    storageImages.push(localStorage.getItem(DEMO_PROFILE_PORTFOLIO_3));
    storageImages.push(localStorage.getItem(DEMO_PROFILE_PORTFOLIO_4));
    storageImages.push(localStorage.getItem(DEMO_PROFILE_PORTFOLIO_5));
    storageImages.push(localStorage.getItem(DEMO_PROFILE_PORTFOLIO_6));
    data = JSON.parse(data);
    let selectedImage = {
      index: 0,
      path: require("../../assets/images/not-found.jpg"),
      fileName: "not-found.png",
    };
    let images = [];
    for (let i = 0; i < 6; i++) {
      if (storageImages[i] !== "undefined" && storageImages[i] !== "") {
        images.push({
          index: i,
          path: storageImages[i],
          fileName: "",
        });
      } else {
        images.push({
          index: i,
          path: require("../../assets/images/not-found.jpg"),
          fileName: "not-found.png",
        });
      }
    }
    this.setState({
      companyName: data.companyName,
      companyWebsite: data.companyWebsite,
      firstName: data.firstName === "" ? "X" : data.firstName,
      lastName: data.lastName === "" ? "X" : data.lastName,
      email: data.email === "" ? "X" : data.email,
      phoneNumber: data.phoneNumber === "" ? "X" : data.phoneNumber,
      state: data.state === "" ? "X" : data.state,
      file:
        data.profilePicture === ""
          ? require("../../assets/images/dummy-profile-pic-male1.jpg")
          : data.profilePicture,
      insured: data.insured ? "Insured" : "Uninsured",
      licensed: data.licensed ? "Licensed" : "Unlicensed",
      is24Hours: data.is24Hours,
      images: images,
      selectedImagePath: selectedImage,
      servicesPromoted: data.servicesPromoted,
      activeKeywords: data.activeKeywords,
      jobFunctions: data.jobFunctions,
    });
    localStorage.removeItem(DEMO_PROFILE_DATA);
    localStorage.removeItem(DEMO_PROFILE_PORTFOLIO_1);
    localStorage.removeItem(DEMO_PROFILE_PORTFOLIO_2);
    localStorage.removeItem(DEMO_PROFILE_PORTFOLIO_3);
    localStorage.removeItem(DEMO_PROFILE_PORTFOLIO_4);
    localStorage.removeItem(DEMO_PROFILE_PORTFOLIO_5);
    localStorage.removeItem(DEMO_PROFILE_PORTFOLIO_6);
  }

  getStarRating(rating) {
    var html = [];
    for (var i = 1; i <= 5; i++) {
      if (i <= rating) {
        html.push(
          <span key={`star-${i}`}>
            <i style={{ color: "orange" }} className={`fa fa-star`} />
          </span>
        );
      } else {
        html.push(
          <span key={`star-${i}`}>
            <i style={{ color: "orange" }} className={`far fa-star`} />
          </span>
        );
      }
    }
    return html;
  }

  toggleReviewsPopup = () => {
    this.setState({
      showReviewsPopup: !this.state.showReviewsPopup,
    });
  };

  toggleComingSoonPopup = () => {
    this.setState({
      showComingSoonPopup: !this.state.showComingSoonPopup,
    });
  };

  toggleServicesPopup = () => {
    this.setState({
      showServicesPopup: !this.state.showServicesPopup,
    });
  };

  handleImageSlideChange = () => {
    const { images, swiper2 } = this.state;
    this.setState({
      selectedImagePath: images[swiper2.activeIndex],
    });
  };

  toggleReportImagePopup = () => {
    this.setState({
      showReportImagePopup: !this.state.showReportImagePopup,
      reportReasons: [],
    });
  };

  toggleReportProfilePopup = () => {
    this.setState({
      showReportProfilePopup: !this.state.showReportProfilePopup,
      reportReasons: [],
    });
  };

  selectImageReportReason = (selection) => {
    const { reportReasons } = this.state;
    if (!reportReasons.includes(selection)) {
      reportReasons.push(selection);
      this.setState({
        reportReasons: [...reportReasons],
      });
    } else {
      reportReasons.splice(selection, 1);
      this.setState({
        reportReasons: [...reportReasons],
      });
    }
  };

  setLargeImageViewPicture = (picture) => {
    let index = picture.index;
    const { swiper2 } = this.state;
    if (swiper2 !== null) {
      swiper2.slideTo(index);
    }
    this.setState({
      selectedImagePath: picture,
    });
  };

  handleSwipeRight = () => {
    const { swiper } = this.state;
    swiper.slideNext();
  };

  handleSwipeLeft = () => {
    const { swiper } = this.state;
    swiper.slidePrev();
  };

  handleSlideChange = () => {
    const { swiper } = this.state;
    this.setState({
      swiperIndex: swiper.activeIndex,
    });
  };

  render() {
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    const {
      companyName,
      companyWebsite,
      firstName,
      lastName,
      email,
      phoneNumber,
      state,
      file,
      showReviewsPopup,
      selectedImagePath,
      showComingSoonPopup,
      showServicesPopup,
      images,
      showReportImagePopup,
      showReportProfilePopup,
      reportReasons,
      notFound,
      notFoundMessage,
      servicesPromoted,
      activeKeywords,
      is24Hours,
      swiperIndex,
      jobFunctions,
    } = this.state;
    let keywords = activeKeywords.filter(
      (x) => x.parentId === servicesPromoted[swiperIndex].serviceId
    );
    let mediaPath =
      "https://firebasestorage.googleapis.com/v0/b/propagile-cd779.appspot.com/o/logo.png?alt=media&token=ed1994f1-f3ee-4ab5-afa2-17c70a4f33d0";
    return notFound === true ? (
      <React.Fragment>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section
          style={{
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="new_profile_design"
        >
          <h1 className="has-text-centered title is-4">{notFoundMessage}</h1>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div
          className={`${showReviewsPopup ? "is-active" : ""} modal mainn-map`}
        >
          <div className="modal-background" />
          <div className="modal-card">
            <button
              onClick={this.toggleReviewsPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body ">
              {showReviewsPopup && reviews.length !== 0 ? (
                reviews.map((x, index) => {
                  return (
                    <div
                      key={index}
                      className="al-fb-reviews al-fb-reviews-1 align-left"
                    >
                      <header className="header">
                        <img
                          className="avatar lazyloaded"
                          src={require("../../assets/images/dummy-profile-pic-male1.jpg")}
                          alt="dummy"
                        />
                        <h3
                          className="title"
                          data-fontsize="18"
                          data-lineheight="27"
                        >
                          <span
                            className="rating"
                            title={`${x.rating} out of 5`}
                          >
                            {this.getStarRating(x.rating)}
                          </span>{" "}
                          <span className="name">
                            Review by {x.autor_first_name} {x.autor_last_name}
                          </span>
                        </h3>
                        <div className="meta">Created at {x.created_at}</div>
                      </header>
                      <div className="review-body review-body-short">
                        <p>{x.comment}</p>
                      </div>
                      <br />
                    </div>
                  );
                })
              ) : (
                <div className="column is-12">
                  <p className="title is-3 has-text-centered">No Reviews</p>
                </div>
              )}
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showComingSoonPopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleComingSoonPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Coming Soon</h1>
                <br />
                <p className="has-text-weight-normal has-text-justified">
                  This feature is coming soon.
                </p>
                <div className="map-buton">
                  <button
                    onClick={this.toggleComingSoonPopup}
                    className="btn-fill"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showServicesPopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleServicesPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h3 className="title">All Interior Services: </h3>
                <ul>
                  {keywords.map((x, index) => (
                    <li key={`services-keywords-${index}`}>
                      {servicesPromoted[swiperIndex].name} _ {x.name}
                    </li>
                  ))}
                </ul>
                <div className="map-buton">
                  <button
                    onClick={this.toggleServicesPopup}
                    className="btn-fill"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showReportProfilePopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleReportProfilePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Report Profile</h1>
                <br />
                <p className="has-text-weight-normal has-text-left">
                  Tell us a little more about why you are reporting
                </p>
                <div className="clearfix" />
                <div
                  className="membership_checkboxes"
                  style={{ display: "block", padding: 0 }}
                >
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(0)}
                        onClick={() => this.selectImageReportReason(0)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[0]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(1)}
                        onClick={() => this.selectImageReportReason(1)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[1]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(2)}
                        onClick={() => this.selectImageReportReason(2)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[2]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(3)}
                        onClick={() => this.selectImageReportReason(3)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[3]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(4)}
                        onClick={() => this.selectImageReportReason(4)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[4]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(5)}
                        onClick={() => this.selectImageReportReason(5)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[5]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(6)}
                        onClick={() => this.selectImageReportReason(6)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[6]}
                    </label>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="map-buton">
                  <button
                    onClick={this.toggleReportProfilePopup}
                    className="btn-fill"
                  >
                    SUBMIT
                  </button>
                  <button
                    onClick={this.toggleReportProfilePopup}
                    className="btn-empty"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className={`modal small-popup ${
            showReportImagePopup === true ? "is-active" : ""
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card ">
            <button
              onClick={this.toggleReportImagePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Report Image</h1>
                <br />
                <p className="has-text-weight-normal has-text-left">
                  Tell us a little more about why you are reporting
                </p>
                <div className="clearfix" />
                <div
                  className="membership_checkboxes"
                  style={{ display: "block", padding: 0 }}
                >
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(0)}
                        onClick={() => this.selectImageReportReason(0)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[0]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(1)}
                        onClick={() => this.selectImageReportReason(1)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[1]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(2)}
                        onClick={() => this.selectImageReportReason(2)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[2]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(3)}
                        onClick={() => this.selectImageReportReason(3)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[3]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(4)}
                        onClick={() => this.selectImageReportReason(4)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[4]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(5)}
                        onClick={() => this.selectImageReportReason(5)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[5]}
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label style={{ fontWeight: 400, color: "#4a4a4a" }}>
                      <input
                        checked={reportReasons.includes(6)}
                        onClick={() => this.selectImageReportReason(6)}
                        type="checkbox"
                        onChange={() => {}}
                      />
                      <span onClick={() => {}} className="checkmark" />
                      {reasons[6]}
                    </label>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="map-buton">
                  <button
                    onClick={this.toggleReportImagePopup}
                    className="btn-fill"
                  >
                    SUBMIT
                  </button>
                  <button
                    onClick={this.toggleReportImagePopup}
                    className="btn-empty"
                  >
                    Close
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section className="new_profile_design">
          <div className="container clearfix">
            <div className="right_main_slider">
              <div className="porfolio-main clearfix">
                <div className="swiper-container swiper-container1">
                  <div className="swiper-wrapper">
                    <Swiper
                      getSwiper={(e) => {
                        this.setState({
                          swiper: e,
                        });
                      }}
                      on={{
                        slideChange: this.handleSlideChange,
                      }}
                      {...hardSkillsSwiperParams}
                    >
                      {servicesPromoted.map((x, index) => (
                        <div key={`${x.id}:${index}`} className="swiper-slide">
                          <nav className="tabs is-boxed is-fullwidth illo">
                            <ul>
                              <li className="tab is-active">
                                <label
                                  onClick={this.handleSwipeLeft}
                                  className="m_svc_left"
                                >
                                  More services
                                </label>
                                <a>
                                  {x.name}
                                  {is24Hours === "Yes" && (
                                    <span className="twenty-four-hour-tag">
                                      (24 Hr.)
                                    </span>
                                  )}
                                </a>
                                <label
                                  onClick={this.handleSwipeRight}
                                  className="m_svc_right"
                                >
                                  More services
                                </label>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="portfolio-in-sec">
                  <div id="tab1" className="content-tab">
                    <Swiper
                      shouldSwiperUpdate={true}
                      getSwiper={(e) => {
                        this.setState({
                          swiper2: e,
                        });
                      }}
                      on={{
                        slideChange: this.handleImageSlideChange,
                      }}
                    >
                      {images.map((image, index) => {
                        return (
                          <div key={index} className="gallery-top">
                            <div
                              className="main-profile-img"
                              style={{
                                backgroundImage: `url(${image.path})`,
                              }}
                            >
                              <div className="report-like">
                                <div className="like-icons">
                                  <a onClick={this.toggleComingSoonPopup}>
                                    <i className="fa fa-thumbs-up" />
                                  </a>
                                  <a
                                    className="dislike "
                                    onClick={this.toggleComingSoonPopup}
                                  >
                                    <i className="fa fa-thumbs-down" />
                                  </a>
                                </div>
                                <a
                                  className="report-pro"
                                  onClick={this.toggleReportImagePopup}
                                >
                                  Report this portfolio
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Swiper>
                    <div className="new-bullet">
                      {images.map((image, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => this.setLargeImageViewPicture(image)}
                            style={{ margin: 3 }}
                            className={
                              selectedImagePath.index === image.index
                                ? "swiper-pagination-bullet swiper-pagination-bullet-active"
                                : "swiper-pagination-bullet"
                            }
                          />
                        );
                      })}
                    </div>
                    <div className="gallery-thumbs">
                      {images
                        .filter((x) => x.index !== selectedImagePath.index)
                        .map((image, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                this.setLargeImageViewPicture(image)
                              }
                              className="gall-thumb"
                            >
                              <div className="new-gall-thumb-tbl">
                                <div
                                  style={{
                                    backgroundImage: `url(${image.path})`,
                                  }}
                                  className="new-gall-thumb-cell"
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="left_testi">
              <p className="left_testi_heading">Contracting level services</p>
              <span className="left_testi_license">
                <a onClick={this.toggleServicesPopup}>
                  <u>(See All Services)</u>
                </a>
              </span>
              <span className="testi_user_name">
                {`${firstName} ${lastName}`}
              </span>
              <div
                className="left_testi_dp"
                style={{
                  backgroundImage: `url(${file})`,
                }}
              />
              <span className="left_testi_skills_selected">
                <a>
                  <u>{jobFunctions}</u>
                </a>
              </span>
              <div className="left_testi_stars">
                <p>
                  <span style={{ color: "#4a4a4a", fontWeight: 500 }}>
                    Customer Reviews
                  </span>
                  {"  "}
                  {this.getStarRating(5)}
                  <span
                    className="left_testi_stars_count"
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleReviewsPopup}
                  >
                    (5)
                  </span>
                </p>
              </div>
              <div className="left_testi_comp_info clearfix">
                {companyName !== "" ? (
                  <div className="left_testi_left clearfix">
                    <a style={{ marginBottom: 4 }}>{companyName}</a>
                  </div>
                ) : null}
                <div className="clearfix" />
                <div className="left_testi_left clearfix">
                  <a>
                    <i className="fa fa-map-marker-alt" />
                    {`${state}, USA`}
                  </a>
                  <a href={`tel:${phoneNumber}`}>
                    <i className="fa fa-phone" />
                    {phoneNumber}
                  </a>
                  <a href={`mailto:${email}`}>
                    <i className="fa fa-envelope" />
                    {email}
                  </a>
                </div>
                <div className="left_testi_right clearfix">
                  <div className="clearfix" />
                  <div className="favorite-sec">
                    <label onClick={this.toggleComingSoonPopup}>
                      <input disabled={true} type="checkbox" />
                      <span className="checkmark" />
                      Save To Favorites
                    </label>
                  </div>
                  <div className="favorite-sec">
                    <label onClick={this.toggleComingSoonPopup}>
                      <input disabled={true} type="checkbox" />
                      <span className="checkmark" />
                      Save To Project
                    </label>
                  </div>
                  {companyWebsite !== "" ? (
                    <div className="favorite-sec">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="comp_web_a"
                        href={`https://${companyWebsite}`}
                      >
                        {companyWebsite}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="left_testi_endorsement">
                <h2>Colleague / Co-worker endorsements</h2>
                <div className="endorse_list">
                  {vendorEndorsement.map((x) => {
                    return (
                      <div key={x.uuid} className="endorse_list_li">
                        <div className="endorse_list_left">
                          <span className="endorse_list_left_name">
                            {x.autor_name}
                          </span>
                          <span className="endorse_list_left_designation">
                            {x.autor_occupation}
                          </span>
                        </div>
                        <div className="endorse_list_right">
                          <span>{x.created}</span>
                        </div>
                        <div className="clearfix" />
                        <p>{x.body}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="righ-social">
                  <h1>Share this profile</h1>
                  <FacebookShareButton url={window.location.href}>
                    <i className="fab fa-facebook-f" />
                  </FacebookShareButton>
                  <PinterestShareButton
                    media={mediaPath}
                    url={window.location.href}
                  >
                    <i className="fab fa-pinterest-p" />
                  </PinterestShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <i className="fab fa-twitter" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={window.location.href}>
                    <i className="fab fa-linkedin" />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={window.location.href}>
                    <i className="fab fa-whatsapp" />
                  </WhatsappShareButton>
                  <EmailShareButton url={window.location.href}>
                    <i className="fa fa-envelope" />
                  </EmailShareButton>
                  <p className="reprot-profile">
                    <a onClick={this.toggleReportProfilePopup}>
                      Report This Profile{" "}
                      <img
                        src={require("../../assets/images/report-icon.png")}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(connect(mapStateToProps, null), withRouter)(CardDummy);
