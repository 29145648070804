import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Header from "../Header/header";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

class PrivacyPolicy extends Component {
  render() {
    const {
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
      isUserLoggedIn,
    } = this.props;
    return (
      <div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <div className="landing-banner-body">
          <div className="container">
            <h2 className="has-text-left">Privacy Policy</h2>
            <p className="has-text-grey-darker has-text-justified">
              Last Updated: October 31, 2022
            </p>
            <p className="has-text-grey-darker has-text-justified">
              IMPORTANT: PLEASE REVIEW THIS AGREEMENT CAREFULLY. IT AFFECTS YOUR
              LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              PropAGILE ("PropAGILE," "we," "our," or "us") values your privacy.
              In this Privacy Policy ("Policy"), we describe the information we
              collect, how we use it, and when and with whom we share it. This
              Policy applies to all sites, mobile applications, and other online
              services (collectively, "Platform") made available by PropAGILE.
              This Policy applies only to information collected on or through
              the Platform. It does not apply to information collected or
              obtained by or through any other means (including, without
              limitation, information collected offline, in person, over the
              telephone and/or by mail, or from third parties outside the
              Platform). Undefined capitalized terms used herein shall have the
              definitions as set forth in our Terms of Use. By accessing or
              using the Platform, you agree to this Policy. IF YOU DO NOT AGREE
              TO THIS POLICY, PLEASE DO NOT ACCESS OR USE THE PLATFORM.
            </p>
            <br />
            <h2 className="has-text-left">KEY TERMS</h2>
            <p className="has-text-grey-darker has-text-justified">
              Please review the "Key Terms" section in the Terms of Use.
            </p>
            <br />
            <h2 className="has-text-left">INFORMATION WE COLLECT</h2>
            <p className="has-text-grey-darker has-text-justified">
              How we collect and store information depends on how you access and
              use the Platform. We collect information in multiple ways
              including when you provide information directly to us, when you
              permit third parties to provide information to us, and when we
              passively collect information from you, such as information
              collected from your browser or device.
            </p>
            <br />
            <h2 className="has-text-left">
              Information You Provide Directly to Us
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              We may collect information from you during your use or access of
              the Platform, such as:
            </p>
            <p className="has-text-grey-darker has-text-justified">
              • When you register for an Account;
              <br />
              • When you participate in polls or surveys;
              <br />
              • When you enroll for electronic newsletters;
              <br />
              • When you request a quote or other information;
              <br />
              • When you submit or respond to a quote;
              <br />
              • When you make a purchase;
              <br />
              • When you fill out any forms;
              <br />
              • When you enter a sweepstakes or contest, or register for a
              promotion;
              <br />
              • When you transmit User Content;
              <br />
              • When you download or use one of our mobile applications; or
              <br />
              • When you otherwise communicate with us or other users through
              the Platform.
              <br />
              This list is illustrative, not exhaustive; the Privacy Policy
              applies to all use of the Platform.
              <br />
            </p>
            <p className="has-text-grey-darker has-text-justified">
              The information you provide directly to us may concern you or
              others and may include, but is not limited to: (a) name; (b) zip
              code; (c) email address; (d) home or business telephone number;
              (e) home, business or mailing address; (f) demographic information
              (e.g., gender, age, political preference, education, race or
              ethnic origin, and other information relevant to user surveys
              and/or offers); (g) date of birth; (h) insurance information; (i)
              photographs; (j) information about your project, request or need;
              (k) video or audio files; (l) in certain circumstances, payment
              and/or identity verification information; and/or (m) any other
              content you include in communications with other users through the
              Platform or communications with us. It may also include
              information specific to services you are requesting or offering
              through the Platform, such as a business name, service
              description, qualifications and credentials. You are not required
              to provide us with such information, but certain features of the
              Platform may not be accessible or available, absent the provision
              of the requested information.
            </p>
            <br />
            <h2 className="has-text-left">
              Information from Affiliates, Social Networking Sites, and other
              Non-affiliated Third Parties
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              We may collect information about you or others through PropAGILE
              affiliates or through non-affiliated third parties. For example,
              you may be able to access the Platform through a social networking
              account, such as Facebook. If you access the Platform through your
              Facebook account, you may allow us to have access to certain
              information in your Facebook profile. This may include your name,
              profile picture, gender, networks, user IDs, list of friends,
              location, date of birth, email address, photos, videos, people you
              follow and/or who follow you, and/or your posts or "likes."
            </p>
            <p className="has-text-grey-darker has-text-justified">
              Social networking sites, such as Facebook, have their own policies
              for handling your information. For a description of how these
              sites may use and disclose your information, including any
              information you make public, please consult the sites' privacy
              policies. We have no control over how any third-party site uses or
              discloses the personal information it collects about you.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              We may collect information about you or others through
              non-affiliated third parties. For example, to the extent permitted
              by law, we may, in our sole discretion, ask for and collect
              supplemental information from third parties, such as information
              about your credit from a credit bureau, or information to verify
              your identity or trustworthiness, or for other fraud or safety
              protection purposes. We may combine information that we collect
              from you through the Platform with information that we obtain from
              such third parties and information derived from any other products
              or services we provide.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              Through non-affiliated third parties, we may also collect
              information that demonstrates the occurrence of an off-Platform
              communication between a Customer Member and a Service Member in
              order to ensure accurate charging of fees imposed upon Service
              Members and to enforce the Terms of Use. Except as explicitly
              stated herein, we will not collect or store the contents of any
              off-Platform communication between a Customer Member and a Service
              Member.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              If you send text messages with a Customer Member or Service Member
              using the telephone number for that Member available on the
              Platform, we may use a third-party service provider to track these
              text messages. We track these text messages for fraud prevention,
              to ensure appropriate charging of Fees, to enforce these Terms,
              and for quality and training purposes. As part of this process,
              PropAGILE and its service provider will receive in real time and
              store data about your text message, including the date and time of
              the text message, your phone number, and the content of the text
              message.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              If you send text messages with a Customer Member or Service Member
              using the telephone number for that Member available on the
              Platform, we may use a third-party service provider to track these
              text messages. We track these text messages for fraud prevention,
              to ensure appropriate charging of Fees, to enforce these Terms,
              and for quality and training purposes. As part of this process,
              PropAGILE and its service provider will receive in real time and
              store data about your text message, including the date and time of
              the text message, your phone number, and the content of the text
              message.
            </p>
            <br />
            <h2 className="has-text-left">
              Information That is Passively or Automatically Collecteds
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              Device/Usage Information. We and our third-party service
              providers, which include ad networks and analytics companies
              including DoubleClick and Google Analytics, may use cookies, web
              beacons, and other tracking technologies to collect information
              about the computers or devices (including mobile devices) you use
              to access the Platform. As described further below, we may collect
              and analyze information including but not limited to (a) browser
              type; (b) ISP or operating system; (c) domain name; (d) access
              time; (e) referring or exit pages; (f) page views; (g) IP address;
              (h) unique device identifiers (e.g. IDFA or Android ID); and (i)
              the type of device that you use. We may also track when and how
              frequently you access or use the Platform. We use this information
              (including the information collected by our third-party service
              providers) for Platform analytics (including to determine which
              portions of the Platform are used most frequently and what our
              users like/do not like), to assist in determining relevant
              advertising (both on and off the Platform), to evaluate the
              success of our advertising campaigns, and as otherwise described
              in this Policy.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              Location Information. When you use the Platform, we may collect
              general location information (such as IP address). If you install
              our mobile app, we may ask you to grant us access to your mobile
              device's geolocation data. If you grant such permission, we may
              collect information about your precise geolocation, and we may use
              that information to improve the Platform, including providing you
              with location-based features (e.g. for identification of Pro
              Services available near you). To deliver customized content and
              advertising, we may share your location information with our
              agents, vendors, or advertisers. If you access the Platform
              through a mobile device and you do not want your device to provide
              us with location-tracking information, you can disable the GPS or
              other location-tracking functions on your device, provided your
              device allows you to do this. See your device manufacturer's
              instructions for further details. If you disable certain
              functions, you may be unable to use certain parts of the
              Platform.Device/Usage Information. We and our third-party service
              providers, which include ad networks and analytics companies
              including DoubleClick and Google Analytics, may use cookies, web
              beacons, and other tracking technologies to collect information
              about the computers or devices (including mobile devices) you use
              to access the Platform. As described further below, we may collect
              and analyze information including but not limited to (a) browser
              type; (b) ISP or operating system; (c) domain name; (d) access
              time; (e) referring or exit pages; (f) page views; (g) IP address;
              (h) unique device identifiers (e.g. IDFA or Android ID); and (i)
              the type of device that you use. We may also track when and how
              frequently you access or use the Platform. We use this information
              (including the information collected by our third-party service
              providers) for Platform analytics (including to determine which
              portions of the Platform are used most frequently and what our
              users like/do not like), to assist in determining relevant
              advertising (both on and off the Platform), to evaluate the
              success of our advertising campaigns, and as otherwise described
              in this Policy.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              Cookies and Other Electronic Technologies. We and our third-party
              service providers may use cookies, clear GIFs, pixel tags, and
              other technologies that help us better understand user behavior,
              personalize preferences, perform research and analytics, and
              improve the Platform. These technologies, for example, may allow
              us to tailor the Platform to your needs, save your password in
              password-protected areas, track the pages you visit, help us
              manage content, and compile statistics about Platform usage. We
              also use certain of these technologies to deliver advertisements
              through the Platform that may interest you. We or our third-party
              service providers also may use certain of these technologies in
              emails to our customers to help us track email response rates,
              identify when our emails are viewed, and track whether our emails
              are forwarded.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              We may also use local shared objects (also known as "Flash
              cookies") to assist in delivering special content, such as video
              clips or animation. Flash cookies are stored on your device, but
              they are not managed through your web browser. To learn more about
              how to manage Flash cookies, you can visit the Adobe website and
              make changes at the Global Privacy Settings Panel.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but your browser may allow you to
              modify your browser settings to decline cookies if you prefer. If
              you disable cookies, you may be prevented from taking full
              advantage of the Platform, because it may not function properly.
              Flash cookies operate differently than browser cookies, and cookie
              management tools available in a web browser may not affect flash
              cookies. As we adopt additional technologies, we may also gather
              additional information through other methods.
            </p>
            <br />
            <h2 className="has-text-left">Use of Information</h2>
            <p className="has-text-grey-darker has-text-justified">
              The information PropAGILEcollects is used in a variety of ways,
              including
            </p>
            <p className="has-text-grey-darker has-text-justified">
              • for internal review;
              <br />
              • to improve the Site and Services;
              <br />
              • to optimize third-party offers of products and/or services;
              <br />
              • to verify the legitimacy of reviews and ratings;
              <br />
              • to notify you about updates to the Site and Services;
              <br />
              • to let you know about products, services, and promotions that
              you may be interested in;
              <br />
              • to get reviews and ratings;
              <br />
              • for our marketing purposes and the marketing of discounts
              offered through our Site and Services by service contractors or
              health care related providers (collectively "Service Providers");
              <br />
              • to fulfill and provide products and services, including
              personalized or enhanced services, requested by you; and
              <br />• internal business analysis or other business purposes
              consistent with our mission; and to carry out other purposes that
              are disclosed to you and to which you consent.
            </p>
            <br />
            <h2 className="has-text-left">
              Disclosures and Transfers of Information
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              We do not disclose Personal Information to third parties, except
              when one or more of the following conditions is true:
            </p>
            <p className="has-text-grey-darker has-text-justified">
              • We have your permission to make the disclosure;
              <br />
              • The disclosure is necessary for the purpose for which the
              personal information was obtained;
              <br />
              • The disclosure is to the service provider from whom you
              purchased services through Angie’s List’s platform, including
              without limitation Big Deals, Storefronts, and project
              submissions;
              <br />
              • The disclosure is to financial service providers in order to
              fulfill and carry out the purchase and provision of goods and
              services requested by you;
              <br />
              • The disclosure is permitted by relevant law;
              <br />
              • The Personal Information to be disclosed is otherwise publicly
              available in accordance with the applicable law;
              <br />
              • The disclosure is reasonably related to the sale or other
              disposition of all or part of our business or assets;
              <br />
              • The disclosure is for our own marketing purposes (including,
              without limitation, for PropAGILE to market services to you on
              third-party social media platforms such as Facebook), or, with
              your authorization, for the marketing purposes of third parties;
              <br />
              • The disclosure is combined with information collected from other
              companies and used to improve and personalize services, content,
              and advertising from us or third parties;
              <br />
              • The party to whom the disclosure is made controls, is controlled
              by, or is under common control with Angie's List;
              <br />
              • The disclosure is in our sole discretion necessary for the
              establishment or maintenance of legal claims or legal compliance,
              to satisfy any law, regulation, subpoena or government request, or
              in connection with litigation;
              <br />
              • The disclosure is in our sole discretion about users who we
              believe are engaged in illegal activities or are otherwise in
              violation of our PropAGILE Membership Agreement, even without a
              subpoena, warrant or court order; or
              <br />
              • The disclosure is to outside businesses to perform certain
              services for us, such as maintaining our Site and Services,
              mailing lists, processing orders and delivering products and
              services, sending postal mail, processing claims for lost or
              stolen certificates, providing marketing assistance, confirming
              your identity for review integrity, and data analysis
              ("Administrative Service Providers"), including Administrative
              Service Providers outside the country or jurisdiction in which you
              reside.
              <br />
              Prior to sharing of your Personal Information with others,
              agreements are made between PropAGILE and such outside parties to
              whom we are transferring data to provide for the allowable use and
              security of the transferred information in accordance with this
              Privacy Policy and applicable law. If PropAGILE becomes aware that
              any such outside party is improperly using or disclosing Personal
              Information, it will notify that outside party and request that it
              take reasonable steps to cease such use or disclosure.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              We may provide certain Personal Information as a service to others
              for their marketing purposes. We may also disclose your name and
              address to Service Providers when you submit User Generated
              Content relating to those Service Providers. If you properly
              notify us that you no longer want us to share your Personal
              Information, we will cease sharing your Personal Information.
              However, except as otherwise stated in this Privacy Policy, we do
              not directly control the third parties to which we share such
              Personal Information and you will need to further notify such
              third parties directly if you would like them to cease use of your
              Personal Information.
            </p>
            <p className="has-text-grey-darker has-text-justified">
              We may also disclose aggregate visitor data in order to describe
              the use of the Site and Services to our existing or potential
              business partners or other third parties, or in response to a
              government request. We may also share aggregated demographic and
              preference data with third parties to enable the provision of
              targeted information, promotions, offers and/or discounts on
              products and services.
            </p>
            <br />
            <h2 className="has-text-left">
              Choice: Accessing Your Information and Opt-in/Opt-out Requests
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              To help you review, edit, and control how we use much of the
              information we collect about you, you can visit your "Manage My
              Accounts" webpage. On the "Manage My Accounts" webpage, you can
              update certain information and unsubscribe from receiving specific
              types of email messages and telephone calls, including autodialed
              or pre-recorded telemarketing calls placed by us or a third party
              on our behalf. You may also unsubscribe from our monthly digital
              magazine, which is a benefit that is included with your
              subscription. You cannot, however, unsubscribe from receiving
              certain emails, such as account status and billing related emails,
              unless you close your account and fulfill any existing contractual
              obligations.
            </p>
            <br />
            <h2 className="has-text-left">PRIVACY OF MINORS</h2>
            <p className="has-text-grey-darker has-text-justified">
              Our Services are not designed for minors under 18. Only persons 18
              years of age or older may use the Platform. If we discover that an
              individual under 18 has provided us with personal information, we
              will close the account and delete the personal information to the
              extent required by the Children's Online Privacy Protection Act.
              We may, where permitted by law, retain certain information
              internally for purposes described in this Policy.
            </p>
            <br />
            <h2 className="has-text-left">SECURITY</h2>
            <p className="has-text-grey-darker has-text-justified">
              We employ physical, procedural and technological security measures
              to help protect your personal information from unauthorized access
              or disclosure. PropAGILE may use encryption, passwords, and
              physical security measures to help protect your personal
              information against unauthorized access and disclosure. No
              security measures, however, are 100% failsafe. Therefore, we do
              not promise and cannot guarantee, and thus you should not expect,
              that your personal information or communications will not be
              collected, disclosed and/or used by others. You should take steps
              to protect against unauthorized access to your password, phone,
              and computer by, among other things, signing off after using a
              shared computer, choosing a robust password that nobody else knows
              or can easily guess, keeping your log-in and password private, and
              not recycling passwords from other websites or accounts. PropAGILE
              is not responsible for the unauthorized use of your information
              nor for any lost, stolen, or compromised passwords, or for any
              activity on your Account via unauthorized password activity.
            </p>
            <br />
            <h2 className="has-text-left">LINKS TO EXTERNAL PLATFORMS</h2>
            <p className="has-text-grey-darker has-text-justified">
              The Platform may contain links to other websites or resources over
              which PropAGILE does not have any control. Such links do not
              constitute an endorsement by PropAGILE of those external websites.
              You acknowledge that PropAGILE is providing these links to you
              only as a convenience, and further agree that PropAGILE is not
              responsible for the content of such external websites or the
              protection and privacy of information you provide while visiting
              such external websites.
            </p>
            <br />
            <h2 className="has-text-left">
              UPDATING, DELETING, AND CORRECTING YOUR INFORMATION
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              You may review, correct and delete certain information about you
              by logging in to the Platform and navigating to your preferences
              page in "Your Dashboard." You must promptly update your Account
              information if it changes or is inaccurate. Upon your request, we
              will close your Account and remove your profile information from
              view as soon as reasonably possible. We may retain information
              from closed Accounts in order to comply with the law, prevent
              fraud, collect any fees owed, resolve disputes, troubleshoot
              problems, assist with any investigations of any user, enforce our
              Terms of Use, and/or for any other purposes otherwise permitted by
              law that we deem necessary in our sole discretion. You should
              understand, however, that once you transmit User Content through
              the Platform, you may not be able to change or remove it. Once we
              have deactivated or removed your Account, you agree that PropAGILE
              will not be responsible to you for retaining information related
              to your Account.
            </p>
            <br />
            <h2 className="has-text-left">
              YOUR CHOICES REGARDING EMAIL COMMUNICATIONS
            </h2>
            <p className="has-text-grey-darker has-text-justified">
              We may send periodic emails to you. You may opt out of promotional
              emails by following the opt-out instructions contained in the
              email. Please note that it may take up to 10 business days for us
              to process opt-out requests. If you opt out of receiving emails
              about recommendations or other information we think may interest
              you, we may still send you emails about your Account or any
              services you have requested or received from us.
            </p>
            <br />
            <h2 className="has-text-left">CHANGES TO THIS POLICY</h2>
            <p className="has-text-grey-darker has-text-justified">
              THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE SET FORTH ABOVE.
              PROPAGILE MAY, IN ITS SOLE AND ABSOLUTE DISCRETION, CHANGE THIS
              POLICY AT ANY TIME. PROPAGILE WILL POST ITS UPDATED POLICY ON THE
              PLATFORM, SEND YOU A MESSAGE OR OTHERWISE NOTIFY YOU WHEN YOU ARE
              LOGGED INTO YOUR ACCOUNT. PROPAGILE ENCOURAGES YOU TO REVIEW THIS
              POLICY REGULARLY FOR ANY CHANGES. YOUR CONTINUED ACCESS TO OR USE
              OF THE PLATFORM WILL BE SUBJECT TO THE TERMS OF THE THEN-CURRENT
              POLICY.
            </p>
            <br />
            <h2 className="has-text-left">CONTACT US</h2>
            <p className="has-text-grey-darker has-text-justified">
              If you have any questions about the Privacy Policy or the
              Platform, please contact us by sending an email to 
              <a href="mailto:support@propagile.com">support@propagile.com</a>,
              or by writing to: propagile, 41-98 Forley Street 4D, Elmhurst NY, 11373
            </p>
            <br />
            <h2 className="has-text-left">PropAGILE</h2>
            <p className="has-text-grey-darker has-text-justified">
              Let customers find you.
            </p>
            <br />
          </div>
        </div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(connect(mapStateToProps, null))(PrivacyPolicy);
