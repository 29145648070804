/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";

class TermsPopup extends Component {
  render() {
    const { closeTerms, acceptTerms } = this.props;
    return (
      <div className="modal mainn-map is-active pp-tt-popup">
        <div className="modal-background"></div>
        <div className="modal-card">
          <button
            onClick={closeTerms}
            className="delete"
            aria-label="close"
          ></button>
          <section className="modal-card-body home-modal">
            <div className="endos_panel2 clearfix">
              <h1>Terms of Use</h1>
              <hr />
              <div className="pp-tt">
                <h2 className="has-text-left">
                  PROPAGILE MEMBERSHIP AGREEMENT
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  Last updated on October 31, 2019
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  IMPORTANT: PLEASE REVIEW THIS AGREEMENT CAREFULLY. IT AFFECTS
                  YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
                  COURT.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  THESE TERMS REQUIRE BINDING ARBITRATION TO RESOLVE ANY DISPUTE
                  OR CLAIM RELATING TO THESE TERMS, THE SERVICES OR PROPERTIES
                  OR ANY RELATIONSHIP BETWEEN US. INCLUDING BUT NOT LIMITED TO
                  CLAIMS ARISING OUT OF OR RELATING TO ANY ASPECT OF THE
                  RELATIONSHIP BETWEEN US, WHETHER BASED IN CONTRACT, TORT,
                  STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY;
                  CLAIMS THAT AROSE BEFORE THIS AGREEMENT OR ANY PRIOR AGREEMENT
                  (INCLUDING, BUT NOT LIMITED TO, CLAIMS RELATED TO
                  ADVERTISING); AND CLAIMS THAT MAY ARISE AFTER THE TERMINATION
                  OF THIS AGREEMENT. SUCH DISPUTE OR CLAIM WILL BE RESOLVED ONLY
                  ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
                  REPRESENTATIVE ACTION OR ARBITRATION.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  THESE TERMS ALSO CONTAIN A DISCLAIMER OF WARRANTIES AND A
                  DISCLAIMER OF LIABILITY, AS WELL AS A RELEASE AND
                  INDEMNIFICATION BY YOU. PLEASE READ THEM CAREFULLY. SERVICE
                  PROVIDERS: PROPAGILE IS NOT LIMITED TO ARBITRATION AS TO ACTS
                  OF FRAUD OR OTHER WILLFUL MISCONDUCT BY YOU, WHICH PROPAGILE
                  MAY ADDRESS BY ALL LAWFUL MEANS.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  All references to (“Users,” used interchangeably with “you”,
                  “Vendor”, “Service Providers” and “Customer” throughout this
                  Agreement) as applicable, mean the person who accesses, uses,
                  and/or participates in the use of any of PropAGILE’s services,
                  and you represent and warrant that you are authorized to enter
                  into this Agreement on behalf of yourself and such employer or
                  company.
                </p>
                <br />
                <h2 className="has-text-left">ACKNOWLEDGEMENT & ACCEPTANCE</h2>
                <p className="has-text-grey-darker has-text-justified">
                  Welcome to PropAGILE, a technology service operated by GV
                  Design Studio Inc., and offered via the propagile.com website,
                  which enables service providers and customers, in the
                  residential market to run business operations.
                </p>
                <br />
                <h2 className="has-text-left">
                  USE OF OUR SERVICES AND PROHIBITED USES
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  The Service is offered to Users who are 18 years of age or
                  older and reside in the United States. Use of the Service is
                  permitted only to individuals who can form legally binding
                  contracts under applicable law. If you do not qualify, do not
                  use the Service. By using the Service, you represent and
                  warrant that you are 18 years of age or older and otherwise
                  meet all eligibility requirements herein, and that you have
                  read, understood, and agree to be bound by this Terms of
                  Service Agreement together with the Privacy Policy, just as if
                  you had signed this agreement.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You acknowledge and agree that your use of PropAGILE is for
                  your personal or company use and not for advertising of any
                  purposes other than the services included in PropAGILE’s list
                  of services intended exclusively to serve property
                  development, management and promotion. You agree not to
                  copy/collect PropAGILE content via scripts, robots, spiders,
                  crawlers, scrapers or any automated or by hand. You may not
                  use PropAGILE to recreate or compete with us, or to solicit or
                  harass anyone, or for any other purpose not intended herein.
                  You acknowledge that a violation of the foregoing could result
                  in significant damages, and you agree that you are liable to
                  PropAGILE for any such damages, and will cover PropAGILE’s
                  cost in the event of any claims against PropAGILE based on or
                  arising from your violation of the previous. We reserve the
                  right to revoke your access to any PropAGILE Services,
                  products or properties at any time. All information about
                  Service Professionals is confidential and for your personal
                  use only. If it is determined or suspected by PropAGILE in its
                  sole discretion that you are misusing or attempting to misuse
                  or circumvent PropAGILE’s services or system, or are using or
                  attempting to use them for any inappropriate purposes,
                  including but not limited to activities such as hacking,
                  infiltrating, advertising, fraud, scraping content, jamming or
                  spamming, PropAGILE reserves the right, in its sole
                  discretion, at any time and without notice, to remove any
                  Materials, and suspend or terminate your Account or your
                  access to the Service or initiate without notice appropriate
                  legal actions or proceedings to seek appropriate remedies
                  and/or damages, including but not limited to lost revenue,
                  repairs, legal fees, costs and expenses, and to seek
                  injunctions or other equitable remedies if you engage in
                  activities that violate this Agreement
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You also acknowledge and agree that we may amend this
                  Agreement at any time by posting a revised version of this
                  Agreement on the Service or our website or by notifying you
                  directly in our sole discretion. Your continued use of the
                  Service after any changes are made to this Agreement shall
                  constitute your consent to such changes. We do not and will
                  not assume any obligation to notify Users of any changes to
                  this Agreement, or the creation or modification of any
                  additional terms. You are responsible for remaining
                  knowledgeable about the terms of the Agreement.
                </p>
                <br />
                <h2 className="has-text-left">
                  ACCOUNT REGISTRATION AND OTHER SUBMISSIONS
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  By registering for an account to use the Site or Site
                  Services, by using the Site or Site Services after the
                  Effective Date if you had an Account on the Effective Date, or
                  by clicking to accept the Terms of Service when prompted on
                  the Site, you agree to abide by this Agreement and the other
                  Terms of Service.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Users may access the Platform without payment during the free
                  trial period to access and participate in certain features of
                  the Platform, you will need to create a password-protected
                  account ("Account"). Account registration is required for
                  Users to receive or use certain Services. When you open or
                  confirm an account, you will be required provide us with
                  specified personal information, which may include your name,
                  address, mobile phone number, etc. You must keep your account
                  information complete, accurate and current at all times. You
                  agree to maintain the security of your account information and
                  that you are responsible for all activity in connection with
                  your account and you will notify PropAGILE immediately of any
                  unauthorized use. By creating your account, you agree that
                  communication may be made with you via telephone, email, text
                  (SMS) message or other reasonable electronic or non-electronic
                  means, at any of the contact numbers or addresses you provide,
                  as part of the normal business operation of the Services.
                </p>
                <br />
                <h2 className="has-text-left">
                  INFORMATION YOU PROVIDE TO US/TCPA CONSENT
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  Upon using PropAGILE , you will be prompted to disclose
                  certain information about yourself and the services you
                  provide, and you will be able to store information, such as
                  services, on our website. By providing this information to us,
                  or by submitting a service request, you are requesting, and
                  you expressly consent to being contacted by us and by our
                  customers, via phone, email, at any of your contact numbers or
                  addresses, even if you are listed on any federal, state,
                  provincial or other applicable "Do Not Call" list, in order
                  that we may provide the Services set forth on our site, to
                  service your account, to reasonably address matters pertaining
                  to your account, including but not limited to notifying you
                  of, or confirming, appointments that you have scheduled, or
                  for other purposes reasonably related to your service request
                  and our business, including marketing related emails. You also
                  authorize PropAGILE to send you automated emails with
                  communications concerning promotions run by us, and news
                  concerning PropAGILE and industry developments. For complete
                  details on our use of your information, please see our 
                  <Link to="/privacy">privacy statement</Link>. You agree that
                  by completing a service request, you are entering into a
                  business relationship with PropAGILE and/or a customer and
                  thus agree to be contacted by PropAGILE and/or a customer. You
                  promise that all information you provide (including but not
                  limited to your contact information, and any Endorsements and
                  Reviews) will be accurate, current and truthful to the best of
                  your knowledge. If you provide any information that is untrue,
                  not current or incomplete or request for reviews or
                  Endorsement not from the intended purpose but for the purpose
                  of deceiving customers to favorite or select you, or PropAGILE
                  has reasonable grounds to suspect that such information is
                  untrue, inaccurate, not current or incomplete, PropAGILE has
                  the right to refuse any current or future use of the PropAGILE
                  Services (or any portion thereof) by you. You are responsible
                  for any use of the PropAGILE Services by persons to whom you
                  intentionally or negligently allow access to your
                  password. You acknowledge that you are not required to consent
                  to receive promotional messages as a condition of using the
                  Services. If a contact number you have provided to us is no
                  longer your number, you agree to change it in your profile
                  page. You represent that you have received, and are authorized
                  to convey to us, the consent of any authorized users on your
                  account to be contacted by us as described in this Section.
                  You agree that all consents provided in this Section will
                  survive cancellation of your account. You may opt-out of
                  receiving promotional or marketing texts or calls from
                  PropAGILE at any time. You may opt-out of receiving all text
                  (SMS) messages from PropAGILE (including informational or
                  transactional messages) by replying with the word "STOP" to a
                  text message from us; however you acknowledge that opting out
                  of receiving all texts may impact your use of the Services.
                  You also acknowledge that PropAGILE or its third party
                  partners may record customer service calls after notice to you
                  and with your consent, in order to assist you when you contact
                  our customer support services.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  TO KNOWINGLY INPUT FALSE INFORMATION, INCLUDING BUT NOT
                  LIMITED TO NAME, PHONE NUMBER, ADDRESS OR E-MAIL ADDRESS IS A
                  VERY SERIOUS AND FRAUDULENT MATTER THAT COULD RESULT IN
                  SIGNIFICANT COSTS AND DAMAGES INCLUDING INVASION OF PRIVACY
                  RIGHTS, TO PropAGILE AND THE CUSTOMERS, AND TO CONSUMERS, AS
                  WELL AS THE LOSS OF TIME, EFFORT AND EXPENSE RESPONDING TO AND
                  PURSUING SUCH FALSE INFORMATION AND REQUEST, AND FURTHER,
                  COULD RESULT IN REGULATORY FINES AND PENALTIES. ACCORDINGLY,
                  IF YOU KNOWINGLY INPUT FALSE INFORMATION IN A SERVICE REQUEST,
                  INCLUDING BUT NOT LIMITED TO SOME ONE ELSE'S NAME, E-MAIL
                  ADDRESS, PHYSICAL ADDRESS OR PHONE NUMBER OR A RANDOM OR MADE
                  UP NAME, ADDRESS, E-MAIL OR PHONE NUMBER YOU AGREE TO FULLY
                  INDEMNIFY AND BE LIABLE TO PropAGILE AND EACH SERVICE PROVIDER
                  WHO ACCEPTS SUCH SERVICE REQUESTS, FOR THE GREATER OF: (1) A
                  MINIMUM AMOUNT OF $10,000 TO EACH OF PropAGILE AND EACH OF THE
                  AFFECTED SERVICE PROFESSIONALS AND FOR EACH OF THE ACTUAL
                  PERSON(S) AFFECTED BY ANY OF THE IMPROPER, INCORRECT OR
                  FRAUDULENT INFORMATION YOU ENTER (FOR EXAMPLE THE ACTUAL OWNER
                  OF THE E-MAIL ADDRESS OR PHONE NUMBER, ETC.), PER IMPROPER
                  SUBMISSION, PLUS ANY ATTORNEYS FEES COSTS AND EXPENSE RELATING
                  THERETO, IF APPLICABLE, OR (2) THE ACTUAL DAMAGES, DIRECT,
                  PUNITIVE AND CONSEQUENTIAL, AND ANY REGULATORY OR JUDICIAL
                  FINES OR PENALTIES THAT MAY ARISE FROM SUCH INTENTIONAL,
                  MISLEADING, HARMFUL AND FRAUDULENT ACTIVITY, PLUS REASONABLE
                  LEGAL FEES, COST AND EXPENSES RELATING THERETO, WHICH EVER IS
                  GREATER.
                </p>
                <br />
                <h2 className="has-text-left">
                  PROPAGILE BUDGET, FEES, AND TAXES
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  In connection with use of PropAGILE 's Platform, PropAGILE
                  charges certain fees ("PropAGILE Fees" or "Fees").
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Service Members can pay Fees to PropAGILE in order to receive
                  certain services on the Platform, including but not limited to
                  being matched with and receiving contacts from Customer
                  Members or other individuals. As a Service Member, PropAGILE
                  will automatically charge your stored payment method when
                  those Customer Members or other individuals contact you. Fees
                  for additional products or services, including ongoing
                  products or services, will be presented to you before you use
                  such products or services. In order to use PropAGILE , you
                  must have at least one valid payment method stored on file. As
                  described more fully below, you may also be required to set a
                  Budget (as defined below) for at least one service category
                  (which may also apply to contacts in jobs related to your
                  preferences). Except as otherwise described herein, all sales
                  on PropAGILE are final and non-refundable.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  <i>Budget for Service Members</i>. As a Service Member,
                  PropAGILE may require you to set a budget (“Budget”) for how
                  much you are willing to spend to be contacted by customers
                  over a specific period (“Budget Period”) per category of
                  services. If a Budget is required, you agree that PropAGILE
                  can charge your payment method up to the dollar amount set in
                  your Budget during a given Budget Period. You further agree
                  PropAGILE is authorized to charge your payment method in
                  excess of your budget for the sole purpose of collecting any
                  applicable taxes. You will not be charged the Budget amount
                  upfront or automatically. Rather, you will be charged Fees
                  when Customer Members or other individuals contact you (as
                  further described below). Unless explicitly specified,
                  PropAGILE will not charge your payment method for contacts by
                  a Customer Member or other individual in excess of your Budget
                  during a given Budget Period unless you adjust your Budget
                  upward before the charges are incurred. While PropAGILE may
                  provide an estimate for the number of customers who may
                  contact you if you set a particular Budget for a particular
                  Budget Period, you acknowledge and agree that any such
                  estimate is not a guarantee, that PropAGILE does not and
                  cannot control any Customer Member or other individual’s
                  interest in contacting or hiring you, and that actual results
                  will vary.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  We will identify the length of the Budget Period when you set
                  your Budget. The length of the Budget Period will not change
                  within a given Budget Period but may be changed for subsequent
                  Budget Periods. If a Budget Period length changes, you can
                  adjust your Budget. If you do not do so, PropAGILE will
                  automatically pro-rate your then-current Budget to the length
                  of the new period. For example, if you indicate you are
                  willing to spend $100 for a one-week Budget Period, the Budget
                  Period shifts to two weeks, and you do not make changes
                  manually, your Budget will automatically be adjusted to $200
                  for the two-week Budget Period.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Similarly, if PropAGILE merges two or more categories of
                  services for which you have set a Budget, your Budget for the
                  newly combined category will be the sum of the Budgets for the
                  two or more categories prior to the merger. You will be able
                  to adjust how much you are willing to spend in the newly
                  combined category for future Budget Periods.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You can change your Budget for a Budget Period at any time,
                  including in the middle of a Budget Period. If you adjust your
                  Budget upward, the new Budget will take effect immediately. If
                  you adjust your Budget downward, the new Budget will only take
                  effect at the beginning of the next Budget Period. If you do
                  not change your Budget in advance of a subsequent Budget
                  Period, your Budget from the then-current Budget Period will
                  automatically be used for that subsequent Budget Period,
                  subject to the forgoing.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  <i>Timing of Charges for Service Members on PropAGILE</i>. As
                  a Service Member using PropAGILE , there are different points
                  at which your payment method may be charged:
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If You Lack Sufficient Purchasing History on PropAGILE
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If you are new to PropAGILE or do not have a sufficient
                  purchasing history on the Platform as determined in PropAGILE
                  ’s sole and absolute discretion, your payment method may be
                  charged each time you are contacted by a Customer Member or
                  other individual and you do not have an adequate PropAGILE
                  balance to cover the cost of that contact.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If You Have a Sufficient Purchasing History On PropAGILE
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Once PropAGILE determines, in its sole and absolute
                  discretion, that you have sufficient purchasing history on
                  PropAGILE , PropAGILE may charge your payment method
                  periodically for the total Fees accrued when Customer Members
                  or other individuals contact you. Specifically, PropAGILE may
                  charge your payment method each time (a) a specific time
                  period has passed; and/or (b) within that time period, each
                  time the total Fees accrued hit a certain dollar threshold.
                  The frequency of charges may not match the Budget Period
                  described above.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE may adjust both the specific time period and the
                  dollar threshold that trigger charges in its sole and absolute
                  discretion. PropAGILE may also, in its sole and absolute
                  discretion, revert to charging your payment method each time
                  you are contacted by a Customer Member or other individual and
                  you do not have an adequate PropAGILE balance to cover the
                  cost of that contact.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  <i>Default Payment Method</i>. For ordinary charges of Service
                  Members, PropAGILE will first attempt to charge the default
                  payment method. In the event that PropAGILE is unable to
                  charge that payment method for any reason--including but not
                  limited to exceeding a payment method’s credit limit, payment
                  method cancellation, a payment method being out of date, or
                  payment method details being incorrect--you agree that
                  PropAGILE may charge any other payment method associated with
                  your account. If your payment details change, your card
                  provider may provide us with updated card details. We may use
                  these new details in order to help prevent any interruption to
                  the Pro Services. If PropAGILE is unable to charge the default
                  payment method, PropAGILE will attempt to charge any other
                  payment methods associated with your Account in the order in
                  which they were most recently provided, used, or updated on
                  the Platform, charging the most recently provided, used, or
                  updated valid payment method first.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  <i>Penalty Fees for All Members</i>. PropAGILE may also charge
                  penalty Fees for fraud, misconduct or other violations of
                  these Terms, as determined in our sole discretion. Information
                  about current penalty Fees is available in the help center.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You agree to pay all applicable Fees or charges based on the
                  Fee and billing terms then in effect, regardless of whether
                  you have an active Account. Charges shall be made to the
                  payment method designated at the time you accrue a Fee. If you
                  do not pay on time or if PropAGILE cannot charge a payment
                  method for any reason, PropAGILE reserves all rights
                  permissible under law to recover payment and all costs and
                  expenses incurred, including reasonable attorneys' fees, in
                  our pursuit of payment. If any Fee is not paid in a timely
                  manner, or we are unable to process your transaction using the
                  payment method provided, we reserve the right to terminate or
                  suspend your Account.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You explicitly agree that all communication in relation to
                  delinquent accounts or Fees due will be made by electronic
                  mail or by phone. You agree that all notices, disclosures, and
                  other communications that we provide to you electronically
                  satisfy any legal requirement that such communications be in
                  writing. You agree that you have the ability to store such
                  electronic communications such that they remain accessible to
                  you in an unchanged form. You agree to keep your contact
                  information, including, but not limited to, email address and
                  phone number current. Such communication may be made by
                  PropAGILE or by anyone on its behalf, including but not
                  limited to a third-party collection agent. If you cancel your
                  Account at any time, you will not receive any refund. If you
                  have a balance due on any Account, you agree that PropAGILE
                  may charge such unpaid Fees to any payment method or otherwise
                  bill you for such unpaid Fees.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  <i>Taxes</i>. Payments required by these Terms may be stated
                  exclusive of all taxes, duties, levies, imposts, fines or
                  similar governmental assessments, including sales and use
                  taxes, value-added taxes, goods and services taxes, excise,
                  business, service, and similar transactional taxes imposed by
                  any jurisdiction and the interest and penalties thereon
                  (collectively, “Taxes”). Certain jurisdictions, however, may
                  require us to collect Taxes from you in connection with the
                  purchase of, payment for, access to, or use of the Pro
                  Services, and you shall be responsible for and bear Taxes
                  associated with the purchase of, payment for, access to, or
                  use of the Pro Services. Charges are inclusive of applicable
                  Taxes where required by law. You hereby confirm that PropAGILE
                  can determine your appropriate jurisdiction for tax purposes
                  however it deems appropriate or as required by law, and you
                  agree to pay taxes to PropAGILE when PropAGILE includes a
                  charge for taxes on any invoice. You also understand and agree
                  that you are solely responsible for determining your own tax
                  reporting and sales and use tax collection requirements in
                  consultation with your own tax advisers, and that we cannot
                  and do not offer specific tax advice to either Service Members
                  or Customer Members.
                </p>
                <br />
                <h2 className="has-text-left">TRACKING COMMUNICATIONS</h2>
                <p className="has-text-grey-darker has-text-justified">
                  In order to ensure appropriate charging of Fees and to enforce
                  these Terms, PropAGILE may track the occurrence of
                  communications between Customer Members and Service Members
                  that occur off of the Platform via email, phone call, SMS text
                  message, third-party websites, or any other means, whether
                  initiated by a Customer Member or Service Member. In order to
                  track the occurrence of such communications, PropAGILE may
                  obscure Service Member contact information in a Service Member
                  profile, replace Service Member contact information in a
                  Service Member profile with a different piece of contact
                  information that will forward to the Service Member, or take
                  any other step reasonably calculated to track the occurrence
                  of such communications. Except as explicitly stated herein, we
                  will not record or review the content of any communications
                  that do not come into contact with the PropAGILE Platform
                  unless we first obtain your permission.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If you send text messages with a Customer Member or Service
                  Member using the telephone number for that Member available on
                  the Platform, we may use a third-party service provider to
                  track these text messages. We track these text messages for
                  fraud prevention, to ensure appropriate charging of Fees, to
                  enforce these Terms, and for quality and training purposes. As
                  part of this process, PropAGILE and its service provider will
                  receive in real time and store data about your text message,
                  including the date and time of the text message, your phone
                  number, and the content of the text message.
                </p>
                <br />
                <h2 className="has-text-left">
                  SERVICE FEES AND BILLING METHODS; AUTOMATIC RENEWAL
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  (a) Membership Fee
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE will charge You a membership fee in order to provide
                  the Service. Your membership fee is the amount You were
                  charged for one term of Your Subscription or Plan, not
                  including any promotions or discounts that may have been
                  applied (the “Membership Fee”). For this or other reasons, the
                  Membership Fee might be different than the amount paid by
                  another new or existing member who purchased the same services
                  in the same market. You may check Your account online or, if
                  Your Plan includes live member care support, call an PropAGILE
                  call center representative if You have any questions.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You acknowledge that PropAGILE reserves the right, at any
                  time, to modify its Membership Fees and billing methods.
                  Membership Fees may be paid in advance by credit card, debit
                  card, or PayPal.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  (b) Automatic Renewal
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If You have paid membership Plan (e.g., Silver or Gold),
                  PropAGILE will automatically renew Your Plan for one year at
                  the Membership Fee for the Plan (as such Membership Fee may be
                  modified as provided above) using the credit card, debit card
                  or other payment information on file with PropAGILE . Such
                  renewal payment will take place on or about Your Renewal Date.
                  Your Plan will continue, and Your payment method will be
                  charged, the Membership Fee until You cancel the Plan, which
                  You may do at any time (see Canceling Your Plan, below).
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If the payment processing for the renewal of Your Plan fails
                  for any reason, we will attempt to process Your renewal for a
                  period up to one hundred eighty (180) days in accordance with
                  our standard renewal practices then in effect (which may be
                  modified from time to time by PropAGILE ). Except as otherwise
                  required by applicable law, You agree that PropAGILE will not
                  provide You with any notices prior to each annual renewal
                  payment.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If You chose a free Green Plan, Your Plan also will renew on
                  Your Renewal Date, but You will not be charged.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  (c) Membership Fee Refund Policy:
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • Automatic Renewal Refund—Regardless of when You joined
                  PropAGILE , if your Plan automatically renews and You cancel
                  Your Plan within thirty (30) days after your Renewal Date, You
                  may request a full refund of the Membership Fee.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • Under no circumstances shall refunds exceed the amount you
                  paid for your Membership Fee during the prior membership term.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • In accordance with Section 18, below, if PropAGILE
                  terminates Your account for any reason and You are not in
                  breach of this Agreement, PropAGILE will refund Your
                  Membership Fee on a pro rata basis from the date of such
                  termination to the end of the then current term.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • Members are not entitled to refunds of their Membership Fee
                  under any other circumstances.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  (d) Authorization to Update Credit Card Account Information;
                  Account Updater
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  If the credit card or debit card provided by You to PropAGILE
                  has expired during an attempt to renew Membership Fees
                  pursuant to this Section, You authorize PropAGILE to revise
                  the expiration date and proceed with billing using the same
                  credit or debit card account. In addition, as a convenience to
                  You, PropAGILE contracts with a third-party service that
                  refreshes expired or replaced credit card and debit card
                  numbers with the numbers of any replacement cards so that Your
                  paid Services do not lapse because the credit card or debit
                  card information initially on file with PropAGILE has expired
                  or changed (“Account Updater”).
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  By registering for the Service and receiving a Plan, You
                  consent to and authorize PropAGILE ’s disclosure of Your
                  credit or debit card information to Account Updater. You
                  further consent to the third party’s use of such information
                  solely in connection with Account Updater. You also consent to
                  PropAGILE receipt and use of updated credit card or debit card
                  account information from Your financial institution in
                  connection with the provision of the Service as provided in
                  this Agreement and the Privacy Policy.
                </p>
                <br />
                <h2 className="has-text-left">ACCOUNT SECURITY</h2>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE will assign You a user ID and a password when You
                  register. Your user ID and password may only be used by You
                  and the members of Your household (meaning anyone who
                  currently shares with You the address You registered with
                  PropAGILE ). You are solely responsible for maintaining and
                  protecting the confidentiality of Your user ID and password,
                  and are fully responsible for all activities that occur under
                  Your user ID and password.
                </p>
                <br />
                <h2 className="has-text-left">LIMITED LICENSE TO WEBSITE</h2>
                <p className="has-text-grey-darker has-text-justified">
                  By agreeing to the terms and conditions of this Agreement,
                  PropAGILE grants You a limited license to access and use the
                  reviews and ratings offered by the Service for Your personal
                  purchase decisions. You acknowledge and agree that You will
                  not access, reproduce, duplicate, copy, sell, re-sell, visit
                  or otherwise exploit for any commercial, educational, or any
                  other non-personal purpose the reviews and ratings and any
                  content, without the express written consent of PropAGILE .
                </p>
                <br />
                <h2 className="has-text-left">SUBMISSIONS OF REVIEWS</h2>
                <p className="has-text-grey-darker has-text-justified">
                  In order for You to submit Your own reviews and ratings on the
                  Website, You acknowledge and agree that:
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • all of Your reviews and ratings will either be based upon:
                  (i) Your actual first-hand experiences with the Service
                  Providers You are reviewing; or (ii) as provided under Section
                  14 (Service Providers) below, an individual and that
                  individual’s actual first-hand experience with a health care
                  or wellness provider whereby You have the legal authority to
                  disclose such health information and experience of such
                  individual;
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • all of Your reviews and ratings of the Service Providers
                  that You are rating will be accurate, truthful and complete in
                  all respects;
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • You do not work for, own any interest in, or serve on the
                  board of directors of, any of the Service Providers for which
                  You submit reviews and ratings;
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • You do not work for, own any interest in or serve on the
                  board of directors of any competitors of the Service Providers
                  for which You submit reviews and ratings;
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • You are not in any way related (by blood, adoption,
                  marriage, or domestic partnership, if the Service Provider is
                  an individual) to any of the Service Providers for which You
                  submit reviews or ratings;
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • Your name and review information will be made available to
                  the Service Providers on which You review; and
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • PropAGILE may redact, delete, or reject Your reviews if they
                  do not conform with PropAGILE ’s publication criteria, which
                  may change from time to time at PropAGILE ’s sole discretion.
                </p>
                <br />
                <h2 className="has-text-left">
                  CONTENT LICENSE AND PROMOTION PLACEMENT
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  Although PropAGILE does not claim ownership of any of the
                  reviews, ratings, communications, images, videos, information,
                  data, text or other materials You give us (collectively, the
                  “Content”), by providing Content for the Website, You
                  automatically grant, and You represent and warrant that You
                  have the right to grant, to PropAGILE an irrevocable,
                  perpetual, non-exclusive, fully paid, worldwide license to
                  use, copy, perform, display, reproduce, adapt, modify and
                  distribute such Content and to prepare derivative works of, or
                  incorporate into other works, such Content, and to grant and
                  to authorize sublicenses (through multiple tiers) of the
                  foregoing. In addition, by providing PropAGILE with Content,
                  You automatically grant us all rights necessary to prohibit
                  the subsequent aggregation, display, copying, duplication,
                  reproduction or exploitation of the Content on our Website by
                  any other party.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You understand that PropAGILE may display, disseminate, or
                  place Promotions near, with, or adjacent to Your Content in
                  any form or media (whether now known or subsequently
                  created).  The manner, mode, and extent of such Promotions are
                  subject to change at PropAGILE ’s discretion and without
                  notice to You.
                </p>
                <br />
                <h2 className="has-text-left">
                  PUBLICATION AND DISTRIBUTION OF CONTENT
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE does not guarantee the accuracy, integrity, quality
                  or appropriateness of any Content transmitted to or through
                  the Service. You acknowledge that PropAGILE simply acts as a
                  passive conduit and an interactive computer service provider
                  for the publication and distribution of Content and for the
                  publication and distribution of any content posted by Service
                  Providers in response to Content (“Service Provider Content”).
                  You understand that all Content and Service Provider Content
                  posted on, transmitted through or linked through the Service,
                  are the sole responsibility of the person from whom such
                  Content originated. You understand that PropAGILE does not
                  control, and is not responsible for Content or Service
                  Provider Content made available through the Service, and that
                  by using the Service, You may be exposed to Content that is
                  inaccurate, misleading, or offensive. You agree that You must
                  evaluate and make Your own judgment, and bear all risks
                  associated with, the use of any Content and Service Provider
                  Content.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You further acknowledge that PropAGILE has no obligation to
                  screen, preview, monitor or approve any Content or Service
                  Provider Content, or Content posted or submitted by any other
                  PropAGILE member or any Service Provider. However, PropAGILE
                  reserves the right to review and delete any Content that, in
                  its sole judgment, violates the terms and conditions of this
                  Agreement. By using the Service, You agree that it is solely
                  YOUR RESPONSIBILITY to evaluate Your risks to bear associated
                  with the use, accuracy, usefulness, completeness or
                  appropriateness of any Content that You submit, receive,
                  access, transmit or otherwise convey through the Service.
                  Under no circumstances will PropAGILE be liable in any way for
                  any Content or Service Provider Content, including, but not
                  limited to, any Content or Service Provider Content that
                  contains any errors, omissions, defamatory statements, or
                  confidential or private information (including, but not
                  limited to, health information) or for any loss or damage of
                  any kind incurred as a result of the use of any Content or
                  Service Provider Content submitted, accessed, transmitted or
                  otherwise conveyed via the Service. You waive the right to
                  bring or assert any claim against PropAGILE relating to
                  Content or Service Provider Content, and release PropAGILE
                  from any and all liability for or relating to any Content or
                  Service Provider Content.
                </p>
                <br />
                <h2 className="has-text-left">SERVICE PROVIDERS</h2>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE does not endorse and is not responsible or liable
                  for any Content, Service Provider Content, Promotions, data,
                  advertising, products, goods or services available or
                  unavailable from, or through, any Service Providers (which
                  includes, but is not limited to, health care and wellness
                  providers). You agree that should You use or rely on such
                  Content, Service Provider Content, Promotions, data,
                  advertisement, products, goods or services, available or
                  unavailable from, or through any Service Provider (which
                  includes, but is not limited to, health care and wellness
                  providers), PropAGILE is not responsible or liable, indirectly
                  or directly, for any damage or loss caused or alleged to be
                  caused by or in connection with such use or reliance. Your
                  dealings with, or participation in promotions of any Service
                  Provider (which includes, but is not limited to, health care
                  and wellness providers), and any other terms, conditions,
                  representations or warranties associated with such dealings,
                  are between You and such Service Provider (which includes, but
                  is not limited to, health care and wellness providers)
                  exclusively and do not involve PropAGILE . You should make
                  whatever investigation or other resources that You deem
                  necessary or appropriate before hiring or engaging Service
                  Providers (which includes, but is not limited to, health care
                  and wellness providers).
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You agree that PropAGILE is not responsible for the
                  accessibility or unavailability of any Service Provider (which
                  includes, but is not limited to, health care and wellness
                  providers) or for Your interactions and dealings with them,
                  waive the right to bring or assert any claim against PropAGILE
                  relating to any interactions or dealings with any Service
                  Provider (which includes, but is not limited to, health care
                  and wellness providers), and release PropAGILE from any and
                  all liability for or relating to any interactions or dealings
                  with Service Providers (which includes, but is not limited to,
                  health care and wellness providers). In addition, You agree
                  that PropAGILE may exclude Service Providers from displaying
                  in search results on the PropAGILE Website for failing to meet
                  particular PropAGILE standards regarding Service Provider
                  conduct.  In addition, You understand that PropAGILE may
                  exclude Service Providers from displaying in search results on
                  the PropAGILE Website for failing to meet particular PropAGILE
                  standards regarding Service Provider conduct and performance.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE may, in its sole discretion, have criminal and/or
                  financial background checks conducted on certain Service
                  Providers. By having such background checks conducted,
                  PROPAGILE DOES NOT WAIVE ANY OF ITS DISCLAIMER OR LIMITATIONS
                  OF LIABILITY, INCLUDING WITHOUT LIMITATION, THOSE SET FORTH
                  UNDER THIS SECTION, SECTION 24 (WARRANTY DISCLAIMER), OR
                  SECTION 25 (LIMITATION OF LIABILITY).
                </p>
                <br />
                <h2 className="has-text-left">DISCLOSURE OF INFORMATION</h2>
                <p className="has-text-grey-darker has-text-justified">
                  As PropAGILE continues to develop its business, it might sell
                  or buy other companies or assets or be acquired or have
                  substantially all of its assets acquired by a third party. In
                  such transactions, customer information generally is one of
                  the transferred business assets. You hereby consent to the
                  transfer of Your information as one of the transferred assets
                  and to be used for any purpose allowed under this Agreement.
                </p>
                <br />
                <h2 className="has-text-left">TERM AND TERMINATION</h2>
                <p className="has-text-grey-darker has-text-justified">
                  The term of this Agreement (“Term”) will be in effect and
                  continue so long as You have an active Subscription or Plan.
                  In other words, the Term shall continue through each automatic
                  Plan renewal until termination by either party in accordance
                  with the terms of this Agreement.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE may, for any reason in its sole discretion,
                  immediately terminate this Agreement, Your account, and Your
                  access to the Service. If PropAGILE merely terminates Your
                  account for its convenience and You are not in breach of this
                  Agreement, PropAGILE will refund Your Membership Fee on a pro
                  rata basis from the date of such termination to the end of the
                  then current term.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Termination of Your account will include removal of Your
                  access to all offerings of the Service, deletion of Your
                  password, deletion of all related information and files, may
                  include the deletion of the Content associated with Your
                  account (or any part thereof), and barring Your further use of
                  the Service.
                </p>
                <br />
                <h2 className="has-text-left">
                  MODIFICATION OF TERMS AND CONDITIONS
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE will have the right to modify and restate the terms
                  and conditions of this Agreement, and such modification(s)
                  will be effective immediately upon being posted on our Website
                  (www.propagile.com). You will receive notice if modifications
                  to the Agreement are made. PropAGILE will make note of the
                  date of the last update to the Agreement on the first page of
                  this Agreement. You are responsible for reviewing these terms
                  and conditions regularly. Your continued use of the Service
                  after such modifications will be deemed to be Your conclusive
                  acceptance of all modifications to this Agreement. If You are
                  dissatisfied as a result of such modification(s), Your only
                  recourse is to immediately discontinue use of the Service.
                </p>
                <br />
                <h2 className="has-text-left">
                  MODIFICATION, LIMITATION AND DISCONTINUANCE OF SERVICE
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE reserves the right at any time to limit access to,
                  modify, change or discontinue the Service with or without
                  notice to You and we shall not be liable to You for any such
                  modification, suspension or discontinuance of the Service. You
                  agree that PropAGILE will not be liable to You or to any third
                  party for any such limitation, modification, change,
                  suspension or discontinuance of the Service. You agree that
                  PropAGILE may establish general practices, policies and
                  limits, which may or may not be published, concerning the use
                  of the Service, including without limitation, the time that
                  reviews and ratings will be retained, the maximum number of
                  reviews and ratings that may be sent from an account, the
                  length of reviews and ratings sent, and the maximum number of
                  times and the maximum duration for which You may access the
                  Service in a given period of time. You agree that PropAGILE
                  has no responsibility or liability for the deletion or failure
                  to store any reviews, ratings and other communications
                  maintained or transmitted by or through the Service. You agree
                  that PropAGILE has the right to change these general practices
                  and limits at any time, in its sole discretion, with or
                  without notice.
                </p>
                <br />
                <h2 className="has-text-left">DELAYS</h2>
                <p className="has-text-grey-darker has-text-justified">
                  The Service may be subject to limitations, delays and other
                  problems inherent in the use of the Internet and electronic
                  communications. PropAGILE is not responsible for any delays,
                  failures or other damage resulting from such problems.
                </p>
                <br />
                <h2 className="has-text-left">USER FEEDBACK</h2>
                <p className="has-text-grey-darker has-text-justified">
                  PropAGILE appreciates hearing from You, as well as our other
                  users, and welcomes Your comments regarding our Service.
                  Please be advised, however, that our policy does not permit us
                  to accept or consider creative ideas, suggestions, or
                  materials other than those which we have specifically
                  requested. Although we do value Your feedback on our Service,
                  please be specific in Your comments regarding our services and
                  do not submit creative ideas, suggestions or materials. If,
                  despite our request, You send us creative suggestions, ideas,
                  drawings, concepts or other information (collectively, the
                  “Submissions”), such Submissions will be the property of
                  PropAGILE . In addition, none of the Submissions will be
                  subject to any obligations of confidentiality and PropAGILE
                  will not be liable for any future use or disclosure of such
                  Submissions.
                </p>
                <br />
                <h2 className="has-text-left">COPYRIGHT MATERIALS</h2>
                <p className="has-text-grey-darker has-text-justified">
                  Aside from user-submitted Content and Service Provider
                  Content, all other materials and other information on the
                  Website, including, but not limited to, all text, graphics,
                  logos, icons, images, audio clips, downloads, data
                  compilations and software (collectively, the “Copyright
                  Materials”) are the exclusive property of PropAGILE and/or its
                  licensors and are protected by all United States and
                  international copyright laws.
                </p>
                <br />
                <h2 className="has-text-left">WARRANTY DISCLAIMER</h2>
                <p className="has-text-grey-darker has-text-justified">
                  You understand and agree that THE SERVICE IS PROVIDED ON AN
                  “AS IS” AND “AS AVAILABLE” BASIS AND THAT PROPAGILE ASSUMES NO
                  RESPONSIBILITY OR LIABILITY FOR THE TIMELINESS, DELETION OF
                  CONTENT OR FAILURE BY THE SERVICE. PROPAGILE EXPRESSLY
                  DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR
                  IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
                  OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                  NON-INFRINGEMENT OR ANY WARRANTY THAT (A) THE SERVICE WILL
                  MEET YOUR REQUIREMENTS, (B) THE SERVICE WILL BE UNINTERRUPTED,
                  TIMELY, SECURE OR ERROR-FREE, (C) THE RESULTS THAT MAY BE
                  OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR
                  RELIABLE, (D) ANY CONTENT OR INFORMATION YOU PROVIDE OR
                  PROPAGILE COLLECTS WILL NOT BE DISCLOSED OR (E) ANY ERRORS IN
                  ANY SERVICE WILL BE CORRECTED. YOU AGREE THAT USE OF THE
                  SERVICE AND THE WEBSITE IS AT YOUR OWN RISK. In some
                  jurisdictions, disclaimers of implied warranties are not
                  permitted. In such jurisdictions, some of the foregoing
                  disclaimers may not apply to You as they relate to implied
                  warranties.
                </p>
                <br />
                <h2 className="has-text-left">LIMITATION OF LIABILITY</h2>
                <p className="has-text-grey-darker has-text-justified">
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT PROPAGILE WILL NOT BE
                  LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                  PUNITIVE, COMPENSATORY, CONSEQUENTIAL OR EXEMPLARY DAMAGES
                  (EVEN IF PROPAGILE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES) (COLLECTIVELY, “DAMAGES”), RESULTING FROM: (A) THE
                  USE OR INABILITY TO USE THE SERVICE; (B) THE COST OF ANY GOODS
                  AND/OR SERVICES PURCHASED OR OBTAINED AS A RESULT OF THE USE
                  OF THE SERVICE; (C) DISCLOSURE OF, UNAUTHORIZED ACCESS TO OR
                  ALTERATION OF YOUR INFORMATION OR CONTENT; (D) CONTENT YOU
                  SUBMIT, RECEIVE, ACCESS, TRANSMIT OR OTHERWISE CONVEY THROUGH
                  THE SERVICE; (E) STATEMENTS OR CONDUCT OF ANY SERVICE
                  PROVIDERS OR OTHER THIRD PARTY THROUGH THE SERVICE; (F) ANY
                  OTHER MATTER RELATING TO THE SERVICE; (G) ANY BREACH OF THIS
                  AGREEMENT BY PROPAGILE OR THE FAILURE OF PROPAGILE TO PROVIDE
                  THE SERVICE UNDER THIS AGREEMENT OR (H) ANY OTHER DEALINGS OR
                  INTERACTIONS YOU HAVE WITH ANY SERVICE PROVIDERS (OR ANY OF
                  THEIR REPRESENTATIVES OR AGENTS). THESE LIMITATIONS SHALL
                  APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. In some
                  jurisdictions, limitations of liability are not permitted. In
                  such jurisdictions, some of the foregoing limitations may not
                  apply to You.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  You understand and agree that Your unlimited access to the
                  Content on the Website represents a substantial portion of the
                  value You receive from Your PropAGILE ’s Membership Fee.
                  THEREFORE, TO THE EXTENT PROPAGILE IS FOUND LIABLE FOR
                  ANYTHING RELATED TO THIS AGREEMENT OR THE USE OF THE SERVICE,
                  PROPAGILE ’S LIABILITY FOR DAMAGES WILL NOT EXCEED THE
                  EQUIVALENT OF ONE (1) MONTH OF YOUR MEMBERSHIP FEE (I.E., THE
                  AMOUNT OF YOUR ANNUAL MEMBERSHIP FEE DIVIDED BY TWELVE).
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT PROPAGILE CONTRACTS
                  WITH A THIRD PARTY TO PROCESS YOUR PAYMENT OF MEMBERSHIP FEES
                  TO PROPAGILE THROUGH THE USE OF A CREDIT CARD (A “CREDIT CARD
                  PROCESSOR”). YOU UNDERSTAND AND AGREE THAT NEITHER A CREDIT
                  CARD PROCESSOR NOR ANY OTHER PARTY INVOLVED IN THE CREDIT CARD
                  PROCESSING PROCESS FOR PROPAGILE , INCLUDING, BUT NOT LIMITED
                  TO, THE COMPANY ISSUING THE CREDIT CARD TO YOU AND THE
                  MERCHANT BANK (COLLECTIVELY, THE “RELEASED PARTIES”) SHALL BE
                  LIABLE FOR ANY DAMAGES (AS DEFINED HEREIN AND SUBJECT TO THE
                  LIMITATIONS SET FORTH IN THIS SECTION SUFFERED BY YOU AS A
                  RESULT OF THE FAILURE OF PROPAGILE TO PROVIDE SERVICES TO YOU
                  UNDER THIS AGREEMENT OR ANY BREACH OF THIS AGREEMENT BY
                  PROPAGILE . YOU HEREBY RELEASE EACH OF THE RELEASED PARTIES
                  FROM ANY AND ALL DAMAGES YOU MAY SUFFER AS A RESULT OF THE
                  FAILURE OF PROPAGILE TO PROVIDE SERVICES TO YOU UNDER THIS
                  AGREEMENT OR ANY BREACH OF THIS AGREEMENT BY PROPAGILE . YOU
                  AGREE TO INDEMNIFY AND HOLD HARMLESS EACH OF THE RELEASED
                  PARTIES FOR ANY AND ALL DAMAGES IT MAY SUFFER AS A RESULT OF
                  YOUR BREACH OF THIS SECTION. YOU HEREBY UNDERSTAND AND AGREE
                  THAT PROPAGILE SHALL BE SOLELY LIABLE FOR THE PAYMENT OF ANY
                  DAMAGES TO YOU UNDER THIS AGREEMENT.
                </p>
                <br />
                <h2 className="has-text-left">INDEMNIFICATION</h2>
                <p className="has-text-grey-darker has-text-justified">
                  You agree to indemnify, defend and hold harmless PropAGILE ,
                  its officers, managers, owners, employees, agents, designees,
                  users, successors, assigns, service providers and suppliers
                  from and against all losses, liabilities, expenses, damages,
                  claims, demands and costs, including reasonable attorneys’
                  fees and court costs due to or arising from: (a) any violation
                  of this Agreement by You; (b) the inaccurate or untruthful
                  Content or other information provided by You to PropAGILE or
                  that You submit, transmit or otherwise make available through
                  the Service; or (c) any intentional or willful violation of
                  any rights of another or harm You may have caused to another.
                  PropAGILE will have sole control of the defense of any such
                  damage or claim.
                </p>
                <br />
                <h2 className="has-text-left">
                  BREACH OF AGREEMENT AND LIQUIDATED DAMAGES
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  You understand and agree that, because damages are often
                  difficult to calculate, if it becomes necessary for PropAGILE
                  to pursue legal action to enforce the terms and conditions of
                  this Agreement, You will be liable to pay us the following
                  amounts as liquidated damages, which You accept as reasonable
                  estimates of PropAGILE ’s damages for the specified breaches
                  of this Agreement:
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • If You post Content in violation of this Agreement, You
                  agree to promptly pay PropAGILE One Thousand Dollars ($1,000)
                  for each item of Content posted in violation of this
                  Agreement. We may (but shall not be required to) to issue You
                  a warning before assessing damages.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • If You display, copy, duplicate, reproduce, sell, re-sell or
                  exploit for any purpose any Content in violation of this
                  Agreement, You agree to pay One Thousand Dollars ($1,000) for
                  each item of Content displayed, copied, duplicated,
                  reproduced, sold, re-sold or exploited in violation of this
                  Agreement
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • If You use computer programming routines that are intended
                  to aggregate records or reviews from the Service or otherwise
                  damage, interfere with, disrupt, impair, disable or otherwise
                  overburden our Website, You agree to pay One Hundred Dollars
                  ($100) for each review or record that is aggregated,
                  disrupted, damaged or otherwise affected by You.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  • Except as set forth in the foregoing subsections (a) through
                  (c), inclusive, You agree to pay the actual damages suffered
                  by PropAGILE , including, but not limited to attorneys’ fees
                  and court costs, to the extent such actual damages can be
                  reasonably calculated. Notwithstanding any other provision of
                  this Agreement, we reserve the right to seek the remedy of
                  specific performance of any term contained herein, or a
                  preliminary or permanent injunction against the breach of any
                  such term or in aid of the exercise of any power granted in
                  this Agreement, or any combination thereof.
                </p>
                <br />
                <h2 className="has-text-left">ENTIRE AGREEMENT</h2>
                <p className="has-text-grey-darker has-text-justified">
                  This Agreement governs Your use of the Service and constitutes
                  the entire agreement between You and PropAGILE . It supersedes
                  any prior or contemporaneous negotiations, discussions or
                  agreements, whether written or oral, between You and PropAGILE
                  regarding the subject matter contained in this Agreement.
                  Additional terms and conditions may exist between You and
                  third parties, including but not limited to, Service Providers
                  and others. You represent and warrant that those third party
                  agreements do not interfere with Your obligations and duties
                  to PropAGILE under this Agreement.
                </p>
                <br />
                <h2 className="has-text-left">
                  PROVISIONS REMAINING IN EFFECT
                </h2>
                <p className="has-text-grey-darker has-text-justified">
                  In the event Your Plan with PropAGILE is terminated or lapses
                  or You are no longer a user of PropAGILE , certain provisions
                  of this Agreement will continue to remain in effect,
                  including, but not limited to, Sections 12, 14, 15, 17 and 24
                  through 31.
                </p>
                <br />
                <h2 className="has-text-left">MISCELLANEOUS</h2>
                <p className="has-text-grey-darker has-text-justified">
                  This Agreement may not be re-sold or assigned by You. If You
                  assign, or try to assign, this Agreement, such assignment or
                  attempted assignment will be void and unenforceable. It will
                  not be considered a waiver of PropAGILE ’s rights if PropAGILE
                  fails to enforce any of the terms or conditions of this
                  Agreement against You. In the event a court finds a provision
                  in this Agreement to not be valid, You and PropAGILE agree
                  that such court should incorporate a similar provision that
                  would be considered valid, with all other provisions remaining
                  valid in the Agreement. No joint venture, partnership,
                  employment or agency relationship exists between You and
                  PropAGILE as a result of this Agreement or use of the Service.
                  You acknowledge and agree that each of the Released Parties
                  shall be an intended third party beneficiary of this
                  Agreement.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS
                  AGREEMENT, YOU MUST NOT USE THE SERVICE. BY USING THE SERVICE,
                  YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THE TERMS
                  AND CONDITIONS OF THIS AGREEMENT AND YOU AGREE TO BE BOUND BY
                  THESE TERMS AND CONDITIONS.
                </p>
                <br />
                <h2 className="has-text-left"> Binding Arbitration</h2>
                <p className="has-text-grey-darker has-text-justified">
                  <i>
                    You agree that any dispute or claim arising out of or
                    relating in any way to the Terms, your access to or use of
                    the Services or the Properties or any relationship between
                    us, including the validity, applicability or interpretation
                    of the Terms (any of these, a “Dispute”) will be resolved by
                    binding arbitration rather than in court. There is no judge
                    or jury in arbitration, and court review of an arbitration
                    award is limited.
                  </i>
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  The arbitration will be conducted by the American Arbitration
                  Association (AAA) under its then-applicable rules, including
                  (as appropriate) its Supplementary Procedures for
                  Consumer-Related Disputes. The AAA's rules are available at
                  http://www.adr.org/. Payment of all filing, administration and
                  arbitrator fees will be governed by the AAA’s rules. The
                  arbitration shall be conducted in the English language by a
                  single independent and neutral arbitrator. For any hearing
                  conducted in person as part of the arbitration, you agree that
                  the hearing will be conducted in Queens County, New York or
                  the city within the United States in which you reside. Without
                  limiting the jurisdiction of any other court, you admit and
                  further irrevocably agree to submit to the personal
                  jurisdiction of the courts located within Queens County, New
                  York for the purpose of entering judgments on arbitral awards.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Class Action Waiver. 
                  <i>
                    Any Dispute will be conducted only on an individual basis
                    and not in a class, consolidated or representative action or
                    arbitration or as a named or unnamed member in a class,
                    consolidated, representative or private attorney general
                    legal action. Your access and continued use of the
                    Properties and/or the Services signifies your explicit
                    consent to this waiver.
                  </i>
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Venue. If for any reason a claim proceeds in court rather than
                  in arbitration, we each waive any right to a jury trial and
                  agree that such claim shall be brought only in courts of
                  competent jurisdiction located in Queens County, New York. You
                  hereby submit to the personal jurisdiction and venue of such
                  courts and waive any objection on the grounds of venue, forum
                  non conveniens or any similar grounds with respect to any such
                  proceeding.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  Notwithstanding anything to the contrary, you and PropAGILE
                  may seek injunctive relief and any other equitable remedies
                  from any court of competent jurisdiction to protect
                  intellectual property rights, whether in aid of, pending or
                  independently of the resolution of any Dispute pursuant to the
                  arbitration procedures set forth above.
                </p>
                <br />
                <h2 className="has-text-left">Governing Law.</h2>
                <p className="has-text-grey-darker has-text-justified">
                  You agree that the Terms and any Dispute will be governed
                  solely by United States and New York state law, without regard
                  to conflict of law provisions.
                </p>
                <p className="has-text-grey-darker has-text-justified">
                  A User’s rights under an agreement with a Service Provider
                  will be governed by the terms of that agreement and by
                  applicable federal, state, and local laws.
                </p>
                <br />
                <h2 className="has-text-left">Attorneys’ Fees.</h2>
                <p className="has-text-grey-darker has-text-justified">
                  If any of the PropAGILE Parties take legal action against you
                  as a result of your violation of these Terms, the PropAGILE
                  Parties will be entitled to recover from you, and you agree to
                  pay, all reasonable attorneys' fees and costs of such action,
                  in addition to any other relief granted to the PropAGILE
                  Parties.
                </p>
                <br />
              </div>
              <div className="pp-tt-buttons clearfix">
                <a onClick={acceptTerms} className="pp-tt-agree ">
                  Agree
                </a>
                <a onClick={closeTerms} className="pp-tt-cancel ">
                  Cancel
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default TermsPopup;
