// Packages
import React, { useState } from "react";
import axios from "axios";

// Components
import Message from "../Message/Message";

// Constants
import { BASE_URL } from "../../Constants/serverConfig";
const successMessage = "Project Created successfully.";

export default function CreateProject(props) {
  const [projectName, setProjectName] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState(successMessage);
  const createProject = () => {
    if (projectName.trim() === "") {
      setShowSuccess(true);
      setMessage("Please enter project name");
      return;
    }
    props.setShowLoader(true);

    const body = {
      name: projectName,
    };
    axios
      .post(`${BASE_URL}/project`, body, {
        headers: {
          authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        props.setShowLoader(false);
        if (response.status === 200) {
          props.setShowLoader(false);
          setProjectName("");
          props.setShowCreateProject(false);
          props.populateProjectList();
        }
      })
      .catch((error) => {
        props.setShowLoader(false);
        props.showMessage(error.response.data);
        console.error(error.response);
      });
  };

  const closeSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div
      className={`modal ${
        props.showCreateProject ? "is-active" : ""
      } mainn-map`}
    >
      <div className="modal-background" />
      <div className="modal-card" style={{ width: "50%" }}>
        <Message
          clearMessage={closeSuccess}
          message={message}
          showMessage={showSuccess}
        />
        <button
          onClick={() => {
            props.setShowCreateProject(false);
          }}
          className="delete"
          aria-label="close"
        />

        <section className="modal-card-body custom-modal">
          <div className="endos_panel2 clearfix">
            <h1>CREATE PROJECT</h1>
            <hr />
            <div>
              <div className="form-group" style={{ float: "none" }}>
                <input
                  type="text"
                  value={projectName}
                  name="project name"
                  required
                  onChange={(e) => setProjectName(e.target.value)}
                  placeholder="Enter project name"
                  className="form-control"
                />
              </div>
              <div className="columns" style={{ margin: "auto" }}>
                <div className="column"></div>
                <div className="column is-2">
                  <div className="form-group">
                    <button onClick={createProject} className="add-btn">
                      <i className="fa fa-plus" /> CREATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
