import React from "react";

const BusinessFeatures = () => {
  return (
    <>
      {/* Ticket # 794 requires to add and/or update text in this component - Nov 16th, 2022 - Old text is commented */}
      <div className="new_feaut">
        <h5 className="feature ">Features</h5>

        <div className="pricing-list ">
          <ul style={{ listStyle: "none" }}>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Only companies allowed{" "}
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Instant profile and promotion enabled
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Unlimited services promotion
              <span className="promo_span"> (General Contracting Level)</span>
              {/* Promote Up to 20{" "}
              <span className="promo_span"> (Contracting level)</span> services */}
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Includes 5 licenses (5 profiles) full services coverage
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Promote Up to 20 services (trades) per license
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Additional licenses can be purchased as needed
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Promote management level functions
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              <span className="promo_span_2">Nationwide Coverage</span>
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Link to website enabled
            </li>
            {/* <li>
              <span className="list_check">
                <i className="fa fa-check"></i>
              </span>
              Service coverage{" "}
              <span className="promo_span_2"> Up To 5,000 </span> Zip codes
            </li> */}
          </ul>
        </div>
      </div>

      {/* from subscriptionUpgrade.jsx
      <div className="new_feaut">
                  <h5 className="feature ">Features</h5>

                  <div className="pricing-list ">
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        Companies Only{" "}
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        Instant profile and promotion enabled
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        Promote Up to 20{" "}
                        <span className="promo_span"> (Contracting level)</span>{" "}
                        services
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        Service coverage{" "}
                        <span className="promo_span_2"> Up To 5,000 </span> Zip
                        codes
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        {/* Ticket # 786 requires us to remove these texts 
                        {/* Be added to projects (October 2020) 
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        {/* Ticket # 786 requires us to remove these texts 
                        {/* Be added to favorites (October 2020) 
                      </li>
                    </ul>
                  </div>
                </div> */}
    </>
  );
};

export default BusinessFeatures;
