import React from "react";
import { Link } from "react-router-dom";

const StandardFeatures = ({
  metroArea,
  paymentType,
  isSubscriptionUpgrade,
}) => {
  return (
    <>
      {/* Ticket # 794 requires to add and/or update text in this component - Nov 16th, 2022 - Old text is commented */}
      <div className="new_feaut">
        <h5 className="feature ">Features</h5>
        <div className="pricing-list">
          <ul style={{ listStyle: "none" }}>
            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>{" "}
              {/* Everyone allowed */}
              Individuals and companies allowed
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>
              Instant profile and promotion enabled
            </li>

            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>{" "}
              Promote Up to 5{/* 10 */}{" "}
              <span className="promo_span">(Sub-contracting level) </span>
              services{" "}
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>
              Promote leadership level functions
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>
              Service coverage{" "}
              <span className="promo_span_2"> Up To 1,000 </span> Zip codes
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>
              Single license only
            </li>
            <li>
              <span className="list_check">
                <i className="fa fa-check" />
              </span>
              Link to website enabled in profile
            </li>
          </ul>
          {/* Ticket # 724 */}
          {!isSubscriptionUpgrade && (
            <div
              className="free-trail-btn top_margin"
              style={{ position: "absolute" }}
            >
              <Link
                className="btn"
                to={
                  // paymentType === "annual"?
                  `/vendor/sign-up?metro=${metroArea}&subsription=free-trial-membership` /* `/vendor/sign-up?metro=${metroArea}&subsription=standard-pre-launch-annual` */
                  // : `/vendor/sign-up?metro=${metroArea}&subsription=standard-monthly`
                }
                onClick={() => {
                  window.pagesense = window.pagesense || [];
                  window.pagesense.push([
                    "trackEvent",
                    "business membership button",
                  ]);
                }}
              >
                Free Trial
              </Link>

              {/* <a
                        className="btn"
                        onClick={() => {
                          window.pagesense = window.pagesense || [];
                          window.pagesense.push([
                            "trackEvent",
                            "standard membership button",
                          ]);
                          this.showCouponPopup();
                        }}
                      >
                        Free Trial
                      </a> */}
            </div>
          )}
        </div>
      </div>

      {/* from subscriptionUpgrade.jsx
<div className="new_feaut">
                  <h5 className="feature ">Features</h5>
                  <div className="pricing-list">
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                        Everyone allowed
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        Instant profile and promotion enabled
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                        Promote Up to 10{" "}
                        <span className="promo_span">
                          (Sub-contracting level){" "}
                        </span>
                        services{" "}
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        Service coverage{" "}
                        <span className="promo_span_2"> Up To 1,000 </span> Zip
                        codes
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        {/* Ticket # 786 requires us to remove these texts 
                        {/* Be added to projects (October 2020) 
                      </li>

                      <li>
                        <span className="list_check">
                          <i className="fa fa-check"></i>
                        </span>
                        {/* Ticket # 786 requires us to remove these texts 
                        {/* Be added to favorites (October 2020) 
                      </li>
                    </ul>
                  </div>
                </div>

*/}
    </>
  );
};

export default StandardFeatures;
