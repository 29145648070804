/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import querystring from "querystring";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import TermsPopup from "../Terms/terms-popup";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
import { Box } from "@material-ui/core";

class VendorSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      zipCode: "",
      email: "",
      userId: "",
      password: "",
      couponCode: "",
      startUpYear: null,
      showPassword: false,
      showError: false,
      errorMessage: "",
      termsAccepted: false,
      selectedMembership: "",
      individual: true,
      company: false,
      metroArea: "",
      showLoader: false,
      showTerms: false,
      showConcentBox: false,
      disableCouponCode: false,
    };
    this.footerRef = React.createRef();
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(50), (val, index) => (index - year) * -1);
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentDidMount() {
    const { isSpLoggedIn, isUserLoggedIn } = this.props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      this.props.history.push("/");
    }
    const { search } = this.props.history.location;
    if (search !== "") {
      let params = querystring.parse(search);
      const metroArea = params["?metro"];
      const selectedMembership = params["subsription"]
        ? params["subsription"]
        : "";
      const couponCode = params["coupon_code"] ? params["coupon_code"] : "";
      const disableCouponCode = params["coupon_code"] ? true : false;
      this.setState({
        metroArea,
        selectedMembership,
        couponCode,
        disableCouponCode,
      });
    }
    const script = document.createElement("script");
    script.id = "page-sense";
    script.src =
      "https://cdn.pagesense.io/js/propagile/a104ce787de64435be353cddd7e6b745.js";
    script.async = true;
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    const script = document.getElementById("page-sense");
    document.head.removeChild(script);
  }

  handleChange = (event) => {
    if (event.target.name === "zipCode") {
      let zipCodeRegex = /^[0-9]{0,5}$/;
      if (zipCodeRegex.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleSelectChange = (name) => (value) => {
    this.setState({
      [name]: value,
    });
  };

  handleZipCodeChange = (event) => {
    let zipCodeRegex = /^[0-9]*$/;
    if (
      zipCodeRegex.test(event.target.value) &&
      event.target.value.length <= 5
    ) {
      this.setState({
        zipCode: event.target.value,
      });
    }
  };

  validate = () => {
    this.clearError();
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let userIdValidation = /^[a-zA-Z][a-zA-Z0-9]{2,14}$/;
    let zipCodeValidation = /^\d{5}$/;
    const {
      zipCode,
      firstName,
      lastName,
      email,
      password,
      userId,
      selectedMembership,
      startUpYear,
    } = this.state;
    if (firstName.trim() === "") {
      this.showError("Please enter name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName.trim() === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (zipCode === "") {
      this.showError("Please enter zip code");
      return false;
    }
    if (!zipCodeValidation.test(zipCode)) {
      this.showError("Please enter a valid zip code");
      return false;
    }
    if (email.trim() === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    if (userId.trim() === "") {
      this.showError("Please enter username");
      return false;
    }
    if (!userIdValidation.test(userId.trim())) {
      this.showError(
        "Please enter a valid username, Username can be 3 to 50 characters long and can have only alphabets and numbers. It should not contain any spaces."
      );
      return false;
    }
    if (password === "") {
      this.showError("Please enter password");
      return false;
    }
    if (selectedMembership === "") {
      this.showError("Please choose a membership");
      return false;
    }
    if (selectedMembership.includes("business")) {
      if (startUpYear === null) {
        this.showError("Please choose business start year");
        return false;
      }
    }
    // if (selectedMembership === "free") {
    //   if (couponCode === "") {
    //     this.showError("Please enter invitation coupon.");
    //     return false;
    //   }
    // }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.closeConcentBox();
    if (!this.validate()) {
      return;
    }
    const {
      firstName,
      lastName,
      zipCode,
      email,
      userId,
      password,
      individual,
      selectedMembership,
      startUpYear,
      couponCode,
    } = this.state;
    if (selectedMembership === "free-trial-membership" && couponCode !== "") {
      this.startLoader();
      axios
        .get(`${BASE_URL}/coupon-management/validate/${couponCode}`)
        .then((response) => {
          this.stopLoader();
          if (response.status === 200) {
            const body = this.getBody(
              firstName,
              lastName,
              zipCode,
              email,
              userId,
              password,
              couponCode,
              selectedMembership,
              individual,
              startUpYear
            );
            this.signUpNetworkCall(body, zipCode);
          }
        })
        .catch((error) => {
          this.stopLoader();
          this.showError(error.response.data);
          console.error(error.response);
          this.setState({
            couponCode: "",
            disableCouponCode: false,
          });
        });
    } else {
      const body = this.getBody(
        firstName,
        lastName,
        zipCode,
        email,
        userId,
        password,
        "",
        selectedMembership,
        individual,
        startUpYear
      );
      this.signUpNetworkCall(body, zipCode);
    }
  };

  getBody(
    firstName,
    lastName,
    zipCode,
    email,
    userId,
    password,
    couponCode,
    selectedMembership,
    individual,
    startUpYear
  ) {
    const body = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      zip_code: zipCode,
      email_address: email.trim(),
      username: userId.trim(),
      password,
      user_type: 4,
      membership_id:
        selectedMembership === "free"
          ? 1
          : selectedMembership === "free-trial-membership"
          ? 10
          : selectedMembership.includes("business")
          ? 3
          : 2,
      vendor_type:
        selectedMembership === "free"
          ? "Individual"
          : individual
          ? "Individual"
          : "Company",
    };
    if (couponCode !== "") {
      body.coupon_code = couponCode;
    }
    if (selectedMembership.includes("business")) {
      body.vendor_type = "Company";
      body.start_up_year = startUpYear.value;
    }
    return body;
  }

  signUpNetworkCall(body, zipCode) {
    console.log("signUpNetworkCall Body ==> ", body);
    this.startLoader();
    axios
      .post(`${BASE_URL}/entrance/signup`, body, {
        withCredentials: true,
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          axios
            .get(`${BASE_URL}/zip-code/find-zip-code?zip_code=${zipCode}`)
            .then((response) => {
              if (response.status === 200) {
                this.redirectToStripe();
              }
            })
            .catch((error) => {
              this.stopLoader();
              if (error.response.status === 409) {
                this.showError(
                  "Opps, we do not cover services in your area yet but please do send us your feedback to let us know your interest so we can find our way to prioritize services in your area.$$Thanks."
                );
                this.setState({
                  firstName: "",
                  lastName: "",
                  zipCode: "",
                  email: "",
                  userId: "",
                  password: "",
                  couponCode: "",
                  startUpYear: null,
                  showPassword: false,
                  termsAccepted: false,
                  individual: true,
                  company: false,
                  metroArea: "",
                  disableCouponCode: false,
                });
              }
            });
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
        this.setState({
          couponCode: "",
          disableCouponCode: false,
        });
      });
  }

  redirectToStripe = () => {
    let { selectedMembership, email } = this.state;
    let stripe = window.Stripe("pk_test_kDN5G7K0AMgrNM6w5D3Afo0a00q3zIqhxW");
    if (selectedMembership === "free") {
      this.props.history.push("/thanks?status=new&payment=success");
    } else {
      stripe
        .redirectToCheckout({
          items: [{ plan: selectedMembership, quantity: 1 }],
          customerEmail: email,
          successUrl: `https://propagile.com/thanks?status=new&payment=success`,
          cancelUrl: `https://propagile.com/thanks?status=new&payment=failed`,
          billingAddressCollection: "required",
        })
        .then(function(result) {
          if (result.error) {
            console.error(result.error.message);
          }
        });
    }
  };

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  formatMembershipName = (name) => {
    console.log("Name:", name);
    var splitStr = name.toLowerCase().split("-");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  closeTerms = () => {
    this.setState({
      showTerms: false,
    });
  };

  acceptTerms = () => {
    this.setState({
      termsAccepted: !this.state.termsAccepted,
      showTerms: false,
    });
  };

  closeConcentBox = () => {
    this.setState({
      showConcentBox: false,
    });
  };

  showGetInTouch = () => {
    this.clearError();
    this.footerRef.current.showGetInTouch();
  };

  render() {
    const {
      firstName,
      lastName,
      zipCode,
      email,
      startUpYear,
      userId,
      password,
      showPassword,
      couponCode,
      showError,
      errorMessage,
      termsAccepted,
      selectedMembership,
      individual,
      company,
      showLoader,
      showTerms,
      disableCouponCode,
    } = this.state;
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div
        style={{
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                {errorMessage.includes(
                  "we do not cover services in your area"
                ) ? (
                  <>
                    <p>{errorMessage.split("$$")[0]}</p>
                    <p>{errorMessage.split("$$")[1]}</p>
                  </>
                ) : (
                  <p>{errorMessage}</p>
                )}

                <a
                  onClick={
                    errorMessage.includes(
                      "we do not cover services in your area"
                    )
                      ? this.showGetInTouch
                      : this.clearError
                  }
                  className="btn-fill"
                >
                  {errorMessage.includes(
                    "we do not cover services in your area"
                  )
                    ? "Take me to feedback page"
                    : "OK"}
                </a>
              </div>
            </div>
          </div>
        )}
        {showTerms && (
          <TermsPopup
            closeTerms={this.closeTerms}
            acceptTerms={this.acceptTerms}
          />
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <section className="form-wrapper">
          <div className="form-inner signup-form">
            <div className="form-main">
              <div className="form-title">
                <h3>Sign Up</h3>
                <p>
                  You are one step away from unlocking the true potential of
                  your business.
                </p>
              </div>

              <div className="form-fields">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      required
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Zip Code</label>
                    <input
                      type="text"
                      required
                      name="zipCode"
                      value={zipCode}
                      onChange={this.handleZipCodeChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      required
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      required
                      name="userId"
                      value={userId}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      required
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    <a onClick={this.togglePassword} className="view-password">
                      <img
                        alt="show password"
                        src={require("../../assets/images/view-password-icon.png")}
                      />
                    </a>
                  </div>
                  {selectedMembership === "free-trial-membership" && (
                    <div className="form-group">
                      <label>Enter Invitation Coupon (Optional)</label>
                      <input
                        type="text"
                        required={false}
                        disabled={disableCouponCode}
                        name="couponCode"
                        value={couponCode}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>Membership of interest:</label>
                    <div className="membership-type">
                      {selectedMembership === "standard-pre-launch-annual"
                        ? "Standard Annual Membership"
                        : selectedMembership === "business-pre-launch-annual"
                        ? "BUSINESS ANNUAL MEMBERSHIP"
                        : selectedMembership === "standard-monthly"
                        ? "Standard Monthly Membership"
                        : selectedMembership === "free-trial-membership"
                        ? "FREE TRIAL (15 DAYS) STANDARD MEMBERSHIP"
                        : selectedMembership === "free"
                        ? "Free" /*  */
                        : `${this.formatMembershipName(
                            selectedMembership
                          )} Membership`}
                    </div>
                  </div>
                  {(selectedMembership.includes("standard") ||
                    selectedMembership === "free-trial-membership") && (
                    <div className="form-group">
                      <div className="column is-6">
                        <div
                          onClick={() => {
                            this.setState({
                              individual: !individual,
                              company: false,
                            });
                          }}
                          className="checkbox"
                        >
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <input
                              checked={individual}
                              onChange={() => {}}
                              type="checkbox"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            />
                            <span className="checkmark" />
                            Individual
                          </label>
                        </div>
                      </div>
                      <div className="column is-6">
                        <div
                          onClick={() => {
                            this.setState({
                              individual: false,
                              company: !company,
                            });
                          }}
                          className="checkbox"
                        >
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <input
                              checked={company}
                              onChange={() => {}}
                              type="checkbox"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            />
                            <span className="checkmark" />
                            Company
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedMembership.includes("business") && (
                    <div className="form-group">
                      <label>Business start year</label>
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            background: "#fcfcfc none repeat scroll 0 0",
                            border: "1px solid #ececec",
                          }),
                        }}
                        value={startUpYear}
                        isClearable={true}
                        onChange={this.handleSelectChange("startUpYear")}
                        name="startUpYear"
                        id="startUpYear"
                        options={this.years.map((x) => {
                          return {
                            value: x,
                            label: x,
                          };
                        })}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          spacing: {
                            baseUnit: 4.84,
                            controlHeight: 46,
                            menuGutter: 9.68,
                          },
                        })}
                      />
                    </div>
                  )}
                  <div className="terms">
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          showTerms: true,
                        });
                      }}
                    >
                      <i>Terms & conditions</i>
                    </h4>
                    <p>
                      You must read all terms and conditions below before
                      continuing with signup. * All (PropAgile) Members are
                      subject to the following policies, including the Member
                      Code of Conduct and all (PropAgile) Bylaws, located at:
                      <a href="https://propagile.com/code_of_conduct">
                        https://propagile.com/code_of_conduct
                      </a>
                      . By clicking "I accept" below you are indicating you have
                      read and agree to these (PropAgile) policies. You may also
                      choose to decline (PropAgile) membership by hitting back
                      or closing your browser, at which point you will leave the
                      signup process.
                    </p>
                  </div>
                  <div onClick={this.acceptTerms} className="checkbox">
                    <label
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        checked={termsAccepted}
                        onChange={() => {}}
                        type="checkbox"
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      />
                      <span className="checkmark" />I Accept
                    </label>
                  </div>
                  <div className="signup-btns">
                    <button
                      className="signup-btn"
                      style={{ width: "100%" }}
                      disabled={!termsAccepted}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Box style={{ marginTop: "auto" }}>
            <Footer ref={this.footerRef} />
          </Box>
        ) : (
          <Box style={{ marginTop: "auto" }}>
            <NewFooter />
          </Box>
        )}
      </div>
    );
  }
}

export default withRouter(VendorSignUp);
