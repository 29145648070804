import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL } from "../../Constants/serverConfig";
import axios from "axios";
import Loader from "../Loader/loader";
import { useToasts } from "react-toast-notifications";
import { makeStyles } from "@material-ui/core/styles";

const getInTouchSchema = Yup.object().shape({
  userName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  comment: Yup.string().required("Required"),
  zipCode: Yup.string().required("Required"),
  userType: Yup.string().required("Required"),
  supportType: Yup.string().required("Required"),
  reCaptcha: Yup.string().required("Required"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    width: 800,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,

    padding: theme.spacing(2, 4, 3),
  },
  gridContainer: {
    padding: "10px 30px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px",
    },
  },
  signUpBtn: {
    border: "1px solid #1a67a3", //#1a67a3",
    color: "#1a67a3", //"#1a67a3",
    background: "#f8fafb none repeat scroll 0 0",
    display: "inline-block",
    fontSize: "16px",
    fontweight: "600",
    margin: "0",
    padding: "12px 15px",
    textTransform: "uppercase",
    width: "100%",
    "&:hover": {
      background: "#1a67a3 none repeat scroll 0 0",
      // background: "#1a67a3 none repeat scroll 0 0",
      color: "#f8fafb",
    },
  },
}));

const GetInTouchForm = ({ openGetInTouchModal, handleCloseModal }) => {
  const classes = useStyles();
  console.log("Get in Touch form initiated");
  console.log("openGetInTouchModal", openGetInTouchModal);
  const [initialValues, setInitialValues] = useState({
    userName: "",
    email: "",
    userType: "",
    supportType: "",
    zipCode: "",
    reCaptcha: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const { addToast } = useToasts();

  const handleSubmit = (values) => {
    const body = {
      autor_name: values.userName.trim(),
      email_address: values.email.trim(),
      message: values.comment.trim(),
      zip_code: values.zipCode,
      user_type: values.userType === "customer" ? 1 : 2,
      support_type: values.supportType === "technicalIssue" ? 1 : 2,
      "g-recaptcha-response": values.reCaptcha,
    };
    setShowLoader(true);
    axios
      .post(`${BASE_URL}/contact`, body)
      .then((response) => {
        setShowLoader(false);
        if (response.status === 200) {
          setInitialValues({
            userName: "",
            email: "",
            comment: "",
            zipCode: "",
            userType: "",
            supportType: "",
            captcha: "",
          });
          handleCloseModal();
          addToast("Your message has been sent successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        setShowLoader(false);
        addToast(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  };
  const handleClose = () => {
    handleCloseModal();
  };
  // console.log("in get in Touch Open => ", openGetInTouchModal);
  console.log("in get in touch close => ", handleCloseModal);
  return (
    <>
      <Dialog
       
        fullWidth={true}
        maxWidth="md"
        style={{
          border: "1px solid #fcc75c" /* #e99709 */,
          overflowX: "hidden",
        }}
        open={openGetInTouchModal}
        // onClose={handleClose}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        aria-describedby="simple-dialog-description"
      >
        {/* <Paper> */}
        <DialogTitle
          style={{
            textAlign: "center",
            color: "#fcc75c " /* #f9b62f */,
            fontWeight: "700",
            fontSize: "20px",
          }}

          id="simple-dialog-title"
        >
          Get In Touch
        </DialogTitle>
        <DialogContent id="simple-dialog-description" style={{ padding: "15px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={getInTouchSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formikProps) => {
              return (
                <Form>
                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        style={{ width: "100%" }}
                        label="Name*"
                        name="userName"
                        variant="outlined"
                        onChange={(e) => {
                          formikProps.setFieldValue(`userName`, e.target.value);
                        }}
                        size="small"
                      />
                      {formikProps.errors.userName &&
                      formikProps.touched.userName ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.userName}
                        </FormLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        style={{ width: "100%" }}
                        label="Email*"
                        name="email"
                        variant="outlined"
                        onChange={(e) =>
                          formikProps.setFieldValue(`email`, e.target.value)
                        }
                        size="small"
                      />
                      {formikProps.errors.email && formikProps.touched.email ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.email}
                        </FormLabel>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <RadioGroup row aria-label="position" name="userType">
                        <FormControlLabel
                          control={
                            <Radio
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  `userType`,
                                  e.target.value
                                )
                              }
                            />
                          }
                          value="customer"
                          color="primary"
                          label="I am customer"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  `userType`,
                                  e.target.value
                                )
                              }
                            />
                          }
                          value="serviceProvider"
                          color="primary"
                          label="I am service provider"
                        />
                      </RadioGroup>
                    </Grid>
                    {formikProps.errors.userType &&
                    formikProps.touched.userType ? (
                      <FormLabel style={{ color: "red" }}>
                        {formikProps.errors.userType}
                      </FormLabel>
                    ) : null}
                  </Grid>
                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="comment"
                        name="comment"
                        label="Comment"
                        multiline
                        rows={6}
                        style={{ width: "-webkit-fill-available" }}
                        variant="outlined"
                        onChange={(e) =>
                          formikProps.setFieldValue(`comment`, e.target.value)
                        }
                        size="small"
                      />
                      {formikProps.errors.comment &&
                      formikProps.touched.comment ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.comment}
                        </FormLabel>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                      style={{ width: "100%" }}
                        label="Zip Code"
                        name="zipCode"
                        id="zipCode"
                        variant="outlined"
                        onChange={(e) =>
                          formikProps.setFieldValue(`zipCode`, e.target.value)
                        }
                        size="small"
                      />
                    </Grid>
                    {formikProps.errors.zipCode &&
                    formikProps.touched.zipCode ? (
                      <FormLabel style={{ color: "red" }}>
                        {formikProps.errors.zipCode}
                      </FormLabel>
                    ) : null}
                  </Grid>
                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <RadioGroup row aria-label="position" name="supportType">
                        <FormControlLabel
                          control={
                            <Radio
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  `supportType`,
                                  e.target.value
                                )
                              }
                            />
                          }
                          value="technicalIssue"
                          color="primary"
                          label="Technical Issue"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  `supportType`,
                                  e.target.value
                                )
                              }
                            />
                          }
                          value="feedback"
                          color="primary"
                          label="Feedback"
                        />
                      </RadioGroup>
                    </Grid>
                    {formikProps.errors.supportType &&
                    formikProps.touched.supportType ? (
                      <FormLabel style={{ color: "red" }}>
                        {formikProps.errors.supportType}
                      </FormLabel>
                    ) : null}
                  </Grid>
                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <ReCAPTCHA
                        // size="compact"
                        name="reCaptcha"
                        sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                        onChange={(e) =>
                          formikProps.setFieldValue(`reCaptcha`, e)
                        }
                      />
                    </Grid>
                    {formikProps.errors.reCaptcha &&
                    formikProps.touched.reCaptcha ? (
                      <FormLabel style={{ color: "red" }}>
                        {formikProps.errors.reCaptcha}
                      </FormLabel>
                    ) : null}
                  </Grid>
                  <Grid container spacing={3} style={{ margin: "15px 0px" }}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button className={classes.signUpBtn} type="submit">
                        Submit
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        onClick={handleClose}
                        className={classes.signUpBtn}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        {/* </Paper> */}
      </Dialog>
      <Loader text="Loading..." open={showLoader} />
    </>
  );
};
export default GetInTouchForm;
