/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Constants/serverConfig";
import Loader from "../../Loader/loader";
import { isMobile } from 'react-device-detect';
class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReviewsForm: false,
      firstName: "",
      lastName: "",
      email: "",
      reviewsList: [],
      showError: false,
      errorMessage: "",
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  closePopup = () => {
    this.setState({
      showReviewsForm: false,
    });
  };

  showReviewRequestForm = () => {
    this.setState({
      showReviewsForm: true,
    });
  };

  validateInformation = () => {
    const { serviceProvider } = this.props;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    const { firstName, lastName, email } = this.state;
    if (
      serviceProvider.email_address === email ||
      serviceProvider.vendor_email === email
    ) {
      this.showError("You cannot endorse yourself.");
      return false;
    }
    if (firstName.trim() === "") {
      this.showError("Please enter first name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName.trim() === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email.trim() === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    this.clearError();
    return true;
  };

  addToReviewsList = () => {
    const { firstName, lastName, email, reviewsList } = this.state;
    if (!this.validateInformation()) {
      return;
    }
    this.clearError();
    if (reviewsList.filter((x) => x.email_address === email).length > 0) {
      this.showError("Cannot add same email for multiple people.");
      return;
    }
    if (reviewsList.length < 5) {
      this.setState({
        reviewsList: [
          ...reviewsList,
          {
            email_address: email.trim(),
            customer_first_name: firstName.trim(),
            customer_last_name: lastName.trim(),
          },
        ],
        firstName: "",
        lastName: "",
        email: "",
      });
    } else {
      this.showError(
        "Cannot add more than 5 people to reviews list at a time."
      );
    }
  };

  removeFromReviewsList = (index) => {
    const { reviewsList } = this.state;
    reviewsList.splice(index, 1);
    this.setState({
      reviewsList: [...reviewsList],
    });
  };

  sendReviewsRequest = () => {
    const { token, showSuccess, clearMessage } = this.props;
    const { reviewsList } = this.state;
    if (reviewsList.length === 0) {
      this.showError("Please add people to get reviewed");
      return;
    }
    if (token !== null) {
      let reviewsListOldFormat = [];
      reviewsList.forEach((review) => {
        reviewsListOldFormat.push({
          email_address: review.email_address,
          customer_name: `${review.customer_first_name} ${review.customer_last_name}`,
        });
      });
      const body = {
        receiver_list: reviewsListOldFormat,
      };
      this.startLoader();
      axios
        .post(`${BASE_URL}/review/review-request`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.stopLoader();
          if (response.status === 200) {
            this.setState({
              firstName: "",
              lastName: "",
              email: "",
              reviewsList: [],
            });
            showSuccess("Reviews request sent successfully.");
            setTimeout(clearMessage, 5000);
            this.closePopup();
          }
        })
        .catch((error) => {
          this.stopLoader();
          console.error(error);
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  renderReviewsForm(
    serviceProviderName,
    activeServices,
    showError,
    errorMessage,
    firstName,
    lastName,
    email,
    reviewsList
  ) {
    return (
      <React.Fragment>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        <h2>
          Reviews Request from <i>{serviceProviderName}</i>{" "}
          <span>for propagile.com</span>
        </h2>
        <hr />
        <div className="endos_panel1 clearfix">
          <h1>Current services: (Promoted)</h1>
          <table>
            <tbody>
              {activeServices.map((x) => (
                <tr key={x.selected_service_id}>
                  <td>
                    <span>{x.name}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="endos_panel2 clearfix">
          <h1>Sample mail</h1>
          <p className="has-text-weight-normal">Dear Customer name</p>
          <br />
          <p className="has-text-weight-normal">
            I am so grateful to you for making me part of your project and hope
            my work and skills served you well. I look forward to the next
            opportunity to serve you on the mean time I would appreciate if you
            can provide a review of my services, your ratings and comments will
            show in my professional profile at PropAgile.com and will help me
            build a trustworthy online reputation. It will take only a couple of
            minutes and I very much appreciate your support.
          </p>
          <br />
          <p className="has-text-weight-normal">Thank you,</p>
          <hr />
        </div>
        <div className="endos_panel3 clearfix">
          <h1>
            Enter customer details <span>(Send up to 5 request at a time)</span>
          </h1>
          <div className="columns">
            <div className="column">
              <div className="form-group">
                <input
                  value={firstName}
                  name="firstName"
                  onChange={this.handleChange}
                  required
                  placeholder="Enter first name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="column">
              <div className="form-group">
                <input
                  value={lastName}
                  name="lastName"
                  onChange={this.handleChange}
                  required
                  placeholder="Enter last name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="column">
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                  required
                  placeholder="Enter email"
                  className="form-control"
                />
              </div>
            </div>
            <div className="column is-2">
              <div className="form-group">
                <button onClick={this.addToReviewsList} className="add-btn">
                  <i className="fa fa-plus" /> ADD
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <h1>People added</h1>
          <table>
            <thead>
              <tr>
                <th>Sr. no</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reviewsList.map((review, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{review.customer_first_name}</td>
                    <td>{review.customer_last_name}</td>
                    <td>{review.email_address}</td>
                    <td>
                      <div onClick={() => this.removeFromReviewsList(index)}>
                        <i className="fa fa-trash" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="map-buton clearfix">
          <button onClick={this.sendReviewsRequest} className="btn-fill">
            Submit requests
          </button>
          <button onClick={this.closePopup} className="btn-empty">
            Close
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      showReviewsForm,
      showError,
      errorMessage,
      firstName,
      lastName,
      email,
      reviewsList,
      showLoader,
    } = this.state;
    const { activeServices, hasMembership, serviceProvider } = this.props;
    return (
      <React.Fragment>
        <Loader text="Loading..." open={showLoader} />
        <div
          className={`modal mainn-map ${showReviewsForm ? "is-active" : ""}`}
        >
          <div className="modal-background" />
          <div className="modal-card endorsment-form">
            <button
              onClick={this.closePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body">
              {this.renderReviewsForm(
                `${serviceProvider.first_name} ${serviceProvider.last_name}`,
                activeServices,
                showError,
                errorMessage,
                firstName,
                lastName,
                email,
                reviewsList
              )}
            </section>
          </div>
        </div>
        <div className="form-group">


          {isMobile ?
            <>
              <label style={{ textAlign: 'center' }}>
                Request Reviews{" "}
              </label>
              <h6>
                {/* (Only colleagues and co-workers you previously worked with) */}
                Send this to current or previous customers
              </h6>
            </>
            :
            <label>
              Request Reviews{" "}
              <span>
                {/* (Only customers to whom you provided this type of services before) */}
                (Send this to current or previous customers)
              </span>
            </label>
          }
          <button
            onClick={this.showReviewRequestForm}
            disabled={!hasMembership || activeServices.length === 0}
            className="btn-empty"
          >
            Review Request Form
          </button>
          {!hasMembership && (
            <p className="has-text-danger">
              (Your account does not have a membership, please purchase a
              membership.)
            </p>
          )}
          {hasMembership && activeServices.length === 0 && (
            <p className="has-text-danger">
              (You do not have active services, please add one to access this
              function.)
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Reviews;
