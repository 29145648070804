/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link } from "react-router-dom";

class HeaderWithoutOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileOptions: false,
    };
  }

  toggleProfileOptions = () => {
    this.setState({
      showProfileOptions: !this.state.showProfileOptions,
    });
  };

  goHome = () => {
    const { isSpLoggedIn, serviceProvider } = this.props;

    if (isSpLoggedIn) {
      if (serviceProvider.user_type === 5) {
        this.props.history.push("/construction-project-management/team");
      } else if (serviceProvider.user_type === 6) {
        this.props.history.push(
          "/construction-project-management/project-manager"
        );
      }
    } else {
      this.props.history.push("/");
    }
  };

  render() {
    const { showProfileOptions } = this.state;
    const {
      isSpLoggedIn,
      handleLogout,
      metroArea,
      serviceProvider,
      isDark,
    } = this.props;

    return (
      <section className="hero">
        <div
          className={isDark === true ? "hero-head gray-hero-head" : "hero-head"}
        >
          <div className="container">
            <div className="columns is-mobile is-marginless">
              <div className="column left logo">
                <figure
                  onClick={this.goHome}
                  style={{ cursor: "pointer" }}
                  className="navbar-item image"
                >
                  <img
                    src={require(isDark === true
                      ? "../../assets/images/logo_colored.png"
                      : "../../assets/images/logo_colored.png")}
                    alt="Logo"
                  />
                  <span className="logoName">PropAgile</span>
                  <span className="logoInfo new_home_span">
                    Services Marketplace{" "}
                    <span className="logoInfo_small"> Based on </span> UniFormat{" "}
                    <span className="logoInfo_small"> & </span> MasterFormat{" "}
                  </span>
                </figure>
              </div>
              <div className="column right menu-right has-text-right">
                {isSpLoggedIn ? (
                  <React.Fragment>
                    <div className="dropdown login-user">
                      <div className="dropdown-trigger">
                        <button
                          className="button"
                          onClick={this.toggleProfileOptions}
                        >
                          <span>
                            <img
                              src={
                                serviceProvider &&
                                serviceProvider !== null &&
                                serviceProvider.profile_image[0]
                              }
                              alt="Service Provider"
                            />
                          </span>
                          <label className="hidden-xs">
                            {serviceProvider && serviceProvider !== null
                              ? serviceProvider.first_name
                              : ""}{" "}
                            {serviceProvider && serviceProvider !== null
                              ? serviceProvider.last_name
                              : ""}
                          </label>
                          <i className="togle-dots">
                            <p />
                            <p />
                            <p />
                          </i>
                        </button>
                      </div>
                      <div
                        className={
                          showProfileOptions
                            ? "dropdown-menu is-open"
                            : "dropdown-menu"
                        }
                      >
                        <div className="dropdown-content">
                          <a onClick={handleLogout} href="#">
                            LOGOUT
                          </a>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="back-dashboard">
                      <Link to="/customer/request">
                        <span>CUSTOMERS</span>
                      </Link>
                    </div>
                    <div className="back-dashboard">
                      <Link
                        to={
                          metroArea === undefined
                            ? "/vendor/lead/login"
                            : `/vendor/login?metro=${metroArea}`
                        }
                      >
                        <span>SERVICE PRO LOGIN</span>
                      </Link>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(HeaderWithoutOptions);
