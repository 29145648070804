import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import querystring from "querystring";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import TermsPopup from "../Terms/terms-popup";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

const vendorInviteSignUp = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [trade, setTrade] = useState("");
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [func, setFunc] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const user_type = 5;
  const projectName = "68-19 Woodhaven Blvd. Queens";

  useEffect(() => {
    const { isSpLoggedIn, isUserLoggedIn } = props;
    if (isSpLoggedIn === true || isUserLoggedIn === true) {
      history.push("/");
    }
    const params = querystring.parse(location.search);
    setEmail(params["email_address"]);
    setTrade(params["trade"]);
    setToken(params["token"]);
  });

  const clearError = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const showErrorMessage = (message) => {
    setShowError(true);
    setErrorMessage(message);
  };

  const closeTerms = () => {
    setShowTerms(false);
  };

  const acceptTerms = () => {
    setShowTerms(false);
    setTermsAccepted(!termsAccepted);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    const body = {
      username,
      password,
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      trade: trade,
      user_function: func,
      user_type,
      token: token,
    };
    axios
      .post(`${BASE_URL}/entrance/signup`, body)
      .then((response) => {
        if (response.status === 200) {
          setShowLoader(false);
          setShowTerms(false);
          setShowError(false);
          setErrorMessage("");
          setTermsAccepted(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setTrade("");
          setToken("");
          setShowPassword(false);
          const body = {
            username: username.trim(),
            password,
          };
          axios
            .post(`${BASE_URL}/entrance/login`, body, { withCredentials: true })
            .then((response) => {
              setShowLoader(false);
              setUsername("");
              setPassword("");
              props.handleServiceProviderLogin(response.data);
              setTimeout(() => {
                history.push("/construction-project-management/team");
              }, 200);
            })
            .catch((error) => {
              setShowLoader(false);
              console.error(error);
            });
        }
      })
      .catch((error) => {
        setShowLoader(false);
        showErrorMessage(error.response.data);
        console.error(error.response);
      });
  };

  const validate = () => {
    clearError();
    // const nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    const emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    const userIdValidation = /^[a-zA-Z][a-zA-Z0-9]{2,14}$/;
    if (firstName.trim() === "") {
      showErrorMessage("Please enter name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      showErrorMessage(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName.trim() === "") {
      showErrorMessage("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      showErrorMessage(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email.trim() === "") {
      showErrorMessage("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      showErrorMessage("Please enter a valid email");
      return false;
    }
    if (username.trim() === "") {
      showErrorMessage("Please enter username");
      return false;
    }
    if (!userIdValidation.test(username.trim())) {
      showErrorMessage(
        "Please enter a valid username, Username can be 3 to 50 characters long and can have only alphabets and numbers. It should not contain any spaces."
      );
      return false;
    }
    if (password === "") {
      showErrorMessage("Please enter password");
      return false;
    }
    if (func.trim() === "") {
      showErrorMessage("Please enter function");
      return false;
    }
    if (!nameValidation.test(func.trim()) || func.split(" ").length > 2) {
      showErrorMessage(
        "Please enter a valid function, last name should have 3 to 50 alphabets only and 2 words only"
      );
      return false;
    }
    return true;
  };

  return (
    <div>
      <Loader text="Loading..." open={showLoader} />
      {showError && (
        <div className="modal is-active custom-modal error-message">
          <div className="modal-background" />
          <div className="modal-content">
            <div className="error-message-modal">
              <button onClick={clearError} className="modal-close" />
              <p>{errorMessage}</p>
              <a onClick={clearError} className="btn-fill">
                OK
              </a>
            </div>
          </div>
        </div>
      )}
      {showTerms && (
        <TermsPopup closeTerms={closeTerms} acceptTerms={acceptTerms} />
      )}
      {!!props.isSpLoggedIn || !!props.isUserLoggedIn ? (
        <Header
          isDark={true}
          isUserLoggedIn={props.isUserLoggedIn}
          isSpLoggedIn={props.isSpLoggedIn}
          handleLogout={props.handleLogout}
          serviceProvider={props.serviceProvider}
          // childRef={(ref) => (this.headerChildRef = ref)}
        />
      ) : (
        <NewHeader />
      )}
      <section className="form-wrapper">
        <div className="form-inner signup-form">
          <div className="form-main">
            <div className="form-title">
              <h3>Sign Up</h3>
              <p>
                {trade} team for {projectName}
              </p>
            </div>

            <div className="form-fields">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input disabled value={email} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    required
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                  <a
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="view-password"
                  >
                    <img
                      alt="show password"
                      src={require("../../assets/images/view-password-icon.png")}
                    />
                  </a>
                </div>
                <div className="form-group">
                  <label>Function</label>
                  <input
                    type="text"
                    required
                    value={func}
                    onChange={(e) => setFunc(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="terms">
                  <h4
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowTerms(true);
                    }}
                  >
                    <i>Terms & conditions</i>
                  </h4>
                  <p>
                    You must read all terms and conditions below before
                    continuing with signup. * All (PropAgile) Members are
                    subject to the following policies, including the Member Code
                    of Conduct and all (PropAgile) Bylaws, located at:
                    http://www.propagile.com. By clicking "I accept" below you
                    are indicating you have read and agree to these (PropAgile)
                    policies. You may also choose to decline (PropAgile)
                    membership by hitting back or closing your browser, at which
                    point you will leave the signup process.
                  </p>
                </div>
                <div onClick={acceptTerms} className="checkbox">
                  <label
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <input
                      checked={termsAccepted}
                      onChange={() => {}}
                      type="checkbox"
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    />
                    <span className="checkmark" />I Accept
                  </label>
                </div>
                <div className="signup-btns">
                  <button
                    className="signup-btn"
                    style={{ width: "100%" }}
                    disabled={!termsAccepted}
                  >
                    ACCESS TEAM DASHBOARD
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {!!props.isSpLoggedIn || !!props.isUserLoggedIn ? (
        <Footer />
      ) : (
        <NewFooter />
      )}
    </div>
  );
};

export default vendorInviteSignUp;
