/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Constants/serverConfig";
import Loader from "../../Loader/loader";
import { isMobile } from 'react-device-detect';

class Endorsement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      endorsementsList: [],
      showEndorsementsForm: false,
      errorMessage: "",
      showError: false,
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  closePopup = () => {
    this.setState({
      showEndorsementsForm: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  showEndorsementRequestForm = () => {
    this.setState({
      showEndorsementsForm: true,
    });
  };

  validateInformation = () => {
    const { serviceProvider } = this.props;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    const { firstName, lastName, email } = this.state;
    if (
      serviceProvider.email_address === email ||
      serviceProvider.vendor_email === email
    ) {
      this.showError("You cannot endorse yourself.");
      return false;
    }
    if (firstName.trim() === "") {
      this.showError("Please enter first name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName.trim() === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email.trim() === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    this.clearError();
    return true;
  };

  addToEndorsementsList = () => {
    const { firstName, lastName, email, endorsementsList } = this.state;
    if (!this.validateInformation()) {
      return;
    }
    this.clearError();
    if (endorsementsList.filter((x) => x.email_address === email).length > 0) {
      this.showError("Cannot add same email for multiple people.");
      return;
    }
    if (endorsementsList.length < 5) {
      this.setState({
        endorsementsList: [
          ...endorsementsList,
          {
            email_address: email.trim(),
            receiver_first_name: firstName.trim(),
            receiver_last_name: lastName.trim(),
          },
        ],
        firstName: "",
        lastName: "",
        email: "",
      });
    } else {
      this.showError(
        "Cannot add more than 5 people to endorsements list at a time."
      );
    }
  };

  removeFromEndorsementList = (index) => {
    const { endorsementsList } = this.state;
    endorsementsList.splice(index, 1);
    this.setState({
      endorsementsList: [...endorsementsList],
    });
  };

  sendEndorsementRequest = () => {
    const { token, showSuccess, clearMessage } = this.props;
    const { endorsementsList } = this.state;
    if (endorsementsList.length === 0) {
      this.showError("Please add people to get endorsed");
      return;
    }
    if (token !== null) {
      let endorsementListOldFormat = [];
      endorsementsList.forEach((endorsement) => {
        endorsementListOldFormat.push({
          email_address: endorsement.email_address,
          receiver_name: `${endorsement.receiver_first_name} ${endorsement.receiver_last_name}`,
        });
      });
      const body = {
        receiver_list: endorsementListOldFormat,
      };
      this.startLoader();
      axios
        .post(
          `${BASE_URL}/endorsement/endorsement-request`,
          JSON.stringify(body),
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.stopLoader();
          if (response.status === 200) {
            this.setState({
              firstName: "",
              lastName: "",
              email: "",
              endorsementsList: [],
            });
            showSuccess("Endorsement request sent successfully.");
            setTimeout(clearMessage, 5000);
            this.closePopup();
          }
        })
        .catch((error) => {
          this.stopLoader();
          console.error(error);
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  renderEndorsementSection(
    serviceProviderName,
    activeServices,
    showError,
    errorMessage,
    firstName,
    lastName,
    email,
    endorsementsList
  ) {
    return (
      <React.Fragment>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background" />
            <div className="modal-content">
              <div className="error-message-modal">
                <button onClick={this.clearError} className="modal-close" />
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        <h2>
          Endorsements Request from <i>{serviceProviderName}</i>{" "}
          <span>for propagile.com</span>
        </h2>
        <hr />
        <div className="endos_panel1 clearfix">
          <h1>Current services: (Promoted)</h1>
          <table>
            <tbody>
              {activeServices.map((x) => {
                return (
                  <tr key={x.selected_service_id}>
                    <td>
                      <span>{x.name}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="endos_panel2 clearfix">
          <h1>Sample mail</h1>
          <p className="has-text-weight-normal">Dear Colleague name</p>
          <br />
          <p className="has-text-weight-normal">
            I created a professional profile at PropAgile as a strategy to help
            promote my services online and to also become part of the lists of
            professionals servicing multifamily projects in the New York
            metropolitan area.
          </p>
          <br />
          <p className="has-text-weight-normal">
            This profile will become the way for customers to find me online and
            the web link I will share with customers, friends and in social
            networks to show my services, portfolio, reviews and professional
            endorsements which is the main reason for this email; I hope I can
            count with your endorsement which is very significant to me, it will
            take only a couple of minutes and will be so grateful to see it in
            my profile.
          </p>
          <br />
          <p className="has-text-weight-normal">Thank you,</p>
          <hr />
        </div>
        <div className="endos_panel3 clearfix">
          <h1>
            Enter colleague details{" "}
            <span>(Send up to 5 request at a time)</span>
          </h1>
          <div className="columns">
            <div className="column">
              <div className="form-group">
                <input
                  value={firstName}
                  name="firstName"
                  onChange={this.handleChange}
                  required
                  placeholder="Enter first name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="column">
              <div className="form-group">
                <input
                  value={lastName}
                  name="lastName"
                  onChange={this.handleChange}
                  required
                  placeholder="Enter last name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="column">
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                  required
                  placeholder="Enter email"
                  className="form-control"
                />
              </div>
            </div>
            <div className="column is-2">
              <div className="form-group">
                <button
                  onClick={this.addToEndorsementsList}
                  className="add-btn"
                >
                  <i className="fa fa-plus" /> ADD
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <h1>People added</h1>
          <table>
            <thead>
              <tr>
                <th>Sr. no</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {endorsementsList.map((endorsement, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{endorsement.receiver_first_name}</td>
                    <td>{endorsement.receiver_last_name}</td>
                    <td>{endorsement.email_address}</td>
                    <td>
                      <div
                        onClick={() => this.removeFromEndorsementList(index)}
                      >
                        <i className="fa fa-trash" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="map-buton clearfix">
          <button onClick={this.sendEndorsementRequest} className="btn-fill">
            Submit requests
          </button>
          <button onClick={this.closePopup} className="btn-empty">
            Close
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      showEndorsementsForm,
      firstName,
      lastName,
      email,
      endorsementsList,
      showError,
      errorMessage,
      showLoader,
    } = this.state;
    const { activeServices, hasMembership, serviceProvider } = this.props;
    return (
      <React.Fragment>
        <Loader text="Loading..." open={showLoader} />
        <div
          className={`modal mainn-map ${showEndorsementsForm ? "is-active" : ""
            }`}
        >
          <div className="modal-background" />
          <div className="modal-card endorsment-form">
            <button
              onClick={this.closePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body">
              {this.renderEndorsementSection(
                `${serviceProvider.first_name} ${serviceProvider.last_name}`,
                activeServices,
                showError,
                errorMessage,
                firstName,
                lastName,
                email,
                endorsementsList
              )}
            </section>
          </div>
        </div>
        <div className="form-group">
          {isMobile ?
            <>
              <label style={{textAlign:'center'}}>
                Request Endorsement{" "}
              </label>
              <h6>
                {/* (Only colleagues and co-workers you previously worked with) */}
                Send this to colleagues and others who can testify to your
                skills
              </h6>
            </>
            :
            <label>
              Request Endorsement{" "}
              <span>
                {/* (Only colleagues and co-workers you previously worked with) */}
                (Send this to colleagues and others who can testify to your
                skills)
              </span>
            </label>
          }
          <button
            onClick={this.showEndorsementRequestForm}
            disabled={!hasMembership || activeServices.length === 0}
            className="btn-empty"
          >
            Endorsement Request Form
          </button>
          {!hasMembership && (
            <p className="has-text-danger">
              (Your account does not have a membership, please purchase a
              membership.)
            </p>
          )}
          {hasMembership && activeServices.length === 0 && (
            <p className="has-text-danger">
              (You do not have active services, please add one to access this
              function.)
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Endorsement;
