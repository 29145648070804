/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import Loader from "../Loader/loader";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNo: "",
      subject: "",
      comment: "",
      captcha: "",
      showError: false,
      errorMessage: "",
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onChange = (value) => {
    this.setState({
      captcha: value,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, phoneNo, subject, comment, captcha } = this.state;
    if (!name || name.trim() === "" || name === null) {
      this.showError("Please enter a name");
      return;
    }
    if (!email || email.trim() === "" || email === null) {
      this.showError("Please enter your email");
      return;
    }
    if (!phoneNo || phoneNo.trim() === "" || phoneNo === null) {
      this.showError("Please enter your phone number");
      return;
    }
    if (!subject || subject.trim() === "" || subject === null) {
      this.showError("Please enter subject");
      return;
    }
    if (!comment || comment.trim() === "" || comment === null) {
      this.showError("Please enter comment");
      return;
    }
    if (!captcha || captcha === "" || captcha === null) {
      this.showError("Please confirm that you are not a robot");
      return;
    }
    const body = {
      autor_name: name.trim(),
      email_address: email.trim(),
      phone_number: phoneNo.trim(),
      subject: subject.trim(),
      message: comment.trim(),
    };
    this.startLoader();
    axios
      .post(`${BASE_URL}/contact`, body)
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  render() {
    const {
      name,
      email,
      phoneNo,
      subject,
      comment,
      showError,
      errorMessage,
      showLoader,
    } = this.state;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        <section className="form-wrapper">
          {showError && (
            <div className="modal is-active custom-modal error-message">
              <div className="modal-background"></div>
              <div className="modal-content">
                <div className="error-message-modal">
                  <button
                    onClick={this.clearError}
                    className="modal-close"
                  ></button>
                  <p>{errorMessage}</p>
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="form-inner">
            <div className="logo-forms">
              <Link to="/">
                <img
                  src={require("../../assets/images/logo-new-yellow-blue-no-text.png")}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="form-main">
              <div className="form-title">
                <h3>Contact</h3>
                <p>Please report your issue and we will get back to you.</p>
              </div>

              <div className="form-fields">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={name}
                      name="name"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      name="email"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      className="form-control"
                      type="text"
                      value={phoneNo}
                      name="phoneNo"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      className="form-control"
                      type="text"
                      value={subject}
                      name="subject"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Comment</label>
                    <textarea
                      className="form-control textarea"
                      value={comment}
                      name="comment"
                      required
                      placeholder="Comment"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="captcha">
                    <ReCAPTCHA
                      sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="login-btn">
                    <button type="submit" className="btn btn-default">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ContactUs);
