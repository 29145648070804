import {
  SET_NEW_INVITEES,
  SET_LICENSE_AVAILABLE,
} from "../Constants/actionTypes.jsx";

const initialState = {
  requestList: [],
  // unsendInviteesRequestList: [],
  licenseAvailable: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_INVITEES:
      return Object.assign({}, state, {
        requestList: action.payload,
      });
    case SET_LICENSE_AVAILABLE:
      return Object.assign({}, state, {
        licenseAvailable: action.payload,
      });
    default:
      return state;
  }
};
