const TOKEN = "token";
const USERLOGIN = "userLogin";
const USER = "user";
const SERVICEPROVIDERLOGIN = "spLogin";
const SERVICEPROVIDER = "serviceProvider";
const EXPIRY = "expiry";
const LOAD_PREVIOUS = "load-previous-data";
const DATA = "x-encode-data";
const DEMO_PROFILE_DATA = "dppv";
const DEMO_PROFILE_PORTFOLIO_1 = "ppt1";
const DEMO_PROFILE_PORTFOLIO_2 = "ppt2";
const DEMO_PROFILE_PORTFOLIO_3 = "ppt3";
const DEMO_PROFILE_PORTFOLIO_4 = "ppt4";
const DEMO_PROFILE_PORTFOLIO_5 = "ppt5";
const DEMO_PROFILE_PORTFOLIO_6 = "ppt6";
const TAB_KEY = "TAB_KEY";

export {
  TOKEN,
  USERLOGIN,
  USER,
  SERVICEPROVIDERLOGIN,
  SERVICEPROVIDER,
  EXPIRY,
  LOAD_PREVIOUS,
  DATA,
  DEMO_PROFILE_DATA,
  DEMO_PROFILE_PORTFOLIO_1,
  DEMO_PROFILE_PORTFOLIO_2,
  DEMO_PROFILE_PORTFOLIO_3,
  DEMO_PROFILE_PORTFOLIO_4,
  DEMO_PROFILE_PORTFOLIO_5,
  DEMO_PROFILE_PORTFOLIO_6,
  TAB_KEY,
};
