import React, { useState } from 'react'
import './treeTrade.css'

function TreeTrades({ data, saveGroup }) {

    console.log(data);
    const [dataSource, setData] = useState(data);

    // const handleCheckboxChange = (id) => {

    //     setData(prevData => prevData.map(service => {
    //         console.log(service);
    //         if (service.id === id) {
    //             service.checked = !service.checked;
    //         }
    //         if (service.children && service.children.length > 0) {
    //             service.children = toggleChecked(service.children, service.checked);
    //         }
    //         return service;
    //     }));
    // };

    // const toggleChecked = (children, checked) => {
    //     return children.map(child => {
    //         child.checked = checked;
    //         if (child.children && child.children.length > 0) {
    //             child.children = toggleChecked(child.children, checked);
    //         }
    //         return child;
    //     });
    // };

    

    const handleCheckboxChange = (id) => {
        setData(prevData => {

            const updatedData = toggleChecked(prevData, id);
            return [...updatedData]; // Devolvemos un nuevo array
        });
        saveGroup(dataSource);
    };

    const toggleChecked = (services, id) => {

        
        return services.map(service => {
            if (service.id === id && service.children && service.children.length > 0) {
                service.checked = !service.checked;
                //service.children = toggleChecked(service.children, id);
            }
           
            if(service.id === id && service.children && service.children.length == 0){
                service.checked = !service.checked;
            }else{
                service.children = toggleChecked(service.children, id);
            }

            // if (service.children && service.children.length > 0) {
            //     service.children = toggleChecked(service.children, id);
            // }else{
            //     service.checked = !service.checked;
            // }
            return service;
        });
    };


    const renderItems = (data) => {
        return (
            <ul>
                {data.map((item, index) => (
                    <li key={item.id}>
                        {/* Renderiza el nombre del item */}
                        {(item.children && item.children.length > 0) ?
                            <>
                                <input className='control-node' type="checkbox" id={item.path + '-' + 1} />
                                {/* <input className='control-input' onChange={() => handleCheckboxChange(item.id)} checked={item.checked} type="checkbox" id={item.path + '-' + 1} /> */}
                                <label for={item.path + '-' + 1} className="tree_label">{item.name}</label>
                            </>
                            : <><input className='control-input' onChange={() => handleCheckboxChange(item.id)} checked={item.checked} type="checkbox" id={item.path + '-' + 1} /><span class="tree_label_child">&nbsp;{item.name}</span></>
                        }

                        {/* Si el item tiene children y no está vacío, renderiza una nueva lista */}
                        {item.children && item.children.length > 0 && renderItems(item.children)}
                    </li>
                ))}
            </ul>
        );

        return renderItems(data);
    }

    //console.log(dataSource);

    return (

        <div>

            <ul class="tree">
                <li>
                    <input className='control-node' type="checkbox" checked={true} id={dataSource[0].path} />
                    {/* <input className='control-input' type="checkbox" id={dataSource[0].path + '-' + 0} /> */}
                    <label class="tree_label" for={dataSource[0].path}>{dataSource[0].name}</label>
                    {dataSource[0].children && dataSource[0].children.length > 0 && renderItems(dataSource[0].children)}
                </li>
                {/* {data.children.map(item => {
                    return (
                        <li>
                            <input type="checkbox" id={item.path} />
                            <label class="tree_label" for={item.path}>{item.name}</label>
                            {item.children && item.children.length > 0 && renderItems(item.children)}
                        </li>
                    )
                })} */}
                {/* <li>
                    <input type="checkbox" checked="checked" id="c1" />
                    <label class="tree_label" for="c1">Level 0</label>
                    <ul>
                        <li>
                            <input type="checkbox" checked="checked" id="c2" />
                            <label for="c2" class="tree_label">Level 1</label>
                            <ul>
                                <li><span class="tree_label">Level 2</span></li>
                                <li><span class="tree_label">Level 2</span></li>
                            </ul>
                        </li>
                        <li>
                            <input type="checkbox" id="c3" />
                            <label for="c3" class="tree_label">Looong level 1 <br />label text <br />with line-breaks</label>
                            <ul>
                                <li><span class="tree_label">Level 2</span></li>
                                <li>
                                    <input type="checkbox" id="c4" />
                                    <label for="c4" class="tree_label"><span class="tree_custom">Specified tree item view</span></label>
                                    <ul>
                                        <li><span class="tree_label">Level 3</span></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>


                <li>
                    <input type="checkbox" id="c5" />
                    <label class="tree_label" for="c5">Level 0</label>
                    <ul>
                        <li>
                            <input type="checkbox" id="c6" />
                            <label for="c6" class="tree_label">Level 1</label>
                            <ul>
                                <li><span class="tree_label">Level 2</span></li>
                            </ul>
                        </li>
                        <li>
                            <input type="checkbox" id="c7" />
                            <label for="c7" class="tree_label">Level 1</label>
                            <ul>
                                <li><span class="tree_label">Level 2</span></li>
                                <li>
                                    <input type="checkbox" id="c8" />
                                    <label for="c8" class="tree_label">Level 2</label>
                                    <ul>
                                        <li><span class="tree_label">Level 3</span></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>  */}
            </ul>
        </div>
    )
}

export default TreeTrades