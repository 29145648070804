import { SAVE_USER, REMOVE_USER } from "../Constants/actionTypes.jsx";

const saveUser = (data) => {
  return {
    type: SAVE_USER,
    payload: data,
  };
};

const removeUser = () => {
  return {
    type: REMOVE_USER,
    payload: null,
  };
};

export { saveUser, removeUser };
