import React, { Component } from "react";
import axios from "axios";
import querystring from "querystring";
import { BASE_URL } from "../../../Constants/serverConfig";
import Loader from "../../Loader/loader";
import { Paper } from "@material-ui/core";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changePassword = (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
    const { showError, showSuccess, token, clearMessage } = this.props;
    if (newPassword !== confirmNewPassword) {
      showError("New password and confirm password does not match.");
      return;
    }
    this.startLoader();
    axios
      .patch(
        `${BASE_URL}/account/password`,
        querystring.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        }),
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          showSuccess("Password changed successfully");
          setTimeout(clearMessage, 5000);
          this.setState({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
        if (error.response.status === 401) {
          showError("Invalid old password.");
        }
      });
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
      showLoader,
    } = this.state;
    return (
      <Paper elevation={3}>
        <Loader text="Loading..." open={showLoader} />
        <div className="changepassword clearfix">
          <h1>Change Password</h1>
          <form onSubmit={this.changePassword}>
            <div className="row" style={{ }}>
              <div className="form-group divide-3">
                <label>Current Password</label>
                <input
                  type="password"
                  value={currentPassword}
                  name="currentPassword"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-3">
                <label>New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  name="newPassword"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-3">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  value={confirmNewPassword}
                  name="confirmNewPassword"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
              <div className="">
                <button className="pf-submit" style={{}}>
                  Submit
                </button>
              </div>


          </form>
        </div>
      </Paper>
    );
  }
}

export default ChangePassword;
