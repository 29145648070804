/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import querystring from "querystring";
import Rating from "react-rating";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import Loader from "../Loader/loader.jsx";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      vendorName: "",
      profileName: "",
      token: "",
      quality: 1,
      availability: 1,
      teamwork: 1,
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      comment: "",
      selectedServices: [],
      showError: false,
      errorMessage: "",
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentDidMount() {
    let query = querystring.parse(this.props.location.search);
    this.setState({
      vendorName: query["?vendor_name"],
      token: query["token"],
      profileName: query["profile_name"],
    });
    axios
      .get(`${BASE_URL}/review/service?token=${query["token"]}`)
      .then((response) => {
        console.log(response);
        if (response.data.filter((x) => x.is_rated === false).length === 0) {
          this.showError("You have already added reviews for all services.");
        }
        this.setState({
          services: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleChange = (event) => {
    if (event.target.name === "comment") {
      if (event.target.value.length <= 1500) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      } else {
        this.setState({
          [event.target.name]: event.target.value.substr(0, 1500),
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleCheckboxClick = (name) => {
    const selection = this.state[name];
    let parent_id = name.split("-")[0];
    let child_id = name.split("-")[1];
    const { selectedServices } = this.state;
    if (selection === true) {
      this.setState({
        [name]: false,
        selectedServices: [
          ...selectedServices.filter((item) => item.parent_id !== parent_id),
        ],
      });
    } else {
      this.setState({
        [name]: true,
        selectedServices: [...selectedServices, { parent_id, child_id }],
      });
    }
  };

  validateInformation = () => {
    this.clearError();
    let companyNameValidation = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/;
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    const { firstName, lastName, companyName, email, comment } = this.state;
    if (firstName === "") {
      this.showError("Please enter name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (companyName === "") {
      this.showError("Please enter your Company/Individual Owner Name");
      return false;
    }
    if (!companyNameValidation.test(companyName.trim())) {
      this.showError("Please enter a valid Company/Individual Owner Name");
      return false;
    }
    if (email === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    if (comment.trim() === "") {
      this.showError("Please enter your comment");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validateInformation()) {
      return;
    }
    const {
      token,
      quality,
      availability,
      teamwork,
      firstName,
      lastName,
      companyName,
      email,
      comment,
      selectedServices,
      profileName,
    } = this.state;
    if (selectedServices.length === 0) {
      this.showError("Please choose a service for review");
      return;
    }
    const body = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      company_name: companyName.trim(),
      email_address: email.trim(),
      comment: comment.trim(),
      quality_work: quality === 0 ? 1 : quality,
      availability: availability === 0 ? 1 : availability,
      team_work: teamwork === 0 ? 1 : teamwork,
      selected_service: JSON.stringify(selectedServices),
      request_token: token,
    };
    this.startLoader();
    axios
      .post(`${BASE_URL}/review`, querystring.stringify(body), {
        "Content-Type": "application/x-www-form-urlencoded",
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          this.props.history.push(`/pro1/${profileName}`);
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  rate = (name) => (event) => {
    this.setState({
      [name]: event,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  render() {
    const {
      services,
      vendorName,
      quality,
      availability,
      teamwork,
      firstName,
      lastName,
      companyName,
      email,
      comment,
      showError,
      errorMessage,
      showLoader,
    } = this.state;
    const {
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
      isUserLoggedIn,
    } = this.props;
    return (
      <div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section className="review-form-dd">
          <Loader text="Loading..." open={showLoader} />
          {showError && (
            <div className="modal is-active custom-modal error-message">
              <div className="modal-background" />
              <div className="modal-content">
                <div className="error-message-modal">
                  <button onClick={this.clearError} className="modal-close" />
                  <p>{errorMessage}</p>
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="form-inner signup-form">
            <div className="form-main">
              <div className="form-title">
                <h3>Review for {vendorName}</h3>
              </div>
              <div className="form-fields">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>Select one or multiple services:</label>
                    <div className="promo_checkboxes_new">
                      {services.map((service, index) => (
                        <div
                          key={index}
                          onClick={
                            service.is_rated
                              ? () => {
                                  this.showError(
                                    "This service is already reviewed"
                                  );
                                }
                              : () =>
                                  this.handleCheckboxClick(
                                    `${service.parent_id}-${service.child_id}`
                                  )
                          }
                          className={
                            service.is_rated
                              ? "checkkk clearfix is-disabled"
                              : "checkkk clearfix"
                          }
                        >
                          <div
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                            className="checkbox"
                          >
                            <label
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                              className={
                                this.state[
                                  `${service.parent_id}-${service.child_id}`
                                ] === true
                                  ? "is-active-checkbox"
                                  : "checkbox"
                              }
                            >
                              <input
                                onChange={() => {}}
                                onClick={(event) => {
                                  event.preventDefault();
                                }}
                                checked={
                                  this.state[
                                    `${service.parent_id}-${service.child_id}`
                                  ] === true
                                }
                                type="checkbox"
                              />
                              <span className="checkmark" />
                              {`${service.parent_name} / ${service.child_name}`}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="rev-stars">
                      <label>Quality of work</label>
                      <div className="v-stars">
                        <Rating
                          initialRating={quality}
                          onClick={this.rate("quality")}
                          emptySymbol="far fa-star"
                          fullSymbol="fa fa-star"
                          fractions={2}
                        />
                      </div>
                    </div>
                    <div className="rev-stars">
                      <label>Availability</label>
                      <div className="v-stars">
                        <Rating
                          initialRating={availability}
                          onClick={this.rate("availability")}
                          emptySymbol="far fa-star"
                          fullSymbol="fa fa-star"
                          fractions={2}
                        />
                      </div>
                    </div>
                    <div className="rev-stars">
                      <label>Team work</label>
                      <div className="v-stars">
                        <Rating
                          initialRating={teamwork}
                          onClick={this.rate("teamwork")}
                          emptySymbol="far fa-star"
                          fullSymbol="fa fa-star"
                          fractions={2}
                        />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      required
                      value={firstName}
                      name="firstName"
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      required
                      value={lastName}
                      name="lastName"
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label> Company/Individual Owner Name:</label>
                    <input
                      type="text"
                      required
                      value={companyName}
                      name="companyName"
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      required
                      value={email}
                      name="email"
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Comment</label>
                    <textarea
                      rows="6"
                      value={comment}
                      name="comment"
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    <label className="has-text-weight-normal is-size-7 has-text-danger has-text-right">
                      {1500 - comment.length} of 1500 characters
                    </label>
                  </div>
                  <div className="signup-btns">
                    <button className="signup-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(Review);
