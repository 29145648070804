//PACKAGES
import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

//COMPONENTS
import Header from "../Header/header";
import Footer from "../Footer";
import Form from "../Form/Form";
import Loader from "../Loader/loader";
import ErrorMessage from "../Message/Message";

//CONSTANTS
import { BASE_URL } from "../../Constants/serverConfig";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

export default function Register({
  isSpLoggedIn,
  handleLogout,
  serviceProvider,
  isUserLoggedIn,
}) {
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const validate = (body) => {
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let userIdValidation = /^[a-zA-Z][a-zA-Z0-9]{2,14}$/;

    if (body.first_name.trim() === "") {
      showErrorMessage("Please enter name");
      return false;
    }
    if (!nameValidation.test(body.first_name.trim())) {
      showErrorMessage(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (body.last_name.trim() === "") {
      showErrorMessage("Please enter last name");
      return false;
    }
    if (!nameValidation.test(body.last_name.trim())) {
      showErrorMessage(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }

    if (body.email_address.trim() === "") {
      showErrorMessage("Please enter email");
      return false;
    }
    if (!emailValidation.test(body.email_address.trim())) {
      showErrorMessage("Please enter a valid email");
      return false;
    }
    if (body.username.trim() === "") {
      showErrorMessage("Please enter username");
      return false;
    }
    if (!userIdValidation.test(body.username.trim())) {
      showErrorMessage(
        "Please enter a valid username, Username can be 3 to 50 characters long and can have only alphabets and numbers. It should not contain any spaces."
      );
      return false;
    }
    if (body.password === "") {
      showErrorMessage("Please enter password");
      return false;
    }
    console.log(body);
    return true;
  };

  const startLoader = () => {
    setShowLoader(true);
  };

  const stopLoader = () => {
    setShowLoader(false);
  };

  const showErrorMessage = (errorMessage) => {
    setErrorMessage(errorMessage);
    setShowError(true);
  };

  const clearError = () => {
    setErrorMessage("");
    setShowError(false);
  };

  const handleSubmit = (event, body, resetStates) => {
    event.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    if (!validate(body)) {
      return;
    }
    console.log(body, resetStates);
    startLoader();
    axios
      .post(`${BASE_URL}/entrance/signup`, JSON.stringify(body), {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        stopLoader();
        console.log(response);
        resetStates();
        history.push("/Construction-Project-Management/login");
      })
      .catch((error) => {
        console.log(error);
        stopLoader();
        showErrorMessage("Opps! Something went wrong please try again.");
      });
  };

  return (
    <div>
      {!!isSpLoggedIn || !!isUserLoggedIn ? (
        <Header
          isDark={true}
          isSpLoggedIn={isSpLoggedIn}
          handleLogout={handleLogout}
          serviceProvider={serviceProvider}
        />
      ) : (
        <NewHeader />
      )}
      <Loader text="Loading..." open={showLoader} />

      <ErrorMessage
        showError={showError}
        errorMessage={errorMessage}
        clearError={clearError}
      />

      <section className="form-wrapper">
        <div className="form-inner signup-form">
          <div className="form-main">
            <div className="form-title">
              <h3>Register</h3>
            </div>

            <div className="form-fields">
              <Form handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </section>
      {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
    </div>
  );
}
