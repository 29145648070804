import React, { Component } from 'react'
import { connect } from "react-redux";

// Import Swiper React components

import SampleServices from "../SampleServices/sample-services.jsx";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader.jsx";
import { Box } from "@material-ui/core";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Carousel, Button } from 'react-bootstrap';
import './newHome.css';



class HomeTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.isUserLoggedIn !== nextProps.isUserLoggedIn ||
            this.props.isSpLoggedIn !== nextProps.isSpLoggedIn ||
            this.props.serviceProvider !== nextProps.serviceProvider ||
            this.state.activeIndex !== nextState.activeIndex

        );
    }

    handleSelect = (selectedIndex) => {
        this.setState({ activeIndex: selectedIndex });
    };

    goToSlide = (selectedIndex) => {
        this.setState({ activeIndex: selectedIndex });
    };
    render() {
        const {
            isUserLoggedIn,
            isSpLoggedIn,
            handleLogout,
            serviceProvider,
        } = this.props;
        const { activeIndex } = this.state;
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);

        return (
            <>

                {!!isSpLoggedIn || !!isUserLoggedIn ? (
                    <Header
                        isDark={true}
                        metroArea="NYMA"
                        isUserLoggedIn={isUserLoggedIn}
                        isSpLoggedIn={isSpLoggedIn}
                        handleLogout={handleLogout}
                        serviceProvider={serviceProvider}
                        location={this.props.location}
                    />
                ) : (
                    <NewHeader />
                )}

                <div className='container-fluid mx-0 px-0'>
                    <div className='home-container d-flex flex-column justify-content-center align-items-center'>
                        <div className='home-tittle-main m-0 mt-md-3 p-0 w-100'>
                            <h1 className='text-center m-0 pb-2 pb-md-0'>Turn Construction Experience Into</h1>
                        </div>
                        <div className='mt-0 mt-md-5 home-carousel'>

                            <Carousel slide={false} controls={false} indicators={false} >
                                <Carousel.Item>
                                    <div className='row'>
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h1 className="text-center mt-1 mt-md-2">Visibility</h1>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2">
                                            <img
                                                className="d-block w-100"
                                                src="./images/visibilityHead.png"
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>

                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='row'>
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h1 className="text-center mt-1 mt-md-2">Leads</h1>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2">
                                            <img
                                                className="d-block w-100"
                                                src="./images/leadsHead.png"
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>

                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='row'>
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h1 className="text-center mt-1 mt-md-2">Connections</h1>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2">
                                            <img
                                                className="d-block w-100"
                                                src="./images/connectionHead.png"
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>

                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='row'>
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h1 className="text-center mt-1 mt-md-2">Customers</h1>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2">
                                            <img
                                                className="d-block w-100"
                                                src="./images/customerHead.png"
                                                alt="First slide"
                                            />
                                        </div>
                                    </div>

                                </Carousel.Item>
                            </Carousel>

                            <div className='home-fixed-header-carousel d-flex flex-column justify-content-center align-items-center p-2 p-md-0'>
                                <span className='text-center'>Make it easier for prospective clients and people in construction to find you. <br></br>Build an online reputation that attracts clients and partners</span>
                                <a href='/vendor/subscriptions' className='btn btn-primary mt-4 mt-md-5 btn-header' style={{ backgroundColor: "#1a67a3" }}>Membership's options</a>
                                <small><a href='/profile/edit' className='btn btn-link text-danger '>See it in action</a></small>
                            </div>

                        </div>
                        <div className='mt-5 home-carousel-2 bg-light py-5'>
                            <div className='home-tittle bg-light w-100 mb-5'>
                                <h1 className='text-center'>Promote Services, Build Connections, and Multiply Opportunities.<br></br>No Tech Skills? No Problem!</h1>

                            </div>

                            {isMobile ?

                                <div className=" d-flex flex-column flex-md-row" >

                                    <Carousel className="w-100" activeIndex={activeIndex} onSelect={this.handleSelect} controls={true} indicators={false}>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-md-4">
                                                    <img
                                                        className="d-none d-block w-100 home-img-details"
                                                        src="./images/portfolioScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Show what you can do everywhere online</h2>
                                                    <p>
                                                        Displaying your work is key to capturing the interest of potential clients. With our platform, you can easily upload or modify your portfolio from your mobile device, anywhere, anytime. Make your talent accessible and update it on the fly.
                                                    </p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/reviewScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Get Reviews in Construction</h2>
                                                    <p>Reviews are the currency of trust in today's digital world. Customers are constantly seeking them out. We simplify the process of collecting these vital testimonials, empowering you to effortlessly boost your credibility. The more reviews, the stronger your trustworthiness</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/endorsementScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Endorsements to reinforce a reputation</h2>
                                                    <p>Endorsements are the backbone of credibility in our interconnected world. Your partners, coworkers, and collaborators are the ones who vouch for you. Their voices have a profound impact, shaping perceptions and building trust.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/QRScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Connect print media with your profile</h2>
                                                    <p>Stamp your unique QR Code on all print media, from business cards to flyers, and turn mere spectators into active website visitors. This strategy bolsters your PropAgile profile and enhances your online visibility through effective multichannel marketing</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="https://th.bing.com/th/id/OIP.OelkMNedN5iGunpypAJd9wHaFd?pid=ImgDet&rs=1"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Connect your website, Increase traffic and improve SEO</h2>
                                                    <p>Boost your website's reach with PropAgile.. By incorporating your website link, you create valuable backlinks that not only drive more traffic to your site but also amplify your search engine rankings, effectively bolstering your online presence.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="https://th.bing.com/th/id/OIP.OelkMNedN5iGunpypAJd9wHaFd?pid=ImgDet&rs=1"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Email Promotion made simple</h2>
                                                    <p>Keep your customers updated with your work effortlessly. With a single click, instantly email your profile, services, and portfolio, ensuring your accomplishments are always at their fingertips.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                {/* <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/shareScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div> */}
                                                <div className="col-12  text-center">
                                                    <h2 className="text-center mt-0 mb-4">Social Network Promotion  made simple</h2>
                                                    <p>Maintain a dynamic presence on social networks with a single click. Instantly share updates to your portfolio and profile on Facebook, Twitter, LinkedIn, Pinterest, and WhatsApp, ensuring your work is always in the spotlight, no matter where your audience is.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>

                                    </Carousel>
                                </div>
                                : <div className=" d-flex flex-column flex-md-row" >
                                    <div className="d-flex flex-column justify-content-between align-items-start carousel-buttons mr-5">
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(0)}
                                            className={"btn-block " + (activeIndex === 0 ? 'active' : '')}
                                        >
                                            Portfolios
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(1)}
                                            className={"btn-block " + (activeIndex === 1 ? 'active' : '')}
                                        >
                                            Reviews
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(2)}
                                            className={"btn-block " + (activeIndex === 2 ? 'active' : '')}
                                        >
                                            Endorsements
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(3)}
                                            className={"btn-block " + (activeIndex === 3 ? 'active' : '')}
                                        >
                                            Your QR Code
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(4)}
                                            className={"btn-block " + (activeIndex === 4 ? 'active' : '')}
                                        >
                                            Backlink to Website
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(5)}
                                            className={"btn-block " + (activeIndex === 5 ? 'active' : '')}
                                        >
                                            Email promotion
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.goToSlide(6)}
                                            className={"btn-block " + (activeIndex === 6 ? 'active' : '')}
                                        >
                                            Social Networks
                                        </Button>
                                    </div>
                                    <Carousel className="w-100" activeIndex={activeIndex} onSelect={this.handleSelect} controls={false} indicators={false}>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/portfolioScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Show what you can do everywhere online</h2>
                                                    <p>
                                                        Displaying your work is key to capturing the interest of potential clients. With our platform, you can easily upload or modify your portfolio from your mobile device, anywhere, anytime. Make your talent accessible and update it on the fly.
                                                    </p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/reviewScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Get Reviews in Construction</h2>
                                                    <p>Reviews are the currency of trust in today's digital world. Customers are constantly seeking them out. We simplify the process of collecting these vital testimonials, empowering you to effortlessly boost your credibility. The more reviews, the stronger your trustworthiness</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/endorsementScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Endorsements to reinforce a reputation</h2>
                                                    <p>Endorsements are the backbone of credibility in our interconnected world. Your partners, coworkers, and collaborators are the ones who vouch for you. Their voices have a profound impact, shaping perceptions and building trust.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/QRScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Connect print media with your profile</h2>
                                                    <p>Stamp your unique QR Code on all print media, from business cards to flyers, and turn mere spectators into active website visitors. This strategy bolsters your PropAgile profile and enhances your online visibility through effective multichannel marketing</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/linkScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Connect your website, Increase traffic and improve SEO</h2>
                                                    <p>Boost your website's reach with PropAgile.. By incorporating your website link, you create valuable backlinks that not only drive more traffic to your site but also amplify your search engine rankings, effectively bolstering your online presence.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/promotionScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Email Promotion made simple</h2>
                                                    <p>Keep your customers updated with your work effortlessly. With a single click, instantly email your profile, services, and portfolio, ensuring your accomplishments are always at their fingertips.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <img
                                                        className="d-block w-100 home-img-details"
                                                        src="./images/shareScreen.png"
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="col-8 px-5 text-center">
                                                    <h2 className="text-center mt-0 mb-4">Social Network Promotion  made simple</h2>
                                                    <p>Maintain a dynamic presence on social networks with a single click. Instantly share updates to your portfolio and profile on Facebook, Twitter, LinkedIn, Pinterest, and WhatsApp, ensuring your work is always in the spotlight, no matter where your audience is.</p>
                                                </div>
                                            </div>

                                        </Carousel.Item>

                                    </Carousel>
                                </div>}
                        </div>


                        <div className='home-third-content row mt-5'>
                            <div className='home-tittle mb-5 w-100'>
                                <h1 className='text-center'>Assemble Trade-Specialized Construction Teams Online <br></br>and be found by customers</h1>
                            </div>
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <h2 className="text-center my-4">Connections Fuel <br></br>Opportunities in Construction</h2>
                                <p className='home-paragraph'>Work opportunities in construction often hinge on field connections, which can be constrained by available networking time. PropAgile enhances your digital reach, enabling you to join multiple construction teams, boost your visibility, and ensure you're easily found online when your expertise is needed.</p>

                            </div>
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <img
                                    className="d-block w-100"
                                    src="./images/assembleSection.png"
                                    alt="First slide"
                                />
                            </div>
                            <div className='col-12 text-center mt-3 order-3 order-md-3'>
                                <a href="#" className='btn btn-primary btn-content' style={{ backgroundColor: "#1a67a3" }}>Learn More</a>
                            </div>
                        </div>


                        <div className='home-four-content row mt-5 bg-light'>
                            <div className='home-tittle mb-5 mt-3 w-100'>
                                <h1 className='text-center'>PropAgile Account Management for Busy Builders</h1>
                            </div>

                            <div className="col-12 col-md-6 order-1 order-md-1">
                                <img
                                    className="d-block w-100"
                                    src="./images/managementSection.png"
                                    alt="First slide"
                                />
                            </div>
                            <div className="col-12 col-md-6 order-2 order-md-2">
                                <h2 className="text-center my-4">Too Busy for Marketing? <br></br>We've Got You Covered.</h2>
                                <p className='home-paragraph'>Juggling construction and marketing? Our Builder Account Manager can help. Serving as your personal marketer, they refine your profile and gather key reviews and endorsements. They skillfully craft an engaging portfolio, showcasing your work for easy sharing across networks. To top it off, we elevate your local presence with targeted local ad campaigns.</p>

                            </div>
                            <div className='col-12 col-md-12 order-3 order-md-3 text-center my-5'>
                                <a href="#" className='btn btn-primary btn-content' style={{ backgroundColor: "#1a67a3" }}>Learn More</a>
                            </div>
                        </div>

                        <div className='home-tittle my-4 text-center'>
                            <h1 className='text-center' style={{ lineHeight: "25px" }}>What success looks like <br></br><span style={{ fontSize: "20px", fontWeight: "700", color: "#1a67a3" }}>Connections, Online Reputation, Lead Generation.</span></h1>

                        </div>

                        <div className="home-five-content row justify-content-center align-items-center">
                            <div className="col-12 d-flex justify-content-center">
                                <img
                                    className="d-block img-five-content"
                                    src="./images/laborerSection.png"
                                    alt="First slide"
                                />
                            </div>
                            <div className="col-12 my-4">
                                <p className='home-paragraph'>
                                    Envision crafting your brand seamlessly - that's the power of PropAgile. Our dynamic tools amplify your online presence, converting prospects into dedicated customers, and help you build strategic connections as bridges to opportunities. By enabling the sharing of your profile, portfolio, and reviews via multichannel marketing, we ensure you're never overlooked. Steady promotion cultivates a powerful online reputation, offering you a decisive competitive advantage. With PropAgile, your success isn't just a possibility, it's an expectation.
                                </p>
                            </div>
                            <div className="col-12 text-center">
                                <a href="/vendor/subscriptions" className='btn btn-primary btn-content' style={{ backgroundColor: "#1a67a3" }}>Membership Options</a>
                            </div>


                        </div>

                        <div className='home-tittle mt-5 text-center w-100 bg-light'>
                            <h1 className='text-center' style={{ color: "#1a67a3" }}>Benefits</h1>
                        </div>
                        {isMobile ?

                            <div className='container-fluid home-six-content  px-3 py-5' style={{ backgroundColor: "#1a67a3" }}>
                                <div className='row justify-content-center'>
                                    <div className="col-12 text-center mb-3 p-3">
                                        <h5>Online visibility</h5>
                                        <div><p>Be found online when your skills are needed in your area</p></div>
                                        <span className='text-center text-light w-100'>___</span>
                                    </div>
                                    <div className="col-12 text-center mb-3 p-3">
                                        <h5>Dulti channel promotion</h5>
                                        <div ><p>Joining multiple teams will fast-track your efficiency and trade specialization.</p></div>
                                        <span className='text-center text-light w-100'>___</span>
                                    </div>
                                    <div className="col-12 text-center mb-3 p-3">
                                        <h5>Do it from your phone</h5>
                                        <div ><p>Your expertise will catch the attention of the top industry players.</p></div>
                                        <span className='text-center text-light w-100'>___</span>
                                    </div>
                                    <div className="col-12 text-center mb-3 p-3">
                                        <h5>Competitive Advantage</h5>
                                        <div ><p>Amplified connections and a solid reputation will make you a top option.</p></div>
                                        <span className='text-center text-light w-100'>___</span>
                                    </div>
                                    <div className="col-12 text-center mb-3 p-3">
                                        <h5>Work and financial stability</h5>
                                        <div ><p>Strong connections will bring you plenty of work and stability in your trade.</p></div>
                                        <span className='text-center text-light w-100'>___</span>
                                    </div>
                                </div>                              

                            </div>
                            :
                            <div className='container-fluid home-six-content  p-5 ' style={{ backgroundColor: "#1a67a3" }}>
                                <div className='row justify-content-center'>
                                    <div className="col-2"><h5>Online visibility</h5></div>
                                    <div className="col-2"><h5>Dulti channel promotion</h5></div>
                                    <div className="col-2"><h5>Do it from your phone</h5></div>
                                    <div className="col-2"><h5>Competitive Advantage</h5></div>
                                    <div className="col-2"><h5>Work and financial stability</h5></div>
                                </div>
                                <div className='row mt-3 justify-content-center'>
                                    <div className="col-2"><p>Be found online when your skills are needed in your area</p></div>
                                    <div className="col-2"><p>Joining multiple teams will fast-track your efficiency and trade specialization.</p></div>
                                    <div className="col-2"><p>Your expertise will catch the attention of the top industry players.</p></div>
                                    <div className="col-2"><p>Amplified connections and a solid reputation will make you a top option.</p></div>
                                    <div className="col-2"><p>Strong connections will bring you plenty of work and stability in your trade.</p></div>
                                </div>

                            </div>
                        }


                    </div>
                </div >
                <Box style={{ marginTop: "auto" }} className='border-0 border-md-top'>
                    <Footer />
                </Box>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        serviceProvider: state.serviceProvider.serviceProvider,
    };
};

export default connect(mapStateToProps, null)(HomeTest);