import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual';
import './index.css';

export default class Container extends Component {
  constructor(props){
    super(props)
    this.state = { data: props.data }
  }

  componentWillReceiveProps = (nextProps) => {
    if(!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data })
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data)
  }

  render() {
    const { data, ...rest } = this.props
    return (
      <DropdownTreeSelect data={this.state.data} {...rest} id="tradeInput" className='bootstrap-demo' texts={{ placeholder: 'SELECT TRADE', inlineSearchPlaceholder: "Do not find the trade? Search here" }}  inlineSearchInput={true} keepTreeOnSearch={true} showDropdown={'always'} />
    )
  }
}
