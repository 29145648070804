import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

// Components
import Loader from "../Loader/loader";
import Message from "../Message/Message";

// Constants
import { BASE_URL } from "../../Constants/serverConfig";
const successMessage = "Invite Resend Successfully";
export default function Manage(props) {
  const token = useSelector((state) => state.token.token);
  const [showLoader, setShowLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [trade, setTrade] = useState(null);
  const [project, setProject] = useState("68-19 Woodhaven Blvd. Queens");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const resendButtonDisableStyle = {
    marginLeft: "5px",
    cursor: "not-allowed",
  };

  const resendButtonEnableStyle = {
    marginLeft: "5px",
    cursor: "pointer",
  };

  const resendInvitation = (status, email, trade) => {
    if (status === "Pending") {
      setShowLoader(true);
      const user = {
        email_address: email,
        trade_name: trade,
        project_id: props.projectId,
      };
      const body = {
        receiver_list: [user],
      };
      axios
        .post(`${BASE_URL}/project/invitation`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setShowLoader(false);
          if (response.status === 200) {
            setShowLoader(false);
            setShowMessage(true);
            setMessage(successMessage);
            setEmail("");
            setProject("");
          }
        })
        .catch((error) => {
          setShowLoader(false);
          showMessage(error.response.data);
          console.error(error.response);
        });
    } else {
      setShowMessage(true);
      setMessage("Already Accepted the Invitation");
    }
  };
  const clearMessage = (disableShowInvite = false) => {
    setShowMessage(false);
    if (disableShowInvite) props.setShowInvite(false);

    setMessage("");
  };

  const deleteTeamMember = (user_id) => {
    setShowLoader(true);
    axios
      .delete(`${BASE_URL}/project/${props.projectId}/user/${user_id}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setShowLoader(false);
        if (response.status === 200) {
          setShowLoader(false);
          setShowMessage(true);
          setMessage("Deleted Successfully");
          setEmail("");
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setShowMessage(true);
        setMessage(error.response.data);
        console.error(error.response);
      });
  };

  const showInviteUserPopUp = () => {
    props.setShowInvitedUsers(false);
    props.setShowInvite(true);
  };
  return (
    <>
      <Loader text="Loading..." open={showLoader} />

      <div
        className={`modal ${
          props.showInvitedUsers ? "is-active" : ""
        } mainn-map`}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <button
            onClick={() => {
              props.setShowInvitedUsers(false);
            }}
            className="delete"
            aria-label="close"
          />
          <section className="modal-card-body home-modal">
            <div className="endos_panel2 clearfix">
              <h1>INVITED PEOPLE</h1>
              <hr />
              <div className="endorsment-form">
                <Message
                  clearMessage={clearMessage}
                  showMessage={showMessage}
                  message={message}
                />
                <section
                  className="modal-card-body"
                  style={{ paddingTop: "0" }}
                >
                  <div className="endos_panel3 clearfix">
                    <h1></h1>
                    <div className="columns">
                      <div className="column">
                        <h1>TEAM MEMBERS ADDED</h1>
                      </div>
                      <div className="column is-2">
                        <div className="form-group">
                          <button
                            style={{
                              paddingLeft: "2px",
                              paddingRight: "2px",
                            }}
                            onClick={showInviteUserPopUp}
                            className="add-btn"
                          >
                            INVITE NEW MEMBERS
                          </button>
                        </div>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th>Sr. no</th>
                          <th>Trade Name</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.invitedUsers &&
                          props.invitedUsers.map((member, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{member.trade}</td>
                                <td>{member.email}</td>
                                <td>{member.invitation_status}</td>
                                <td>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      onClick={() =>
                                        deleteTeamMember(member.id)
                                      }
                                    >
                                      <i
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-trash"
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        resendInvitation(
                                          member.invitation_status,
                                          member.email,
                                          member.trade
                                        );
                                      }}
                                    >
                                      <i
                                        style={
                                          member.invitation_status ===
                                          "Accepted"
                                            ? resendButtonDisableStyle
                                            : resendButtonEnableStyle
                                        }
                                        className="fas fa-external-link-alt"
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
