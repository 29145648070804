/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import querystring from "querystring";
import Loader from "../../Loader/loader.jsx";
import { BASE_URL } from "../../../Constants/serverConfig.jsx";

class CompanyInfomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      originalEmail: "",
      companyWebsite: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      vendorName: "",
      vendorLastName: "",
      vendorEmail: "",
      vendorContactNumber: "",
      profileStatus: "",
      showLoader: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      sameAsAbove: false,
      confirmedEmailChange: false,
      showEmailConfirmation: false,
      individual: true,
      company: false,
    };
  }

  componentDidMount() {
    const { serviceProvider } = this.props;
    if (serviceProvider !== null) {
      this.setState({
        address: serviceProvider.street_address,
        city: serviceProvider.city,
        state: serviceProvider.state,
        zipCode: `${serviceProvider.zip_code}`,
        firstName: serviceProvider.first_name,
        lastName: serviceProvider.last_name,
        email: serviceProvider.email_address,
        originalEmail: serviceProvider.email_address,
        vendorName:
          serviceProvider.vendor_name === null
            ? ""
            : serviceProvider.vendor_name,
        vendorLastName:
          serviceProvider.vendor_last_name === null
            ? ""
            : serviceProvider.vendor_last_name,
        vendorEmail:
          serviceProvider.vendor_email === null
            ? ""
            : serviceProvider.vendor_email,
        vendorContactNumber:
          serviceProvider.vendor_contact_number === null
            ? ""
            : serviceProvider.vendor_contact_number,
        companyName: serviceProvider.company_name,
        companyWebsite: serviceProvider.company_page,
        profileStatus: serviceProvider.profile_status,
      });
      if (serviceProvider.vendor_type === "Company") {
        this.setState({
          individual: false,
          company: true,
        });
      } else {
        this.setState({
          individual: true,
          company: false,
        });
      }
    }
  }

  handleChange = (event) => {
    if (event.target.name === "zipCode") {
      let zipCodeRegex = /^[0-9]{0,5}$/;
      if (zipCodeRegex.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleFileUpload = (event) => {
    const { serviceProvider, token, saveServiceProvider } = this.props;
    if (token !== null && serviceProvider !== null) {
      this.startLoader();
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      axios
        .patch(`${BASE_URL}/account/profile-image`, formData, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.stopLoader();
          serviceProvider.profile_image = response.data.profile_image;
          saveServiceProvider(serviceProvider);
          this.showSuccess("Profile picture updated successfully");
        })
        .catch((error) => {
          this.stopLoader();
          console.error(error);
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  showSuccess = (successMessage) => {
    this.setState({
      showSuccess: true,
      successMessage,
    });
  };

  clearSuccess = () => {
    const { successMessage } = this.state;
    if (successMessage === "Profile picture updated successfully") {
      window.location.reload();
    } else {
      this.setState({
        showSuccess: false,
        successMessage: "",
      });
    }
  };

  closePopup = () => {
    this.setState({
      showEmailConfirmation: false,
    });
  };

  confirmEmailChange = () => {
    this.setState({
      confirmedEmailChange: true,
      showEmailConfirmation: false,
    });
    this.updateProfile();
  };

  validatePersonalInformation = () => {
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let urlValidation = /^(www\.\/\/www\.)?[a-z0-9]+([\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let contactNumberValidation = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\\-]?[\0-9]{3}[\\-]?[0-9]{4}$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let companyNameValidation = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/;
    let zipCodeValidation = /^\d{5}$/;
    const {
      companyName,
      firstName,
      lastName,
      email,
      companyWebsite,
      address,
      city,
      state,
      zipCode,
      vendorName,
      vendorLastName,
      vendorEmail,
      vendorContactNumber,
    } = this.state;
    if (companyName.trim() === "") {
      this.showError("Please enter company name");
      return false;
    }
    if (!companyNameValidation.test(companyName.trim())) {
      this.showError(
        "Please enter a valid company name, company name should have 3 to 50 characters"
      );
      return false;
    }
    if (firstName.trim() === "") {
      this.showError("Please enter name");
      return false;
    }
    if (!nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName.trim() === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (!nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email.trim() === "") {
      this.showError("Please enter email");
      return false;
    }
    if (!emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    if (companyWebsite !== "") {
      if (!urlValidation.test(companyWebsite.trim())) {
        this.showError("Please enter a valid company website url");
        return false;
      }
    }
    if (address.trim() === "") {
      this.showError("Please enter company/vendor address");
      return false;
    }
    if (city.trim() === "") {
      this.showError("Please enter city");
      return false;
    }
    if (state.trim() === "") {
      this.showError("Please enter state");
      return false;
    }
    if (zipCode === "") {
      this.showError("Please enter zip code");
      return false;
    }
    if (!zipCodeValidation.test(zipCode)) {
      this.showError("Please enter a valid zip code");
      return false;
    }
    if (vendorName.trim() === "") {
      this.showError("Please enter vendor name");
      return false;
    }
    if (!nameValidation.test(vendorName.trim())) {
      this.showError(
        "Please enter a valid vendor name, vendor name should have 3 to 50 characters"
      );
      return false;
    }
    if (vendorLastName.trim() === "") {
      this.showError("Please enter vendor last name");
      return false;
    }
    if (!nameValidation.test(vendorLastName.trim())) {
      this.showError(
        "Please enter a valid vendor last name, vendor last name should have 3 to 50 characters"
      );
      return false;
    }
    if (vendorContactNumber.trim() === "") {
      this.showError("Please enter vendor contact number");
      return false;
    }
    if (!contactNumberValidation.test(vendorContactNumber.trim())) {
      this.showError("Please enter a valid contact number");
      return false;
    }
    if (vendorEmail.trim() === "") {
      this.showError("Please enter contact email");
      return false;
    }
    if (!emailValidation.test(vendorEmail.trim())) {
      this.showError("Please enter a valid vendor email");
      return false;
    }
    this.clearError();
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validatePersonalInformation()) {
      return;
    }
    const { serviceProvider, token } = this.props;
    if (serviceProvider !== null && token !== null) {
      const { email, originalEmail, confirmedEmailChange } = this.state;
      if (originalEmail !== email && confirmedEmailChange === false) {
        this.setState({
          showEmailConfirmation: true,
        });
      } else {
        this.updateProfile();
      }
    }
  };

  updateProfile = () => {
    const { serviceProvider, token, saveServiceProvider } = this.props;
    this.startLoader();
    const {
      companyName,
      firstName,
      lastName,
      email,
      companyWebsite,
      address,
      city,
      state,
      zipCode,
      vendorName,
      vendorLastName,
      vendorEmail,
      vendorContactNumber,
      individual,
    } = this.state;
    const body = {
      company_name: companyName.trim(),
      company_page: companyWebsite.trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email_address: email.trim(),
      username: serviceProvider.username.trim(),
      state: state.trim(),
      city: city.trim(),
      zip_code: zipCode.trim(),
      street_address: address.trim(),
      vendor_name: vendorName.trim(),
      vendor_last_name: vendorLastName.trim(),
      vendor_email: vendorEmail.trim(),
      vendor_contact_number: vendorContactNumber.trim(),
    };
    if (
      serviceProvider !== null &&
      (serviceProvider.membership_information.membership_name.toLowerCase() ===
        "standard" ||
        serviceProvider.membership_information.membership_name.toLowerCase() ===
          "trial")
    ) {
      body.vendor_type = individual ? "Individual" : "Company";
    }
    axios
      .patch(`${BASE_URL}/account/profile`, querystring.stringify(body), {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          let updatedServiceProvider = Object.assign({}, response.data);
          updatedServiceProvider.membership_information.additional_method_remaining =
            serviceProvider.membership_information.additional_method_remaining;
          updatedServiceProvider.membership_information.max_size_project_allowed =
            serviceProvider.membership_information.max_size_project_allowed;
          updatedServiceProvider.membership_information.message =
            serviceProvider.membership_information.message;
          updatedServiceProvider.vendor_type = individual
            ? "Individual"
            : "Company";
          this.showSuccess(
            "Your profile information has been updated successfully"
          );
          saveServiceProvider(updatedServiceProvider);
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
        this.setState({
          confirmedEmailChange: false,
          showEmailConfirmation: false,
        });
      });
  };

  /*   setSameAsAccountOwner = (sameAsAbove) => {
    if (!sameAsAbove) {
      this.setState({
        vendorName: this.state.firstName,
        vendorLastName: this.state.lastName,
        vendorEmail: this.state.email,
        sameAsAbove: true,
      });
    } else {
      this.setState({
        vendorName: "",
        vendorLastName: "",
        vendorEmail: "",
        sameAsAbove: false,
      });
    }
  }; */

  render() {
    const { serviceProvider } = this.props;
    const {
      companyName,
      firstName,
      lastName,
      email,
      companyWebsite,
      address,
      city,
      state,
      zipCode,
      vendorName,
      vendorLastName,
      vendorContactNumber,
      vendorEmail,
      profileStatus,
      showLoader,
      showError,
      individual,
      company,
      errorMessage,
      showEmailConfirmation,
      sameAsAbove,
      showSuccess,
      successMessage,
    } = this.state;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {showSuccess && (
          <div className="modal is-active custom-modal success-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="success-message-modal">
                <button
                  onClick={this.clearSuccess}
                  className="modal-close"
                ></button>
                <p>{successMessage}</p>
                <a onClick={this.clearSuccess} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        <div
          className={`modal small-popup ${
            showEmailConfirmation ? "is-active" : ""
          }`}
        >
          <div className="modal-background"></div>
          <div className="modal-card ">
            <button
              onClick={this.closePopup}
              className="delete"
              aria-label="close"
            ></button>
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <h1>Are you sure to change your email?</h1>
                <p>
                  A notification will be sent to <strong> {email}</strong>,
                  please click on the link provided to confirm this update.
                </p>
                <div className="map-buton">
                  <button
                    onClick={this.confirmEmailChange}
                    className="btn-fill"
                  >
                    Yes
                  </button>
                  <button onClick={this.closePopup} className="btn-empty">
                    No
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        {profileStatus === "incomplete" && (
          <React.Fragment>
            <div className="notification is-warning text">
              Your personal information is incomplete, please update your
              profile.
            </div>
            <div className="clearfix"></div>
            <div className="clearfix"></div>
            <br />
          </React.Fragment>
        )}

        <form onSubmit={this.handleSubmit}>
          <div className="pro-info clearfix">
            <div className="pro-pic visible-767">
              <div className="dashboard-pic is-280x280">
                <figure
                  style={{
                    backgroundImage: `url(${
                      serviceProvider !== null
                        ? serviceProvider.profile_image[0]
                        : ""
                    })`,
                  }}
                ></figure>
                <div className="image">
                  <div className="pic-btn">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="profile-picture-mobile"
                      name="profile-picture"
                      type="file"
                      onChange={this.handleFileUpload}
                    />
                    <label htmlFor="profile-picture-mobile">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="row">
              <div className="form-group divide-2">
                <label>Company Name</label>
                <input
                  type="text"
                  value={companyName}
                  name="companyName"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-2">
                <label>Company Website</label>
                <input
                  type="text"
                  value={companyWebsite}
                  name="companyWebsite"
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="row">
              <div className="form-group divide-2">
                <label>Account Owner Name</label>
                <input
                  type="text"
                  value={firstName}
                  name="firstName"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-2">
                <label>Account Owner Last Name</label>
                <input
                  type="text"
                  value={lastName}
                  name="lastName"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="row">
              <div className="form-group divide-2">
                <label>Account Owner Email</label>
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-2">
                <label>Membership</label>
                <div className="columns" style={{ marginLeft: 0 }}>
                  <div className="column is-6" style={{ padding: 0 }}>
                    <input
                      type="text"
                      className="form-control"
                      disabled="disabled"
                      value={
                        serviceProvider !== null
                          ? serviceProvider.membership_information
                              .membership_name !== undefined
                            ? serviceProvider.membership_information
                                .membership_name
                            : "You do not have a membership account"
                          : ""
                      }
                      name="membership"
                    />
                  </div>
                  <div className="column is-6" style={{ padding: 0 }}>
                    <button
                      className="pf-submit"
                      type="button"
                      // disabled={
                      //   serviceProvider.membership_information.membership_id ===
                      //   3
                      // }
                      onClick={() => {
                        this.props.history.push("/vendor/subscription-upgrade");
                      }}
                      style={{
                        margin: 0,
                        height: 46,
                        width: "100px",
                        backgroundColor:
                          serviceProvider.membership_information
                            .membership_id === 3
                            ? "rgba(26, 103, 163, 0.7)" /* "rgba(54, 51, 119, 0.7)" */
                            : "rgb(26, 103, 163)" /* "rgb(54, 51, 119)" */,
                      }}
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="form-group">
              <label>Company/Vendor Address</label>
              <input
                type="text"
                value={address}
                name="address"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="pro-pic hidden-767">
            <div className="dashboard-pic is-280x280">
              <figure
                style={{
                  backgroundImage: `url(${
                    serviceProvider !== null
                      ? serviceProvider.profile_image[0]
                      : ""
                  })`,
                }}
              ></figure>
              <div className="image">
                <div className="pic-btn">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="profile-picture-desktop"
                    name="profile-picture"
                    type="file"
                    onChange={this.handleFileUpload}
                  />
                  <label htmlFor="profile-picture-desktop">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="new-row clearfix">
            <div className="form-group divide-3">
              <label>City</label>
              <input
                type="text"
                value={city}
                name="city"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group divide-3">
              <label>State</label>
              <input
                type="text"
                value={state}
                name="state"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group divide-3">
              <label>Zip Code</label>
              <input
                type="text"
                value={zipCode}
                name="zipCode"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
          </div>
          {serviceProvider !== null &&
            (serviceProvider.membership_information.membership_name.toLowerCase() ===
              "standard" ||
              serviceProvider.membership_information.membership_name.toLowerCase() ===
                "trial") && (
              <React.Fragment>
                <div className="clearfix"></div>
                <div className="new-row clearfix">
                  <div className="form-group divide-3">
                    <label>Type of provider</label>
                    <div className="columns">
                      <div className="column is-6">
                        <div
                          onClick={() => {
                            this.setState({
                              individual: !individual,
                              company: false,
                            });
                          }}
                          className="checkbox"
                        >
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <input
                              checked={individual}
                              onChange={() => {}}
                              type="checkbox"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            />
                            <span className="checkmark"></span>Individual
                          </label>
                        </div>
                      </div>
                      <div className="column is-6">
                        <div
                          onClick={() => {
                            this.setState({
                              individual: false,
                              company: !company,
                            });
                          }}
                          className="checkbox"
                        >
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <input
                              checked={company}
                              onChange={() => {}}
                              type="checkbox"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            />
                            <span className="checkmark"></span>Company
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          <div className="clearfix"></div>
          <div className="new-row clearfix">
            <div className="form-group divide-3">
              <p className="title is-4" style={{ color: "#4a4a4a" }}>
                Vendor Details
              </p>
            </div>
          </div>
          <div className="clearfix"></div>
          {/* <div className="new-row clearfix">
            <div className="form-group divide-3">
              <div
                onClick={() => this.setSameAsAccountOwner(sameAsAbove)}
                className="checkbox"
              >
                <label
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <input
                    onChange={() => {}}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    checked={sameAsAbove}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>Same as account owner
                </label>
              </div>
            </div>
          </div> */}
          <div className="clearfix"></div>
          <div className="new-row clearfix">
            <div className="form-group divide-3">
              <label>Vendor Name</label>
              <input
                type="text"
                value={vendorName}
                name="vendorName"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group divide-3">
              <label>Vendor Last Name</label>
              <input
                type="text"
                value={vendorLastName}
                name="vendorLastName"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group divide-3">
              <label>Vendor Contact Number</label>
              <input
                type="text"
                value={vendorContactNumber}
                name="vendorContactNumber"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="new-row clearfix">
            <div className="form-group divide-3">
              <label>Vendor Email</label>
              <input
                type="email"
                value={vendorEmail}
                name="vendorEmail"
                onChange={this.handleChange}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="prof-button">
            <button className="pf-submit">Submit</button>
          </div>
        </form>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withRouter(CompanyInfomation);
