import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Header from "../Header/header";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

class CustomerThanks extends Component {
  render() {
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        {/* {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header isDark={true} />
        ) : (
          <NewHeader />
        )} */}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <section className="form-wrapper">
          <div className="form-inner">
            <div className="form-main thankyou-main">
              <div className="thankyou-title">
                <h3>Thank You</h3>
                <div className="tick-icon">
                  <i className="fa fa-check"></i>
                </div>
                <p>
                  Thank you for trusting PropAGILE, We will get back to you
                  shortly.
                </p>
                <div className="digital-upgrate-btn center-align-content">
                  <Link to="/" className="digital-btn">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

export default withRouter(CustomerThanks);
