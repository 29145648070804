/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import querystring from "querystring";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import axios from "axios";
import StandardFeatures from "./StandardFeatures.jsx";
import BusinessFeatures from "./BusinessFeatures.jsx";
import FreeFeatures from "./FreeFeatures.jsx";
import NewHeader from "../Header/NewHeader.jsx";
import NewFooter from "../Footer/NewFooter.jsx";

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metroArea: "",
      paymentType: "annual",
      showPopup: false,
      showCouponPopup: false,
      couponAvailable: "",
      termsAccepted: false,
      showTerms: true,
      couponCode: "",
      freeSubscription: {},
      standardSubscription: {},
      businessSubscription: {},
    };
  }

  componentDidMount() {
    const { isSpLoggedIn } = this.props;
    if (isSpLoggedIn === true) {
      this.props.history.push("/");
    }
    const { search } = this.props.history.location;
    const metroArea = querystring.parse(search)["?metro"];
    this.setState({
      metroArea: metroArea ? metroArea : "NYMA",
    });
    let elements = document.getElementsByClassName("top_part_same_height");
    this.equalHeight(elements);
    elements = document.getElementsByClassName("pricing-list");
    this.equalHeight(elements);
    window.scrollTo(0, 0);
    const script = document.createElement("script");
    script.id = "page-sense";
    script.src =
      "https://cdn.pagesense.io/js/propagile/a104ce787de64435be353cddd7e6b745.js";
    script.async = true;
    document.head.appendChild(script);

    axios
      .get(`https://api.propagile.com/api/v1/membership/membership`)
      .then((res) => {
        res.data.map((item) => {
          if (item.name === "Free") {
            this.setState({ freeSubscription: item });
          } else if (item.name === "Standard") {
            this.setState({ standardSubscription: item });
          } else if (item.name === "Business") {
            this.setState({ businessSubscription: item });
          }
        });
      })
      .catch((err) => {
        console.log("Error while fetching subscriptions Amount: ", err);
      });
  }

  componentWillUnmount() {
    const script = document.getElementById("page-sense");
    document.head.removeChild(script);
  }

  equalHeight = (group) => {
    let tallest = 0;
    for (let index = 0; index < group.length; index++) {
      let element = group[index];
      let thisHeight = element.offsetHeight;
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    }
    for (let index = 0; index < group.length; index++) {
      let element = group[index];
      element.style.height = `${tallest}px`;
    }
  };

  setPaymentType = () => {
    if (this.state.paymentType === "annual") {
      this.setState({
        paymentType: "monthly",
      });
    } else {
      this.setState({
        paymentType: "annual",
      });
    }
  };

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  showCouponPopup = () => {
    this.setState({
      showCouponPopup: true,
    });
  };

  closeCouponPopup = () => {
    this.setState({
      showCouponPopup: false,
    });
  };

  acceptTerms = () => {
    this.setState({
      termsAccepted: !this.state.termsAccepted,
      showTerms: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      paymentType,
      metroArea,
      showPopup,
      /* showCouponPopup,
      couponAvailable,
      termsAccepted,
      couponCode,
      freeSubscription, */
      standardSubscription,
      businessSubscription,
    } = this.state;
    const {
      isUserLoggedIn,
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        <div
          className={`${
            showPopup === true ? "modal mainn-map is-active" : "modal mainn-map"
          }`}
        >
          <div className="modal-background" />
          <div className="modal-card p_m_card">
            <button
              onClick={this.togglePopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <table>
                  <thead>
                    <tr>
                      <th>Line of services</th>
                      <th>Services</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="servic_blue">
                        {""}
                        Electrical Install/Repair
                      </td>
                      <td className="servic_orange">_Wiring & Rewiring</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Electrical Install/Repair</td>
                      <td className="servic_orange">_Service Panels</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Specialty inspections</td>
                      <td className="servic_orange">_Asbestos</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Specialty inspections</td>
                      <td className="servic_orange">_Mold</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">General Removal/Disposal</td>
                      <td className="servic_orange">_Hauling & Containers</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">General Removal/Disposal</td>
                      <td className="servic_orange">_Flooring & Carpets</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">
                        Finish carpentry Install/Repair
                      </td>
                      <td className="servic_orange">_Kitchen Cabinetry</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">
                        Finish carpentry Install/Repair
                      </td>
                      <td className="servic_orange">_Custom Millwork</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Plumbing Install/Repair</td>
                      <td className="servic_orange">_Leak Detection</td>
                    </tr>

                    <tr>
                      <td className="servic_blue">Plumbing Install/Repair</td>
                      <td className="servic_orange">_Gas Repair</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
        {/* ticker # 724 */}
        {/* <div
          className={`modal mainn-map ${showCouponPopup ? "is-active" : ""}`}
        >
          <div className="modal-background" />
          <div
            className="modal-card"
            style={{ minHeight: "60vh", width: "50%" }}
          >
            <button
              onClick={this.closeCouponPopup}
              className="delete"
              aria-label="close"
            />
            <section className="modal-card-body home-modal">
              <div className="endos_panel2 clearfix">
                <br />
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    <p className="title">Welcome to Coupon Invitations!</p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-offset-1 is-5">
                    <div
                      onClick={() => {
                        this.setState({
                          couponAvailable: "yes",
                        });
                      }}
                      className="checkkk clearfix"
                    >
                      <div
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        className="checkbox"
                      >
                        <label
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                          className="checkbox"
                        >
                          <input
                            onChange={() => {}}
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                            checked={couponAvailable === "yes"}
                            type="checkbox"
                          />
                          <span className="checkmark" />
                          Enter invitation coupon
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="column is-5">
                    <div
                      onClick={() => {
                        this.setState({
                          couponAvailable: "no",
                        });
                        setTimeout(() => {
                          this.props.history.push(
                            `/vendor/sign-up?metro=${metroArea}&subsription=free-trial-membership`
                          );
                        }, 3000);
                      }}
                      className="checkkk clearfix"
                    >
                      <div
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        className="checkbox"
                      >
                        <label
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                          className="checkbox"
                        >
                          <input
                            onChange={() => {}}
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                            checked={couponAvailable === "no"}
                            type="checkbox"
                          />
                          <span className="checkmark" />I do not have an
                          invitation coupon
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {couponAvailable !== "" && (
                  <div className="columns">
                    <div className="column is-10 is-offset-1">
                      {couponAvailable === "no" && (
                        <>
                          <p className="title is-5">
                            We will enable a 15 days trial
                          </p>
                          <img
                            alt="Loader"
                            src={require("../../assets/images/loader.gif")}
                          />
                          <p>Loading...</p>
                        </>
                      )}
                      {couponAvailable === "yes" && (
                        <>
                          <div className="form-group">
                            <label>Enter Invitation Coupon</label>
                            <input
                              type="text"
                              required={false}
                              name="couponCode"
                              value={couponCode}
                              onChange={this.handleChange}
                              className="form-control"
                            />
                          </div>
                          <br />
                          <div className="terms">
                            <h4
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.setState({
                                  showTerms: true,
                                });
                              }}
                            >
                              <i>Terms & conditions</i>
                            </h4>
                            <p>
                              You must read all terms and conditions below
                              before continuing with signup. * All (PropAgile)
                              Members are subject to the following policies,
                              including the Member Code of Conduct and all
                              (PropAgile) Bylaws, located at:
                              http://www.propagile.com. By clicking "I accept"
                              below you are indicating you have read and agree
                              to these (PropAgile) policies. You may also choose
                              to decline (PropAgile) membership by hitting back
                              or closing your browser, at which point you will
                              leave the signup process.
                            </p>
                          </div>
                          <div onClick={this.acceptTerms} className="checkbox">
                            <label
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            >
                              <input
                                checked={termsAccepted}
                                onChange={() => {}}
                                type="checkbox"
                                onClick={(event) => {
                                  event.preventDefault();
                                }}
                              />
                              <span className="checkmark" />I Accept
                            </label>
                          </div>
                          <div className="signup-btns">
                            <button
                              className="signup-btn"
                              style={{ width: "100%" }}
                              type="button"
                              onClick={() => {
                                if (termsAccepted && couponCode !== "") {
                                  this.props.history.push(
                                    `/vendor/sign-up?metro=${metroArea}&subsription=free-trial-membership&coupon_code=${couponCode}`
                                  );
                                }
                              }}
                            >
                              Accept
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div> */}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <div className="section pckg_new_head">
          <div className="container container-fluid">
            <h1>Connect With More Customers In More Ways!</h1>
            <p style={{ color: "#fcc75c" /* #e99709 */ }}>
              We made it easy to promote services everywhere in minutes...{" "}
              <span>You will not need to hire anyone!</span>
            </p>
            {/*  <h1>
              Increase Job Opportunities
              <span>in Property Renovation Projects </span>
            </h1>
            <p>
              <span>NO NEED FOR EXPERTS, </span> Promote your services online in
              minutes.{" "}
              <Link to="/profile/edit">(See your own sample profile)</Link>
            </p> */}
          </div>
        </div>
        <section id="pricing" className="pricing-area pt-115">
          <div className="p_contain clearfix">
            {/* <h4>Phase 1: Interior construction in NYC tri-state area</h4>
             */}
            <p className="mem_op_h">Membership options</p>

            <div className="p_basic_price first_pckg">
              <div className="single-pricing text-center pricing-color-1">
                <div className="top_part_same_height">
                  <div className="pricing-title mt-20">
                    <h4 className="title">Free</h4>
                  </div>
                  <div className="pricing-price price-after">
                    <h1>No credit card required </h1>
                  </div>
                  <div>
                    <Link
                      className="btn-join"
                      to={`/vendor/sign-up?metro=${metroArea}&subsription=free`}
                      onClick={() => {
                        window.pagesense = window.pagesense || [];
                        window.pagesense.push([
                          "trackEvent",
                          "free membership button",
                        ]);
                      }}
                    >
                      Join Now
                    </Link>
                  </div>
                </div>
                <FreeFeatures />
              </div>
            </div>
            <div className="p_basic_price p_standard_price second_pckg">
              <div className="single-pricing text-center pricing-active pricing-color-2">
                <div className="top_part_same_height">
                  <div className="pricing-title mt-20">
                    <div className="hot">hot</div>
                    <h4 className="title highlight_title">
                      Standard <span>(Free 15-Day Trial)</span>
                    </h4>
                  </div>
                  <div className="pricing-price  price-before">
                    <h2>
                      <span className="price" id="change1">
                        {paymentType === "annual" && (
                          <React.Fragment>
                            ${standardSubscription.annually}/<span>Yr</span>.
                          </React.Fragment>
                        )}
                        {paymentType === "monthly" && (
                          <React.Fragment>
                            ${standardSubscription.monthly}/<span>Mo</span>.
                          </React.Fragment>
                        )}
                      </span>
                    </h2>
                  </div>
                  <div className="changeer">
                    <span
                      className={
                        paymentType === "monthly"
                          ? "chang_text_2"
                          : "chang_text"
                      }
                    >
                      Per month
                    </span>
                    <label id="switch" className="switch">
                      <input
                        className="input"
                        type="checkbox"
                        onClick={() => this.setPaymentType()}
                        onChange={() => {}}
                        checked={paymentType === "annual"}
                      />
                      <span className="slider round" />
                    </label>
                    <span
                      className={
                        paymentType === "annual" ? "chang_text_2" : "chang_text"
                      }
                    >
                      Per Year
                    </span>
                  </div>
                  <div className="pricing-btn top_margin">
                    <p>&nbsp;</p>
                    <Link
                      className="btn-join"
                      to={
                        /* ticket # 785 - Requires to change standard-pre-launch-annual to standard-annual */
                        paymentType === "annual"
                          ? `/vendor/sign-up?metro=${metroArea}&subsription=standard-annual`
                          : `/vendor/sign-up?metro=${metroArea}&subsription=standard-monthly`
                      }
                      onClick={() => {
                        window.pagesense = window.pagesense || [];
                        window.pagesense.push([
                          "trackEvent",
                          "business membership button",
                        ]);
                      }}
                    >
                      Join Now
                    </Link>
                  </div>
                </div>
                <StandardFeatures
                  metroArea={metroArea}
                  paymentType={paymentType}
                  isSubscriptionUpgrade={false}
                />
              </div>
            </div>
            <div className="p_basic_price last_pckg">
              <div className="single-pricing text-center pricing-color-3">
                <div className="top_part_same_height">
                  <div className="pricing-title mt-20">
                    <h4 className="title">Business</h4>
                  </div>
                  <div className="pricing-price  price-before">
                    <h2>
                      <span className="price" id="change1">
                        {paymentType === "annual" && (
                          <React.Fragment>
                            ${businessSubscription.annually}/<span>Yr</span>.
                          </React.Fragment>
                        )}
                        {paymentType === "monthly" && (
                          <React.Fragment>
                            ${businessSubscription.monthly}/<span>Mo</span>.
                          </React.Fragment>
                        )}
                      </span>
                    </h2>
                  </div>
                  <div className="changeer">
                    <span
                      className={
                        paymentType === "monthly"
                          ? "chang_text_2"
                          : "chang_text"
                      }
                    >
                      Per month
                    </span>
                    <label id="switch" className="switch">
                      <input
                        className="input"
                        type="checkbox"
                        onClick={() => this.setPaymentType()}
                        onChange={() => {}}
                        checked={paymentType === "annual"}
                      />
                      <span className="slider round" />
                    </label>
                    <span
                      className={
                        paymentType === "annual" ? "chang_text_2" : "chang_text"
                      }
                    >
                      Per Year
                    </span>
                  </div>
                  <div className="top_margin">
                    <p>&nbsp;</p>
                    <Link
                      className="btn-join"
                      to={
                        /* ticket # 785 - Requires to change business-pre-launch-annual to business-annual */
                        paymentType === "annual"
                          ? `/vendor/sign-up?metro=${metroArea}&subsription=business-annual`
                          : `/vendor/sign-up?metro=${metroArea}&subsription=business-monthly`
                      }
                      onClick={() => {
                        window.pagesense = window.pagesense || [];
                        window.pagesense.push([
                          "trackEvent",
                          "business membership button",
                        ]);
                      }}
                    >
                      Join Now
                    </Link>
                  </div>
                </div>
                <BusinessFeatures />
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

export default withRouter(Subscriptions);
