/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import ReactMapboxGl, { GeoJSONLayer } from "react-mapbox-gl";
import { withRouter, Link } from "react-router-dom";
import querystring from "querystring";
//import SupplyChainNewDesign from "../SupplyChain/supplyChainNewDesign.jsx";
import Loader from "../Loader/loader.jsx";
import { BASE_URL } from "../../Constants/serverConfig.jsx";
import MetroAreaPicker from "./components/metroAreaPicker.jsx";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});

const style = "mapbox://styles/muhammad-asad-266/cjus8d2q83bsf1gqw8dbriqvm";

const mapStyle = {
  height: "100%",
  width: "100%",
};

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      metroAreas: [],
      mapToView: null,
      editMapIndex: -1,
      potentialHardSkills: [],
      center: [],
      zipCodes: null,
      selectedZipCode: [],
      selectedPloygons: [],
      selectedMap: -1,
      currentStep: 0,
      maxSteps: 1,
      selectedMetroArea: "",
      showMap: false,
      size: "",
      articleWriting: false,
      qa: false,
      workInTeam: false,
      showLoader: false,
      showError: false,
      showSignup: false,
      maxAllowedPotentialService: 1,
      hasSignedUp: false,
      errorMessage: "",
      selectedMembership: "",
    };
    this.supplyChain = React.createRef();
  }

  componentDidMount() {
    const { isSpLoggedIn } = this.props;
    if (isSpLoggedIn === true) {
      this.props.history.push("/");
    }
    const { search } = this.props.history.location;
    if (search.includes("step=2")) {
      let params = querystring.parse(search);
      this.setState({
        currentStep: 1,
        selectedMetroArea: querystring.parse(search).metro,
        selectedMembership: params["?membership"],
      });
    } else {
      let params = querystring.parse(search);
      this.setState({
        currentStep: 0,
        selectedMetroArea: "",
        selectedMembership: params["?membership"],
      });
    }
    axios
      .get(`${BASE_URL}/metropolitan-area`)
      .then((response) => {
        this.setState({
          metroAreas: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  nextStep = () => {
    const { metroAreas, selectedMetroArea, currentStep, maxSteps } = this.state;
    if (currentStep < maxSteps) {
      if (currentStep === 0) {
        if (this.validateMetroAreaSelected()) {
          const metroAreaInfo = metroAreas.find(
            (x) => x.code === selectedMetroArea
          );
          if (metroAreaInfo) {
            this.setState({
              currentStep: Number(currentStep) + 1,
            });
            // if (metroAreaInfo.status === "Launched") {
            //   this.props.history.push("/home");
            // }
            // if (metroAreaInfo.status === "Under-Launch") {
            //   this.props.history.push(
            //     `/vendor/subscriptions?metro=${selectedMetroArea}`
            //   );
            // }
            // if (metroAreaInfo.status === "Prospective") {
            //   this.setState({
            //     currentStep: Number(currentStep) + 1
            //   });
            // }
          }
        }
      }
    }
  };

  previousStep = () => {
    const { currentStep } = this.state;
    if (currentStep > 0) {
      this.setState({
        currentStep: Number(currentStep) - 1,
      });
    }
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  closePopup = () => {
    this.setState({
      showMap: false,
      mapToView: null,
      editMapIndex: -1,
      showSignup: false,
    });
  };

  validateMetroAreaSelected = () => {
    const { selectedMetroArea } = this.state;
    if (selectedMetroArea === "") {
      this.showError("Please choose a metropolitan area");
      return false;
    }
    this.clearError();
    return true;
  };

  handleCheckboxClick = (name) => {
    const { selectedMetroArea } = this.state;
    if (selectedMetroArea === name) {
      this.setState({
        selectedMetroArea: "",
      });
    } else {
      this.setState({
        selectedMetroArea: name,
        zipCodes: null,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClick = (map, event) => {
    const features = map.queryRenderedFeatures(event.point);
    if (
      features[0] !== undefined &&
      features[0].properties !== undefined &&
      features[0].properties.zipCode !== undefined
    ) {
      const { selectedZipCode, selectedPloygons, zipCodes } = this.state;
      const index = selectedZipCode.findIndex(
        (zipCode) => zipCode === features[0].properties.zipCode
      );
      if (index === -1) {
        const rawZipCodes = zipCodes.features.find(
          (feature) =>
            feature.properties.zipCode === features[0].properties.zipCode
        );
        var myGeoJSON = {};
        myGeoJSON.type = "FeatureCollection";
        myGeoJSON.features = [rawZipCodes];
        this.setState({
          selectedPloygons: [...selectedPloygons, myGeoJSON],
          selectedZipCode: [...selectedZipCode, features[0].properties.zipCode],
        });
      } else {
        var array1 = [...selectedPloygons];
        var array2 = [...selectedZipCode];
        array1.splice(index, 1);
        array2.splice(index, 1);
        this.setState({ selectedPloygons: array1, selectedZipCode: array2 });
      }
    }
  };

  handleFilesUpload = (event) => {
    if (event.target.files.length > 6) {
      this.setState({
        [event.target.name]: [...event.target.files].slice(0, 6),
        showError: true,
        errorMessage: "Cannot upload more than 6 pictures",
      });
    } else {
      this.setState({
        [event.target.name]: [...event.target.files],
      });
    }
  };

  handleSubmit = () => {
    const { selectedMembership, selectedMetroArea } = this.state;
    if (selectedMembership === undefined) {
      this.props.history.push("/vendor/subscriptions");
    } else {
      this.props.history.push(
        `/vendor/sign-up?metro=${selectedMetroArea}&subsription=${selectedMembership}`
      );
    }
  };

  removePotentialHardSkill = (index) => {
    const { potentialHardSkills } = this.state;
    potentialHardSkills.splice(index, 1);
    this.setState({
      potentialHardSkills: [...potentialHardSkills],
    });
  };

  removePicture = (index) => {
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({
      files: [...files],
    });
  };

  showMap = () => {
    const { zipCodes, selectedMetroArea } = this.state;
    if (zipCodes === null) {
      this.startLoader();
      let url = `${BASE_URL}/zip-code/metropolitan-area/${selectedMetroArea}/county`;
      axios
        .get(url)
        .then((response) => {
          const point =
            response.data.features[0].geometry.geometries[0].coordinates;
          this.setState({
            center: point,
            zipCodes: response.data,
            showMap: true,
          });
          this.stopLoader();
        })
        .catch((error) => {
          console.error(error);
          this.stopLoader();
        });
    } else {
      this.setState({
        showMap: true,
      });
    }
  };

  saveService = () => {
    const {
      size,
      center,
      zipCodes,
      selectedZipCode,
      selectedPloygons,
      potentialHardSkills,
    } = this.state;
    const {
      selectedSegment,
      selectedSubSegment,
      selectedHardSkill,
      selectedMethod,
      selectedExperience,
    } = this.supplyChain.current.state;
    if (potentialHardSkills.length >= 1) {
      this.showError(
        "Cannot add more than 1 service. Please sign up to add more."
      );
      return;
    }
    if (selectedExperience === undefined || selectedExperience === null) {
      this.showError("Please choose a service.");
      return;
    }
    if (selectedZipCode.length === 0) {
      this.showError(
        "Please pick some zip codes at which you provide this service"
      );
      return;
    }
    if (size === "") {
      this.showError(
        "Please enter max project size which you can handle for selected service"
      );
      return;
    }
    let zip_code = this.getFormattedZipCodesArray(selectedZipCode);
    const selected_service = this.getSelectedService(
      selectedSegment,
      selectedSubSegment,
      selectedHardSkill,
      selectedMethod,
      selectedExperience,
      zip_code,
      size
    );
    const potentialService = {
      segment_id: selectedSegment.id,
      sub_segment_id: selectedSubSegment.id,
      hard_skill_id: selectedHardSkill.id,
      method_id: selectedMethod.id,
      method_detail_id: selectedExperience.id,
      size_handle: size,
      zip_code,
      selected_service,
    };
    this.supplyChain.current.clearSelections();
    this.setState({
      potentialHardSkills: [
        ...potentialHardSkills,
        {
          map: {
            center,
            zipCodes,
            selectedZipCode,
            selectedPloygons,
          },
          potentialService,
        },
      ],
      selectedZipCode: [],
      selectedPloygons: [],
      size: "",
      selectedMap: -1,
    });
    this.setState({
      showSignup: true,
    });
  };

  selectMap = (index, map) => {
    const { selectedMap } = this.state;
    if (selectedMap === index) {
      this.setState({
        selectedMap: -1,
        selectedZipCode: [],
        selectedPloygons: [],
      });
    } else {
      this.setState({
        selectedMap: index,
        selectedZipCode: [...map.selectedZipCode],
        selectedPloygons: [...map.selectedPloygons],
      });
    }
  };

  viewMap = (map) => {
    this.setState({
      mapToView: map,
    });
  };

  editMap = (index, map) => {
    this.setState({
      selectedZipCode: [...map.selectedZipCode],
      selectedPloygons: [...map.selectedPloygons],
      center: map.center,
      editMapIndex: index,
    });
  };

  updateMap = () => {
    const {
      editMapIndex,
      center,
      zipCodes,
      selectedZipCode,
      selectedPloygons,
      potentialHardSkills,
    } = this.state;
    if (selectedZipCode.length === 0) {
      this.showError("Cannot update map because no zip code is selected");
      return;
    }
    const map = {
      center,
      zipCodes,
      selectedZipCode,
      selectedPloygons,
    };
    let zip_code = this.getFormattedZipCodesArray(selectedZipCode);
    potentialHardSkills[editMapIndex].map = map;
    potentialHardSkills[editMapIndex].potentialService.zip_code = zip_code;
    potentialHardSkills[
      editMapIndex
    ].potentialService.selected_service.Segments[0].SubSegments[0].HardSkills[0].Methods[0].MethodDetails[0].ZipCodes = zip_code;
    this.setState({
      potentialHardSkills: [...potentialHardSkills],
      editMapIndex: -1,
      selectedZipCode: [],
      selectedPloygons: [],
    });
  };

  getFormattedZipCodesArray(selectedZipCode) {
    let zip_code = [];
    selectedZipCode.forEach((zipCode) => {
      zip_code.push({ zip_code: zipCode });
    });
    return zip_code;
  }

  showSignupPopup = () => {
    this.setState({
      showSignup: true,
    });
  };

  getSelectedService(
    selectedSegment,
    selectedSubSegment,
    selectedHardSkill,
    selectedMethod,
    selectedExperience,
    zip_code,
    size
  ) {
    return {
      Segments: [
        {
          id: selectedSegment.id,
          name: selectedSegment.value,
          SubSegments: [
            {
              id: selectedSubSegment.id,
              name: selectedSubSegment.value,
              HardSkills: [
                {
                  id: selectedHardSkill.id,
                  name: selectedHardSkill.value,
                  Methods: [
                    {
                      id: selectedMethod.id,
                      name: selectedMethod.value,
                      MethodDetails: [
                        {
                          id: selectedExperience.id,
                          name: selectedExperience.value,
                          ZipCodes: zip_code,
                          size_handle: size,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  }

  renderFormC1() {
    const { selectedMetroArea } = this.state;
    let metroAreaArray = [selectedMetroArea];
    return (
      <React.Fragment>
        <h2>
          Be found online without expending the thousands needed to be listed in
          the first page of Google
        </h2>
        <h3>
          Tell us the services you good at and we will connect you with the
          customers in your area who are looking for the services
        </h3>
        <div className="clearfix"></div>
        <div className="metropolitan-area">
          <h4 className="area-title">Select your Metropolitan Area:</h4>
          <MetroAreaPicker
            handleCheckboxClick={this.handleCheckboxClick}
            selectedMetroAreas={metroAreaArray}
          />
          <div className="next-btn">
            <button onClick={this.nextStep} className="next-butn">
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFormC2() {
    const { files, selectedMembership } = this.state;
    return (
      <React.Fragment>
        <div className="clearfix"></div>
        <h2>Sign up now to add upto 5 potential services from dashboard</h2>
        <h3>
          Select (UP TO 1) services you are best known for and the zip codes
          where you can provide services.
        </h3>
        <div className="clearfix"></div>
        {/* <SupplyChainNewDesign ref={this.supplyChain} /> */}
        <div className="clearfix"></div>
        <div className="upload-related">
          <p>
            Upload related portfolio: (Make it visual, show your work to gain
            more interest from customers)
          </p>
          <div className="upload-main">
            <div className="file-upload">
              <span>
                <i className="fa fa-upload"></i>
              </span>
              <p>Choose a file...</p>
              <input
                multiple={true}
                type="file"
                onChange={this.handleFilesUpload}
                name="files"
                accept="image/jpeg,image/gif,image/png"
              />
            </div>
            <div>
              {files.map((file, index) => (
                <div key={index} className="upload-img-view">
                  <span>
                    <img
                      className="image is-196x196"
                      src={URL.createObjectURL(file)}
                      alt=""
                    />
                  </span>
                  <a
                    className="remove-image"
                    onClick={() => this.removePicture(index)}
                  >
                    X
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="zipcode">
          <p>Zip Codes Of Coverage For This Service:</p>
          <button onClick={this.showMap} type="button" className="map-btn">
            Map
          </button>
        </div>
        <div className="clearfix"></div>
        <div className="sub-button">
          <button onClick={this.handleSubmit} className="try-btn">
            {selectedMembership === undefined
              ? "Go back to subscriptions"
              : "Go back to sign up"}
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      currentStep,
      showMap,
      zipCodes,
      selectedPloygons,
      showLoader,
      showError,
      errorMessage,
    } = this.state;
    return (
      <div>
        <section className="membership clearfix">
          <Loader text="Loading..." open={showLoader} />
          {showError && (
            <div className="modal is-active custom-modal error-message">
              <div className="modal-background"></div>
              <div className="modal-content">
                <div className="error-message-modal">
                  <button
                    onClick={this.clearError}
                    className="modal-close"
                  ></button>
                  <p>{errorMessage}</p>
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className={`modal mainn-map ${showMap ? "is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
              <button
                onClick={this.closePopup}
                className="delete"
                aria-label="close"
              ></button>
              <section className="modal-card-body">
                <h1>Select area of converage for this service</h1>
                <div className="mappp">
                  {zipCodes !== null && (
                    <Map
                      // eslint-disable-next-line react/style-prop-object
                      style={style}
                      containerStyle={mapStyle}
                      center={this.state.center}
                      onClick={this.handleClick}
                    >
                      {selectedPloygons.map((geoJson) => (
                        <GeoJSONLayer
                          key={geoJson.features[0].properties.zipCode}
                          data={geoJson}
                          fillPaint={{
                            "fill-color": "rgba(200, 100, 240, 0.4)",
                            "fill-outline-color": "rgba(0, 0, 0, 1)",
                          }}
                        />
                      ))}
                      <GeoJSONLayer
                        data={zipCodes}
                        fillPaint={{
                          "fill-color": "rgba(200, 100, 240, 0.0)",
                          "fill-outline-color": "rgba(0, 0, 0, 1)",
                        }}
                        symbolLayout={{
                          "text-field": "{zipCode}",
                          "text-font": [
                            "Open Sans Semibold",
                            "Arial Unicode MS Bold",
                          ],
                          "text-offset": [0, 0.6],
                          "text-anchor": "top",
                          "symbol-placement": "point",
                          "text-size": 10,
                        }}
                      />
                    </Map>
                  )}
                  <p>
                    Selected zip codes will be available at a single click when
                    selecting coverage for other services
                  </p>
                  <div className="map-buton">
                    <button onClick={this.closePopup} className="btn-fill">
                      Done
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="form-inner">
            <div className="logo-forms">
              <Link to="/">
                <img
                  src={require("../../assets/images/logo_colored.png")}
                  alt="logo"
                />
              </Link>
            </div>
            {currentStep === 0 ? this.renderFormC1() : this.renderFormC2()}
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Campaign);
