// Packages
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useSelector } from "react-redux";

// Components
import Loader from "../Loader/loader";
import Message from "../Message/Message";

// Constants
import { BASE_URL } from "../../Constants/serverConfig";
const EmailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
const blankEmailError = "Please enter email";
const invalidEmailError = "Please enter a valid email";
const choseTradeError = "Please choose a trade";
const successMessage = "Your invite was sent successfully.";
const notDataToSubmit = "No Data to Submit";
export default function Invite(props) {
  const token = useSelector((state) => state.token.token);
  const [showLoader, setShowLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [trade, setTrade] = useState(null);
  const [project, setProject] = useState("68-19 Woodhaven Blvd. Queens");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const clearMessage = (disableShowInvite = false) => {
    setShowMessage(false);
    if (disableShowInvite) props.setShowInvite(false);

    setMessage("");
  };

  const showAnyMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
  };

  const validateFields = () => {
    if (email.trim() === "") {
      showAnyMessage(blankEmailError);
      return false;
    }
    if (!EmailValidation.test(email.trim())) {
      showAnyMessage(invalidEmailError);
      return false;
    }
    if (trade === null) {
      showAnyMessage(choseTradeError);
      return false;
    }
    return true;
  };

  const removeMember = (index) => {
    const teamMem = [...teamMembers];
    teamMem.splice(index, 1);
    setTeamMembers([...teamMem]);
  };

  const addTeamMember = () => {
    const areFieldsValid = validateFields();
    if (!areFieldsValid) {
      return;
    } else {
      const user = {
        email_address: email,
        trade_name: trade.value,
        project_id: props.projectId,
      };
      setTeamMembers([...teamMembers, user]);
      setEmail("");
      setTrade(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (teamMembers.length === 0) {
      setShowMessage(true);
      setMessage(notDataToSubmit);
    } else {
      setShowLoader(true);
      const body = {
        receiver_list: teamMembers,
      };
      axios
        .post(`${BASE_URL}/project/invitation`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setShowLoader(false);
          if (response.status === 200) {
            setShowLoader(false);
            setShowMessage(true);
            setMessage(successMessage);
            setEmail("");
            setTrade(null);
            setProject("");
            setTeamMembers([]);
            props.populateInvitedUsers(props.projectId, false);
          }
        })
        .catch((error) => {
          setShowLoader(false);
          showMessage(error.response.data);
          console.error(error.response);
        });
    }
  };

  return (
    <>
      <Loader text="Loading..." open={showLoader} />

      <div className="services_main_top_head new_sep_dashboard">
        <div className="p_tabs" style={{ paddingTop: "0", marginBottom: "0" }}>
          <a className="p_tab_active" style={{ cursor: "default" }}>
            Projects Management
          </a>
        </div>
      </div>
      <div className={`modal ${props.showInvite ? "is-active" : ""} mainn-map`}>
        <div className="modal-background" />
        <div className="modal-card">
          <Message
            clearMessage={clearMessage}
            showMessage={showMessage}
            message={message}
          />
          <button
            onClick={() => {
              props.setShowInvite(false);
              props.setShowInvitedUsers(true);
            }}
            className="delete"
            aria-label="close"
          />
          <section className="modal-card-body home-modal">
            <div className="endos_panel2 clearfix">
              <h1>INVITE PEOPLE</h1>
              <hr />
              <div className="endorsment-form">
                <section className="modal-card-body">
                  <h2>
                    Timeline and deliverables invitation form from project{" "}
                    <i>{project}</i>
                  </h2>
                  <hr />
                  <div className="endos_panel2 clearfix">
                    <h1>PRE-DEFINED EMAIL</h1>
                    <p className="has-text-weight-normal">
                      Dear {"{{Trade name}}"} team
                    </p>
                    <br />
                    <p className="has-text-weight-normal">
                      This invitation provides you exclusive access to the admin
                      panel that enable you to manage timelines and deliverables
                      for {project}.
                    </p>
                    <br />
                    <p>
                      The system will require that you create a user name & a
                      password to provide you with future access to make
                      modifications as needed; it is easy to add, edit, remove
                      deliverables and assign dates, and there is no need for
                      further steps; the P.M. will be notified via email every
                      time a change is made.
                    </p>
                    <br />
                    <p>
                      The input of your {"{{Trade name}}"} deliverables with
                      corresponding timelines is critical for the proper
                      management of this project; I very much appreciate your
                      cooperation.
                    </p>
                    <br />
                    <p>Thank you,</p>
                    <br />
                    <hr />
                  </div>
                  <div className="endos_panel3 clearfix">
                    <h1>INVITE TEAM MEMBERS</h1>
                    <div className="columns">
                      <div className="column">
                        <div className="form-group">
                          <Select
                            styles={{
                              control: (base) => ({
                                ...base,
                                background: "#fcfcfc none repeat scroll 0 0",
                                border: "1px solid #ececec",
                              }),
                            }}
                            value={trade}
                            isClearable={true}
                            onChange={(e) => {
                              setTrade(e);
                            }}
                            options={[
                              "Architecture",
                              "MEP Engineering",
                              "Structural Engineer",
                            ].map((x) => {
                              return {
                                value: x,
                                label: x,
                              };
                            })}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              spacing: {
                                baseUnit: 4.84,
                                controlHeight: 46,
                                menuGutter: 9.68,
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="column">
                        <div className="form-group">
                          <input
                            type="email"
                            value={email}
                            name="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="column is-2">
                        <div className="form-group">
                          <button onClick={addTeamMember} className="add-btn">
                            <i className="fa fa-plus" /> ADD
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <h1>TEAM MEMBERS ADDED</h1>
                    <table>
                      <thead>
                        <tr>
                          <th>Sr. no</th>
                          <th>Trade Name</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.invitedUsers &&
                          teamMembers.map((member, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{member.trade_name}</td>
                                <td>{member.email_address}</td>
                                <td>
                                  <div onClick={() => removeMember(index)}>
                                    <i className="fa fa-trash" />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="map-buton clearfix">
                    <button onClick={handleSubmit} className="btn-fill">
                      Submit
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
