import React from "react";
import { Dialog, Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

const Loader = props => {
  const { text, classes, ...other } = props;
  return (
    <Dialog {...other}>
      <Grid
        container
        className={classes.content}
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <img alt="Loader" src={require("../../assets/images/loader.gif")} />
        <Typography
          style={{ textAlign: "center", marginTop: 15 }}
          variant="h5"
          component="h5"
        >
          {text}
        </Typography>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles)(Loader);
