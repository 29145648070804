/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import Loader from "../../Loader/loader.jsx";
import Reviews from "../../HelperComponents/Reviews/reviews.jsx";
import Endorsement from "../../HelperComponents/Endorsements/endorsement.jsx";
import { BASE_URL } from "../../../Constants/serverConfig.jsx";

class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      showError: false,
      errorMessage: "",
      articleWriting: false,
      qa: false,
      workInTeam: false,
      projectName: "68-19 Woodhaven Blvd. Queens",
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  savePreferences = () => {
    const { token, showSuccess } = this.props;
    const { articleWriting, qa, workInTeam } = this.state;
    if (token !== null) {
      const body = {
        write_article: articleWriting ? 1 : 0,
        replying_user: qa ? 1 : 0,
        join_team: workInTeam ? 1 : 0,
      };
      this.startLoader();
      axios
        .post(`${BASE_URL}/user-preference`, JSON.stringify(body), {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.stopLoader();
          if (response.status === 200) {
            showSuccess("Preferences updated successfully");
          }
        })
        .catch((error) => {
          this.stopLoader();
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  render() {
    const {
      servicesPromoted,
      token,
      showSuccess,
      clearMessage,
      serviceProvider,
    } = this.props;
    const {
      articleWriting,
      qa,
      workInTeam,
      showLoader,
      showError,
      errorMessage,
    } = this.state;
    return (
      <React.Fragment>
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="promo-main clearfix">
          <p className="has-text-justified">
            This Online reputation and promotional tools is designed to help you
            build the online reputation that will help customers trust you
            enough to contact you. It is a simple way to request endorsements
            from people you worked with in the past, and to request reviews from
            customers you served those specific services in the past.
          </p>
          <br />
          <p className="has-text-justified">
            Keep it relevant. We built a user ranking system aiming to protect
            the quality of offerings to both vendors (pros) and customers;
            keeping endorsements, reviews, portfolio and information most
            relevant will rank you first in search results, and will keep you
            out of trouble to be black listed or banned from using our services.
            Please refer to terms of use and conditions to make the best out of
            this system.
          </p>
          <br />
          <br />
          <Endorsement
            showSuccess={showSuccess}
            clearMessage={clearMessage}
            activeServices={servicesPromoted}
            token={token}
            hasMembership={
              serviceProvider !== null
                ? serviceProvider.membership_information.membership_name !==
                  undefined
                  ? true
                  : false
                : false
            }
            serviceProvider={serviceProvider}
          />
          <Reviews
            showSuccess={showSuccess}
            clearMessage={clearMessage}
            activeServices={servicesPromoted}
            token={token}
            hasMembership={
              serviceProvider !== null
                ? serviceProvider.membership_information.membership_name !==
                  undefined
                  ? true
                  : false
                : false
            }
            serviceProvider={serviceProvider}
          />
          <div className="clearfix"></div>
          <div className="promo_checkboxes_new">
            <div
              onClick={() => {
                this.setState({
                  articleWriting: !articleWriting,
                });
              }}
              className="checkkk clearfix"
            >
              <div
                onClick={(event) => {
                  event.preventDefault();
                }}
                className="checkbox"
              >
                <label
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  className={articleWriting ? "is-active-checkbox" : "checkbox"}
                >
                  <input
                    onChange={() => {}}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    checked={articleWriting}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>I would like to write
                  articles relevant to my expertise and publish them here
                </label>
              </div>
            </div>
            <div
              onClick={() => {
                this.setState({
                  qa: !qa,
                });
              }}
              className="checkkk clearfix"
            >
              <div
                onClick={(event) => {
                  event.preventDefault();
                }}
                className="checkbox"
              >
                <label
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  className={qa ? "is-active-checkbox" : "checkbox"}
                >
                  <input
                    onChange={() => {}}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    checked={qa}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>I would like to be part of
                  Q&A and answer customer questions via text
                </label>
              </div>
            </div>
            <div
              onClick={() => {
                this.setState({
                  workInTeam: !workInTeam,
                });
              }}
              className="checkkk clearfix"
            >
              <div
                onClick={(event) => {
                  event.preventDefault();
                }}
                className="checkbox"
              >
                <label
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  className={workInTeam ? "is-active-checkbox" : "checkbox"}
                >
                  <input
                    onChange={() => {}}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    checked={workInTeam}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>I would like to form teams
                  and be found online also as a team
                </label>
              </div>
            </div>
          </div>
          <button onClick={this.savePreferences} className="pf-submit">
            Submit
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Promotion;
