import { SAVE_TOKEN, REMOVE_TOKEN } from "../Constants/actionTypes.jsx";

const saveToken = (data) => {
  return {
    type: SAVE_TOKEN,
    payload: data,
  };
};

const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
    payload: null,
  };
};

export { saveToken, removeToken };
