import React, { Component } from "react";

class MetroAreaPicker extends Component {
  render() {
    const { handleCheckboxClick, selectedMetroAreas } = this.props;
    return (
      <div className="area-names">
        <ul>
          <li>
            <div onClick={() => handleCheckboxClick("NYMA")}>
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("NYMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>New York Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("LAMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("LAMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Los Angeles Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("CHMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("CHMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Chicago Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("DAMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("DAMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Dallas Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("HOMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("HOMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Houston Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("WAMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("WAMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Washington Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("MIMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("MIMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Miami Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("PHIMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("PHIMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Philadelphia Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("ATMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("ATMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Atlanta Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("BOMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("BOMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Boston Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("PHOMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("PHOMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Phoenix Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("SEMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("SEMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Seattle Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("DEMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("DEMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Denver Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("AUMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("AUMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Austin Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("SFMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("SFMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>San Francisco Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("ORMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("ORMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Orlando Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("TAMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("TAMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Tampa Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("CHAMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("CHAMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Charlotte Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("DETMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("DETMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Detroit Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("SAMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("SAMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>San Antonio Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("POMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("POMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Portland Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("SMMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("SMMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Sacramento Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("LVMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("LVMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Las Vegas Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("RSBMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("RSBMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Riverside-san Bernardino
                Metro Area
              </label>
            </div>
          </li>
          <li>
            <div
              //onClick={() => handleCheckboxClick("RDMA")}
              onClick={() => {}}
            >
              <label
                onClick={(event) => {
                  event.preventDefault();
                }}
                style={{ textAlign: "left" }}
              >
                <input
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  onChange={() => {}}
                  checked={selectedMetroAreas.includes("RDMA")}
                  type="checkbox"
                />
                <span className="checkmark"></span>Raleigh-Durham Research
                Triangle Metro Area
              </label>
            </div>
          </li>
        </ul>
        <div className="clearfix"></div>
        <p className="metro-imp">
          Note: ONLY{" "}
          <i>
            <strong>NEW YORK METROPOLITAN AREA</strong>
          </i>{" "}
          IS UNDER LAUNCH
        </p>
      </div>
    );
  }
}

export default MetroAreaPicker;
