/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import querystring from "querystring";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import Loader from "../Loader/loader";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      showError: false,
      errorMessage: "",
      showLoader: false,
    };
  }

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    const { location } = this.props;
    let token = querystring.parse(location.search)["?token"];
    if (!token) {
      this.props.history.push("/");
    }
  }

  resetPassword = (event) => {
    event.preventDefault();
    this.clearError();
    const { newPassword, confirmPassword } = this.state;
    const { location } = this.props;
    let token = querystring.parse(location.search)["?token"];
    if (newPassword !== confirmPassword) {
      this.showError(
        "Password Mismatch, Please verify that new password and confrim password are same."
      );
      return;
    }
    this.setState({
      showLoader: true,
    });
    axios
      .patch(`${BASE_URL}/entrance/password`, {
        password: newPassword,
        token: token,
      })
      .then((response) => {
        this.setState({
          showLoader: false,
        });
        if (response.status === 200) {
          this.props.history.push("/vendor/lead/login");
        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        });
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  render() {
    const {
      showError,
      errorMessage,
      newPassword,
      confirmPassword,
      showLoader,
    } = this.state;
    const {
      isSpLoggedIn,
      isUserLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            childRef={(ref) => (this.headerChildRef = ref)}
          />
        ) : (
          <NewHeader />
        )}
        <section className="form-wrapper">
          <div className="form-inner">
            <div className="form-main">
              <div className="form-title">
                <h3>Reset Password</h3>
                <p>Please set your new password.</p>
              </div>

              <div className="form-fields">
                <form onSubmit={this.resetPassword}>
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={newPassword}
                      name="newPassword"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={confirmPassword}
                      name="confirmPassword"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="login-btn">
                    <button type="submit" className="btn btn-default">
                      Reset
                    </button>
                  </div>
                </form>
                <div className="get-started">
                  Don't want to reset?{" "}
                  <Link to="/vendor/lead/login">Login Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!!isSpLoggedIn || !!isUserLoggedIn ? <Footer /> : <NewFooter />}
      </div>
    );
  }
}

export default withRouter(ResetPassword);
