import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRouter = ({
  component: Component,
  isLoggedIn,
  redirectionPath,
  classes,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component classes={classes} rest={rest} props={props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectionPath,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRouter;
