import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import { createStore } from "redux";
import { Provider } from "react-redux";
import "./index.css";
import App from "./Components/App.jsx";
import Reducers from "./Reducers/index.jsx";
import * as serviceWorker from "./serviceWorker";
import { ToastProvider } from "react-toast-notifications";

const store = createStore(Reducers);

ReactDOM.render(
  <StripeProvider apiKey="pk_live_D6acKRm2oyG2nOn7DGnJTlv300d5qmN16t">
    <Provider store={store}>
      <BrowserRouter>
        <ToastProvider>
          <App />
        </ToastProvider>
      </BrowserRouter>
    </Provider>
  </StripeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
