import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Home/dummyLandingImages/logo.png";
const NewHeader = ({ metroArea }) => {

  const [showAlert, setShowAlert] = useState(false); // initialize state



  const showMessageAlert = () => {
    setShowAlert(!showAlert);
  }

  const navigateToPage = (route) => {
    setShowAlert(!showAlert);
    window.location.replace('https://www.propagile.com/building-construction-teams/' + route);
  }

  return (
    <>
      {showAlert && (
        <div className="modal is-active custom-modal error-message">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="error-message-modal" style={{ backgroundColor: '#ECECEC', border: '1px solid black', borderRadius: '5px' }}>
              <button
                onClick={showMessageAlert}
                className="modal-close"
              ></button>
              <p style={{ fontStyle: 'normal', fontWeight: '400' }}>I am a:</p>
              <a onClick={() => navigateToPage('builders')} className="btn-fill-menu">
                Builder
              </a>
              <a onClick={() => navigateToPage('customers')} className="btn-fill-menu">
                Customer
              </a>
            </div>
          </div>
        </div>
      )}
      <header className="site-header" id="siteHeader">
        <section className="land-main-menu">
          <div className="container">
            <nav className="navbar flex-column flex-lg-row justify-content-between align-items-center">
              <Link to="/">
                <a className="navbar-brand" href="javascript:void(0);">
                  <span>
                    <img
                      alt="PROP AGLIE"
                      className="img-fluid d-block"
                      src={logo}
                    />
                  </span>

                  <div style={{display: "flex",justifyContent: "space-between",flexDirection: "column"}}>
                    <span style={{color: "white",fontSize: "22px",marginBottom: "2px"}}>PropAgile</span>
                    <span style={{fontSize: '13px',color: '#fcc75c'}}>Building Construction Connections</span>
                  </div>
                </a>
              </Link>
              {/* <div className="navbar-text">
                A Marketplace For Everyone In Construction{" "}
              </div> */}

              <div className="d-flex flex-row navbar-build-menu">
                {/* <Link >Services</Link> */}
                <Link onClick={showMessageAlert} style={{ fontSize: "1rem" }} >Construction Teams</Link>
                {/* <Link >Builder AM</Link> */}
                {/* <Link onClick={()=>window.location.replace('https://www.propagile.com/blog')} style={{ fontSize: "1rem" }}>Blog</Link> */}
              </div>
              <div className="navbar-buttons">
                <Link onClick={() => window.location.replace('https://www.propagile.com/building-construction-teams/customers')}>
                  <a className="navbar-buttons-link" href="javascript:void(0);">
                    CUSTOMERS
                  </a>
                </Link>

                <Link
                  to={
                    metroArea === undefined
                      ? "/vendor/lead/login"
                      : `/vendor/login?metro=${metroArea}`
                  }
                >
                  <a className="navbar-buttons-link">BUILDERS LOGIN</a>
                </Link>
              </div>
            </nav>
          </div>
        </section>
      </header>
    </>
  );
};

export default NewHeader;
