import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  InputLabel,
  FormLabel,
} from "@material-ui/core";
import Select from "react-select";
import { Formik, Form } from "formik";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import {
  leadershipFunctionOptions,
  workFunctionOptions,
} from "../Dashboard/utils/commons";
import * as Yup from "yup";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const formValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  zip: Yup.string().required("Required"),
  workFunction: Yup.string().required("Required"),
  leadershipFunction: Yup.string().required("Required"),
  trade1: Yup.string().required("Required"),
  reCaptcha: Yup.string().required("Required"),
});

const BaseForm = ({ classes, open, setOpen }) => {
  const { addToast } = useToasts();
  const [initialValues] = useState({
    name: "",
    email: "",
    zip: "",
    workFunction: "",
    leadershipFunction: "",
    trade1: "",
    trade2: "",
    trade3: "",
    reCaptcha: "",
  });
  const [tradeOptions, setTradeOptions] = useState([]);
  const [allTradeOptions, setAllTradeOptions] = useState([]);
  const [secondaryTradeOptions, setSecondaryTradeOptions] = useState([]);
  const [tertiaryTradeOptions, setTertiaryTradeOptions] = useState([]);
  const [disableSecondSelect, setDisableSecondSelect] = useState(true);
  const [disableThirdSelect, setDisableThirdSelect] = useState(true);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [trade1, setTrade1] = useState(null);
  const [trade2, setTrade2] = useState(null);
  const [trade3, setTrade3] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://api.propagile.com/api/v1/items-wbs-branching/masterformat?name=`
      )
      .then((res) => {
        console.log("res => ", res);
        if (res.status === 200) {
          setAllTradeOptions(res.data);
          setTradeOptions(
            res.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          );
        } else {
          addToast("Failed to retrieve trade options.", {
            appearance: "error",
          });
        }
      })
      .catch((err) => {
        console.log("err => ", err);
      });
  }, []);

  const handleSubmit = (values) => {
    setOpen(false);

    let _trade1 = null;
    let _trade2 = null;
    let _trade3 = null;
    if (!!trade1) {
      _trade1 = allTradeOptions.filter((item) => item.id === trade1.value);
    }
    if (!!trade2) {
      _trade2 = secondaryTradeOptions.filter(
        (item) => item.value === trade2.value
      );
    }
    if (!!trade3) {
      _trade3 = tertiaryTradeOptions.filter(
        (item) => item.value === trade3.value
      );
    }
    const body = {
      name: values.name,
      email: values.email,
      zip: values.zip,
      trade: [
        { id: _trade1[0].id, name: _trade1[0].name },
        {
          id: !!_trade2 ? _trade2[0].value : null,
          name: !!_trade2 ? _trade2[0].label : null,
        },
        {
          id: !!_trade3 ? _trade3[0].value : null,
          name: !!_trade3 ? _trade3[0].label : null,
        },
      ],
      job_functions: [values.workFunction, values.leadershipFunction],
      "g-recaptcha-response": values.reCaptcha,
      landing: "Friends",
    };

    console.log("body before posting => ", body);

    axios
      .post(
        `https://api.propagile.com/api/v1/coupon-management/coupon-request`,
        body
      )
      .then((res) => {
        console.log("Data posted!", res);
        addToast("Saved!", {
          appearance: "success",
        });
      })
      .catch((err) => {
        console.log("Error while posting data", err);
        addToast("Could not be saved!", {
          appearance: "error",
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const handleTrade1 = (e) => {
          setTrade1({ value: e.value, label: e.label });
          setTrade2(null);
          setTrade3(null);
          setTertiaryTradeOptions([]);
          formikProps.setFieldValue(`trade1`, e.value);
          let temp = [];
          const opt = allTradeOptions.filter((item) => item.id === e.value);
          setSelectedTrade(opt[0].name);
          if (opt.length > 0 && opt[0].children.length > 0) {
            opt[0].children.map((item) => {
              temp = [
                ...temp,
                {
                  value: item.id,
                  label: item.name,
                  children: item.children,
                  masterformat_code: item.masterformat_code,
                },
              ];
            });
            setSecondaryTradeOptions(temp);
            setDisableSecondSelect(false);
          }
        };

        const handleTrade2 = (e) => {
          setTrade2({ value: e.value, label: e.label });
          setTrade3(null);
          formikProps.setFieldValue(`trade2`, e.value);
          let temp = [];
          const opt = secondaryTradeOptions.filter(
            (item) => item.value === e.value
          );
          setSelectedTrade(opt[0].label);
          if (opt.length > 0 && opt[0].children.length > 0) {
            opt[0].children.map((item) => {
              temp = [
                ...temp,
                {
                  value: item.id,
                  label: item.name,

                  masterformat_code: item.masterformat_code,
                },
              ];
            });
            setTertiaryTradeOptions(temp);
            setDisableThirdSelect(false);
          }
        };

        const handleTrade3 = (e) => {
          setTrade3({ value: e.value, label: e.label });
          formikProps.setFieldValue(`trade3`, e.value);
          const opt = tertiaryTradeOptions.filter(
            (item) => item.value === e.value
          );
          setSelectedTrade(opt[0].label);
        };

        const handleClearTrade = () => {
          setTrade1("");
          setTrade2("");
          setTrade3("");
          setSelectedTrade(null);
          formikProps.setFieldValue(`trade1`, "");
          formikProps.setFieldValue(`trade2`, "");
          formikProps.setFieldValue(`trade3`, "");
          setSecondaryTradeOptions([]);
          setTertiaryTradeOptions([]);
        };

        return (
          <Form>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setOpen(false)}
            >
              <DialogTitle style={{ position: "relative" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#fcc75c",
                    fontSize: "28px",
                    fontWeight: "700",
                  }}
                >
                  {"Request Invitation Coupon"}
                </Typography>
                <HighlightOffIcon
                  onClick={() => setOpen(false)}
                  style={{
                    position: "absolute",
                    right: 15,
                    top: 25,
                    color: "#1a67a3",
                    cursor: "pointer",
                  }}
                />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Grid container spacing={3} type={{ padding: "10px 30px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.formikInput}
                        label="Name"
                        name="name"
                        variant="outlined"
                        onChange={formikProps.handleChange}
                      />
                      {formikProps.errors.name && formikProps.touched.name ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.name}
                        </FormLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.formikInput}
                        label="Email"
                        name="email"
                        variant="outlined"
                        onChange={formikProps.handleChange}
                      />
                      {formikProps.errors.email && formikProps.touched.email ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.email}
                        </FormLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.formikInput}
                        label="Zip"
                        name="zip"
                        variant="outlined"
                        onChange={formikProps.handleChange}
                      />
                      {formikProps.errors.zip && formikProps.touched.zip ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.zip}
                        </FormLabel>
                      ) : null}
                    </Grid>

                    {/* Job Funtions Select Fields */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel style={{ fontWeight: "bold" }}>
                        Job Functions:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Select
                        className={classes.formikInput}
                        variant="outlined"
                        name="workFunction"
                        onChange={(e) =>
                          formikProps.setFieldValue(`workFunction`, e.value)
                        }
                        options={workFunctionOptions}
                      />
                      {formikProps.errors.workFunction &&
                      formikProps.touched.workFunction ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.workFunction}
                        </FormLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Select
                        className={classes.formikInput}
                        variant="outlined"
                        name="leadershipFunction"
                        onChange={(e) =>
                          formikProps.setFieldValue(
                            `leadershipFunction`,
                            e.value
                          )
                        }
                        options={leadershipFunctionOptions}
                      />
                      {formikProps.errors.leadershipFunction &&
                      formikProps.touched.leadershipFunction ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.leadershipFunction}
                        </FormLabel>
                      ) : null}
                    </Grid>

                    {/* Trade Select Fields */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel>Trade</InputLabel>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.selectedTradeField}>
                        {!selectedTrade ? "No Trade Selected" : selectedTrade}
                        <button
                          onClick={handleClearTrade}
                          style={{
                            justifyContent: "flex-end",
                            border: "none",
                            backgroundColor: "inherit",
                            padding: "6px 12px",
                            cursor: "pointer",
                          }}
                        >
                          x
                        </button>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Select
                        className={classes.formikInput}
                        variant="outlined"
                        name="trade1"
                        onChange={(e) => handleTrade1(e)}
                        options={tradeOptions}
                        value={trade1}
                      />
                      {formikProps.errors.trade1 &&
                      formikProps.touched.trade1 ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.trade1}
                        </FormLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Select
                        disabled={disableSecondSelect}
                        className={classes.formikInput}
                        variant="outlined"
                        name="trade2"
                        onChange={(e) => handleTrade2(e)}
                        options={secondaryTradeOptions}
                        value={trade2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Select
                        disabled={disableThirdSelect}
                        className={classes.formikInput}
                        variant="outlined"
                        name="trade3"
                        onChange={(e) => handleTrade3(e)}
                        options={tertiaryTradeOptions}
                        value={trade3}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} style={{ padding: "10px 0px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <ReCAPTCHA
                        name="reCaptcha"
                        sitekey="6LcONKEUAAAAAHy_z3x58uTMQFYFFSVd1KRjtCE7"
                        onChange={(e) =>
                          formikProps.setFieldValue(`reCaptcha`, e)
                        }
                      />
                      {formikProps.errors.reCaptcha &&
                      formikProps.touched.reCaptcha ? (
                        <FormLabel style={{ color: "red" }}>
                          {formikProps.errors.reCaptcha}
                        </FormLabel>
                      ) : null}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className={classes.formButtons}
                  type="button"
                  onClick={() => formikProps.submitForm()}
                >
                  Request Invitation
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BaseForm;
