import {
  SAVE_SERVICE_PROVIDER,
  REMOVE_SERVICE_PROVIDER,
} from "../Constants/actionTypes.jsx";

const initialState = {
  serviceProvider: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SERVICE_PROVIDER:
      return Object.assign({}, state, {
        serviceProvider: action.payload,
      });
    case REMOVE_SERVICE_PROVIDER:
      return Object.assign({}, state, {
        serviceProvider: action.payload,
      });
    default:
      return state;
  }
};
