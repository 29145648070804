import React, { Component } from "react";
import { connect } from "react-redux";
//import axios from "axios";
// import Search from "../Search/search.jsx";
import SampleServices from "../SampleServices/sample-services.jsx";
import Header from "../Header/header.jsx";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader.jsx";
import { Box } from "@material-ui/core";

class Home extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     metroArea: ""
  //   };
  // }

  // componentDidMount() {
  //   axios
  //     .get("http://ip-api.com/json")
  //     .then(response => {
  //       if (response.data.regionName !== "New York") {
  //         this.setState({
  //           outside: true
  //         });
  //       } else {
  //         this.setState({
  //           outside: false
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.isUserLoggedIn !== nextProps.isUserLoggedIn ||
      this.props.isSpLoggedIn !== nextProps.isSpLoggedIn ||
      this.props.serviceProvider !== nextProps.serviceProvider
    );
  }

  render() {
    const {
      isUserLoggedIn,
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
    } = this.props;
    console.log("this.props", this.props);
    return (
      <div>
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            metroArea="NYMA"
            isUserLoggedIn={isUserLoggedIn}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
            location={this.props.location}
          />
        ) : (
          <NewHeader />
        )}
        {/* <Search /> */}
        <div style={{ minHeight: "85vh", padding: 20 }}>
          <div className="columns mt-10">
            <div className="column is-10 is-offset-1">
              <p className="title is-1 has-text-black has-text-centered">
                Find all service providers for Multifamily projects
              </p>
              <p className="subtitle is-4 has-text-grey-dark has-text-centered">
                Individuals, Companies, Beginners, Experts, Licensed,
                Unlicensed, Find anyone!
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="What service are you looking for?"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SampleServices />
        <Box style={{ marginTop: "auto" }}>
          <Footer />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default connect(mapStateToProps, null)(Home);
