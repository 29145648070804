import {
  SAVE_SERVICE_PROVIDER,
  REMOVE_SERVICE_PROVIDER,
} from "../Constants/actionTypes.jsx";

const saveServiceProvider = (data) => {
  return {
    type: SAVE_SERVICE_PROVIDER,
    payload: data,
  };
};

const removeServiceProvider = () => {
  return {
    type: REMOVE_SERVICE_PROVIDER,
    payload: null,
  };
};

export { saveServiceProvider, removeServiceProvider };
