import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import GetInTouchForm from "./GetInTouchForm";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  footerBanner: {
    backgroundColor: "#1a67a3", //"#1a67a3",
  },
  responsiveGridFirst: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  responsiveGridLast: {
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  blobBtn: {
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "500",
    color: "#fcc75c",
    padding: "9px 16px",
    lineHeight: "normal",
    borderRadius: "7px",
    width: "auto",
    margin: "0",
    border: "solid 1px #fcc75c",
    "&:hover": {
      // transform: " translateZ(0) scale(1.1)",
      // transition: "transform 0.45s",
      background: "#fcc75c",
      transition: "0.3s",
      color: "#1a67a3", //1a67a3",
    },
  },
  socialLinkBtn: {
    color: "#b0b3b8",
    fontSize: "17px",
    background: "#201e3e",
    width: "30px",
    height: "30px",
    padding: "5px 3px",
    borderRadius: "5px",
    display: "block",
    lineHeight: "normal",
    "&:hover": {
      color: "white",
    },
  },
  socialBox: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    textAlign: "center",
    justifyContent: "center",
    listStyle: "none"

  },
  textWhite: {
    color: "white",
  },
}));
const Footer = () => {
  const classes = styles();
  const [openGetInTouchModal, setOpenGetInTouchModal] = useState(false);
  /*   const handleCloseModal = () => {
    setOpenGetInTouchModal(false);
  }; */
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ marginTop: "auto", padding: "10px 30px" }}
        className={classes.footerBanner}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className={classes.responsiveGridFirst}
        >
          <Button
            onClick={() => setOpenGetInTouchModal(true)}
            className={classes.blobBtn}
          >
            Get in Touch
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{
            alignSelf: "center",
          }}
        >
          <ul className={classes.socialBox}>
            <li>
              <a
                className={classes.socialLinkBtn}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Propagile"
              >
                <i className="fab fa-facebook" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                className={classes.socialLinkBtn}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.pinterest.com/propagile/"
              >
                <i className="fab fa-pinterest" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                className={classes.socialLinkBtn}
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/PropAgile"
              >
                <i className="fab fa-twitter" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                className={classes.socialLinkBtn}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/propagile"
              >
                <i className="fab fa-linkedin-in" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                className={classes.socialLinkBtn}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/propagile/"
              >
                <i className="fab fa-instagram" aria-hidden="true" />
              </a>
            </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className={classes.responsiveGridLast}
        >
          <Typography className={classes.textWhite}>
            <span className={classes.textWhite}>
              {moment().format("YYYY")} Propagile{" "}
            </span>{" "}
            /{" "}
            <Link className={classes.textWhite} to="/terms">
              Terms of Use
            </Link>{" "}
            /
            <Link className={classes.textWhite} to="/privacy-policy">
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {!!openGetInTouchModal && (
        <GetInTouchForm
          openGetInTouchModal={openGetInTouchModal}
          handleCloseModal={() => setOpenGetInTouchModal(false)}
        />
      )}
    </>
  );
};
export default Footer;
